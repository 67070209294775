import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Axios from 'axios';

import {
  Box, Button, Dialog, FormHelperText, FormControl, IconButton, InputLabel, MenuItem,
  Select, TextField, Tooltip, Typography
} from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import { CustomError } from 'src/types';
import styles from './style';

interface PropTypes {
  open: boolean;
  editUser: Record<string, string> | null;
  handleClose: () => void;
  onGetAllUsers: () => Promise<void>;
}

type UserRoleType = {
  role: string;
  status: string;
  description: string;
}

const EditUserModal = (props: PropTypes) => {
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { API } = useConfig();

  const { open, editUser, handleClose, onGetAllUsers } = props;

  const [submitError, setSubmitError] = useState<string>('');
  const [userRoles, setUserRoles] = useState<UserRoleType[]>([]);

  const fetchActiveUserRoles = async () => {
    try {
      const response = await Axios.get(`${API.getUserRoles}?status=active`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.data) {
        setUserRoles(response.data.data);
      }
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    fetchActiveUserRoles();
  }, []);
  
  return (
    <Dialog
      open={open}
      sx={styles.modal}
      onClose={handleClose}
    >
      <Box sx={{ width: '600px'}}>
        <Formik
          initialValues={{
            id: editUser?.id,
            email: editUser?.email,
            firstname: editUser?.firstname,
            lastname: editUser?.lastname,
            userRole: editUser?.userRole || '',
            status: editUser?.status || '',
          }}
          validationSchema={Yup.object().shape({
            status: Yup.string().max(255),
            firstname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_FIRSTNAME_REQ')),
            lastname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_LASTNAME_REQ')),
          })}
          onSubmit={async (values, {
            setStatus,
            setSubmitting
          }) => {
            try {
              // Make API request
              const body = {
                id: values?.id?.toString(),
                role: values.userRole,
                status: values.status,
                firstname: values.firstname,
                lastname: values.lastname,
                company: user?.companyID,
              };
              const response = await Axios.put(
                API.updateUserByCreator,
                body,
                axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
              );
              if (response.data.success) {
                onGetAllUsers();
                handleClose();
                setStatus({ success: true });
                setSubmitting(false);
                enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_EDIT_SUCCESS'), {
                  variant: 'success',
                });
              } else {
                setSubmitting(false);
              }
            } catch (e) {
              const error = e as CustomError;
              setSubmitError(appendContactSupport(
                window.config.support_email,
                getLocalisedErrorString(error?.response?.data?.i18n || 'ACCOUNT_SETTINGS_USERS_EDIT_FAILURE', t),
                t
              ));
              setStatus({ success: false });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <form
              style={styles.root}
              onSubmit={handleSubmit}
            >
              <Box sx={styles.modalHeader}>
                <Box sx={styles.titleContainer}>
                  <Typography sx={styles.headerTitle}>
                    {t('ACCOUNT_SETTINGS_USERS_ADD_NEW_USER')}
                  </Typography>
                  <IconButton sx={styles.closeBtn} onClick={handleClose}>
                    <CloseIcon sx={styles.closeIcon} />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={styles.modalContent}>
                <Box sx={styles.userForm}>
                  <Box sx={styles.fieldContainer}>
                    <TextField
                      fullWidth
                      label={ready && t('ACCOUNT_SETTINGS_USERS_EMAIL')}
                      name="email"
                      required
                      value={values.email}
                      variant="outlined"
                      sx={styles.textField}
                      disabled
                    />
                  </Box>
                  <Box style={styles.fieldContainer}>
                    <TextField
                      error={Boolean(touched.firstname && errors.firstname)}
                      fullWidth
                      helperText={touched.firstname && errors.firstname}
                      label={ready && t('ACCOUNT_SETTINGS_USERS_FIRSTNAME')}
                      name="firstname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.firstname}
                      variant="outlined"
                      sx={styles.textField}
                    />
                  </Box>
                  <Box sx={styles.fieldContainer}>
                    <TextField
                      error={Boolean(touched.lastname && errors.lastname)}
                      fullWidth
                      helperText={touched.lastname && errors.lastname}
                      label={ready && t('ACCOUNT_SETTINGS_USERS_LASTNAME')}
                      name="lastname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.lastname}
                      variant="outlined"
                      sx={styles.textField}
                    />
                  </Box>
                  <Box sx={styles.fieldContainer}>
                    <FormControl variant="outlined" sx={styles.formControl}>
                      <InputLabel id="user-role-label">{ready && t('ACCOUNT_SETTINGS_USERS_USER_ROLE')}</InputLabel>
                      <Select
                        labelId="user-role-label"
                        id="user-role"
                        value={values.userRole}
                        onChange={handleChange}
                        name="userRole"
                        label={ready && t('ACCOUNT_SETTINGS_USERS_USER_ROLE')}
                        sx={styles.selectInput}
                      >
                        {
                          userRoles && userRoles.length > 0 && userRoles.map((r) => (
                            <MenuItem key={r.role} value={r.role} sx={styles.menuItem}>
                              {r.role}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Box>
                  <Box style={styles.fieldContainer}>
                    <FormControl variant="outlined" sx={styles.formControl}>
                      <InputLabel id="user-status-label">{ready && t('ACCOUNT_SETTINGS_USERS_USER_STATUS')}</InputLabel>
                      <Select
                        labelId="user-status-label"
                        id="user-status"
                        defaultValue={values.status}
                        onChange={handleChange}
                        name="status"
                        label={ready && t('ACCOUNT_SETTINGS_USERS_USER_STATUS')}
                        sx={styles.selectInput}
                        disabled
                      >
                        <MenuItem key="Active" value="Active" sx={styles.menuItem}>
                          {t('ACCOUNT_SETTINGS_USERS_ACTIVE')}
                        </MenuItem>
                        <MenuItem key="Inactive" value="Inactive" sx={styles.menuItem}>
                          {t('ACCOUNT_SETTINGS_USERS_INACTIVE')}
                        </MenuItem>
                        <MenuItem key="Invited" value="Invited" sx={styles.menuItem}>
                          {t('ACCOUNT_SETTINGS_USERS_INVITED')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {submitError && (
                    <Box mt={3}>
                      <FormHelperText error>
                        {submitError}
                      </FormHelperText>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box sx={styles.modalFooter}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    sx={styles.cancelBtn}
                    onClick={handleClose}
                  >
                    {t('ACCOUNT_SETTINGS_USERS_CANCEL')}
                  </Button>
                  <Tooltip
                    title={
                      ((values.email === user?.email) && user?.userRole !== values.userRole)
                        ? t('ACCOUNT_SETTINGS_USERS_CANNOT_CHANGE_ROLE') : ''
                    }
                  >
                    <span>
                      <Button
                        variant="contained"
                        sx={styles.submitBtn}
                        type="submit"
                        disabled={isSubmitting || ((values.email === user?.email) && user?.userRole !== values.userRole)}
                      >
                        {ready && t('ACCOUNT_SETTINGS_USERS_CONFIRM')}
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
}

export default EditUserModal;
