import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
// import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';

import {
  Backdrop, Fade, Grid, IconButton, Modal, Typography
} from '@mui/material';
import {
  Close as CloseIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import {
  ERRORS, glAccountData
} from 'src/config';
import {
  axiosHeaders, getLocalisedErrorString, isActionPermitted, permissions
} from 'src/utils/helpers';
import authService from 'src/utils/authService';
import ConfigContext from 'src/contexts/ConfigContext';
import GLAccountsTwinfield from 'src/dimensions/glas/components/GLAccountModal/GLAccountsTwinfield';
import GLAccountsStandard from 'src/dimensions/glas/components/GLAccountModal/GLAccountsStandard';
import VatCodeModal from 'src/dimensions/vatcodes/components/VatCodeModal/VatCodeModal';
import styles from './style';

function GLAccountModal({
  gLAccountModalOpen,
  selectedGLA,
  glaYears,
  glaPeriods,
  handleClose,
  getAllGLAccounts,
  getVatCodes,
  onSuccess,
}) {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);

  const auth = useAuth();
  const {user} = auth;
  
  const vatCodes = useSelector((state) => [...glAccountData.vat, ...state.vatcode.vatCodes]);

  const [years, setYears] = useState(glAccountData.year);
  const [endYears, setEndYears] = useState(glAccountData.endYear);
  const [periods, setPeriods] = useState([{ code: 0, name: `${t('GLA_PERIOD')}_0` }]);

  const [vatCodeModalOpen, setVatCodeModalOpen] = useState(false);
  const [selectedVATGroupCode, setSelectedVATGroupCode] = useState(null);

  const [newlyAddingProp, setNewlyAddingProp] = useState(null);
  const [newlyAddedVal, setNewlyAddedVal] = useState(null);

  const getGLAYears = (data) => {
    if (data) {
      const beginFirst = parseInt(data.beginFirst, 10);
      const beginLast = parseInt(data.beginLast, 10);
      const endFirst = parseInt(data.endFirst, 10);
      const endLast = parseInt(data.endLast, 10);

      const yearsAr = [{
        code: glAccountData.year[0].code,
        name: t(glAccountData.year[0].name),
      }];
      for (let i = beginFirst; i <= beginLast; i++) {
        const iStr = i.toString();
        yearsAr.push({
          code: iStr,
          name: `${t('GLA_YEAR_LABEL')} ${iStr}`
        });
      }

      const endYearsAr = [{
        code: glAccountData.endYear[0].code,
        name: t(glAccountData.endYear[0].name),
      }];
      for (let i = endFirst; i <= endLast; i++) {
        const iStr = i.toString();
        endYearsAr.push({
          code: iStr,
          name: `${t('GLA_YEAR_LABEL')} ${iStr}`
        });
      }
      setYears(yearsAr);
      setEndYears(endYearsAr);
    }
  };

  const getGLAPeriods = (data) => {
    if (data) {
      const periodsAr = [];
      for (let i = 0; i < data?.length; i++) {
        const p = data[i];
        periodsAr.push({
          code: p.position || 0,
          name: `${t('GLA_PERIOD')}_${p.position || 0}`
        });
      }

      setPeriods(periodsAr.length > 0 ? periodsAr : [{ code: 0, name: `${t('GLA_PERIOD')}_0` }]);
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionEdit, user.permissions)) {
      getGLAYears(glaYears);
      getGLAPeriods(glaPeriods);
    }
  }, []);

  const hasError = (fields, errors) => {
    for (let i = 0; i < fields.length; i++) {
      if (errors[fields[i].key]) {
        // Returning true until Twinfield integration
        return false;
      }
    }
    return false;
  };

  const handleChangeAutocomplete = (e, prop, values, handleChange) => {
    if (values && values.code) {
      handleChange({ target: { name: prop, value: values.code } });
      return;
    }

    handleChange(e, prop);
  };

  const getLabel = (entities, code) => {
    for (let i = 0; i < entities?.length; i++) {
      if (code === entities[i].code) {
        let namePart = '';
        if (entities[i].name) {
          namePart = ` | ${entities[i].name}`;
        }
        return `${entities[i].code}${namePart}`;
      }
    }

    return code;
  };

  const onVatCoddeModalClose = () => {
    setVatCodeModalOpen(false);
    setSelectedVATGroupCode(null);
  };

  const handleEditVATCode = (id) => {
    if (id !== glAccountData.vat[0].code) {
      setSelectedVATGroupCode(vatCodes.find((v) => v.code === id));
      setVatCodeModalOpen(true);
    }
  };

  const handleAddVATCode = (prop) => {
    setSelectedVATGroupCode(null);
    setNewlyAddedVal(null);
    setVatCodeModalOpen(true);
    setNewlyAddingProp(prop);
  };

  const onVatCodeCreated = (val) => {
    setNewlyAddedVal(val);
  };

  return (
    <>
      <Modal
        style={styles.modal}
        open={gLAccountModalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
      >
        <Fade in={gLAccountModalOpen}>
          <div style={user.interfacePreference === 'twinfield_interface' ? styles.paper : styles.halfPaper}>
            <Grid style={styles.modalHeader}>
              <Typography style={styles.headerTitle}>
                {selectedGLA?.name ? `${t('GLA_TITLE')} - ${selectedGLA ? selectedGLA.name : ''} (${selectedGLA?.code})` : t('GLA_ADD_NEW_GLA')}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={styles.closeIcon} />
              </IconButton>
            </Grid>
            <Formik
              initialValues={{
                code: selectedGLA?.code || '',
                name: selectedGLA?.name || '',
                fromYear: selectedGLA?.fromYear || '',
                fromPeriod: selectedGLA?.fromPeriod || '',
                endYear: selectedGLA?.endYear || '',
                endPeriod: selectedGLA?.endPeriod || '',
                accountNumber: selectedGLA?.accountNumber || '',
                accountHolder: selectedGLA?.accountHolder || '',
                furtherAnalysis: selectedGLA?.furtherAnalysis || glAccountData.furtherAnalysis[2].code,
                matchable: selectedGLA?.matchable || 'no',
                vat: selectedGLA?.vat || glAccountData.vat[0].code,
                vatType: selectedGLA?.vatType || glAccountData.vat[0].code,
                performanceType: selectedGLA?.performanceType || glAccountData.performanceType[0].code,
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string().max(255).matches('^\\S*$', 'GLA_CODE_SPACES_NOT_ALLOWED')
                  .when('name', {
                    is: () => user.interfacePreference === 'twinfield_interface',
                    then: Yup.string().max(255).matches('^\\S*$', 'GLA_CODE_SPACES_NOT_ALLOWED').required('GLA_CODE_REQUIRED')
                  }),
                name: Yup.string().max(80, 'GLA_NAME_MAX_VALIDATION').required('GLA_NAME_REQUIRED'),
                matchable: Yup.string().max(255).required('GLA_MATCHABLE_REQUIRED'),
              })}
              onSubmit={async (values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
              }) => {
                try {
                  if (!authService.validateToken()) {
                    enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
                      variant: 'error',
                    });
                    setTimeout(() => {
                      authService.logout(LOGIN_PANEL_URL);
                    }, 2000);
                    return;
                  }

                  const body = {
                    code: values.code,
                    name: values.name,
                    accountType: selectedGLA ? selectedGLA.accountType : 'pnl',
                    fromYear: values.fromYear,
                    fromPeriod: values.fromPeriod.toString(),
                    endYear: values.endYear,
                    endPeriod: values.endPeriod.toString(),
                    accountNumber: values.accountNumber,
                    accountHolder: values.accountHolder,
                    furtherAnalysis: values.furtherAnalysis,
                    matchable: values.matchable,
                    vat: values.vat,
                    vatType: values.vatType,
                    performanceType: values.performanceType,
                  };

                  const url = `${API.glAccountsByCompany}/${encodeURIComponent(user.companyID)}`;
                  let response = null;
                  let snackText = '';

                  if (selectedGLA?.name) {
                    response = await Axios.put(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
                    snackText = 'GLAS_UPDATE_SUCCESS';
                  } else {
                    response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
                    snackText = 'GLAS_ADD_SUCCESS';
                  }
                  if (response?.data?.success) {
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar(ready && t(snackText), {
                      variant: 'success',
                      style: { maxWidth: 400 }
                    });
                    getAllGLAccounts();
                    handleClose();
                    if (onSuccess) {
                      onSuccess(response.data.id);
                    }
                  }
                } catch (error) {
                  setStatus({ success: false });
                  setSubmitting(false);
                  if (error.response.data.i18n.indexOf('__TFMSG__') > 0) {
                    const errParts = error.response.data.i18n.split('__TFMSG__');
                    setErrors({
                      [errParts[0]]: errParts[1]
                    });
                  } else {
                    setErrors({
                      submit: getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t)
                    });
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setErrors
              }) => (
                user.interfacePreference === 'twinfield_interface'
                  ? (
                    <GLAccountsTwinfield
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      isSubmitting={isSubmitting}
                      touched={touched}
                      values={values}
                      setErrors={setErrors}
                      glAccount={selectedGLA}
                      handleClose={handleClose}
                      hasError={hasError}
                      years={years}
                      endYears={endYears}
                      periods={periods}
                      handleChangeAutocomplete={handleChangeAutocomplete}
                      getLabel={getLabel}
                      vatCodes={vatCodes}
                      onSuccess={onSuccess}
                      newlyAddedVal={newlyAddedVal}
                      newlyAddingProp={newlyAddingProp}
                      handleEditVATCode={handleEditVATCode}
                      handleAddVATCode={handleAddVATCode}
                    />
                  ) : (
                    <GLAccountsStandard
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      isSubmitting={isSubmitting}
                      touched={touched}
                      values={values}
                      glAccount={selectedGLA}
                      handleClose={handleClose}
                      hasError={hasError}
                      years={years}
                      endYears={endYears}
                      periods={periods}
                      handleChangeAutocomplete={handleChangeAutocomplete}
                      getLabel={getLabel}
                      vatCodes={vatCodes}
                      onSuccess={onSuccess}
                      newlyAddedVal={newlyAddedVal}
                      newlyAddingProp={newlyAddingProp}
                      handleEditVATCode={handleEditVATCode}
                      handleAddVATCode={handleAddVATCode}
                    />
                  )
              )}
            </Formik>
          </div>
        </Fade>
      </Modal>
      <VatCodeModal
        vatCodeModalOpen={vatCodeModalOpen}
        handleClose={onVatCoddeModalClose}
        getAllVatCodes={getVatCodes}
        onSuccess={onVatCodeCreated}
        selectedVatCode={selectedVATGroupCode}
      />
    </>
  );
}

// GLAccountModal.propTypes = {
//   gLAccountModalOpen: PropTypes.bool,
//   selectedGLA: PropTypes.object,
//   glaYears: PropTypes.array,
//   glaPeriods: PropTypes.array,
//   handleClose: PropTypes.func,
//   className: PropTypes.string,
//   getAllGLAccounts: PropTypes.func,
//   getVatCodes: PropTypes.func,
//   onSuccess: PropTypes.func,
// };

export default GLAccountModal;
