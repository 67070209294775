import { Box, Typography } from '@mui/material';

import styles from './style';

interface PropTypes {
  name: string;
  isSystemGenerated?: boolean;
}

const DocumentTag = (props: PropTypes) => {
  const { name, isSystemGenerated } = props;
  return (
    <Box sx={isSystemGenerated ? styles.duplicateContainer : styles.container}>
      <Typography sx={isSystemGenerated ? styles.duplicateName : styles.name}>{`# ${name}`}</Typography>
    </Box>
  );
}

export default DocumentTag;
