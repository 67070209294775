import { Box } from '@mui/material'

type PropsType ={
  lineStep: number;
  activeStep: number;
}

const StepLine = (props: PropsType) => {
  const { lineStep, activeStep} = props;
  return (
    <Box sx={{...styles.container, backgroundColor:'#3E8AFF', 
      opacity: lineStep <= activeStep ? '1' : '.12' }}></Box>
  )
}

const styles={
  container:{
    display:'flex',
    flex:1,
    height:'3px',
    maxWidth:'150px',
  }
}

export default StepLine