/* eslint-disable no-nested-ternary */
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, CircularProgress, Grid, IconButton, Menu, MenuItem, Switch, Tooltip, Typography
} from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import LaunchIcon from '@mui/icons-material/LaunchOutlined';
import { alpha } from "@mui/material";

import ManageIntegration from 'src/integrations/components/ManageIntegration/ManageIntegration';
import {
  docunectaIntegrations, integrationNames, procysIntegrations
} from 'src/config';
import { isActionPermitted, permissions } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import theme from "src/theme";

const IntegrationBox = (props) => {
  const { BRAND_NAME, API, BRAND_DOMAIN, IS_BRAND_PROCYS, ENVIRONMENT } = useContext(ConfigContext);
  const { user } = useAuth();
  const { ready, t } = useTranslation();

  const {
    integration, sendNotifyMeRequest, connectRequest, processing, processingAppName, deleteApp,
    deliverFormats, exportEmailPrefFormat, savedExportEmailFormat, savePrefFormat
  } = props;

  const freeUser = user?.subscription && user.subscription.toLowerCase().indexOf('free') >= 0;

  const [manageOpen, setManageOpen] = useState(false);
  const [openMoreEl, setOpenMoreEl] = useState(null);

  const [isActive, setIsActive] = useState(integration?.active);

  const noAllowedForFree = freeUser && integration.name !== integrationNames.emailReceiver;

  const updateCheckBoxStatus = () => {
    setIsActive((prevState) => !prevState);
  };

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpenMoreEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpenMoreEl(null);
    setManageOpen(false);
  };

  const onChangeActiveStatus = () => {
    connectRequest(integration.name, !integration.active, integration, updateCheckBoxStatus, false);
  };

  const deleteIntegration = (event, name) => {
    if (integration.active) {
      updateCheckBoxStatus();
    }
    deleteApp(name);
    handleCloseMenu(event);
  };

  const onConnect = () => {
    if (
      integration.name === integrationNames.onedrive // OneDrive
      || integration.name === integrationNames.dropbox // DropBox
      || integration.name === integrationNames.googleDrive // GoogleDrive
    ) {
      if (!integration.active) {
        connectRequest(integration.name, true, integration, () => {}, false);
      }
      window.open(integration.zapierLink, '_blank');
      return;
    }

    setManageOpen(true);

    // Connect automatically only for [email, api, split-document]
    if (integration.name === integrationNames.emailReceiver || integrationNames.api
      || integration.name === integrationNames.splitDocument || integration.name === integrationNames.assistantAPI
      || integration.name === integrationNames.quickbooks || integration.name === integrationNames.holded
      || integration.name === integrationNames.sftpReceiver || integration.name === integrationNames.sftpSender) {
      connectRequest(integration.name, !integration.active, integration, updateCheckBoxStatus, false);
    }
  };

  const onManageClick = () => {
    setManageOpen(true);
  };

  const isIntegrationNotAllowed = !integration.isAllowed
    || (procysIntegrations.includes(integration.name) && BRAND_NAME !== 'Procys')
    || (docunectaIntegrations.includes(integration.name) && !(BRAND_NAME === 'Docunecta' || BRAND_NAME === 'Docuindexa'))
    || (integration.name === integrationNames.sftpReceiver && !user.customisations.includes('enableSFTPReceiverApp'));
    // || (integration.name === 'quickbooks' && ENVIRONMENT === 'prod');

  const renderZapierConnectBtn = (
    <Button
      variant="contained"
      color="primary"
      style={noAllowedForFree ? styles.disableBtn : styles.connectLinkBtn}
      onClick={noAllowedForFree ? () => {} : onConnect}
      disabled={
        !isActionPermitted(permissions.appsInstall, user.permissions) || !isActionPermitted(permissions.appsView, user.permissions)
      }
    >
      <LaunchIcon style={styles.launchIcon} />
      {integration.integrated ? t('APPS_MANAGE') : t('APPS_CONNECT')}
    </Button>
  );

  const renderNotifyMeButton = () => (
    processing && processingAppName === integration.name
      ? (
        <Grid style={styles.action}>
          <CircularProgress size={20} />
        </Grid>
      )
      : (
        <Tooltip title={freeUser ? t('INTEGRATIONS_SUBSCRIBE_TO_USE') : !isActionPermitted(permissions.appsView, user.permissions)
          ? t('INTEGRATIONS_INSUFFICIENT_PERMISSION_TO_INTERACT')
          : !isActionPermitted(permissions.appsInstall, user.permissions)
            ? t('INTEGRATIONS_INSUFFICIENT_PERMISSION_TO_INSTALL')
            : ''}
        >
          <span>
            <Button
              variant="outlined"
              style={styles.manageBtn}
              disabled={freeUser || !isActionPermitted(permissions.appsView, user.permissions)}
              onClick={sendNotifyMeRequest}
            >
              <span style={styles.btnText}>{ready && t('INTEGRATIONS_NOTIFY_ME')}</span>
            </Button>
          </span>
        </Tooltip>
      )
  );

  const renderStatus = () => {
    if (processing && processingAppName === integration.name) {
      return (
        <Grid style={styles.action}>
          <CircularProgress size={20} />
        </Grid>
      );
    }

    if (!integration.byZapier && (integration.integrated || manageOpen)) {
      return (
        <Grid>
          <Tooltip title={isActive && integration.name === integrationNames.haciendaCertification ? t('INTEGRATION_SWITCH_DISABLE_REMOVE_HACIENDA') : ''}>
            <Switch
              checked={isActive}
              disabled={isActive && integration.name === integrationNames.haciendaCertification}
              onChange={onChangeActiveStatus}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <IconButton onClick={handleOpenMenu} disabled={isActive && integration.name === integrationNames.haciendaCertification}>
              <MoreIcon />
            </IconButton>
            <Menu
              id="card-actions"
              anchorEl={openMoreEl}
              keepMounted
              open={Boolean(openMoreEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                style={styles.moreItem}
                onClick={(e) => deleteIntegration(e, integration.name)}
              >
                {t('INTEGRATIONS_DELETE')}
              </MenuItem>
            </Menu>
          </Tooltip>
        </Grid>
      );
    }

    return (
      <Tooltip title={noAllowedForFree ? t('INTEGRATIONS_SUBSCRIBE_TO_USE') : !isActionPermitted(permissions.appsView, user.permissions)
        ? t('INTEGRATIONS_INSUFFICIENT_PERMISSION_TO_INTERACT')
        : !isActionPermitted(permissions.appsInstall, user.permissions)
          ? t('INTEGRATIONS_INSUFFICIENT_PERMISSION_TO_INSTALL')
          : ''}
      >
        <span>
          {
            integration.byZapier
              ? renderZapierConnectBtn
              : (
                <Button
                  variant="contained"
                  color={noAllowedForFree ? 'primary' : 'secondary'}
                  style={noAllowedForFree ? styles.disableBtn : styles.connectBtn}
                  onClick={noAllowedForFree ? () => {} : onConnect}
                  disabled={
                    !isActionPermitted(permissions.appsInstall, user.permissions) || !isActionPermitted(permissions.appsView, user.permissions)
                  }
                >
                  <span style={styles.btnText}>{ready && t('INTEGRATIONS_CONNECT')}</span>
                </Button>
              )
          }
        </span>
      </Tooltip>
    );
  };

  if (isIntegrationNotAllowed) {
    return null;
  }

  if (user.brand === 'schubec' && integration.name === 'assistantapi') {
    return null;
  }

  if (!IS_BRAND_PROCYS && integration.byZapier) {
    return null;
  }

  if (integration.name === integrationNames.haciendaCertification && (!IS_BRAND_PROCYS || ENVIRONMENT === 'prod')) {
    return null;
  }

  return (
    <Grid key={integration.id} style={styles.integrationBox}>
      <Grid style={styles.integrationHeader}>
        <img style={styles.integrationImage} alt="" src={integration.img || `${API.brandFavicon}${BRAND_DOMAIN}`} />
        <Grid style={styles.integrationBody}>
          <Typography style={styles.integrationTitle}>
            {ready && t(integration.title)}
            {integration.isBeta && <span style={styles.beta}>{ready && t('BETA_RELEASE')}</span>}
            {integration.byZapier && <span style={styles.zapierConnection}>{ready && t('INTEGRATIONS_BY_ZAPIER')}</span>}
            {!integration.available && <span style={styles.comingSoon}>{ready && t('INTEGRATIONS_COMING_SOON')}</span>}
            {freeUser && integration.free && <span style={styles.tryForFree}>{ready && t('INTEGRATIONS_TRY_FOR_FREE')}</span>}
          </Typography>
          <Typography style={styles.integrationDesc}>
            {ready && t(integration.description, { brand: BRAND_NAME })}
          </Typography>
        </Grid>
        <Grid>
          {
            integration.available
              ? renderStatus()
              : renderNotifyMeButton()
          }
        </Grid>
      </Grid>
      {
        !integration.byZapier && integration.integrated && !manageOpen && !integration.noManage && (
          <Grid style={{ display: 'flex' }}>
            <Grid style={{ width: 56 }} />
            <Tooltip title={!isActionPermitted(permissions.appsView, user.permissions)
              ? t('INTEGRATIONS_INSUFFICIENT_PERMISSION_TO_INTERACT')
              : !isActionPermitted(permissions.appsManage, user.permissions)
                ? t('INTEGRATIONS_INSUFFICIENT_PERMISSION_TO_MANAGE')
                : ''}
            >
              <span>
                <Button
                  variant="outlined"
                  style={styles.manageBtn}
                  onClick={onManageClick}
                  disabled={
                    !isActionPermitted(permissions.appsManage, user.permissions) || !isActionPermitted(permissions.appsView, user.permissions)
                  }
                >
                  {ready && t('INTEGRATIONS_MANAGE')}
                </Button>
              </span>
            </Tooltip>
          </Grid>
        )
      }
      {
        manageOpen && (
          <ManageIntegration
            integration={integration}
            deliverFormats={deliverFormats}
            exportEmailPrefFormat={exportEmailPrefFormat}
            savePrefFormat={savePrefFormat}
            savedExportEmailFormat={savedExportEmailFormat}
          />
        )
      }
    </Grid>
  );
};

export default IntegrationBox;

const styles = {
  integrationBox: {
    flexDirection: 'column',
    border: '1px solid #DFDDE4',
    borderRadius: 8,
    marginTop: 16,
    padding: 16
  },
  integrationHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  integrationImage: {
    width: 40,
  },
  integrationTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#334D6E'
  },
  integrationDesc: {
    fontSize: 14,
    color: '#334D6E',
    minHeight: 42
  },
  integrationBody: {
    flex: 1,
    padding: '0px 16px'
  },
  manageBtn: {
    fontSize: 14,
    color: '#334D6E',
    border: theme.palette.border.manage,
    marginTop: 8,
    textTransform: 'none',
  },
  connectBtn: {
    backgroundColor: theme.palette.secondary.main,
    textTransform: 'none'
  },
  notifyMeBtn: {
    textTransform: 'none'
  },
  disableBtn: {
    textTransform: 'none',
    color: '#FFFFFF'
  },
  zapierConnection: {
    fontSize: 11,
    color: '#EC5929',
    marginLeft: 16,
    position: 'relative',
    top: -2
  },
  comingSoon: {
    backgroundColor: '#EC5929',
    color: '#ffffff',
    fontSize: 10,
    borderRadius: 20,
    marginLeft: 8,
    padding: '0px 8px 2px',
    position: 'relative',
    top: -8
  },
  beta: {
    backgroundColor: '#3E8AFF',
    color: '#ffffff',
    fontSize: 10,
    borderRadius: 20,
    marginLeft: 8,
    padding: '0px 8px 2px',
    position: 'relative',
    top: -8
  },
  tryForFree: {
    backgroundColor: alpha(theme.palette.statusbg.toreview, 0.3),
    color: theme.palette.statusbg.toreview,
    fontSize: 10,
    borderRadius: 20,
    marginLeft: 8,
    padding: '0px 8px 2px',
    position: 'relative',
    top: -8
  },
  moreItem: {
    fontSize: 14,
    color: '#334D6E',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    minWidth: 100,
    height: 40,
    textTransform: 'none',
  },
  connectLinkBtn: {
    background: '#DFDFDF',
    color: '#334D6E',
    textTransform: 'none'
  },
  launchIcon: {
    paddingRight: 8
  },
  btnText: {
    paddingLeft: 12,
    paddingRight: 12
  }
};
