import React, { useEffect, useState, useRef, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable, DraggableProvided } from '@hello-pangea/dnd';

import {
  Grid, IconButton, Typography, Paper, TableRow, TableCell, Table, TableHead,
  TextField, Tooltip, TableBody, Checkbox, Autocomplete, Button
} from '@mui/material';
import {
  Close as CloseIcon,
  Add as AddIcon,
  SettingsOutlined as SettingsIcon,
  DragIndicator as ReorderIcon,
  Delete as DeleteIcon,
  Undo as ResetIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { isShowManageFields } from 'src/utils/helpers';
import { lineColumnsReprocess, supplierData } from 'src/config';
import {
  FieldType, DocumentLineType, SupplierType, VatGroupType, GLAccountType, CostCenterType, ProjectType, RootState
} from 'src/types';
import { createDimensionMap } from 'src/document-edit/utils';
import { IHighlight } from 'src/pdf-highligher';
import styles from './style';

interface PropTypes {
  refObj: RefObject<HTMLDivElement>;
  viewerWidth: string;
  lineFields: FieldType[];
  lineDetails: DocumentLineType[];
  supplier: SupplierType | null;
  decimalSeparator: string;
  projects: ProjectType[];
  selectedTextField: FieldType;
  linesDataForReprocessing: Record<string, IHighlight[]>;
  reprocessingLines: boolean;
  handleChange: (_value: string, _line: number, _prop: string) => void;
  onAddLineConfirm: (_line: DocumentLineType) => void;
  onDeleteLineConfirm: (_line: DocumentLineType) => void;
  adjustLinesOrder: (_source: number, _destination?: number) => void;
  handleOpenManageFields: (_entity: string) => void;
  closeLinesComponent: () => void;
  setSelectedTextField: (_val: FieldType) => void;
  handleOnBlur: (_value: string | boolean, _line: number, _prop: string) => void;
  resetLineBoundaries: (_key: string) => void;
  sendLinesForReprocessing: () => Promise<void>;
}

const LinesComponent = (props: PropTypes) => {
  const { ready, t } = useTranslation();
  const { user } = useAuth();

  const {
    refObj,
    // viewerWidth,
    lineFields,
    lineDetails,
    supplier,
    decimalSeparator,
    projects,
    selectedTextField,
    linesDataForReprocessing,
    reprocessingLines,
    handleChange,
    onAddLineConfirm,
    onDeleteLineConfirm,
    adjustLinesOrder,
    handleOpenManageFields,
    closeLinesComponent,
    setSelectedTextField,
    handleOnBlur,
    resetLineBoundaries,
    sendLinesForReprocessing,
  } = props;

  const isEnableLinesReprocessing = user?.customisations?.includes('enableLinesReprocessing');
  const noWhiteSpace = /.*\S.*$/;
  const amountRegex = new RegExp(`^-?\\d*[${decimalSeparator === '.' ? '.' : `.${decimalSeparator}`}]?\\d+$`);
  const dateFormat = /^((0[1-9]|[12][0-9]|3[01])([/\-.])(0[1-9]|1[0-2])\3\d{4}|\d{4}([/\-.])(0[1-9]|1[0-2])\5(0[1-9]|[12][0-9]|3[01]))$/;

  const [supplierUnitMeasurementVals, setSupplierUnitMeasurementVals] =
    useState<string[]>(supplier?.inventories?.map((p) => p.unitMeasurement || '') || []);
  const [unitMeasurementVals, setUnitMeasurementVals] =
    useState<string[]>(supplier?.inventories?.map((p) => p.unitMeasurement || '') || []);

  // @TODO: Fix selector issue
  const glAccounts: GLAccountType[] = useSelector((state: RootState) => [...supplierData.defaultGLA, ...state.gla.glAccounts]);
  const vatGroups: VatGroupType[] = useSelector((state: RootState) => [...supplierData.defaultVatGroup, ...state.vatcode.vatCodes]);
  const costCenters: CostCenterType[] = useSelector((state: RootState) => [...supplierData.defaultCostCenters, ...state.costcenter.costCenters]);

  const dimensionsLists = {
    lineGLAccount: glAccounts.map((g) => g.code),
    lineVatGroup: vatGroups.map((v) => v.code),
    lineCostCenter: costCenters.map((c) => c.code),
    lineProject: projects.map((p) => p.code),
  };

  const dimensionsMap: Record<string, Record<string, string>> = {
    lineGLAccount: createDimensionMap(glAccounts),
    lineVatGroup: createDimensionMap(vatGroups),
    lineCostCenter: createDimensionMap(costCenters),
    lineProject: createDimensionMap(projects),
  };

  const tableRefs: Record<string, RefObject<HTMLDivElement>> = {
    table1Ref: useRef(null),
    table2Ref: useRef(null),
    table3Ref: useRef(null),
    table2HeaderRef: useRef(null),
  };

  const isLinesReprocessingBtnDisabled = () => {
    let isDisabled = true;
    Object.keys(linesDataForReprocessing).forEach((key) => {
      if (linesDataForReprocessing[key].length > 0) {
        isDisabled = false;
      }
    });
    return isDisabled;
  };

  useEffect(() => {
    const fromSupplier = [...new Set(supplier?.inventories?.filter((p) => p.unitMeasurement).map((p) => p.unitMeasurement || ''))];
    const fromLineDetails = [...new Set(lineDetails.map((l) => l.lineUnitMeasurement || ''))];
    const combined = [...new Set(fromSupplier.concat(fromLineDetails))];
    setSupplierUnitMeasurementVals(combined);
    setUnitMeasurementVals(combined);
  }, [supplier]);

  const handleChangeAutoComplete = (value: string, line: number, prop: string) => {
    handleChange(value, line, prop);
    handleOnBlur(value, line, prop);
  };

  const handleOnBlurUnitMeasurementText = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    line: number,
    prop: string
  ) => {
    const { value } = e.target;
    if (!supplierUnitMeasurementVals.includes(value)) {
      setUnitMeasurementVals([...supplierUnitMeasurementVals, value]);
    } else {
      setUnitMeasurementVals(supplierUnitMeasurementVals);
    }
    handleOnBlur(value, line, prop);
  };

  const getDimensionFieldLabel = (option: string, key: string) => {
    const name = dimensionsMap[key][option];
    return `${option}${name ? ` | ${name}` : ''}`;
  };

  const getDimensionDefaultValue = (key: string) => {
    if (key === 'lineGLAccount') {
      return supplierData.defaultGLA[0].code;
    }
    if (key === 'lineVatGroup') {
      return supplierData.defaultVatGroup[0].code;
    }
    if (key === 'lineCostCenter') {
      return supplierData.defaultCostCenters[0].code;
    }
    if (key === 'lineProject') {
      return supplierData.defaultProjects[0].code;
    }
  };

  const handleScroll = (sourceTable: string, targetTable: string) => {
    const sourceTableRef = tableRefs[sourceTable].current;
    const targetTableRef = tableRefs[targetTable].current;
    let scrollPositionY: number;
    if (sourceTableRef) {
      scrollPositionY = sourceTableRef.scrollTop;
      if (targetTableRef) {
        targetTableRef.scrollTop = scrollPositionY;
      }
    }
  };

  const handleAddLine = () => {
    onAddLineConfirm({ line: lineDetails?.length + 1 });
    setTimeout(() => {
      const el = document.getElementById('table-2');
      if (el) {
        el.scrollTo({
          top: el.scrollHeight,
        });
      }
    }, 500);
  };

  const validate = (value: string | number | boolean | undefined, dataType: string) => {
    if (!value || typeof value !== 'string') {
      return true;
    }
    if (dataType === 'float' && !amountRegex.test(value)) {
      return false;
    }
    if (dataType === 'date' && !dateFormat.test(value)) {
      return false;
    }
    if (!noWhiteSpace.test(value)) {
      return false;
    }
    return true;
  };

  const getTooltipTitle = (value: string | number | boolean | undefined, dataType: string) => {
    if (!value || typeof value !== 'string') {
      return '';
    }
    if (!noWhiteSpace.test(value)) {
      return t('NO_WHITE_SPACE_HELPER_TEXT');
    }
    if (dataType === 'float' && !amountRegex.test(value)) {
      return t('INVALID_NUMERIC_INPUT_FOR_INVOICE');
    }
    if (dataType === 'date' && !dateFormat.test(value)) {
      return t('INVALID_DATE_FORMAT_HELPER_TEXT_NEW_2');
    }
    return '';
  };

  const isResetLineBoundariesBtnDisabled = (key: string) => {
    let formattedKey = key;
    if (formattedKey === 'lineDescription') {
      formattedKey = 'lineName';
    }
    return linesDataForReprocessing[formattedKey].length === 0;
  };

  const tableHead1 = (
    <TableRow>
      <TableCell className="edit-invoice-line-line" sx={styles.draggableIconHeader} />
      <TableCell className="edit-invoice-line-line" sx={styles.deleteLineHeader} />
      <TableCell className="edit-invoice-line-line" align="center" sx={styles.columnNumber}>
        #
      </TableCell>
    </TableRow>
  );

  const tableHead2 = (
    <TableRow>
      {lineFields.filter((field) => field.isActive && field.isAvailable).map((field) => {
        if (field.key === 'line') {
          return null;
        }
        return (<TableCell
          id={`invoiceLineColumn_${field.key}`}
          className="edit-invoice-line-header"
          align="center"
          key={field.key}
          sx={{
            ...styles.headerCell,
            ...(isEnableLinesReprocessing
                && lineColumnsReprocess.includes(`invoiceLineColumn_${field.key}`)
                && styles.reprocessColumnHeader
              ),
            ...(selectedTextField.key === `invoiceLineColumn_${field.key}` && styles.selectedColumnHeader)
          }}
          onClick={() => isEnableLinesReprocessing && setSelectedTextField({
            key: `invoiceLineColumn_${field.key}`,
            assistantKey: `invoice_line_column_${field.key}`,
            label: field.label,
            appDbField: field.appDbField,
            dataType: field.dataType,
            exportMapKey: field.exportMapKey,
            position: field.position,
          })}
        >
          {ready && t(field.label)}
          {isEnableLinesReprocessing && lineColumnsReprocess.includes(`invoiceLineColumn_${field.key}`) && (
            <Tooltip title={isResetLineBoundariesBtnDisabled(field.key) ? '' : t('LINE_BOUNDARIES_RESET_TOOLTIP')}>
              <span>
                <IconButton sx={styles.undoBtn} disabled={isResetLineBoundariesBtnDisabled(field.key)} onClick={() => resetLineBoundaries(field.key)}>
                  <ResetIcon sx={styles.icon} />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </TableCell>
      )})}
    </TableRow>
  );

  const tableRow1 = (draggableProvided: DraggableProvided, line: DocumentLineType) => (
    <TableRow
      key={line.line}
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
    >
      <TableCell align="center" sx={styles.draggableIcon} className="edit-invoice-line-reorder-icon">
        <div {...draggableProvided.dragHandleProps}>
          <ReorderIcon sx={styles.icon} />
        </div>
      </TableCell>
      <TableCell sx={styles.draggableIcon} className="edit-invoice-line-action">
        <Tooltip title={t('INVOICE_EDIT_FORM_DELETE_INVOICE_LINE_TOOLTIP')}>
          <IconButton sx={styles.deleteIconBtn} onClick={() => onDeleteLineConfirm(line)}>
            <DeleteIcon sx={styles.icon} />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell sx={styles.lineNoContainer} className="edit-invoice-line-line">
        <TextField
          disabled
          value={line?.line || ''}
          variant="outlined"
          sx={{
            '& .MuiOutlinedInput-root': styles.inputRoot,
            '& .MuiOutlinedInput-input': styles.input,
          }}
        />
      </TableCell>
    </TableRow>
  );

  const tableRow2 = (line: DocumentLineType) => (
    <TableRow key={line.line}>
      {lineFields.filter((field) => field.isActive && field.isAvailable).map((field) => {
        if (field.key === 'line') {
          return null;
        }
        if (field.key === 'lineExtraCost') {
          return (
            <TableCell key={field.key} align="center" sx={styles.bodyCell} className="edit-invoice-line-body">
              <Checkbox
                checked={Boolean(line?.lineExtraCost)}
                sx={styles.checkBox}
                onChange={(e) => handleOnBlur(e.target.checked, line.line, 'lineExtraCost')}
              />
            </TableCell>
          );
        }
        if (field.key === 'lineGLAccount' || field.key === 'lineVatGroup' || field.key === 'lineCostCenter' || field.key === 'lineProject') {
          return (
            <TableCell key={field.key} align="center" sx={styles.bodyCell} className="edit-invoice-line-body">
              <Autocomplete
                id={`line_${line.line}_${field.key}`}
                fullWidth
                onChange={(_, v) => handleChangeAutoComplete(v || '', line.line, field.key)}
                openOnFocus
                onFocus={() => setSelectedTextField({
                  key: `line_${line.line}_${field.key}`,
                  assistantKey: `line_${line.line}_${field.key}`,
                  label: `${t(field.label)} ${line.line}`,
                  appDbField: field.appDbField,
                  dataType: field.dataType,
                  exportMapKey: field.exportMapKey,
                  position: field.position,
                })}
                value={line[field.key] || getDimensionDefaultValue(field.key)}
                options={dimensionsLists[field.key]}
                getOptionLabel={(option) => getDimensionFieldLabel(option, field.key)}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': styles.inputRoot,
                      '& .MuiOutlinedInput-input': {
                        ...styles.input,
                        backgroundColor: selectedTextField.key === `line_${line.line}_${field.key}` ? '#3E8AFF4D' : '#FFFFFF'
                      }
                    }}
                  />
                )}
              />
            </TableCell>
          );
        }
        if (field.key === 'lineUnitMeasurement') {
          return (
            <TableCell
              key={field.key}
              align="center"
              sx={styles.bodyCell}
              className="edit-invoice-line-body"
            >
              <Autocomplete
                id={`line_${line.line}_${field.key}`}
                fullWidth
                onChange={(e, v) => handleChange(v || '', line.line, field.key)}
                onFocus={() => setSelectedTextField({
                  key: `line_${line.line}_${field.key}`,
                  assistantKey: `line_${line.line}_${field.key}`,
                  label: `${t(field.label)} ${line.line}`,
                  appDbField: field.appDbField,
                  dataType: field.dataType,
                  exportMapKey: field.exportMapKey,
                  position: field.position,
                })}
                openOnFocus
                value={line[field.key] || ''}
                options={unitMeasurementVals}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    onBlur={(e) => handleOnBlurUnitMeasurementText(e, line.line, field.key)}
                    sx={{
                      '& .MuiOutlinedInput-root': styles.inputRoot,
                      '& .MuiOutlinedInput-input': {
                        ...styles.input,
                        backgroundColor: selectedTextField.key === `line_${line.line}_${field.key}` ? '#3E8AFF4D' : '#FFFFFF'
                      }
                    }}
                  />
                )}
              />
            </TableCell>
          );
        }
        const value = line[field.key as keyof DocumentLineType];
        return (
          <TableCell
            key={field.key}
            align="center"
            sx={{ ...styles.bodyCell, minWidth: field.key === 'lineDescription' ? '256px' : 'unset' }}
            className="edit-invoice-line-body"
          >
            <Tooltip title={getTooltipTitle(value, field.dataType)}>
              <TextField
                id={`line_${line.line}_${field.key}`}
                fullWidth
                value={value || ''}
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': styles.inputRoot,
                  '& .MuiOutlinedInput-input': {
                    ...styles.input,
                    textAlign: field.key === 'lineDescription' ? 'left' : 'center',
                    backgroundColor: validate(value, field.dataType)
                      ? selectedTextField.key === `line_${line.line}_${field.key}` ? '#3E8AFF4D' : '#FFFFFF'
                      : '#FF4D494D',
                  }
                }}
                onFocus={() => setSelectedTextField({
                  key: `line_${line.line}_${field.key}`,
                  assistantKey: `line_${line.line}_${field.key}`,
                  label: `${t(field.label)} ${line.line}`,
                  appDbField: field.appDbField,
                  dataType: field.dataType,
                  exportMapKey: field.exportMapKey,
                  position: field.position,
                })}
                onChange={(e) => handleChange(e.target.value, line.line, field.key)}
                onBlur={(e) => handleOnBlur(e.target.value, line.line, field.key)}
              />
            </Tooltip>
          </TableCell>
        );
      })}
    </TableRow>
  );

  return (
    <Grid ref={refObj} sx={styles.root}>
      <Grid sx={styles.header}>
        <Grid sx={styles.titleContainer}>
          <Typography sx={styles.headerTitle}>{ready && t('DOCUMENT_EDIT_LINE_ITEMS')}</Typography>
          <IconButton
            sx={!isShowManageFields(user, 'applyParentFieldsLines') ? styles.iconBtnAddLineOnly : styles.iconBtnAddLine}
            onClick={handleAddLine}
          >
            <AddIcon sx={styles.icon} />
          </IconButton>
          {isShowManageFields(user, 'applyParentFieldsLines') && (
            <IconButton sx={styles.iconBtnSettings} onClick={() => handleOpenManageFields('invoice-line')}>
              <SettingsIcon sx={styles.icon} />
            </IconButton>
          )}
          {isEnableLinesReprocessing && (
            <Button
              color="secondary"
              variant="outlined"
              sx={styles.reprocessingBtn}
              disabled={reprocessingLines || isLinesReprocessingBtnDisabled()}
              onClick={sendLinesForReprocessing}
            >
              {ready && t('INVOICE_EDIT_FORM_SEND_FOR_REPROCESSING_LINES')}
            </Button>
          )}
        </Grid>
        <IconButton onClick={closeLinesComponent}>
          <CloseIcon sx={styles.icon} />
        </IconButton>
      </Grid>
      <Grid sx={styles.tablesContainer} className="hide-scroll">
        <Paper
          id="table-1"
          ref={tableRefs.table1Ref}
          sx={styles.table1}
          className="hide-scroll"
          onScroll={() => handleScroll('table1Ref', 'table2Ref')}
        >
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              {tableHead1}
            </TableHead>
            <DragDropContext onDragEnd={(e) => adjustLinesOrder(e?.source?.index, e?.destination?.index)}>
              <Droppable droppableId="droppable" direction="vertical">
                {(droppableProvided) => (
                  <TableBody
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {
                      lineDetails.sort((a, b) => (a.line > b.line ? 1 : -1)).map((line) => (
                        <Draggable
                          key={line.line}
                          draggableId={line.line?.toString()}
                          index={line.line}
                        >
                          {(draggableProvided) => (tableRow1(draggableProvided, line))}
                        </Draggable>
                      ))
                    }
                    {droppableProvided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </Paper>
        <Paper
          id="table-2"
          ref={tableRefs.table2Ref}
          sx={styles.table2}
          className="xy-scroll"
          onScroll={() => handleScroll('table2Ref', 'table1Ref')}
        >
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              {tableHead2}
            </TableHead>
            <TableBody>
              {
                lineDetails.sort((a, b) => (a.line > b.line ? 1 : -1)).map((line) => (
                  tableRow2(line)
                ))
              }
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LinesComponent;
