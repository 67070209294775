import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  Box, Table, TableBody, TableCell, TableRow, Hidden, Tooltip,
  Avatar, Link
} from '@mui/material';

import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import {
  glaMsg, vatMsg, cctMsg, prjtMsg
} from 'src/config';
import styles from '../style';

const DimensionLogsTable = ({
  paginatedLogs, selectedLogs
}) => {
  const {
    dim, code, type, company
  } = useParams();
  const logs = paginatedLogs;
  const navigate = useNavigate();
  const [position, setPosition] = useState({ x: undefined, y: undefined });
  const { ready, t } = useTranslation();

  const today = new Date().setHours(0, 0, 0, 0) / 1000;

  let tooltipTitle;

  switch (dim) {
    case 'glaccounts':
      tooltipTitle = glaMsg.GLA_TABLE_TOOLTIP;
      break;
    case 'vatcodes':
      tooltipTitle = vatMsg.VAT_CODE_TABLE_TOOLTIP;
      break;
    case 'costcenters':
      tooltipTitle = cctMsg.COST_CENTER_TABLE_TOOLTIP;
      break;
    case 'projects':
      tooltipTitle = prjtMsg.PROJECT_TABLE_TOOLTIP;
      break;
    default:
      break;
  }

  return (
    <Box minWidth="100%">
      <Table size="small">
        <TableBody>
          {
            logs && logs.length > 0
              ? logs.map((log) => {
                const isDimensionLogSelected = selectedLogs.includes(log.id);
                const isDimensionLogToday = log.createdDate / 1000000000 >= today;
                const backgroundColorStyle = isDimensionLogToday ? { backgroundColor: '#F9FAFC' } : {};
                const todayTextStyle = isDimensionLogToday ? styles.todayText : {};

                return (
                  <Tooltip
                    key={log.id}
                    disableHoverListener={log.status !== 100}
                    title={log.status === 100 ? ready && t(tooltipTitle) : ''}
                    onMouseMove={(e) => setPosition({ x: e.pageX, y: e.pageY })}
                    PopperProps={{
                      anchorEl: {
                        clientHeight: 0,
                        clientWidth: 0,
                        getBoundingClientRect: () => ({
                          top: position.y,
                          left: position.x,
                          right: position.x,
                          bottom: position.y,
                          width: 0,
                          height: 0,
                        })
                      }
                    }}
                  >
                    <TableRow
                      hover
                      key={log.id}
                      style={{ ...styles.pointer, ...backgroundColorStyle }}
                      selected={isDimensionLogSelected}
                      onClick={
                        () => navigate({
                          pathname: `/dimensions/logs/${dim}/${log.id}`,
                          state: {
                            code,
                            type,
                            company,
                          }
                        })
                      }
                    >
                      {
                        code === undefined && (
                        <Hidden xsDown>
                          <TableCell
                            style={{ ...todayTextStyle, fontSize: 16, lineHeight: '24px' }}
                          >
                            {log.code}
                          </TableCell>
                        </Hidden>
                        )
                      }
                      <Hidden mdDown>
                        <TableCell
                          style={{ ...todayTextStyle, fontSize: 16, lineHeight: '24px' }}
                        >
                          {log.name}
                        </TableCell>
                      </Hidden>
                      <TableCell
                        style={{ ...todayTextStyle, fontSize: 16, lineHeight: '24px' }}
                      >
                        {log.action}
                      </TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                        >
                          <Hidden xsDown>
                            <Avatar
                              alt="user"
                              style={styles.userImage}
                              src={log.avatar}
                            />
                          </Hidden>
                          <Hidden mdDown>
                            <Box ml={2}>
                              <Link
                                variant="subtitle2"
                                color="textPrimary"
                                underline="none"
                                style={styles.usernameText}
                              >
                                {`${log.firstname} ${log.lastname}`}
                              </Link>
                            </Box>
                          </Hidden>
                        </Box>
                      </TableCell>
                      {
                        isDimensionLogToday ? (
                          <TableCell
                            style={{ ...todayTextStyle, fontSize: 15, lineHeight: '22.5px' }}
                            align="right"
                          >
                            {moment.unix(log.createdDate / 1000000000).format('hh:mm')}
                          </TableCell>
                        ) : (
                          <TableCell
                            style={{ ...styles.timeBox, fontSize: 15, lineHeight: '22.5px' }}
                            align="right"
                          >
                            {moment.unix(log.createdDate / 1000000000).format('YYYY-MM-DD HH:mm')}
                          </TableCell>
                        )
                      }
                      <TableCell />
                    </TableRow>
                  </Tooltip>
                );
              })
              : <EmptyText str="DIMENSION_LOGS_LIST_VIEW_NO_LOGS" />
          }
        </TableBody>
      </Table>
    </Box>
  );
};

export default DimensionLogsTable;
