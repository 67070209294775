import {
  useState, useRef, useEffect
} from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  Backdrop, Box, Button, Fade, FormControl, FormHelperText, Grid, IconButton, InputAdornment,
  InputLabel, MenuItem, Modal, Select, TextField, Tooltip, Typography, Autocomplete
} from '@mui/material';
import {
  Edit as EditIcon,
  AddBox as PlusIcon,
  Close as CloseIcon,
  SettingsOutlined as SettingsIcon,
} from '@mui/icons-material';

import useIsInViewport from 'src/hooks/useIsInViewport';
import {
  amountRelatedFields, cocaColaSuppliers, docunectaInvoiceLineFields, lineAmountRelatedFields,
  invoiceLineTooltips, makroSuppliers, supplierData, twinfieldLineFields, lineFieldIds
} from 'src/config';
import {
  caclulateLineDiscountedPrice, calculateLineAmount, calculateLinePrice, calculateLineTax,
  calculateLineUnitPrice, formatAmountFields, isShowManageFields
} from 'src/utils/helpers';
import ManageFields from 'src/shared/components/ManageFields/ManageFields';
import GLAccountModal from 'src/dimensions/glas/components/GLAccountModal/GLAccountModal';
import VatCodeModal from 'src/dimensions/vatcodes/components/VatCodeModal/VatCodeModal';
import CostCenterModal from 'src/dimensions/costcenters/components/CostCenterModal/CostCenterModal';
import styles from './style';

function InvoiceLineAddForm({
  fields,
  getFields,
  user,
  supplier,
  glas,
  vatGroups,
  costCenters,
  projects,
  handleClose,
  onAddLine,
  description,
  isTwinfield,
  linesLength,
  renderInvoicePDF,
  addLineOpen,
  getAllGLAccounts,
  getAllVatCodes,
  getAllCostCenters,
  glaYears,
  glaPeriods,
  parameterCompany,
  senderVatNumber,
  scrollXMLViewBy,
  updateAnnotateFieldsTime,
  setAnnotateSelectedField,
  setAnnotateFieldStart,
  decimalSeparator,
}) {
  const { t, ready } = useTranslation();

  const isDescriptionAutofilled = user.customisations.includes('autoFillLineDescription');

  const lineDetails = {
    line: linesLength + 1,
    lineDescription: isDescriptionAutofilled ? `Line ${linesLength + 1}` : description,
    lineGLAccount: supplier?.generalLedgerAccLine || supplierData.defaultGLA[0].code,
    lineVatGroup: supplier?.vatGroup || supplierData.defaultVatGroup[0].code,
    lineCostCenter: supplier?.costCenter || supplierData.defaultCostCenters[0].code,
    lineProject: supplier?.project || supplierData.defaultProjects[0].code,
    lineUnitPrice: '',
    lineQuantity: '',
    lineDiscount: '',
    linePrice: '',
    lineVatrate: '',
    lineArticle: '',
    lineVatcode: '',
    lineDiscountedPrice: '',
    lineDiscountAmountOnUnitPrice: '',
    lineDiscountAmountOnTotalPrice: '',
    lineTax: '',
    lineDeposit: '',
    lineAmount: '',
    lineExtraCost: 'INVOICE_LINE_ADD_NO',
    lineReferenceNumber: '',
    lineProductCount: '',
    lineUnitMeasurement: '',
    lineArticleClient: '',
    lineBaseAmount: '',
    lineRetentionAmount: '',
    lineRetentionPercentage: '',
    lineCategory: '',
    lineProductCode: '',
    lineTotalPV: '',
    lineMeasureType: '',
    lineDelRecCompName: '',
    lineDelRecContName: '',
    lineDelRecAddress: '',
    lineDelRecPhoneNumber: '',
    lineDelRecEmail: '',
    lineDeliveryDate: '',
    lineTeilnr: '',
    lineTeilnrDelivery: '',
    linePoReferenceNumber: ''
  };

  const [details, setDetails] = useState({});

  useEffect(() => {
    setDetails(lineDetails);
  }, [linesLength, addLineOpen]);

  const [lineErr, setLineErr] = useState('');

  const [errorMessages, setErrorMessages] = useState({
    lineAmount: '',
    lineQuantity: '',
    lineUnitPrice: '',
    linePrice: '',
    lineDiscount: '',
    lineDiscountAmountOnUnitPrice: '',
    lineDiscountAmountOnTotalPrice: '',
    lineDiscountedPrice: '',
    lineTax: '',
    lineVatrate: ''
  });

  const amountRegex = new RegExp(`^-?\\d*[${decimalSeparator === '.' ? '.' : `.${decimalSeparator}`}]?\\d+$`);
  const noWhiteSpace = /.*\S.*$/;

  const [xmlSelectedValue, setXmlSelectedValue] = useState('');
  const [textFieldSelectedKey, setTextFieldSelectedKey] = useState('');
  const [textFieldSelectedValue, _setTextFieldSelectedValue] = useState('');
  const textFieldSelectedValueRef = useRef(textFieldSelectedValue);
  const setTextFieldSelectedValue = (val) => {
    textFieldSelectedValueRef.current = val;
    _setTextFieldSelectedValue(val);
  };

  const fieldRef = {
    lineDescription: useRef(null),
    lineGLAccount: useRef(null),
    lineVatGroup: useRef(null),
    lineCostCenter: useRef(null),
    lineProject: useRef(null),
    lineUnitPrice: useRef(null),
    lineQuantity: useRef(null),
    lineDiscount: useRef(null),
    linePrice: useRef(null),
    lineVatrate: useRef(null),
    lineArticle: useRef(null),
    lineVatcode: useRef(null),
    lineDiscountedPrice: useRef(null),
    lineDiscountAmountOnUnitPrice: useRef(null),
    lineDiscountAmountOnTotalPrice: useRef(null),
    lineTax: useRef(null),
    lineDeposit: useRef(null),
    lineAmount: useRef(null),
    lineReferenceNumber: useRef(null),
    lineProductCount: useRef(null),
    lineUnitMeasurement: useRef(null),
    lineArticleClient: useRef(null),
    lineBaseAmount: useRef(null),
    lineRetentionAmount: useRef(null),
    lineRetentionPercentage: useRef(null),
    lineCategory: useRef(null),
    lineProductCode: useRef(null),
    lineTotalPV: useRef(null),
    lineMeasureType: useRef(null),
    lineDelRecCompName: useRef(null),
    lineDelRecContName: useRef(null),
    lineDelRecAddress: useRef(null),
    lineDelRecPhoneNumber: useRef(null),
    lineDelRecEmail: useRef(null),
    lineDeliveryDate: useRef(null),
    lineTeilnr: useRef(null),
    lineTeilnrDelivery: useRef(null),
    linePoReferenceNumber: useRef(null)
  };

  const isInViewPort = {
    lineDescription: useIsInViewport(fieldRef.lineDescription),
    lineGLAccount: useIsInViewport(fieldRef.lineGLAccount),
    lineVatGroup: useIsInViewport(fieldRef.lineVatGroup),
    lineCostCenter: useIsInViewport(fieldRef.lineCostCenter),
    lineProject: useIsInViewport(fieldRef.lineProject),
    lineUnitPrice: useIsInViewport(fieldRef.lineUnitPrice),
    lineQuantity: useIsInViewport(fieldRef.lineQuantity),
    lineDiscount: useIsInViewport(fieldRef.lineDiscount),
    linePrice: useIsInViewport(fieldRef.linePrice),
    lineVatrate: useIsInViewport(fieldRef.lineVatrate),
    lineArticle: useIsInViewport(fieldRef.lineArticle),
    lineVatcode: useIsInViewport(fieldRef.lineVatcode),
    lineDiscountedPrice: useIsInViewport(fieldRef.lineDiscountedPrice),
    lineDiscountAmountOnUnitPrice: useIsInViewport(fieldRef.lineDiscountAmountOnUnitPrice),
    lineDiscountAmountOnTotalPrice: useIsInViewport(fieldRef.lineDiscountAmountOnTotalPrice),
    lineTax: useIsInViewport(fieldRef.lineTax),
    lineDeposit: useIsInViewport(fieldRef.lineDeposit),
    lineAmount: useIsInViewport(fieldRef.lineAmount),
    lineReferenceNumber: useIsInViewport(fieldRef.lineReferenceNumber),
    lineProductCount: useIsInViewport(fieldRef.lineProductCount),
    lineUnitMeasurement: useIsInViewport(fieldRef.lineUnitMeasurement),
    lineArticleClient: useIsInViewport(fieldRef.lineArticleClient),
    lineBaseAmount: useIsInViewport(fieldRef.lineBaseAmount),
    lineRetentionAmount: useIsInViewport(fieldRef.lineRetentionAmount),
    lineRetentionPercentage: useIsInViewport(fieldRef.lineRetentionPercentage),
    lineCategory: useIsInViewport(fieldRef.lineCategory),
    lineProductCode: useIsInViewport(fieldRef.lineProductCode),
    lineTotalPV: useIsInViewport(fieldRef.lineTotalPV),
    lineMeasureType: useIsInViewport(fieldRef.lineMeasureType),
    lineDelRecCompName: useIsInViewport(fieldRef.lineDelRecCompName),
    lineDelRecContName: useIsInViewport(fieldRef.lineDelRecContName),
    lineDelRecAddress: useIsInViewport(fieldRef.lineDelRecAddress),
    lineDelRecPhoneNumber: useIsInViewport(fieldRef.lineDelRecPhoneNumber),
    lineDelRecEmail: useIsInViewport(fieldRef.lineDelRecEmail),
    lineDeliveryDate: useIsInViewport(fieldRef.lineDeliveryDate),
    lineTeilnr: useIsInViewport(fieldRef.lineTeilnr),
    lineTeilnrDelivery: useIsInViewport(fieldRef.lineTeilnrDelivery),
    linePoReferenceNumber: useIsInViewport(fieldRef.linePoReferenceNumber)
  };

  const [showManageFields, setShowManageFields] = useState(false);
  const [gLAccountModalOpen, setGLAccountModalOpen] = useState(false);
  const [vatCodeModalOpen, setVatCodeModalOpen] = useState(false);
  const [ccModalOpen, setCCModalOpen] = useState(false);
  const [selectedGLA, setSelectedGLA] = useState(null);
  const [selectedVATGroupCode, setSelectedVATGroupCode] = useState(null);
  const [selectedCostCenterCode, setSelectedCostCenterCode] = useState(null);

  const [newlyAddingProp, setNewlyAddingProp] = useState(null);
  const [newlyAddedVal, setNewlyAddedVal] = useState(null);

  const [supplierUnitMeasurementVals, setSupplierUnitMeasurementVals] = useState(supplier?.inventories?.map((p) => p.unitMeasurement) || []);
  const [unitMeasurementVals, setUnitMeasurementVals] = useState(supplier?.inventories?.map((p) => p.unitMeasurement) || []);

  useEffect(() => {
    setSupplierUnitMeasurementVals([...new Set(supplier?.inventories?.filter((p) => p.unitMeasurement).map((p) => p.unitMeasurement))] || []);
    setUnitMeasurementVals([...new Set(supplier?.inventories?.filter((p) => p.unitMeasurement).map((p) => p.unitMeasurement))] || []);
  }, [supplier]);

  const onGLAModalClose = () => {
    setGLAccountModalOpen(false);
    setSelectedGLA(null);
  };

  const handleEditGLA = () => {
    if (details.lineGLAccount !== supplierData.defaultGLA[0].code) {
      setSelectedGLA(glas.find((gla) => gla.code === details.lineGLAccount));
      setGLAccountModalOpen(true);
    }
  };

  const handleAddGLA = (prop) => {
    setSelectedGLA(null);
    setNewlyAddedVal(null);
    setGLAccountModalOpen(true);
    setNewlyAddingProp(prop);
  };

  const onGLACreated = (val) => {
    setNewlyAddedVal(val);
  };

  const handleEditVATCode = () => {
    if (details.lineVatGroup !== supplierData.defaultVatGroup[0].code) {
      setSelectedVATGroupCode(vatGroups.find((v) => v.code === details.lineVatGroup));
      setVatCodeModalOpen(true);
    }
  };

  const handleAddVATCode = (prop) => {
    setSelectedVATGroupCode(null);
    setNewlyAddedVal(null);
    setVatCodeModalOpen(true);
    setNewlyAddingProp(prop);
  };

  const onVatCodeCreated = (val) => {
    setNewlyAddedVal(val);
  };

  const onVatCodeModalClose = () => {
    setVatCodeModalOpen(false);
    setSelectedVATGroupCode(null);
  };

  const handleEditCostCenter = () => {
    if (details.lineCostCenter !== supplierData.defaultCostCenters[0].code) {
      setSelectedCostCenterCode(details.lineCostCenter);
      setCCModalOpen(true);
    }
  };

  const handleAddCostCenter = (prop) => {
    setSelectedCostCenterCode(null);
    setNewlyAddedVal(null);
    setCCModalOpen(true);
    setNewlyAddingProp(prop);
  };

  const onCCCreated = (val) => {
    setNewlyAddedVal(val);
  };

  const onCCModalClose = () => {
    setCCModalOpen(false);
    setSelectedCostCenterCode(null);
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
  };

  const handleChangeForm = (value, prop) => {
    if (prop === 'line') {
      let hasError = false;
      if (value.trim() === '') {
        setLineErr('EMPTY_LINE_ERR');
        hasError = true;
      }

      if (value && !/^\d+$/.test(value)) {
        setLineErr('INVALID_LINE_NUMBER');
        hasError = true;
      }

      if (!hasError) {
        setLineErr('');
      }
    }

    if (lineAmountRelatedFields.includes(prop)) {
      if (value && !amountRegex.test(value)) {
        setErrorMessages({ ...errorMessages, [prop]: t('INVALID_NUMERIC_INPUT_FOR_INVOICE') });
      } else {
        setErrorMessages({ ...errorMessages, [prop]: '' });
      }
    }

    if (user.isDocunecta && makroSuppliers.includes(senderVatNumber)) {
      let val = value;
      if (value === '') {
        val = '0';
      }
      if (prop === 'lineUnitPrice' || prop === 'lineQuantity' || prop === 'lineProductCount') {
        const lineUnitPrice = prop === 'lineUnitPrice' ? val : details.lineUnitPrice;
        const lineQuantity = prop === 'lineQuantity' ? val : details.lineQuantity;
        const lineProductCount = prop === 'lineProductCount' ? val : details.lineProductCount;

        const newDetails = {
          ...details,
          [prop]: value,
        };

        if (amountRegex.test(lineUnitPrice) && amountRegex.test(lineQuantity) && amountRegex.test(lineProductCount)) {
          const linePrice = calculateLinePrice(lineUnitPrice, lineQuantity, lineProductCount, decimalSeparator);
          newDetails.linePrice = linePrice;
          newDetails.lineDiscountedPrice = caclulateLineDiscountedPrice(linePrice, details.lineDiscountAmountOnTotalPrice, decimalSeparator);
          newDetails.lineAmount = linePrice;
          if (details.lineVatrate !== '') {
            const lineTax = calculateLineTax(linePrice, details.lineVatrate, decimalSeparator);
            newDetails.lineTax = lineTax;
            newDetails.lineAmount = calculateLineAmount(linePrice, lineTax, decimalSeparator);
          }
        }
        setDetails(newDetails);
        return;
      }

      if (prop === 'linePrice' && value !== '') {
        const newDetails = {
          ...details,
          [prop]: value,
          lineDiscountedPrice: caclulateLineDiscountedPrice(val, details.lineDiscountAmountOnTotalPrice, decimalSeparator),
          lineAmount: value
        };
        if (details.lineVatrate !== '') {
          const lineTax = calculateLineTax(val, details.lineVatrate, decimalSeparator);
          newDetails.lineTax = lineTax;
          newDetails.lineAmount = calculateLineAmount(val, lineTax, decimalSeparator);
        }
        setDetails(newDetails);
        return;
      }

      if (prop === 'lineDiscountAmountOnTotalPrice' && details.linePrice !== '') {
        setDetails({
          ...details,
          [prop]: value,
          lineDiscountedPrice: caclulateLineDiscountedPrice(details.linePrice, val, decimalSeparator),
        });
        return;
      }

      if (prop === 'lineVatrate' && details.linePrice !== '') {
        const lineTax = calculateLineTax(details.linePrice, val, decimalSeparator);
        setDetails({
          ...details,
          [prop]: value,
          lineTax,
          lineAmount: calculateLineAmount(details.linePrice, lineTax, decimalSeparator),
        });
        return;
      }
    }

    if (user.isDocunecta && cocaColaSuppliers.includes(senderVatNumber)) {
      let val = value;
      if (value === '') {
        val = '0';
      }
      if (prop === 'lineVatrate' && details.lineDiscountedPrice !== '') {
        const newDetails = {
          ...details,
          [prop]: value
        };
        if (details.lineDiscountedPrice !== '') {
          newDetails.lineTax = calculateLineTax(details.lineDiscountedPrice, val, decimalSeparator);
          newDetails.lineAmount = calculateLineAmount(details.lineDiscountedPrice, newDetails.lineTax, decimalSeparator);
        }
        setDetails(newDetails);
        return;
      }

      if (prop === 'lineDiscountedPrice') {
        const newDetails = {
          ...details,
          [prop]: value
        };
        if (details.lineVatrate !== '') {
          newDetails.lineTax = calculateLineTax(val, details.lineVatrate, decimalSeparator);
          newDetails.lineAmount = calculateLineAmount(val, newDetails.lineTax, decimalSeparator);
        }
        if (details.lineQuantity !== '') {
          newDetails.lineUnitPrice = calculateLineUnitPrice(val, details.lineQuantity, decimalSeparator);
        }
        setDetails(newDetails);
        return;
      }

      if (prop === 'lineQuantity' && details.lineDiscountedPrice !== '') {
        const newDetails = {
          ...details,
          [prop]: value
        };
        if (value !== '') {
          newDetails.lineUnitPrice = calculateLineUnitPrice(details.lineDiscountedPrice, value, decimalSeparator);
        } else {
          newDetails.lineUnitPrice = '';
        }
        setDetails(newDetails);
        return;
      }
    }

    setDetails({ ...details, [prop]: value });
  };

  const handleChangeAutocomplete = (e, prop, values) => {
    if (values && values.code) {
      handleChangeForm(values.code, prop);
      return;
    }

    handleChangeForm(values, prop);
  };

  const handleOnBlur = (value, prop) => {
    if (prop !== 'lineArticle' && prop !== 'lineArticleClient') {
      return;
    }
    let product;
    if (supplier) {
      const supplierProducts = supplier?.inventories || [];
      if (prop === 'lineArticle' && supplierProducts.some((p) => p.article === value)) {
        product = supplierProducts.find((p) => p.article === value);
        if (product) {
          setDetails({
            ...details,
            [prop]: value,
            lineArticleClient: product.articleClient || '',
            lineUnitMeasurement: product.unitMeasurement || '',
          });
        }
      } else if (prop === 'lineArticleClient' && supplierProducts.some((p) => p.articleClient === value)) {
        product = supplierProducts.find((p) => p.articleClient === value);
        if (product) {
          setDetails({
            ...details,
            [prop]: value,
            lineArticle: product.article || '',
            lineUnitMeasurement: product.unitMeasurement || '',
          });
        }
      }
    }
  };

  const handleOnBlurUnitMeasurementText = (e, prop) => {
    const { value } = e.target;
    if (!supplierUnitMeasurementVals.includes(value)) {
      setUnitMeasurementVals([...supplierUnitMeasurementVals, value]);
    } else {
      setUnitMeasurementVals(supplierUnitMeasurementVals);
    }
    handleChangeForm(value, prop);
  };

  const dimensionLists = {
    lineGLAccount: glas,
    lineVatGroup: vatGroups,
    lineCostCenter: costCenters,
    lineProject: projects
  };

  const dimensionFunctions = {
    lineGLAccount: {
      edit: handleEditGLA,
      add: handleAddGLA,
      default: [{
        code: 'None',
        name: ''
      }]
    },
    lineVatGroup: {
      edit: handleEditVATCode,
      add: handleAddVATCode,
      default: [{
        code: 'No VAT',
        name: ''
      }]
    },
    lineCostCenter: {
      edit: handleEditCostCenter,
      add: handleAddCostCenter,
      default: [{
        code: 'None',
        name: ''
      }]
    },
  };

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChangeForm(newlyAddedVal, newlyAddingProp);
    }
  }, [newlyAddedVal]);

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        setTextFieldSelectedKey('');
      }
    });
    document.addEventListener('click', (e) => {
      if (![
        'canvasId2',
        'nav_previous',
        'nav_next',
        ...lineFieldIds,
      ].some((elemId) => elemId === e.target.id)) {
        setTextFieldSelectedKey('');
        if (textFieldSelectedValueRef.current === '') {
          setTextFieldSelectedValue(' ');
        } else {
          setTextFieldSelectedValue('');
        }
      }
    });
  }, []);

  useEffect(() => {
    if (textFieldSelectedKey && xmlSelectedValue?.trim() && xmlSelectedValue.trim() !== details[textFieldSelectedKey]) {
      if (amountRelatedFields.includes(textFieldSelectedKey) && (!amountRegex.test(xmlSelectedValue.trim()) || decimalSeparator !== '.')) {
        handleChangeForm(formatAmountFields(xmlSelectedValue.trim(), decimalSeparator), textFieldSelectedKey);
      } else {
        handleChangeForm(xmlSelectedValue.trim(), textFieldSelectedKey);
      }
    } else if (xmlSelectedValue?.trim()) {
      let selectedKey = '';
      Object.keys(details).forEach((k) => {
        if (details[k] === xmlSelectedValue.trim()) {
          if (selectedKey) {
            selectedKey = selectedKey.concat(' ').concat(k);
          } else {
            selectedKey = k;
          }
        }
      });
      setTextFieldSelectedKey(selectedKey);
    } else if (!xmlSelectedValue) {
      setTextFieldSelectedKey('');
    }
  }, [xmlSelectedValue]);

  useEffect(() => {
    if (textFieldSelectedKey === '') {
      updateAnnotateFieldsTime();
      setAnnotateSelectedField(null);
      setAnnotateFieldStart(null);
    }
    if (textFieldSelectedKey !== '' && !isInViewPort[textFieldSelectedKey] && fieldRef[textFieldSelectedKey]) {
      fieldRef[textFieldSelectedKey].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [textFieldSelectedKey]);

  const onFocusField = (prop) => {
    updateAnnotateFieldsTime();
    setTextFieldSelectedKey(prop);
    setAnnotateFieldStart(new Date().getTime());
    setAnnotateSelectedField(prop);
    setTextFieldSelectedValue(details[prop]);
  };

  const getLabel = (entities, code) => {
    for (let i = 0; i < entities?.length; i++) {
      if (code === entities[i].code) {
        let namePart = '';
        if (entities[i].name) {
          namePart = ` | ${entities[i].name}`;
        }
        return `${entities[i].code}${namePart}`;
      }
    }

    return code;
  };

  const isFieldAvailable = (field, isActive) => {
    if (user.isDocunecta) {
      if (docunectaInvoiceLineFields.includes(field) || isActive) {
        return true;
      }
      return false;
    }
    if (user?.interfacePreference === 'twinfield_interface') {
      return true;
    }
    return isActive;
  };

  const validation = (item, name, error, regex) => {
    const isError = item !== undefined
    && item !== ''
    && (
      Boolean(error)
      || !regex.test(item)
    );

    return isError;
  };

  return (
    <>
      <Modal
        style={styles.modal}
        open={addLineOpen}
        onClose={() => handleClose()}
        closeAfterTransition
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
      >
        <Fade in={addLineOpen}>
          <div style={styles.paper}>
            <Grid container style={styles.modalHeader}>
              <Grid item xs={11} md={4} style={styles.modalHeaderLeft}>
                <Grid style={styles.titleContainer}>
                  <Typography style={styles.headerTitle}>
                    {ready && t('INVOICE_EDIT_FORM_ADD_INVOICE_LINE')}
                  </Typography>
                </Grid>
                {isShowManageFields(user, 'applyParentFieldsLines') && (
                  <Grid item xs={12} style={styles.manageFieldsBtnContainer}>
                    <Button
                      variant="outlined"
                      style={styles.manageFieldsBtn}
                      onClick={() => setShowManageFields(true)}
                    >
                      <SettingsIcon style={styles.btnIcon} />
                      {ready && t('INVOICE_EDIT_FORM_MANAGE_FIELDS')}
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={1} md={8} style={styles.modalHeaderRight}>
                <IconButton onClick={() => handleClose()}>
                  <CloseIcon style={styles.closeIcon} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid style={styles.mainContainer} container>
              <Grid item xs={12} md={4} style={styles.leftContainer}>
                <Formik
                  initialValues={lineDetails}
                  validationSchema={Yup.object().shape({})}
                  onSubmit={async () => {
                    const body = {
                      line: details.line,
                      lineDescription: details.lineDescription,
                      lineGLAccount: details.lineGLAccount,
                      lineVatGroup: details.lineVatGroup,
                      lineCostCenter: details.lineCostCenter,
                      lineProject: details.lineProject,
                      lineUnitPrice: details.lineUnitPrice,
                      lineQuantity: details.lineQuantity,
                      lineDiscount: details.lineDiscount,
                      lineVatrate: details.lineVatrate,
                      lineArticle: details.lineArticle,
                      lineVatcode: details.lineVatcode,
                      lineDiscountedPrice: details.lineDiscountedPrice,
                      lineDiscountAmountOnUnitPrice: details.lineDiscountAmountOnUnitPrice,
                      lineDiscountAmountOnTotalPrice: details.lineDiscountAmountOnTotalPrice,
                      lineTax: details.lineTax,
                      lineDeposit: details.lineDeposit,
                      linePrice: details.linePrice,
                      lineAmount: details.lineAmount,
                      lineExtraCost: details.lineExtraCost === 'INVOICE_LINE_ADD_YES',
                      lineReferenceNumber: details.lineReferenceNumber,
                      lineProductCount: details.lineProductCount,
                      lineUnitMeasurement: details.lineUnitMeasurement,
                      lineArticleClient: details.lineArticleClient,
                      lineBaseAmount: details.lineBaseAmount,
                      lineRetentionAmount: details.lineRetentionAmount,
                      lineRetentionPercentage: details.lineRetentionPercentage,
                      lineCategory: details.lineCategory,
                      lineProductCode: details.lineProductCode,
                      lineTotalPV: details.lineTotalPV,
                      lineMeasureType: details.lineMeasureType,
                      lineDelRecCompName: details.lineDelRecCompName,
                      lineDelRecContName: details.lineDelRecContName,
                      lineDelRecAddress: details.lineDelRecAddress,
                      lineDelRecPhoneNumber: details.lineDelRecPhoneNumber,
                      lineDelRecEmail: details.lineDelRecEmail,
                      lineDeliveryDate: details.lineDeliveryDate,
                      lineTeilnr: details.lineTeilnr,
                      lineTeilnrDelivery: details.lineTeilnrDelivery,
                      linePoReferenceNumber: details.linePoReferenceNumber,
                    };

                    let isFormValid = true;

                    if (Object.keys(errorMessages).some((o) => errorMessages[o] !== '')) {
                      isFormValid = false;
                    }

                    if (isFormValid) {
                      onAddLine(body);
                    }
                  }}
                >
                  {({
                    errors,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form
                      style={styles.root}
                      onSubmit={handleSubmit}
                    >
                      <Grid style={styles.userForm}>
                        <Grid style={styles.fieldsWrapper}>
                          {fields.map(({
                            key, label, isRequired, isActive, isAvailable
                          }) => {
                            if (!isFieldAvailable(key, (isAvailable && isActive))
                              || (isTwinfield && !twinfieldLineFields.includes(key))) {
                              return null;
                            }
                            const isAmountRelatedField = lineAmountRelatedFields.includes(key);
                            switch (key) {
                              case 'line':
                                return (
                                  <Grid style={styles.fieldContainer}>
                                    <TextField
                                      fullWidth
                                      label={ready && t('INVOICE_EDIT_FORM_LINE_LINE')}
                                      name="line"
                                      type="number"
                                      value={details.line}
                                      onChange={(e) => handleChangeForm(e.target.value, 'line')}
                                      required
                                      variant="outlined"
                                      style={styles.inputField}
                                      error={lineErr !== ''}
                                      helperText={lineErr}
                                      onFocus={() => setTextFieldSelectedKey('')}
                                    />
                                  </Grid>
                                );

                              case 'lineGLAccount': case 'lineVatGroup': case 'lineCostCenter': case 'lineProject':
                                return (
                                  <Grid style={styles.fieldContainer}>
                                    <FormControl variant="outlined" style={styles.formControl}>
                                      <Autocomplete
                                        id={key}
                                        fullWidth
                                        name={key}
                                        onChange={(e, v) => handleChangeAutocomplete(e.target.value, key, v)}
                                        onFocus={() => { setTextFieldSelectedKey(key); setTextFieldSelectedValue(details[key]); }}
                                        value={getLabel(dimensionLists[key], details[key])}
                                        options={dimensionLists[key]}
                                        getOptionLabel={(option) => option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option}
                                        getOptionSelected={(option) => option.code === (details[key] ? details[key] : ' ')}
                                        renderInput={(params) => (
                                          <TextField
                                            innerRef={fieldRef[key]}
                                            fullWidth
                                            {...params}
                                            label={ready && t(label)}
                                            variant="outlined"
                                            error={
                                              validation(
                                                details[key],
                                                key,
                                                errors[key],
                                                noWhiteSpace
                                              )
                                            }
                                            helperText={
                                              (details[key] !== undefined
                                              && details[key] !== '')
                                              && ((errors[key])
                                              || (!noWhiteSpace.test(details[key])
                                                && t('NO_WHITE_SPACE_HELPER_TEXT')))
                                            }
                                            style={textFieldSelectedKey === key ? styles.selectStyleHighlighted : styles.selectStyle}
                                            InputProps={{
                                              ...params.InputProps,
                                              endAdornment: key !== 'lineProject' && (
                                                <>
                                                  {details[key] !== dimensionFunctions[key].default[0].code && (
                                                    <InputAdornment
                                                      position="end"
                                                      onClick={dimensionFunctions[key].edit}
                                                      style={styles.editIconContainer}
                                                    >
                                                      <EditIcon style={styles.editDimensionIcon} />
                                                    </InputAdornment>
                                                  )}
                                                  <InputAdornment position="end" style={styles.plusIconContainer}>
                                                    <PlusIcon style={styles.plusIcon} onClick={() => dimensionFunctions[key].add(key)} />
                                                  </InputAdornment>
                                                </>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                );

                              case 'lineUnitMeasurement':
                                return (
                                  <Grid style={styles.fieldContainer}>
                                    <FormControl variant="outlined" style={styles.formControl}>
                                      <Autocomplete
                                        id={key}
                                        fullWidth
                                        name={key}
                                        onChange={(e, v) => handleChangeAutocomplete(e.target.value, key, v)}
                                        onFocus={() => onFocusField(key)}
                                        value={details[key]}
                                        options={unitMeasurementVals}
                                        getOptionLabel={(option) => option}
                                        getOptionSelected={(option) => option === (details[key] ? details[key] : ' ')}
                                        renderInput={(params) => (
                                          <TextField
                                            innerRef={fieldRef[key]}
                                            fullWidth
                                            {...params}
                                            label={ready && t(label)}
                                            variant="outlined"
                                            error={
                                              validation(
                                                details[key],
                                                key,
                                                errors[key],
                                                noWhiteSpace
                                              )
                                            }
                                            helperText={
                                              (details[key] !== undefined
                                              && details[key] !== '')
                                              && ((errors[key])
                                              || (!noWhiteSpace.test(details[key])
                                                && t('NO_WHITE_SPACE_HELPER_TEXT')))
                                            }
                                            onBlur={(e) => handleOnBlurUnitMeasurementText(e, key)}
                                            style={textFieldSelectedKey === key ? styles.selectStyleHighlighted : styles.selectStyle}
                                            InputProps={params.InputProps}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                );

                              case 'lineExtraCost':
                                return (
                                  <Grid style={styles.fieldContainer}>
                                    <FormControl variant="outlined" style={styles.formControl}>
                                      <InputLabel id="ec-label">{ready && t('INVOICE_EDIT_FORM_LINE_EXTRA_COST')}</InputLabel>
                                      <Select
                                        labelId="ec-label"
                                        id="ec"
                                        onChange={(e) => handleChangeForm(e.target.value, 'lineExtraCost')}
                                        onFocus={() => setTextFieldSelectedKey('')}
                                        name="lineExtraCost"
                                        value={details.lineExtraCost}
                                        label={ready && t('INVOICE_EDIT_FORM_LINE_EXTRA_COST')}
                                        sx={{
                                          '&. MuiSelect-select': styles.selectStyle
                                        }}
                                      >
                                        {
                                          ['INVOICE_LINE_ADD_YES', 'INVOICE_LINE_ADD_NO'].map((o) => (
                                            <MenuItem key={o} value={o}>
                                              {t(o)}
                                            </MenuItem>
                                          ))
                                        }
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                );

                              default:
                                return (
                                  <Tooltip title={invoiceLineTooltips[key] ? t(invoiceLineTooltips[key]) : ''}>
                                    <Grid style={styles.fieldContainer}>
                                      <TextField
                                        id={key}
                                        ref={fieldRef[key]}
                                        fullWidth
                                        required={isRequired}
                                        label={ready && t(label)}
                                        name={key}
                                        value={details[key]}
                                        onChange={(e) => handleChangeForm(e.target.value, key)}
                                        onBlur={(e) => handleOnBlur(e.target.value, key)}
                                        onFocus={() => onFocusField(key)}
                                        variant="outlined"
                                        error={
                                          validation(
                                            details[key],
                                            key,
                                            errors[key],
                                            isAmountRelatedField ? amountRegex : noWhiteSpace
                                          )
                                        }
                                        helperText={(validation(
                                          details[key],
                                          key,
                                          errors[key],
                                          isAmountRelatedField ? amountRegex : noWhiteSpace
                                        ) && t(isAmountRelatedField ? 'INVALID_NUMERIC_INPUT_FOR_INVOICE' : 'NO_WHITE_SPACE_HELPER_TEXT'))}
                                        style={textFieldSelectedKey === key ? styles.inputFieldHighlighted : styles.inputField}
                                      />
                                    </Grid>
                                  </Tooltip>
                                );
                            }
                          })}
                        </Grid>
                        {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>
                            {t(errors.submit)}
                          </FormHelperText>
                        </Box>
                        )}
                        <Box mt={2} display="flex" justifyContent="flex-end">
                          <Button
                            variant="text"
                            style={styles.closeBtn}
                            disabled={isSubmitting}
                            onClick={handleClose}
                          >
                            {ready && t('INVOICE_EDIT_FORM_CANCEL')}
                          </Button>
                          <Button
                            variant="contained"
                            style={styles.submitBtn}
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {ready && t('INVOICE_EDIT_FORM_ADD_LINE')}
                          </Button>
                        </Box>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
              <Grid item xs={12} md={8} style={styles.invoiceContainer}>
                {renderInvoicePDF(xmlSelectedValue, setXmlSelectedValue, textFieldSelectedValue, scrollXMLViewBy, true, textFieldSelectedKey)}
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <ManageFields
        open={showManageFields}
        entity="invoice-line"
        fields={fields}
        fetchFields={getFields}
        closeModal={handleCloseManageFields}
        parameterCompany={parameterCompany}
      />
      <GLAccountModal
        gLAccountModalOpen={gLAccountModalOpen}
        handleClose={onGLAModalClose}
        getAllGLAccounts={getAllGLAccounts}
        getVatCodes={getAllVatCodes}
        onSuccess={onGLACreated}
        selectedGLA={selectedGLA}
        glaYears={glaYears}
        glaPeriods={glaPeriods}
      />
      <VatCodeModal
        vatCodeModalOpen={vatCodeModalOpen}
        handleClose={onVatCodeModalClose}
        getAllVatCodes={getAllVatCodes}
        onSuccess={onVatCodeCreated}
        selectedVatCode={selectedVATGroupCode}
      />
      <CostCenterModal
        costCenterModalOpen={ccModalOpen}
        handleClose={onCCModalClose}
        getAllCostCenters={getAllCostCenters}
        onSuccess={onCCCreated}
        selectedCostCenter={selectedCostCenterCode}
      />
    </>
  );
}

export default InvoiceLineAddForm;
