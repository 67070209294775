import { useEffect } from 'react';
import NProgress from 'nprogress';

import { Box, LinearProgress, Grid, Typography } from '@mui/material';
import theme from 'src/theme';

function LoadingScreen({ text } : { text?: string }) {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Grid sx={styles.root}>
      <Box width={400}>
        <Typography
          align="center"
          variant="subtitle1"
        >
          {text}
        </Typography>
        <LinearProgress />
      </Box>
    </Grid>
  );
}

export default LoadingScreen;

const styles = {
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 56px)',
    height: '100%',
    maxHeight: 'calc(100vh - 56px)',
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(3),
  }
}
