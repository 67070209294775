import { Grid } from '@mui/material';

import { SectionType } from 'src/settings/settingsTypes';
import PlanAndBilling from 'src/plan-n-billing/PlanAndBilling';
import SecuritySettings from 'src/settings/components/SecuritySettings/SecuritySettings';
import MyProfile from 'src/settings/components/MyProfile/MyProfile';
import SystemSettings from 'src/settings/components/SystemSettings/SystemSettings';
import PartnerStatistics from 'src/settings/components/PartnerStatistics/PartnerStatistics';
import Companies from 'src/settings/components/Companies/Companies';
import Users from 'src/settings/components/Users/Users';
import BrandSettings from 'src/settings/components/BrandSettings/BrandSettings';
import Hacienda from 'src/account/components/Hacienda/Hacienda';
import { useAuth } from 'src/hooks/useAuth';

const ContentContainer = (props: { selectedSection: SectionType }) => {
  const { selectedSection } = props;
  const { user } = useAuth();
  
  const isHacienda = user?.integrations.includes('hacienda-certification');

  return (
    <Grid className="x-scroll" sx={styles.mainContainer}>
      {selectedSection.id === 'myprofile' && (<MyProfile />)}
      {selectedSection.id === 'users' && (<Users />)}
      {
        !user?.isSubCompany && !user?.isPartnerSubCompany && !user?.parent
        && selectedSection.id === 'plannbilling' && (<PlanAndBilling />)
      }
      {selectedSection.id === 'system' && (<SystemSettings />)}
      {selectedSection.id === 'security' && <SecuritySettings />}
      {selectedSection.id === 'companies' && (<Companies />)}
      {
        user?.userRole === 'admin' && !user.isSubCompany && !user.isPartnerSubCompany
        && selectedSection.id === 'brand' && <BrandSettings />
      }
      {
        user?.userRole === 'admin' && !user.isSubCompany
        && selectedSection.id === 'partnerstats' && <PartnerStatistics />
      }
      {isHacienda && selectedSection.id === 'hacienda' && <Hacienda />}
    </Grid>
  );
};

export default ContentContainer;

const styles = {
  mainContainer:{
    height:'100%',
    width:'calc(100% - 300px)',
    display:'flex',
    flexDirection:'column',
    borderRight: '1px solid #4C4E641F',
  },
};
