import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Backdrop, Box, Button, ButtonGroup, CircularProgress, ClickAwayListener, Fade, Grid, Grow,
  IconButton, MenuItem, MenuList, Modal, Paper, Popper, Tooltip, Typography, Alert
} from '@mui/material';
import {
  Close as CloseIcon,
  ArrowDropDown
} from '@mui/icons-material';

import { isActionPermitted, permissions } from 'src/utils/helpers';
import { AuthContext } from 'src/contexts/AuthContext';
import styles from './style';

const ChooseValidatorModal = (props) => {
  const { ready, t } = useTranslation();
  const { user  } = useContext(AuthContext);

  const {
    chooseValidatorModalOpen,
    handleChooseValidatorModalClose,
    validatorOptionsRef,
    handleValidatorOptionsToggle,
    selectedValidator,
    validatorOptionsOpen,
    handleValidatorOptionsClose,
    users,
    handleValidatorClick,
    handleAddUserClick,
    statusChangeLoading,
    onSendToValidate
  } = props;
  return (
    <Modal
      style={styles.modal}
      open={chooseValidatorModalOpen}
      onClose={() => handleChooseValidatorModalClose()}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={chooseValidatorModalOpen}>
        <div style={styles.halfPaper}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {ready && t('CHOOSE_VALIDATOR_MODAL_TITLE')}
            </Typography>
            <IconButton onClick={() => handleChooseValidatorModalClose()}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          <Grid>
            <Typography variant="caption" style={styles.primaryTextColor}>
              {ready && t('CHOOSE_VALIDATOR_MODAL_SUB_TITLE')}
            </Typography>
            <Grid style={styles.fieldContainerFirst}>
              <Typography style={{ ...styles.primaryTextColor, fontSize: 16, fontWeight: 500 }}>
                {ready && t('CHOOSE_VALIDATOR_MODAL_VALIDATOR')}
              </Typography>
              <ButtonGroup variant="outlined" ref={validatorOptionsRef} aria-label="split button" style={styles.btnGroup}>
                <Button style={styles.groupBtn} onClick={handleValidatorOptionsToggle}>
                  {`${selectedValidator?.firstname} ${selectedValidator?.lastname}`}
                  <ArrowDropDown />
                </Button>
              </ButtonGroup>
              <Popper
                open={validatorOptionsOpen}
                anchorEl={validatorOptionsRef.current}
                role={undefined}
                transition
                style={{
                  zIndex: 111111, width: '90%', maxWidth: 500, padding: '0px 28px',
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleValidatorOptionsClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {users.map((option) => (
                            <MenuItem
                              key={option.id}
                              selected={option.id === selectedValidator.id}
                              onClick={() => handleValidatorClick(option)}
                              style={styles.groupBtn}
                            >
                              {`${option.firstname} ${option.lastname}`}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
            <Grid style={styles.fieldContainerFirst}>
              <Typography variant="caption" style={styles.primaryTextColor}>
                {ready && t('CHOOSE_VALIDATOR_MODAL_ADD_USER_1')}
                <Tooltip title={!isActionPermitted(permissions.userAdd, user.permissions)
                  ? ready && t('CHOOSE_VALIDATOR_MODAL_ADD_USER_PERMISSION') : ''}
                >
                  <Typography
                    variant="caption"
                    style={isActionPermitted(permissions.userAdd, user.permissions) ? styles.link : styles.disabledLink}
                    onClick={() => isActionPermitted(permissions.userAdd, user.permissions) && handleAddUserClick()}
                  >
                    {ready && t('CHOOSE_VALIDATOR_MODAL_ADD_USER_2')}
                  </Typography>
                </Tooltip>
                {ready && t('CHOOSE_VALIDATOR_MODAL_ADD_USER_3')}
              </Typography>
            </Grid>
            {
              user.usersCount >= user.maxUsers && (
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                style={styles.addUserDisabled}
              >
                <Alert severity="warning" style={styles.warningBanner}>
                  <Typography style={styles.addUserDisabledText}>
                    {ready && t('ACCOUNT_SETTINGS_ADD_USER_DISABLED')}
                    <strong>
                      <Link href="/subscription-plan">
                        {ready && t('ACCOUNT_SETTINGS_ADD_USER_UPDATE_LINK')}
                      </Link>
                    </strong>
                  </Typography>
                </Alert>
              </Grid>
              )
            }
          </Grid>
          <Grid style={styles.userForm}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                style={styles.cancelBtn}
                onClick={() => handleChooseValidatorModalClose()}
              >
                {ready && t('CHOOSE_VALIDATOR_MODAL_CANCEL')}
              </Button>
              {statusChangeLoading ? (
                <Grid style={styles.loadingAction}>
                  <CircularProgress size={20} />
                </Grid>
              ) : (
                <Button
                  style={styles.validateButton}
                  variant="contained"
                  onClick={(e) => onSendToValidate(e)}
                >
                  {ready && t('CHOOSE_VALIDATOR_MODAL_SEND')}
                </Button>
              )}
            </Box>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default ChooseValidatorModal;
