import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Table, TableBody, TableCell, TableRow, Hidden, Card, Grid, TablePagination,
  Tooltip, IconButton, useMediaQuery, TableHead
} from '@mui/material';
import {
  CardTravel as LogsIcon
} from '@mui/icons-material';

import { useTranslation } from 'react-i18next';

import { useAuth } from 'src/hooks/useAuth';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import { applyCostcenterFilters, isActionPermitted, permissions } from 'src/utils/helpers';
import styles from './style';

const CostCentersTable = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const canEditDimension = isActionPermitted(permissions.dimensionEdit, user.permissions);

  const {
    costCenters, query, gotoItem
  } = props;

  const { ready, t } = useTranslation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const maxWidthRows = useMediaQuery('(max-width: 625px)');

  const filteredCostCenters = applyCostcenterFilters(costCenters, query);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const paginationTab = () => (
    <TablePagination
      component="div"
      // classes={{
      //   caption: classes.paginationCaption,
      //   selectRoot: classes.paginationCaption,
      //   menuItem: classes.paginationCaption,
      //   actions: classes.paginationActions,
      // }}
      count={filteredCostCenters.length}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleLimitChange}
      page={page}
      rowsPerPage={limit}
      rowsPerPageOptions={[10, 25, 50]}
      labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
    />
  );

  const gotoLogs = (event, code) => {
    event.stopPropagation();
    event.preventDefault();
    navigate(`/dimensions/logs/costcenters/code/${code}`, { state: { code } });
  };

  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        !isActionPermitted(permissions.dimensionRead, user.permissions)
          ? (
            <EmptyText str="COST_CENTER_LIST_NO_PERMISSION" />
          )
          : filteredCostCenters && filteredCostCenters.length > 0 ? (
            <Card
              style={styles.cardCont}
            >
              <Box minWidth="100%">
                <div style={styles.bulkOperations}>
                  <div style={styles.bulkActions}>
                    {paginationTab()}
                  </div>
                </div>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={styles.headerName}>{ready && t('DIMENSIONS_LIST_VIEW_HEADER_NAME')}</TableCell>
                      <TableCell style={styles.headerName}>{ready && t('DIMENSIONS_LIST_VIEW_HEADER_CODE')}</TableCell>
                      <Hidden smDown>
                        <TableCell />
                      </Hidden>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredCostCenters.slice(limit * page, limit * (page + 1)).map((costCenter) => (
                      <TableRow
                        hover={canEditDimension}
                        key={costCenter.code}
                        style={canEditDimension ? styles.pointer : styles.tableRow}
                        onClick={() => canEditDimension && gotoItem(costCenter.code)}
                      >
                        <TableCell align="left" style={styles.creditorNameStyle}>
                          {costCenter.name}
                        </TableCell>
                        <TableCell style={styles.todayText}>
                          {costCenter.code}
                        </TableCell>
                        <Hidden smDown>
                          <TableCell width={102} align="center" style={styles.currencyText}>
                            <Tooltip title={t('COST_CENTER_LIST_VIEW_LOGS_TOOLTIP')}>
                              <IconButton
                                onClick={(e) => gotoLogs(e, costCenter.code)}
                              >
                                <LogsIcon
                                  style={{ ...styles.logsIcon, fontSize: '1.8rem' }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </Hidden>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={styles.bottomPagi}
                >
                  {paginationTab()}
                </Grid>
              </Box>
            </Card>
          ) : (
            <EmptyText str="NO_COST_CENTERS" />
          )
      }
    </>
  );
};

export default CostCentersTable;
