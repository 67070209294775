const styles = {
  tableWrapper:{
    display:'flex',
    borderRadius:'8px 8px 0 0'
  },
  table:{
    marginBlockStart:'15px',
  },
  tableHeadRow:{
    width:'100%',
    // backgroundColor:'#C2CFE033',
    padding:'19px 15px 19px 0!important',
    alignItems:'center',
    borderRadius:'8px 8px 0 0',
    height:'56px',
  },
  tableBodyRow:{
    width:'100%',
    backgroundColor:'#fff',
    padding:'20px 15px 20px 0',
    alignItems:'center',
    borderRadius:'8px 8px 0 0',
    height:'56px',
  },
  tableHeadRowDisplay:{
    backgroundColor:'#C2CFE033',
    borderImage: 'linear-gradient(transparent 25%, #4C4E641F 25% 75%, transparent 75%) 0 1 0 0/2px',
    fontSize:'12px',
    color:'#4C4E64DE',
    // border:'none',
    lineHeight:'1.2',
    // borderInlineEnd:'2px solid #4C4E641F',
    borderBottom:'none',
    textOverflow:'ellipsis',
    overflow:'clip visible',
    whiteSpace:'nowrap',
    paddingRight:'10px!important',
  },
  tableRowDisplay:{
    borderImage: 'linear-gradient(transparent 25%, #4C4E641F 25% 75%, transparent 75%) 0 1 0 0/2px',
    fontSize:'12px',
    color:'#4C4E64DE',
    // border:'none',
    lineHeight:'1.2',
    // borderInlineEnd:'2px solid #4C4E641F',
    borderBottom:'none',
    textOverflow:'ellipsis',
    overflow:'clip visible',
    whiteSpace:'nowrap',
    paddingRight:'10px!important',
  },
  generalTableText:{
    fontSize:'12px',
    color:'#4C4E64DE',
  },
  tableRowtext:{
    border:'none',
    paddingBlock:'0px',
    flexShrink:'1',
    lineHeight:'1.2',
    height:'18px',
    borderInlineEnd:'2px solid #4C4E641F',
    color:'#4C4E64',
    fontSize:'14px',
    fontWeight:'500',
    textOverflow:'ellipsis',
    overflow:'hidden',
    whiteSpace:'nowrap',
    display:'inline-block'
  },
  text:{
    color:'#4C4E64',
    fontSize:'14px',
    fontWeight:'500',
    textOverflow:'ellipsis',
    overflow:'hidden',
    whiteSpace:'nowrap',
    paddingRight:'10px',
    width:'100%'

  },
  highlightLabel:{
    color:'#4C4E64',
    fontSize:'14px',
    fontWeight:'500',
    textOverflow:'ellipsis',
    overflow:'hidden',
    whiteSpace:'nowrap',
    display:'inline-block',
    padding:'8px 12px',borderRadius:'8px',
    background: `rgba(109,120,141, .12)`,
  },
  avatarWrapper:{
    display:'flex',
    alignItems:'center',
    gap:'10px'
  },
  actionIconContainer:{
    display:'flex',
    alignItems:'center',
    gap:'16px',
    width:'100%',
    justifyContent:'flex-end',
    paddingRight:'10px'
  },
  actionIcon:{
    color:'#6D788D',
    width:'20px',
    height:'20px',
    cursor:'pointer'
  },
  userImage: {
    flexShrink: 0,
    height: 20,
    width: 20,
    color: '#C2CFE0',
    backgroundColor: '#EFF4FA',
    '& svg': {
      width: 20,
      height: 20
    }
  },
  bRightTop:{
    borderTopRightRadius: '8px',
  },
  bLeftTop:{
    borderTopLeftRadius: '8px',
  }
}

export default styles;