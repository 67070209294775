const styles = {
  container:{
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    backgroundColor: '#FFFFFF',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    paddingRight: '20px',
  },
  title: {
    fontSize: '20px',
    fontWeight: '500',
    color: '#4C4E64',
    lineHeight: '32px',
    marginBottom: '20px',
  },
  tableContainer: {
    width: '98%',
    overflowX: 'scroll',
    border: '1px solid #4C4E641F',
  },
  tableHeader: {
    backgroundColor: '#C2CFE033',
    overflow: 'hidden',
  },
  headerCell: {
    height: '56px',
    color: '#4C4E64DE',
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: '500',
    padding: '0px 12px !important',
    borderBottom: 'none !important',
    whiteSpace: 'nowrap',
  },
  bodyCell: {
    height: '56px',
    fontSize: '13px',
    lineHeight: '32px',
    fontWeight: '500',
    color: '#4C4E64',
    padding: '0 12px !important',
    whiteSpace: 'nowrap',
  },
  rowRed: {
    backgroundColor: '#EB5757',
  },
  viewInvoiceIcon: {
    color: '#6D788D',
    cursor: 'pointer',
    height: '20px',
    width: '20px',
  },
  closingPeriodBtn: {
    width: '108px',
    height: '36px',
    color: '#3E8AFF',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '22px',
    padding: '4px 9px',
    backgroundColor: '#3E8AFF1A',
    '&:hover': {
      backgroundColor: '#3E8AFF1A',
      boxShadow:' 0px 4px 8px -4px #4C4E646B',
    }
  },
  closingPeriodBtnIcon: {
    color: '#3E8AFF',
    width: '20px',
    height: '20px',
  },
  menuContainer:{
    boxShadow:' 0px 4px 8px -4px #4C4E646B',
    marginTop: '36px'
  },
  menuItem: {
    color: '#4C4E64',
    fontSize: '14px',
  }
};

export default styles;
