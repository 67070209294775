import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import { Formik, FormikErrors } from 'formik';
import * as Yup from 'yup';
import { countries, getEmojiFlag, TCountryCode } from 'countries-list';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';

import {
  Box, Button, Checkbox, Fade, FormControl, FormControlLabel, FormHelperText, Grid, Grow, IconButton,
  Autocomplete, List, ListItem, ListItemText, MenuItem, Modal, Paper, Popper, Select, TextField, Tooltip, Typography, InputLabel
} from '@mui/material';
import { Close as CloseIcon, HelpOutline as HelpOutlineIcon, FiberManualRecord as FiberManualRecordIcon } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import { projects } from 'src/config';
import { axiosHeaders, getLocalisedErrorString, isActionPermitted, isBranded, permissions } from 'src/utils/helpers';
import NewUserInviteLinkPopup from 'src/account/components/NewUserInviteLinkPopup/NewUserInviteLinkPopup';
import { CustomError } from 'src/types';
import styles from './style';

type PropsType = {
  addCompanyAndUserOpen: boolean;
  handleClose: () => void;
  getAllCompanies: () => void;
}

type BodyType = {
  isSubCompany: boolean;
  companyType: string;
  project: string;
  name: string;
  email: string;
  vatNumber: string;
  address: string;
  postalCode: string;
  contactNumber: string;
  country: string;
  city: string;
  parent?: string;

}
type CCountryType = {
  capital: string;
  continent: string;
  currency: string[];
  languages: string[];
  name: string;
  native: string;
  phone: number[]
}

type CountryType = {
  code: string;
  name: string;
}

function AddUserToCompanyForm( props: PropsType ) {
  const { addCompanyAndUserOpen, handleClose: _handleClose, getAllCompanies } = props;

  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const auth = useAuth();
  const { user } = auth;
  const allCountries: Record<string, CCountryType>  = countries as unknown as Record<string, CCountryType>;
  const { API, BRAND_NAME } = useContext(ConfigContext);

  const [step, setStep] = useState('company');
  const [countryList, setCountryList] = useState<CountryType[]>([]);
  const [subCompanyHelpShow, setSubCompanyHelpShow] = useState(false);
  const helpIconRef = useRef(null);
  const [companyValues, setCompanyValues] = useState({});

  const [userInviteLinkOpen, setUserInviteLinkOpen] = useState(false);
  const [userInviteLink, setUserInviteLink] = useState('');
  const [isUserExist, setIsUserExist] = useState(false);
  const emailRegexp = /^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/;

  const handleClose = () => {
    _handleClose();
    setStep('company');
  };

  const handleSubCompanyHelpClose = () => {
    setSubCompanyHelpShow(false);
  };

  const handleSubCompanyHelpOpen = () => {
    setSubCompanyHelpShow(true);
  };

  useEffect(() => {
    const countryData: CountryType[] = [];
    Object.keys(countries).forEach((key) => {
      const data = {
        code: key,
        name: allCountries[key]?.name
      };
      countryData.push(data);
    });

    setCountryList(countryData);
  }, []);

  const handleEmailBlur = async ( 
    val: string, 
    setErrors: Function, 
    setFieldValue: Function, 
    setTouched: Function, 
    errors: Record<string, string | boolean>, 
    touched: Record<string, string | boolean>  
  ) => {
    setTouched({ ...touched, email: true });
    const value = val;
    if (value) {
      if (!emailRegexp.test(value)) {
        setErrors({
          ...errors,
          email: t('INVALID_EMAIL_ADDRESS_HELPER_TEXT')
        });
        return;
      }
      try {
        const response = await Axios.post(
          `${API.companyAndUserDetails}`,
          { email: value },
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        if (response.data.success && response.data.data) {
          setIsUserExist(response.data.data.detailsRegistered);
          if (response.data.data.detailsRegistered) {
            setFieldValue('firstname', response.data.data.firstName);
            setFieldValue('lastname', response.data.data.lastName);
          } else {
            setFieldValue('firstname', '');
            setFieldValue('lastname', '');
          }
        }
      } catch (error) {
        setIsUserExist(false);
      }
    } else {
      setIsUserExist(false);
      setErrors({
        ...errors,
        email: t('EMAIL_ADDRESS_REQUIRED_HELPER_TEXT')
      });
    }
  };

  const handleBlurOthers = ( 
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, 
    values: Record<string, string | boolean>,
    handleBlur: Function, 
    setErrors: Function, 
    setTouched: Function, 
    errors: Record<string, string | boolean>, 
    touched: Record<string, string | boolean> 
  ) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: true });
    if (values.email) {
      handleBlur(e);
    } else {
      setErrors({
        ...errors,
        email: t('EMAIL_ADDRESS_REQUIRED_HELPER_TEXT')
      });
    }
  };

  const closeUserInviteLinkPopup = () => {
    setUserInviteLinkOpen(false);
    handleClose();
    getAllCompanies();
    navigate('/settings', { state: { tab: 3 } });
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      sx={styles.modal}
      open={addCompanyAndUserOpen}
      onClose={handleClose}
    >
      <Fade in={addCompanyAndUserOpen}>
        <Box sx={styles.paper} className='y-scroll'>
          <Grid sx={styles.modalHeader}>
            <Typography sx={styles.headerTitle}>
              {ready && t(step === 'company' ? 'ACCOUNT_SETTINGS_COMPANIES_ADD_NEW_COMPANY' : 'ACCOUNT_SETTINGS_COMPANIES_ADD_USER_TO_COMPANY')}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={styles.closeIcon} />
            </IconButton>
          </Grid>
          { step === 'company' 
          ? (
              <Formik
                initialValues={{
                  isSubCompany: Boolean(isBranded(BRAND_NAME) || user?.isSubCompany),
                  companyType: 'company',
                  project: '',
                  name: '',
                  vatNumber: '',
                  address: '',
                  postalCode: '',
                  contactNumber: '',
                  country: '',
                  city: '',
                  submit: '',
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_COMPANIES_NAME_REQ'))
                    .matches(/^(?=.*[\x21-\x7E])[\x20-\x7E]*$/, t('ERROR_COMPANY_NAME_INVALID')),
                  address: Yup.string().max(255).required(t('CREATE_COMPANY_ADDR_REQ')),
                  postalCode: Yup.string().max(255).required(t('CREATE_COMPANY_POSTAL_CODE_REQ')),
                  contactNumber: Yup.string().required(t('CREATE_COMPANY_CONTACT_NO_REQ'))
                    .matches(/^([+\d]\d*)$/, t('CREATE_COMPANY_CONTACT_NO_INVALID'))
                    .min(9, t('CREATE_COMPANY_CONTACT_NO_MIN'))
                    .max(16, t('CREATE_COMPANY_CONTACT_NO_MAX')),
                  country: Yup.string().max(255).required(t('CREATE_COMPANY_COUNTRY_REQ')),
                  city: Yup.string().max(255).required(t('CREATE_COMPANY_CITY_REQ')),
                  submit: Yup.bool(),
                })}
                onSubmit={async (values) => {
                  const body: BodyType = {
                    isSubCompany: values.isSubCompany,
                    companyType: values.companyType,
                    project: values.project,
                    name: values.name.trim(),
                    email: user?.email ? user.email : '',
                    vatNumber: values.vatNumber.trim(),
                    address: values.address,
                    postalCode: values.postalCode.trim(),
                    contactNumber: values.contactNumber.trim(),
                    country: values.country,
                    city: values.city.trim(),
                    parent: '',
                  };

                  if (values.isSubCompany) {
                    body.parent = user?.companyID || '';
                  }
                  else { delete body.parent; }

                  setCompanyValues(body);
                  setStep('user');
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form
                    style={styles.root}
                    onSubmit={handleSubmit}
                  >
                    <Grid sx={styles.userForm}>
                      <Grid sx={styles.modalContent}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sx={styles.fieldContainerRow}>
                            <Tooltip 
                              title={t('TOOLTIP_SUB_COMPANY_CAN_NOT_CREATE_SUB_COMPANY')} 
                              disableHoverListener={!user?.isSubCompany}
                            >
                              <FormControlLabel
                                control={(
                                  <Checkbox
                                    sx={styles.checkBox}
                                    checked={values.isSubCompany}
                                    onChange={(e) => handleChange({ target: { name: 'isSubCompany', value: e.target.checked } })}
                                    name="isSubCompany"
                                    disabled={isBranded(BRAND_NAME) || user?.isSubCompany}
                                  />
                                )}
                                label={ready && t('ACCOUNT_SETTINGS_COMPANIES_IS_SUB_COMPANY_CHECKBOX')}
                                labelPlacement="end"
                                sx={{
                                  '&.MuiFormControlLabel-root': styles.checkboxContainer,
                                }}
                              />
                            </Tooltip>
                            <IconButton
                              ref={helpIconRef}
                              sx={styles.helpIconBtn}
                              onMouseEnter={handleSubCompanyHelpOpen}
                              onMouseLeave={handleSubCompanyHelpClose}
                            >
                              <HelpOutlineIcon sx={styles.interfaceIcon} />
                            </IconButton>
                          </Grid>
                          <Grid item xs={12} sx={styles.topFieldContainer}>
                            <FormControl variant="outlined" sx={styles.formControl}>
                              <InputLabel id="company-type-label">{ready && `${t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE')}`}</InputLabel>
                              <Select
                                labelId="company-type-label"
                                label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE')}`}
                                value={values.companyType}
                                onChange={handleChange}
                                name="companyType"
                                placeholder={`${t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE')}`}
                                required
                                sx={{

                                  '&. MuiSelect-select': styles.selectStyle,
                                  ...styles.selectField,
                                }}
                              >
                                <MenuItem value="company" sx={styles.selectStyle}>
                                  {ready && `${t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE_01')}`}
                                </MenuItem>
                                <MenuItem value="agency" sx={styles.selectStyle}>
                                  {ready && `${t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE_02')}`}
                                </MenuItem>
                                <MenuItem value="freelancer" sx={styles.selectStyle}>
                                  {ready && `${t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE_03')}`}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sx={styles.topFieldContainer}>
                            <FormControl variant="outlined" sx={styles.formControl}>
                              <InputLabel id="company-project-label">{ready && `${t('ACCOUNT_SETTINGS_COMPANIES_PROJECT')}`}</InputLabel>
                              <Select
                                labelId="company-project-label"
                                label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_PROJECT')}`}
                                value={values.project}
                                onChange={handleChange}
                                name="project"
                                placeholder={`${t('ACCOUNT_SETTINGS_COMPANIES_PROJECT')}`}
                                sx={{
                                  '& .MuiSelect-select': styles.selectStyle,
                                  ...styles.selectField,
                                }}
                              >
                                {projects.map((project) => (
                                  <MenuItem key={project.key} value={project.key} sx={styles.selectStyle}>
                                    {ready && t(project.label)}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>{t('PROJECT_SPECIFICITY_HELPER_TEXT')}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sx={styles.topFieldContainer}>
                            <TextField
                              error={Boolean(touched.name && errors.name)}
                              fullWidth
                              helperText={touched.name && errors.name}
                              placeholder={t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_NAME')}
                              label={t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_NAME')}
                              name="name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.name}
                              variant="outlined"
                              sx={styles.inputField}
                            />
                          </Grid>
                          <Grid item xs={12} sx={styles.topFieldContainer}>
                            <TextField
                              error={Boolean(touched.vatNumber && errors.vatNumber)}
                              fullWidth
                              helperText={touched.vatNumber && errors.vatNumber}
                              placeholder={`${t('ACCOUNT_SETTINGS_COMPANIES_VAT_NUMBER')}`}
                              label={`${t('ACCOUNT_SETTINGS_COMPANIES_VAT_NUMBER')}`}
                              name="vatNumber"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.vatNumber}
                              variant="outlined"
                              sx={styles.inputField}
                            />
                          </Grid>
                        </Grid>
                        <Grid sx={styles.fieldContainer}>
                          <TextField
                            error={Boolean(touched.address && errors.address)}
                            fullWidth
                            helperText={touched.address && errors.address}
                            placeholder={`${t('ACCOUNT_SETTINGS_COMPANIES_ADDRESS')}`}
                            label={`${t('ACCOUNT_SETTINGS_COMPANIES_ADDRESS')}`}
                            name="address"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.address}
                            variant="outlined"
                            sx={styles.inputField}
                          />
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sx={styles.fieldContainer}>
                            <TextField
                              error={Boolean(touched.postalCode && errors.postalCode)}
                              fullWidth
                              helperText={touched.postalCode && errors.postalCode}
                              placeholder={`${t('ACCOUNT_SETTINGS_COMPANIES_POSTAL_CODE')}`}
                              label={`${t('ACCOUNT_SETTINGS_COMPANIES_POSTAL_CODE')}`}
                              name="postalCode"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.postalCode}
                              variant="outlined"
                              sx={styles.inputField}
                            />
                          </Grid>
                          <Grid item xs={12} sx={styles.fieldContainer}>
                            <TextField
                              error={Boolean(touched.contactNumber && errors.contactNumber)}
                              fullWidth
                              helperText={touched.contactNumber && errors.contactNumber}
                              placeholder={`${t('ACCOUNT_SETTINGS_COMPANIES_CONTACT_NUMBER')}`}
                              label={`${t('ACCOUNT_SETTINGS_COMPANIES_CONTACT_NUMBER')}`}
                              name="contactNumber"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.contactNumber}
                              variant="outlined"
                              sx={styles.inputField}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sx={styles.fieldContainer}>
                            <FormControl variant="outlined" sx={styles.formControl}>
                              <Autocomplete
                                fullWidth
                                onChange={(e, v) => handleChange({ target: { name: 'country', value: v?.code } })}
                                value={values.country ? Object.keys(allCountries).map((key) => ({
                                  code: key,
                                  name: allCountries[key]?.name
                                })).find((o) => o.code === values.country) : null}
                                options={countryList}
                                getOptionLabel={(option) => `${getEmojiFlag(option?.code as TCountryCode)} ${option?.name}`}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    placeholder={`${t('ACCOUNT_SETTINGS_COMPANIES_COUNTRY')}`}
                                    label={`${t('ACCOUNT_SETTINGS_COMPANIES_COUNTRY')}`}
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                    sx={styles.selectAutoField}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sx={styles.fieldContainer}>
                            <TextField
                              error={Boolean(touched.city && errors.city)}
                              fullWidth
                              helperText={touched.city && errors.city}
                              placeholder={`${t('ACCOUNT_SETTINGS_COMPANIES_CITY')}`}
                              label={`${t('ACCOUNT_SETTINGS_COMPANIES_CITY')}`}
                              name="city"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              value={values.city}
                              variant="outlined"
                              sx={styles.inputField}
                            />
                          </Grid>
                        </Grid>
                        {errors.submit && (
                          <Box mt={3}>
                            <FormHelperText error>
                              {errors.submit}
                            </FormHelperText>
                          </Box>
                        )}
                      </Grid>
                      <Box sx={styles.modalFooter}>
                        <Box mt={2} gap="10px" display="flex" justifyContent="flex-end">
                          <Button
                            variant="outlined"
                            sx={styles.closeBtn}
                            disabled={isSubmitting}
                            onClick={handleClose}
                          >
                            {ready && t('ACCOUNT_SETTINGS_COMPANIES_CANCEL')}
                          </Button>
                          <Button
                            variant="contained"
                            sx={styles.submitBtn}
                            type="submit"
                            disabled={isSubmitting || !isActionPermitted(permissions.companyAdd, user?.permissions)}
                          >
                            {ready && t('ACCOUNT_SETTINGS_COMPANIES_NEXT')}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </form>
                )}
              </Formik>
            ) 
          : (
            <Formik
              initialValues={{
                email: '',
                firstname: '',
                lastname: '',
                submit: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email(t('INVALID_EMAIL_ADDRESS_HELPER_TEXT')).max(255).required(t('EMAIL_ADDRESS_REQUIRED_HELPER_TEXT')),
                firstname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_FIRSTNAME_REQ')),
                lastname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_LASTNAME_REQ')),
                submit: Yup.bool(),
              })}
              onSubmit={async (values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
              }) => {
                const body = {
                  email: values.email,
                  firstName: values.firstname,
                  lastName: values.lastname,
                  company: user && user.companyID ? user.companyID : '',
                  role: 'admin'
                };

                try {
                  const response = await Axios.post(
                    `${API.companyAndUser}`,
                    {
                      ...companyValues,
                      ...body,
                    },
                    axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
                  );
                  if (response.data.success) {
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);

                    enqueueSnackbar(t('ACCOUNT_SETTINGS_ADD_COMPANY_AND_USER_SUCCESS'), {
                      variant: 'success',
                      style: { maxWidth: 400 }
                    });
                    setStep('company');
                    if (isBranded(BRAND_NAME)) {
                      setUserInviteLink(response.data.data.link);
                      setUserInviteLinkOpen(true);
                    } else {
                      handleClose();
                      getAllCompanies();
                      navigate('/settings', { state: { tab: 3 } });
                    }
                  }
                } catch (e) {
                  const error = e as CustomError
                  setErrors({
                    submit: getLocalisedErrorString(error?.response?.data?.i18n, t)
                  } as FormikErrors<any>);
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setErrors,
                setTouched,
                isSubmitting,
                touched,
                values,
                setFieldValue,
              }) => (
                <form
                  style={styles.root}
                  onSubmit={handleSubmit}
                >
                  <Grid sx={styles.userForm}>
                    <Grid sx={styles.modalContent}>
                      <Grid sx={styles.rowItem}>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          placeholder={`${t('ACCOUNT_SETTINGS_USERS_EMAIL')} *`}
                          label={`${t('ACCOUNT_SETTINGS_USERS_EMAIL')}`}
                          fullWidth
                          helperText={touched.email && errors.email}
                          name="email"
                          onBlur={(e) => handleEmailBlur(e.target.value, setErrors, setFieldValue, setTouched, errors, touched)}
                          onChange={(e) => handleChange({ target: { name: 'email', value: e.target.value.toLowerCase() } })}
                          required
                          value={values.email || ''}
                          variant="outlined"
                          sx={styles.inputField}
                        />
                      </Grid>
                      <Grid container spacing={1} sx={{...styles.fieldContainer,marginTop:'10px'}}>
                        <Grid item xs={12} sx={styles.rowItemComp}>
                          <TextField
                            error={Boolean(touched.firstname && errors.firstname)}
                            fullWidth
                            helperText={touched.firstname && errors.firstname}
                            name="firstname"
                            onBlur={(e) => handleBlurOthers(e, values, handleBlur, setErrors, setTouched, errors, touched)}
                            placeholder={isUserExist ? '' : `${t('ACCOUNT_SETTINGS_USERS_FIRSTNAME')} *`}
                            label={`${t('ACCOUNT_SETTINGS_USERS_FIRSTNAME')}`}
                            onChange={handleChange}
                            required
                            value={values.firstname || ''}
                            disabled={isUserExist}
                            variant="outlined"
                            sx={styles.inputField}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{...styles.rowItemComp,marginTop:'10px'}}>
                          <TextField
                            error={Boolean(touched.lastname && errors.lastname)}
                            fullWidth
                            helperText={touched.lastname && errors.lastname}
                            name="lastname"
                            onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) =>
                              handleBlurOthers(e, values, handleBlur, setErrors, setTouched, errors, touched)}
                            onChange={handleChange}
                            required
                            value={values.lastname || ''}
                            disabled={isUserExist}
                            variant="outlined"
                            sx={styles.inputField}
                            placeholder={isUserExist ? '' : `${t('ACCOUNT_SETTINGS_USERS_LASTNAME')} *`}
                            label={`${t('ACCOUNT_SETTINGS_USERS_LASTNAME')}`}
                          />
                        </Grid>
                      </Grid>
                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                      )}
                    </Grid>
                    <Box sx={styles.modalFooter}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          variant="text"
                          sx={{...styles.closeBtn,marginRight:'10px'}}
                          disabled={isSubmitting}
                          onClick={() => setStep('company')}
                        >
                          {ready && t('ACCOUNT_SETTINGS_COMPANIES_PREV')}
                        </Button>
                        <Button
                          variant="contained"
                          sx={styles.submitBtn}
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {ready && t('ACCOUNT_SETTINGS_USERS_ADD_USER')}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </form>
              )}
            </Formik>
            )
          }
          <Popper
            open={subCompanyHelpShow}
            anchorEl={helpIconRef.current}
            transition
            sx={{ zIndex: 111111 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper sx={styles.scPaper} variant="outlined">
                  <Typography sx={styles.scTitle}>{t('CREATE_SUB_COMPANY_TOOLTIP_TITLE')}</Typography>
                  <Typography sx={styles.scSubTitle}>{t('CREATE_SUB_COMPANY_TOOLTIP_SUBTITLE')}</Typography>
                  <List sx={styles.list}>
                    <ListItem sx={styles.listItem}>
                      <FiberManualRecordIcon sx={styles.bulletPoint} />
                      <ListItemText
                        disableTypography
                        primary={(
                          <Typography sx={styles.listItemText}>
                            {t('CREATE_SUB_COMPANY_TOOLTIP_LINE_01')}
                          </Typography>
                        )}
                      />
                    </ListItem>
                    <ListItem sx={styles.listItem}>
                      <FiberManualRecordIcon sx={styles.bulletPoint} />
                      <ListItemText
                        disableTypography
                        primary={(
                          <Typography sx={styles.listItemText}>
                            {t('CREATE_SUB_COMPANY_TOOLTIP_LINE_02')}
                          </Typography>
                        )}
                      />
                    </ListItem>
                  </List>
                  <Typography sx={styles.listItemText}>
                    {t('CREATE_SUB_COMPANY_TOOLTIP_LINE_03')}
                  </Typography>
                </Paper>
              </Grow>
            )}
          </Popper>
          <NewUserInviteLinkPopup
            isOpen={userInviteLinkOpen}
            handleClose={() => closeUserInviteLinkPopup()}
            link={userInviteLink}
          />
        </Box>
      </Fade>
    </Modal>
  );
}


export default AddUserToCompanyForm;
