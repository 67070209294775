import DashboardLayout from 'src/shared/layouts/DashboardLayout';
import Account from 'src/account/Account';
import Settings from 'src/settings/Settings';
import { useAuth } from 'src/hooks/useAuth';

const SettingsView = () => {
  const { user } = useAuth();
  const isOldAnnotationInterface = user?.customisations.includes('oldAnnotationInterface');

  return (
    <>
      {
        isOldAnnotationInterface ?
          <DashboardLayout>
            <Account />
          </DashboardLayout> : <Settings />
      }
    </>
  );
}

export default SettingsView;
