import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Box, CssBaseline } from '@mui/material';

import SideBar from '../components/SideBar/SideBar';
import TopBar from 'src/shared/components/TopBarNew/Topbar';

interface PropTypes {
  children: React.ReactNode,
  title?: string,
  navBarChildren?: React.JSX.Element,
  hideTopBar?: boolean
}

export default function SideBarLayout(
  { children, title, navBarChildren, hideTopBar }: PropTypes
) {
  return (
    <HelmetProvider>
      <Box sx={{ display: 'flex' }}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <CssBaseline />
        <SideBar
          docPreview={Boolean(navBarChildren)}
          docPreviewExtra={navBarChildren}
        />
        <Box
          component="main"
          sx={styles.main}
          className="y-scroll"
        >
          {!hideTopBar && <TopBar />}
          {children}
        </Box>
      </Box>
    </HelmetProvider>
  );
}

const styles = {
  main: {
    height: '100vh',
    flexGrow: 1,
    maxWidth:'calc(100% - 65px)',
    marginLeft:'auto',
    padding: '0px 0px 0px 4px',
    backgroundColor:'#F3F5F9'
  }
};
