import theme from 'src/theme';

const styles = {
  container: {
    display:'flex',
    minWidth: '316px'
  },
  titleText: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  headerTitle: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#4C4E64',
    lineHeight: '36px',
  },
  inputsContainer: {
    padding: '16px 0px',
  },
  formInput: {
    padding: '0px',
    marginBottom:'15px'
  },
  inputLabel: {
    color: theme.palette.text.primary,
    fontSize: 14,
    margin: '8px 0px',
    fontWeight: 500
  },
  uploadsContainer: {
    padding: '10px 0 0',
    display: 'flex',
    gap:'30px',
    flexDirection: 'column',
  },
  uploadItem: {
    width: '100%',
    padding: '0px',
  },
  brandLogoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    padding: '12px 0px',
    height: '60px'
  },
  brandFavicon: {
    maxWidth: 40,
    maxHeight: 40
  },
  brandLogo: {
    maxWidth: 120,
    maxHeight: 40
  },
  logoUploadTitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#4C4E64',
  },
  updateBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    color: '#fff',
    paddingTop: 0,
    paddingBottom: 0,
    border: 'none',
    margin: '6px 0px 6px 0px',
    boxShadow:' 0px 4px 8px -4px #4C4E646B',
    
    backgroundColor:'#3E8AFF',
    '&:disabled,&:hover':{
      color:'#fff',
      backgroundColor:'#3E8AFF',
      boxShadow:' 0px 4px 8px -4px #4C4E646B',
      opacity:'1',
      cursor:'pointer',
      border:'none'
    }
  },
  uploadBtn: {
    fontSize: 14,
    fontWeight: 500,
    width:'100%',
    textTransform: 'none',
    margin: '6px 0px 6px 0px',
    color:'#3E8AFF',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:'#fff',
    borerRadius:'15px!important',
    padding:'30px 0',
    position:'relative',
    gap:'10px',
    border: '1px dashed #C2CFE0',
    '& > input':{
      position:'absolute',
      zIndex:'-1',
      opacity:'0'
    }
  },
  logoUploadDescr: {
    fontSize: 12,
    marginTop:'8px',
    color: '#4C4E64DE',
  },
  btnIcon: {
    height: 24,
    width: 24,
    color: '#3E8AFF',
  },
  btnIconDisabled: {
    height: 24,
    width: 24,
    color: '#3E8AFF',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '0px 24px'
  },
  col:{
    paddingRight: '16px'
  },
  textField:{
    '& .MuiOutlinedInput-input': {
      fontSize: '13px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  }
};

export default styles;
