import theme from "src/theme";

const styles = {
  root: {
    padding: '16px 20px',
    margin: '0px',
    width:'90%',
    minWidth: '316px',
    maxWidth:'550px',
    boxShadow:'none',
    border:'none',
  },
  formInputRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  annoInterfaceLabelContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0 10px',
  },
  annoInterfaceLabel: {
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '-0.05px',
    color:'#4C4E64'
  },
  annoInterfaceHelp: {
    padding: '0px 8px',
    color: '#c8c8c8',
    fontSize: '12px'
  },
  interfaceIcon: {
    fontSize: '14px'
  },
  formInputSelect: {
    color: '#4C4E64 !important',
    fontSize: '13px',
    width:'100%',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '&.MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '&.MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  },
  inputText: {
    color: theme.palette.text.primary,
    fontSize: 14,
  },
  formInputLeft: {
    padding: '13px 0px 5px 0px !important',
  },
  formInputRight: {
    padding: '13px 0px 5px 0px !important',
  },
  formInput: {
    paddingTop: '13px!important',
    paddingBottom: '13px!important',
  },
  manageExportBtn: {
    backgroundColor: '#3E8AFF1A',
    padding: '4px 9px 4px 9px',
    borderRadius: '4px',
    height: '36px',
    textTransform: 'none',
    color: '#3E8AFF',
    fontWeight: '600',
    fontSize: '14px',
    border:'none',
    '&:hover': {
      backgroundColor: '#3E8AFF1A',
      boxShadow: '0px 8px 16px -8px #4C4E646B',
      border:'none',
    }
  },
  arPaper: {
    padding: '10px',
    borderRadius: '15px',
    borderColor: '#d3d3d3',
    borderWidth: '2.3px',
    width: '400px',
    boxShadow: '0px 0px 0px #d3d3d3'
  },
  arSubTitle: {
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  list: {
    marginBottom: '0px',
    color: theme.palette.text.primary,
  },
  listItem: {
    margin: '0px',
    padding: '0px'
  },
  listItemText: {
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  listItemTextWithBt: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    marginBottom: '8px',
  },
  bulletPoint: {
    fontSize: '14px',
    color: theme.palette.text.primary,
    marginRight: '10px',
    marginBottom: '23px',
  },
};

export default styles;
