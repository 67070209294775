import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import { Box, Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { CloudUpload as UploadIcon } from '@mui/icons-material';
import { useConfig } from 'src/hooks/useConfig';

import { CustomError } from 'src/types';
import { appendContactSupport, axiosHeaders, axiosHeadersFormData, getLocalisedErrorString } from 'src/utils/helpers';
import styles from './style';

type PropsType = {
  isEditable: boolean;
  brandDetails: Record<string, string>;
  fetchBrandDetails: () => Promise<void>;
  setErrors: (_errors: Record<string, boolean>) => void;
  errors: Record<string, boolean>;
  helperTexts: Record<string, string | boolean>;
  values: Record<string, string>;
  setValues: (_values: Record<string, string>) => void;
}

type ValidationType = Record<string, RegExp>;

type ValuesType = Record<string, string>;

function BrandCompanyInfo(props: PropsType) {
  const { enqueueSnackbar } = useSnackbar();
  const { ready, t } = useTranslation();
  const { API } = useConfig();

  const { 
    isEditable, 
    brandDetails, 
    fetchBrandDetails, 
    errors, 
    setErrors,
    helperTexts,
    values,
    setValues,
  } = props;


  const [imgValues] = useState({
    favicon: '',
    logo: '',
  });

  const [uploadingImage, setUploadingImage] = useState(false);

  const validations: ValidationType = {
    name: /.*\S.*$/,
    domain: /^[-a-zA-Z0-9@:/%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/,
    supportEmail: /^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/,
    terms: /^[-a-zA-Z0-9@:/%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/,
    privacyPolicy: /^[-a-zA-Z0-9@:/%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/,
  };

  const [brandFavicon, setBrandFavicon] = useState<string>('');
  const [brandLogo, setBrandLogo] = useState<string>('');

  const imageSizes:Record<string, number> = {
    favicon: 2,
    logo: 5,
  };

  const resolutionRatios:Record<string, Record<string, number>> = {
    favicon: {
      min: 0.8,
      max: 1.2,
    },
    logo: {
      min: 0.12,
      max: 0.45,
    },
  };

  const imageErrors: Record<string, Record<string, string | boolean>> = {
    fileSizes: {
      favicon: ready && 'BRAND_COMPANY_SMALL_ICON_FILE_SIZE',
      logo: ready && 'BRAND_COMPANY_LOGO_FILE_SIZE',
    },
    resolutionRatios: {
      favicon: ready && 'BRAND_COMPANY_SMALL_ICON_FILE_RES',
      logo: ready && 'BRAND_COMPANY_LOGO_FILE_RES',
    },
    success: {
      favicon: ready && 'BRAND_COMPANY_SMALL_ICON_UPLOAD_SUCCESS',
      logo: ready && 'BRAND_COMPANY_LOGO_UPLOAD_SUCCESS',
    },
    error: {
      favicon: ready && 'BRAND_COMPANY_SMALL_ICON_UPLOAD_ERROR',
      logo: ready && 'BRAND_COMPANY_LOGO_UPLOAD_ERROR',
    },
  };

  const lastValues = useRef<ValuesType>({
    name: brandDetails.name || '',
    domain: brandDetails.domain || '',
    supportEmail: brandDetails.supportEmail || '',
    terms: brandDetails.terms || '',
    privacyPolicy: brandDetails.privacyPolicy || '',
  });

  useEffect(() => {
    setValues({
      name: brandDetails.name || '',
      domain: brandDetails.domain || '',
      supportEmail: brandDetails.supportEmail || '',
      terms: brandDetails.terms || '',
      privacyPolicy: brandDetails.privacyPolicy || '',
    });
    lastValues.current = {
      name: brandDetails.name || '',
      domain: brandDetails.domain || '',
      supportEmail: brandDetails.supportEmail || '',
      terms: brandDetails.terms || '',
      privacyPolicy: brandDetails.privacyPolicy || '',
    };
  }, [brandDetails]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    if (!validations[event.target.name].test(event.target.value)) {
      setErrors({
        ...errors,
        [event.target.name]: true
      });
    } else {
      setErrors({
        ...errors,
        [event.target.name]: false
      });
    }
  };

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const prop = event.target.name;
    const val = event.target.value;

    if (!errors[event.target.name] && val !== '' && val !== lastValues.current[prop]) {
      lastValues.current = {
        ...lastValues.current,
        [prop]: val
      };
      const data = new FormData();
      data.append(prop, val);
      if (prop !== 'name') {
        data.append('name', values.name);
      }

      try {
        const response = await Axios.post(
          API.brand,
          data,
          axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
        );

        if (response.data.success) {
          enqueueSnackbar(t('BRAND_COMPANY_INFO_UPDATE_SUCCESS'), {
            variant: 'success'
          });
        }
      } catch (e) {
        const error = e as CustomError;
        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || 'BRAND_COMPANY_INFO_UPDATE_FAILURE', t),
          t
        ), {
          variant: 'error'
        });
      }
    }
  };

  const uploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadingImage(true);
    const file = event && event?.target && event?.target?.files && event?.target?.files[0];
    const prop = event.target.name;

    if (values.name === '') {
      enqueueSnackbar(t('BRAND_COMPANY_COM_NAME_EMPTY'), {
        variant: 'error'
      });
      setUploadingImage(false);
      return;
    }

    if (values.domain === '') {
      enqueueSnackbar(t('BRAND_COMPANY_COM_DOMAIN_EMPTY'), {
        variant: 'error'
      });
      setUploadingImage(false);
      return;
    }

    if (file && file?.size > imageSizes[prop] * 1024 * 1024) {
      enqueueSnackbar(t(imageErrors.fileSizes[prop] as string), {
        variant: 'error'
      });
      setUploadingImage(false);
      return;
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(file as Blob);
    let resOkay = true;

    img.onload = async () => {
      const width = parseFloat(img.naturalWidth.toString());
      const height = parseFloat(img.naturalHeight.toString());

      const resolutionRatio = parseFloat((height / width).toFixed(2));

      if (!(resolutionRatio >= resolutionRatios[prop]?.min && resolutionRatio <= resolutionRatios[prop]?.max)) {
        enqueueSnackbar(t(imageErrors.resolutionRatios[prop] as string), {
          variant: 'error'
        });
        resOkay = false;
      }

      if (!resOkay) {
        setUploadingImage(false);
        return;
      }

      const data = new FormData();
      data.append('name', values.name);
      data.append(prop, file as Blob);

      try {
        const response = await Axios.post(
          API.brand,
          data,
          axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
        );

        if (response.data.success) {
          enqueueSnackbar(t(imageErrors.success[prop] as string), {
            variant: 'success'
          });
          const url = URL.createObjectURL(file as Blob);
          setUploadingImage(false);
          fetchBrandDetails();
          if (prop === 'favicon') {
            setBrandFavicon(url);
          } else {
            setBrandLogo(url);
          }
        }
      } catch (e) {
        const error = e as CustomError;
        setUploadingImage(false);
        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || imageErrors.error[prop], t),
          t
        ), {
          variant: 'error'
        });
      }
    };
  };

  const handleSave = async () => {
    let erroredKey: string = '';
    if (Object.keys(errors).some((key) => {
      if (errors[key] === true) {
        erroredKey = key;
        return true;
      }
      return false;
    })) {
      enqueueSnackbar(helperTexts[erroredKey], {
        variant: 'error'
      });
      return;
    }
    if (Object.keys(values).some((key) => values[key] === '') || brandDetails.favicon === '' || brandDetails.logo === '') {
      enqueueSnackbar(t('BRAND_COMPANY_INFO_EMPTY'), {
        variant: 'error'
      });
      return;
    }
    enqueueSnackbar(t('BRAND_COMPANY_INFO_UPDATE_SUCCESS'), {
      variant: 'success'
    });

    await fetchBrandDetails();

    if (brandDetails.status !== 'active') {
      try {
        const response = await Axios.post(
          API.brandStatus,
          {
            status: 'active'
          },
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );

        if (response.data.success) {
          enqueueSnackbar(t('BRANDING_INITIATION_SUCCESS'), {
            variant: 'success'
          });
        }
      } catch (e) {
        const error = e as CustomError;
        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || 'BRANDING_INITIATION_FAILURE', t),
          t
        ), {
          variant: 'error'
        });
      }
    }
  };

  useEffect(()=>{
    setErrors({
      name: false,
      domain: false,
      supportEmail: false,
      favicon: false,
      logo: false,
      terms: false,
      privacyPolicy: false,
    });
  },[])

  return (
    <Grid container spacing={2} sx={styles.container}>
      <Grid item md={6} sm={12} sx={styles.col}>
        <Typography sx={styles.headerTitle}>
          {ready && t('ACCOUNT_SETTINGS_BRAND_TITLE')}
        </Typography>
        <Grid container sx={styles.inputsContainer}>
          <Grid
            item
            xs={12}
            sx={styles.formInput}
          >
            <TextField
              fullWidth
              label={t('BRAND_COMPANY_COM_NAME')}
              variant="outlined"
              error={errors.name}
              helperText={errors.name ? ready && helperTexts.name : ''}
              disabled={!isEditable}
              name="name"
              onChange={handleChange}
              value={values.name}
              onBlur={handleBlur}
              sx={styles.textField}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={styles.formInput}
          >
            <TextField
              label={t('BRAND_COMPANY_DOMAIN')}
              fullWidth
              variant="outlined"
              error={errors.domain}
              helperText={errors.domain ? ready && helperTexts.domain : ''}
              disabled={!isEditable}
              name="domain"
              onChange={handleChange}
              value={values.domain}
              onBlur={handleBlur}
              sx={styles.textField}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={styles.formInput}
          >
            <TextField
              label={t('BRAND_COMPANY_SUPPORT_EMAIL')}
              fullWidth
              variant="outlined"
              error={errors.supportEmail}
              helperText={errors.supportEmail ? ready && helperTexts.supportEmail : ''}
              disabled={!isEditable}
              name="supportEmail"
              onChange={handleChange}
              value={values.supportEmail}
              onBlur={handleBlur}
              sx={styles.textField}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={styles.formInput}
          >
            <TextField
              label={t('BRAND_COMPANY_TERMS')}
              fullWidth
              variant="outlined"
              error={errors.terms}
              helperText={errors.terms ? ready && helperTexts.terms : ''}
              disabled={!isEditable}
              name="terms"
              onChange={handleChange}
              value={values.terms}
              onBlur={handleBlur}
              sx={styles.textField}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={styles.formInput}
          >
            <TextField
              label={t('BRAND_COMPANY_PRIVACY_POLICY')}
              fullWidth
              variant="outlined"
              error={errors.privacyPolicy}
              helperText={errors.privacyPolicy ? ready && helperTexts.privacyPolicy : ''}
              disabled={!isEditable}
              name="privacyPolicy"
              onChange={handleChange}
              value={values.privacyPolicy}
              onBlur={handleBlur}
              sx={styles.textField}
            />
          </Grid>
          <Box>
            <Tooltip title={!isEditable ? t('BRAND_UPDATE_DISABLED_TOOLTIP') : ''}>
              <span>
                <Button
                  variant="outlined"
                  sx={styles.updateBtn}
                  disabled={!isEditable}
                  onClick={handleSave}
                >
                  {ready && t('BRAND_UPDATE_COMPANY_INFO')}
                </Button>
              </span>
              
            </Tooltip>
            
          </Box>
        </Grid>
      </Grid>
      <Grid item md={6} sm={12} sx={styles.col}>
        <Grid sx={styles.uploadsContainer}>
          <Grid sx={styles.uploadItem}>
            {values.domain && (brandDetails.favicon || brandFavicon) && (
              <Grid sx={styles.brandLogoContainer}>
                <img
                  src={brandFavicon || `${API.brandFavicon}${values.domain}`}
                  alt=""
                  style={styles.brandFavicon}
                />
              </Grid>
            )}
            <Typography sx={{...styles.logoUploadTitle,marginBottom:'18px'}}>
              {ready && t('BRAND_COMPANY_SMALL_ICON')}
            </Typography>
            <Button
              variant="outlined"
              sx={styles.uploadBtn}
              component="label"
              disabled={!isEditable || uploadingImage}
            >
              <input
                id="small-icon-input"
                name="favicon"
                accept="image/png,image/jpeg,image/jpg"
                type="file"
                value={imgValues.favicon}
                onChange={uploadImage}
                style={{ display: 'none' }}
              />
              <UploadIcon sx={(isEditable && !uploadingImage) ? styles.btnIcon : styles.btnIconDisabled} />
              {ready && t('BRAND_COMPANY_UPLOAD')}
              
            </Button>
            <Typography sx={styles.logoUploadDescr}>
              {ready && t('BRAND_COMPANY_SMALL_ICON_DESCR')}
            </Typography>
          </Grid>
          <Grid sx={styles.uploadItem}>
            {values.domain && (brandDetails.logo || brandLogo) && (
              <Grid sx={styles.brandLogoContainer}>
                <img
                  src={brandLogo || `${API.brandLogo}${values.domain}`}
                  alt=""
                  style={styles.brandLogo}
                />
              </Grid>
            )}
            <Typography sx={styles.logoUploadTitle}>
              {ready && t('BRAND_COMPANY_LOGO')}
            </Typography>
            <Button
              variant="outlined"
              sx={styles.uploadBtn}
              component="label"
              disabled={!isEditable || uploadingImage}
            >
              <input
                id="logo-input"
                name="logo"
                accept="image/png,image/jpeg,image/jpg"
                type="file"
                value={imgValues.logo}
                onChange={uploadImage}
                style={{ display: 'none' }}
              />
              <UploadIcon sx={(isEditable && !uploadingImage) ? styles.btnIcon : styles.btnIconDisabled} />
              {ready && t('BRAND_COMPANY_UPLOAD')}
              
            </Button>
            <Typography sx={styles.logoUploadDescr}>
              {ready && t('BRAND_COMPANY_SMALL_ICON_DESCR')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BrandCompanyInfo;
