const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  title: {
    fontSize: '20px',
    fontWeight: '500',
    color: '#4C4E64',
    lineHeight: '32px',
    marginBottom: '20px',
  },
  tableHeader: {
    backgroundColor: '#C2CFE033',
    overflow: 'hidden',
  },
  headerCell: {
    height: '56px',
    color: '#4C4E64DE',
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: '500',
    padding: '0px 12px !important',
    borderBottom: 'none !important',
    whiteSpace: 'nowrap',
  },
  bodyCell: {
    height: '56px',
    fontSize: '14px',
    lineHeight: '32px',
    fontWeight: '500',
    color: '#4C4E64',
    padding: '0 12px !important',
    whiteSpace: 'nowrap',
  },
  bodyCellLast: {
    height: '56px',
    fontSize: '14px',
    lineHeight: '32px',
    fontWeight: '500',
    color: '#4C4E64',
    padding: '0 24px 0px 0px !important',
    whiteSpace: 'nowrap',
    borderBottom: 'none !important',
  },
  viewInvoiceIcon: {
    color: '#6D788D',
    cursor: 'pointer',
    height: '20px',
    width: '20px',
  }
}

export default styles;
