import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import { Backdrop, Button, CircularProgress, Fade, Grid, IconButton, MenuList, Modal, Typography } from '@mui/material';
import { Close as CloseIcon, FiberManualRecord as DotIcon, } from '@mui/icons-material';

import { appendContactSupport, axiosHeadersFormData, getLocalisedErrorString } from 'src/utils/helpers';
import styles from './style';

const uploadInstructions = [
  {
    id: 0,
    label: 'DIMENSION_IMPORT_INSTRUCTIONS_01'
  },
  {
    id: 1,
    label: 'DIMENSION_IMPORT_INSTRUCTIONS_02'
  },
  {
    id: 2,
    label: 'DIMENSION_IMPORT_INSTRUCTIONS_03'
  }
];

const GstockImportModal = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [importDimensionFile, setImportDimensionFile] = useState(null);

  const { importModalOpen, onModalClose, url, getAllSuppliers } = props;

  const handleFileInputChange = (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      setImportDimensionFile(file);
    } else {
      enqueueSnackbar(t('DIMENSION_IMPORT_FILE_UPLOAD_WARN'), {
        variant: 'warning',
        persist: false
      });
    }
  };

  const uploadDimensionsFile = async () => {
    setLoading(true);
    if (importDimensionFile) {
      const data = new FormData();
      data.append('importFile', importDimensionFile);
      try {
        const response = await Axios.post(
          url,
          data,
          axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
        );
        if (response.data.success) {
          enqueueSnackbar(t('GSTOCK_IMPORT_FILE_UPLOAD_SUCCESS'), {
            variant: 'success',
            persist: false
          });
          setLoading(false);
          getAllSuppliers();
          onModalClose();
        } else {
          enqueueSnackbar(response.data.message, {
            variant: 'error',
            persist: false
          });
          setLoading(false);
        }
      } catch (error) {
        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || 'GSTOCK_IMPORT_FILE_UPLOAD_FAIL', t),
          t
        ), {
          variant: 'error',
          persist: true
        });
        setLoading(false);
      }
    }
  };

  const loadingIndicator = (
    <Grid style={styles.loaderContainer}>
      <CircularProgress color="secondary" />
      <Typography style={styles.loaderText}>{t('DIMENSION_IMPORT_FILE_UPLOAD_LOADING')}</Typography>
    </Grid>
  );

  return (
    <>
      <Modal
        style={styles.modal}
        open={importModalOpen}
        onClose={onModalClose}
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
      >
        <Fade in={importModalOpen}>
          <div style={styles.halfPaper}>
            <Grid style={styles.modalHeader}>
              <Typography style={styles.headerTitle}>
                {t('DIMENSION_IMPORT_MODAL_HEADER')}
              </Typography>
              <IconButton onClick={onModalClose} disabled={loading}>
                <CloseIcon style={styles.closeIcon} />
              </IconButton>
            </Grid>
            <Grid style={styles.modalContainer}>
              {loading ? loadingIndicator : (
                <>
                  <Grid style={styles.uploadBtnContainer}>
                    {importDimensionFile && <Typography style={styles.fileName}>{importDimensionFile.name}</Typography>}
                    <Button
                      color="secondary"
                      variant="contained"
                      component="label"
                      style={styles.uploadBtn}
                    >
                      <input
                        accept=".xlsx, .csv"
                        type="file"
                        onChange={handleFileInputChange}
                        style={{ display: 'none', zIndex: 111111111 }}
                        required
                      />
                      {t('DIMENSION_IMPORT_CHOOSE_FILE')}
                    </Button>
                  </Grid>
                  <MenuList style={styles.instructionsContainer}>
                    {uploadInstructions.map((instruction) => (
                      <Grid key={instruction.id} style={styles.itemContainer}>
                        <DotIcon style={styles.dotIconInstruction} />
                        <Typography
                          key={instruction.label}
                          style={styles.instructionLabel}
                        >
                          {t(instruction.label)}
                        </Typography>
                      </Grid>
                    ))}
                  </MenuList>
                  <Grid style={styles.modalFooter}>
                    {importDimensionFile && (
                      <Button
                        color="secondary"
                        variant="contained"
                        style={styles.uploadBtn}
                        onClick={() => uploadDimensionsFile()}
                      >
                        {t('DIMENSION_IMPORT_CONFIRM')}
                      </Button>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default GstockImportModal;
