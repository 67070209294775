import { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import * as Yup from 'yup';
import { Formik, FormikErrors } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { countries, getEmojiFlag, TCountryCode } from 'countries-list';
import { useNavigate } from 'react-router-dom';

import {
  Box, Button, Fade, FormControl, FormHelperText, Grid, IconButton, Modal, TextField, Typography, Autocomplete
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { appendContactSupport, axiosHeaders, getLocalisedErrorString, isActionPermitted, permissions } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import { CustomError, UserDataType } from 'src/types';
import styles from './style';


type PropsType = {
  editCompanyOpen: boolean;
  user: UserDataType | null;
  handleClose: () => void;
  editCompany: Record<string, string | number | boolean>;
  getAllCompanies: () => void;
}

type CCountryType = {
  capital: string;
  continent: string;
  currency: string[];
  languages: string[];
  name: string;
  native: string;
  phone: number[]
}

type CountryType = {
  code: string;
  name: string;
}

function CompanyEditForm( props: PropsType ) {

  const {  editCompanyOpen, user, handleClose, editCompany, getAllCompanies} = props;
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const allCountries: Record<string, CCountryType> = countries as unknown as Record<string, CCountryType>;

  const { API } = useContext(ConfigContext);

  const [countryList, setCountryList] = useState<CountryType[]>([]);

  useEffect(() => {
    const countryData: CountryType[] = [];
    Object.keys(countries).forEach((key) => {
      const data = {
        code: key,
        name: allCountries[key]?.name
      };
      countryData.push(data);
    });

    setCountryList(countryData);
  }, []);

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      sx={styles.modal}
      open={editCompanyOpen}
      onClose={handleClose}
    >
      <Fade in={editCompanyOpen}>
        <Box sx={styles.paper} className='y-scroll'>
          <Grid sx={styles.modalHeader}>
            <Typography sx={styles.headerTitle}>
              {ready && t('ACCOUNT_SETTINGS_COMPANIES_MANAGE')}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={styles.closeIcon} />
            </IconButton>
          </Grid>
          <Formik
            initialValues={{
              id: editCompany.id,
              name: editCompany.name || '',
              email: editCompany.email || '',
              vatNumber: editCompany.vatNumber || '',
              address: editCompany.address || '',
              postalCode: editCompany.postalCode || '',
              contactNumber: editCompany.phone || '',
              country: editCompany.country || '',
              city: editCompany.city || '',
              submit: false,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_COMPANIES_NAME_REQ'))
                .matches(/^(?=.*[\x21-\x7E])[\x20-\x7E]*$/, t('ERROR_COMPANY_NAME_INVALID')),
              address: Yup.string().max(255).required(t('CREATE_COMPANY_ADDR_REQ')),
              postalCode: Yup.string().max(255).required(t('CREATE_COMPANY_POSTAL_CODE_REQ')),
              contactNumber: Yup.string().required(t('CREATE_COMPANY_CONTACT_NO_REQ'))
                .matches(/^([+\d]\d*)$/, t('CREATE_COMPANY_CONTACT_NO_INVALID'))
                .min(9, t('CREATE_COMPANY_CONTACT_NO_MIN'))
                .max(16, t('CREATE_COMPANY_CONTACT_NO_MAX')),
              country: Yup.string().max(255).required(t('CREATE_COMPANY_COUNTRY_REQ')),
              city: Yup.string().max(255).required(t('CREATE_COMPANY_CITY_REQ')),
              submit: Yup.bool(),
            })}
            onSubmit={async (values, {
              setErrors,
              setStatus,
              setSubmitting
            }) => {
              try {
                // Make API request
                const body = {
                  id: values.id.toString(),
                  name: values.name,
                  email: values.email,
                  vatNumber: values.vatNumber,
                  address: values.address,
                  postalCode: values.postalCode,
                  contactNumber: values.contactNumber,
                  country: values.country,
                  city: values.city,
                  companyId: editCompany.companyID.toString()
                };
                const response = await Axios.put(
                  API.company,
                  body,
                  axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
                );
                if (response.data.success) {
                  setStatus({ success: true });
                  setSubmitting(false);
                  enqueueSnackbar(t('ACCOUNT_SETTINGS_COMPANIES_EDIT_SUCCESS'), {
                    variant: 'success'
                  });
                  handleClose();
                  getAllCompanies();
                  navigate('/settings', { state: { tab: 3 } });
                } else {
                  setSubmitting(false);
                }
              } catch (e) {
                const error = e as CustomError;
                setErrors({
                  submit: appendContactSupport(
                    window.config.support_email,
                    getLocalisedErrorString(error?.response?.data?.i18n || 'ACCOUNT_SETTINGS_COMPANIES_EDIT_FAILURE', t),
                    t
                  )
                } as FormikErrors<CustomError>);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                style={styles.root}
                onSubmit={handleSubmit}
              >
                <Grid sx={styles.userForm}>
                  <Grid sx={styles.modalContent}>
                    <Grid sx={styles.fieldContainer}>
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        fullWidth
                        placeholder={t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_NAME')}
                        label={ready && t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_NAME')}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.name}
                        variant="outlined"
                        sx={styles.inputField}
                      />
                    </Grid>
                    <Grid sx={styles.fieldContainer}>
                      <TextField
                        error={Boolean(touched.vatNumber && errors.vatNumber)}
                        helperText={touched.vatNumber && errors.vatNumber}
                        fullWidth
                        placeholder={t('ACCOUNT_SETTINGS_COMPANIES_VAT_NUMBER')}
                        label={ready && t('ACCOUNT_SETTINGS_COMPANIES_VAT_NUMBER')}
                        name="vatNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.vatNumber}
                        variant="outlined"
                        sx={styles.inputField}
                      />
                    </Grid>
                    <Grid sx={styles.fieldContainer}>
                      <TextField
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                        fullWidth
                        placeholder={t('ACCOUNT_SETTINGS_COMPANIES_ADDRESS')}
                        label={ready && t('ACCOUNT_SETTINGS_COMPANIES_ADDRESS')}
                        name="address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.address}
                        variant="outlined"
                        sx={styles.inputField}
                      />
                    </Grid>
                    <Grid sx={styles.fieldContainer}>
                      <TextField
                        error={Boolean(touched.postalCode && errors.postalCode)}
                        helperText={touched.postalCode && errors.postalCode}
                        fullWidth
                        placeholder={t('ACCOUNT_SETTINGS_COMPANIES_POSTAL_CODE')}
                        label={ready && t('ACCOUNT_SETTINGS_COMPANIES_POSTAL_CODE')}
                        name="postalCode"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.postalCode}
                        variant="outlined"
                        sx={styles.inputField}
                      />
                    </Grid>
                    <Grid sx={styles.fieldContainer}>
                      <TextField
                        error={Boolean(touched.contactNumber && errors.contactNumber)}
                        helperText={touched.contactNumber && errors.contactNumber}
                        fullWidth
                        placeholder={t('ACCOUNT_SETTINGS_COMPANIES_CONTACT_NUMBER')}
                        label={ready && t('ACCOUNT_SETTINGS_COMPANIES_CONTACT_NUMBER')}
                        name="contactNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.contactNumber}
                        variant="outlined"
                        sx={styles.inputField}
                      />
                    </Grid>
                    <Grid sx={styles.fieldContainer}>
                      <FormControl variant="outlined" sx={styles.formControl}>
                        <Autocomplete
                          fullWidth
                          onChange={(e, v) => handleChange({ target: { name: 'country', value: v?.code } })}
                          value={values.country ? Object.keys(allCountries).map((key) => ({
                            code: key,
                            name: allCountries[key]?.name
                          })).find((o) => o.code === values.country) : null}
                          options={countryList}
                          getOptionLabel={(option: CountryType) => `${getEmojiFlag(option?.code as TCountryCode)} ${option.name}`}
                          isOptionEqualToValue={(option, value) => option?.code === value?.code}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={`${t('ACCOUNT_SETTINGS_COMPANIES_COUNTRY')}`}
                              label={ready && `${t('ACCOUNT_SETTINGS_COMPANIES_COUNTRY')}`}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                              }}
                              sx={styles.selectAutoField}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid sx={styles.fieldContainer}>
                      <TextField
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                        fullWidth
                        placeholder={t('ACCOUNT_SETTINGS_COMPANIES_CITY')}
                        label={ready && t('ACCOUNT_SETTINGS_COMPANIES_CITY')}
                        name="city"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.city}
                        variant="outlined"
                        sx={styles.inputField}
                      />
                    </Grid>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>
                          {t(errors.submit)}
                        </FormHelperText>
                      </Box>
                    )}
                  </Grid>
                  <Box sx={styles.modalFooter}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="text"
                        sx={styles.closeBtn}
                        disabled={isSubmitting}
                        onClick={handleClose}
                      >
                        {ready && t('ACCOUNT_SETTINGS_COMPANIES_CANCEL')}
                      </Button>
                      <Button
                        sx={styles.submitBtn}
                        type="submit"
                        disabled={isSubmitting || !isActionPermitted(permissions.companyManage, user?.permissions)}
                      >
                        {ready && t('ACCOUNT_SETTINGS_COMPANIES_EDIT_COMPANY')}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </Fade>
    </Modal>
  );
}


export default CompanyEditForm;
