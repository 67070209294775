const style = {
  container: {
    height: 'auto',
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #4C4E641F',
  },
  sectionContainer:{
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 12px',
    gap: '6px'
  },
  companySwitchContainer: {
    borderTop: '1px solid #4C4E641F',
    borderBottom: '1px solid #4C4E641F',
    padding: '0 12px'
  },
  itemContainer: {
    padding: '8px 16px',
    height: '42px',
    borderRadius: '8px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#4C4E641F'
    }
  },
  itemContainerActive: {
    padding: '8px 16px',
    height: '42px',
    borderRadius: '8px',
    backgroundColor: '#3E8AFF26',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#3E8AFF33'
    }
  },
  itemText: {
    color: '#4C4E64DE',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px'
  },
  itemTextActive: {
    color: '#3E8AFF',
    fontSize: '14px',
    fontWeight: '520',
    lineHeight: '24px'
  },
  profileDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '16px 0'
  },
  avatarContainer: {
    padding: '0px',
    height: '70px',
    width: '70px',
    borderRadius: '35px',
    marginBottom: '12px',
  },
  avatar: {
    height: '70px',
    width: '70px'
  },
  editIconContainer: {
    position: 'absolute',
    top: '50px',
    right: '-2px',
    backgroundColor: '#3E8AFF',
    width: '24px',
    height: '24px',
    padding: '0px',
    zIndex: 2,
    boxShadow: '0px 4px 8px -4px #4C4E646B',
    '&:hover': {
      backgroundColor: '#3E8AFF',
    },
  },
  editIcon: {
    width: '16px',
    height: '16px',
    color: '#FFFFFF',
  },
  nameText: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#4C4E64',
    lineHeight: '32px',
    marginBottom: '0px'
  },
  userType: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#4C4E6499',
    lineHeight: '14px'
  },
}

export default style;