import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { pdfjs } from 'react-pdf';
import { isMobile } from 'react-device-detect';

import authService from 'src/utils/authService';
import LoadingScreen from 'src/shared/screens/LoadingScreen';
import PdfView from 'src/documents/components/PdfView/PdfView';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';

pdfjs.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js';

const InvoiceDisplay = () => {
  const { t, ready } = useTranslation();

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);

  const [fileResponse, setFileResponse] = useState(null);
  const [fetchingFile, setFetchingFile] = useState(false);
  const [imgName, setImgName] = React.useState('');

  const params = useParams();

  const getImage = async (url) => {
    setFetchingFile(true);
    try {
      const response = await Axios.get(
        `${url}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      setFileResponse(window.URL.createObjectURL(blob));
      localStorage.removeItem('PROCYS_PERSIST_URL');
      setFetchingFile(false);
    } catch (err) {
      if (document?.referrer?.indexOf('login/') >= 0) {
        localStorage.removeItem('PROCYS_PERSIST_URL');
      } else {
        localStorage.setItem('PROCYS_PERSIST_URL', window.location.href);
      }
      setFetchingFile(false);
      setTimeout(() => { window.location.href = '/dashboard'; }, 2000);
    }
  };

  React.useEffect(() => {
    if (params?.id) {
      setImgName(`${params.id}`);
      getImage(`${API.getInvoiceImage}${params.id}`);
    } else if (params?.company && params?.transaction) {
      setImgName(`${params.transaction}`);
      getImage(`${API.getInvImgByCompAndTrans}${params.company}/transaction/${params.transaction}`);
    } else if (params?.transaction) {
      setImgName(`${params.transaction}`);
      getImage(`${API.getInvoiceImageByTrans}${params.transaction}`);
    } else {
      localStorage.removeItem('PROCYS_PERSIST_URL');
      authService.logout(LOGIN_PANEL_URL);
    }
  }, []);

  if (!fileResponse) {
    return <LoadingScreen />;
  }

  return (
    <div style={styles.root}>
      {isMobile
        ? (
          <PdfView
            t={t}
            ready={ready}
            fileResponse={fileResponse}
            fetchingFile={fetchingFile}
            imgName={imgName}
          />
        )
        : <iframe src={fileResponse} title="pdf" style={styles.pdfFrame} />}
    </div>
  );
};

export default InvoiceDisplay;
