/* eslint-disable max-len */
import * as React from "react"
import { JSX } from "react/jsx-runtime"

function SvgComponent(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 18V9H2V4.5h3.833a2.282 2.282 0 01-.156-.478 2.54 2.54 0 01-.052-.522c0-.694.243-1.285.73-1.77A2.41 2.41 0 018.124 1c.365 0 .706.076 1.024.23.317.152.601.36.851.624.236-.264.517-.472.844-.625.326-.153.67-.229 1.031-.229a2.41 2.41 0 011.77.73c.487.485.73 1.076.73 1.77 0 .167-.024.333-.073.5a13.24 13.24 0 00-.135.5H18V9h-1.5v9h-13zm8.375-15.5a.968.968 0 00-.713.288.967.967 0 00-.287.712c0 .283.096.52.287.713.192.191.43.287.713.287s.52-.096.713-.287a.967.967 0 00.287-.713.967.967 0 00-.287-.712.968.968 0 00-.713-.288zm-4.75 1c0 .283.096.52.287.713.192.191.43.287.713.287s.52-.096.713-.287a.968.968 0 00.287-.713.968.968 0 00-.287-.712.968.968 0 00-.713-.288.968.968 0 00-.713.288.968.968 0 00-.287.712zM3.5 6v1.5h5.75V6H3.5zm5.75 10.5V9H5v7.5h4.25zm1.5 0H15V9h-4.25v7.5zm5.75-9V6h-5.75v1.5h5.75z"
        fill="#3E8AFF"
      />
    </svg>
  )
}

export default SvgComponent
