import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Axios  from 'axios';

import { Search as SearchIcon, Event as EventIcon} from '@mui/icons-material';
import { Box, Typography, Tooltip, TextField, Grid, InputAdornment, SvgIcon, Button, Menu, MenuItem} from '@mui/material';

import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import PartnerStatisticsTable from 'src/settings/components/PartnerStatistics/PartnerStatisticsTable/PartnerStatisticsTable';
import { periodFilterOptions } from 'src/dashboard-new/utils';
import TablePagination from 'src/dimensions-new/components/TablePagination';
import CustomPeriodFilter from 'src/shared/components/CustomPeriodFilter/CustomPeriodFilter';
import { axiosHeaders } from 'src/utils/helpers';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import { useConfig } from 'src/hooks/useConfig';
import { useAuth } from 'src/hooks/useAuth';

import styles from './style';

const PartnerStatistics = () => {
  const { ready, t } = useTranslation();
  const [limit, setLimit] = useState<number>(10);
  const [query, setQuery] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [partnerStats, setPartnerStats] = useState<Record<string, string | number | boolean>[]>([]);
  const [start, setStart] = useState<string>((new Date(0).getTime() * 1000000).toString());
  const [end, setEnd] = useState<string>((new Date().getTime() * 1000000).toString());
  const [period, setPeriod] = useState<string>('all')
  const [anchorElPeriod, setAnchorElPeriod] = useState<HTMLElement | null>(null);
  const [anchorElCustomPeriod, setAnchorElCustomPeriod] = useState<HTMLElement | null> (null);

  const { API } = useConfig();
  const { user } = useAuth();

  const handleQueryChange = (value:string) => {
    setQuery(value);
    getPartnerStats(0, limit, value);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    getPartnerStats(newPage, limit, query);
  };

  const handleLimitChange = (limit: number) => {
    setPage(0);
    setLimit(limit);
    getPartnerStats(0, limit, query);
  };

  const getPartnerStats = async (
    pageNo = page, 
    pageLimit = limit, 
    search = query, 
    startTimeStamp = start, 
    endTimeStamp = end 
  ) => {
    setLoading(true);
    try {
      const url = `${API.partnerStats}?page=${pageNo + 1}&limit=${pageLimit}&search=${search}`;
      const body = {
        start: startTimeStamp,
        end: endTimeStamp
      };
      const response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success && response.data.data.stats) {
        setPartnerStats(response.data.data.stats);
        setTotal(response.data.total || response.data.data.stats.length);
      } else {
        setPartnerStats([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleOnSelectPeriod = (start:string,end:string) =>{
    setStart(start); setEnd(end);
    getPartnerStats(0,limit,query,start,end);
  }

  const handlePeriodClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPeriod(event.currentTarget);
  };

  const handlePeriodClose = () => {
    setAnchorElPeriod(null);
  };
  const handleCustomPeriodClose = () => {
    setAnchorElCustomPeriod(null);
  };
  
  const handleCustomPeriodApply = (start: number, end: number) => {
    setAnchorElCustomPeriod(null);
    const fromTimeStamp = Math.floor(new Date(start).getTime() * 1000000).toString();
    const toTimeStamp = Math.floor(new Date(end).getTime() * 1000000).toString();
    handleOnSelectPeriod(fromTimeStamp,toTimeStamp);
  };

  const getPeriodTimestamps = (option:number) => {
    const currentDate = new Date();
    let startDate;
    let endDate;
    switch (option) {
      case 1:
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay());
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + (7 - currentDate.getDay()));
        break;
      case 2:
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay() - 7);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay());
        break;
      case 3:
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        break;
      case 4:
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        break;
      case 5:
        startDate = new Date(currentDate.getFullYear(), Math.floor(currentDate.getMonth() / 3) * 3, 1);
        endDate = new Date(currentDate.getFullYear(), Math.floor(currentDate.getMonth() / 3) * 3 + 3, 1);
        break;
      case 6:
        startDate = new Date(currentDate.getFullYear(), Math.floor(currentDate.getMonth() / 3) * 3 - 3, 1);
        endDate = new Date(currentDate.getFullYear(), Math.floor(currentDate.getMonth() / 3) * 3, 1);
        break;
      case 7:
        startDate = new Date(currentDate.getFullYear(), 0, 1);
        endDate = new Date(currentDate.getFullYear(), 12, 1);
        break;
      case 8:
        startDate = new Date(currentDate.getFullYear() - 1, 0, 1);
        endDate = new Date(currentDate.getFullYear() - 1, 12, 1);
        break;
      default:
        startDate = new Date(0);
        endDate = currentDate;
        break;
    }

    return { startTimestamp: (startDate.getTime() * 1000000).toString(), endTimestamp: (endDate.getTime() * 1000000).toString() };
  };

  const handleCustomPeriodClick = () => {
    handlePeriodClose();
    setAnchorElCustomPeriod(document.getElementById('period-filter'));
  };

  const handleChangePeriodFilter = (val:number, val2:string) =>{
    if(val == 99){
      handleCustomPeriodClick();
      return;
    }
    setPeriod(val2);
    handlePeriodClose();
    let { startTimestamp, endTimestamp } = getPeriodTimestamps(val);
    handleOnSelectPeriod(startTimestamp, endTimestamp)
  }

  const filterSelected = (index:number) => {
    for (let i = 0; i < periodFilterOptions.length; i++) {
      if (periodFilterOptions[i].value === period && periodFilterOptions[i].key === index) {
        return true;
      }
    }
    return false;
  };

  useEffect(()=>{
    if (user && user?.email) {
      getPartnerStats()
    }
  },[])

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>
        {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS')}
      </Typography>
      <Grid sx={styles.searchBar} className="search-bar">
        <Tooltip title={t('ACCOUNT_SETTINGS_COMPANIES_SEARCH')}>
          <TextField
            sx={{...styles.queryField, '&.MuiTextFieldRoot': styles.searchBarInput }}
            onChange={(e)=>handleQueryChange(e.target.value)}
            placeholder={t('ACCOUNT_SETTINGS_COMPANIES_SEARCH')}
            value={query}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon
                    fontSize="small"
                    color="action"
                    sx={{ fill: '' }}
                  >
                    <SearchIcon sx={{ color: '' }} />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
        <Grid>
          <Button
            id="period-filter"
            aria-controls="simple-menu"
            aria-haspopup="true"
            sx={styles.periodBtn}
            onClick={handlePeriodClick}
            startIcon={<EventIcon/>}
          >
            {ready && t('PARTNER_STATISTICS_PERIOD')}
          </Button>
          <Menu
            id="period-simple-menu"
            anchorEl={anchorElPeriod}
            elevation={0}
            keepMounted
            open={Boolean(anchorElPeriod)}
            onClose={handlePeriodClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{
              ".MuiMenu-paper": styles.filterMenu,
            }}
          >
            {
              periodFilterOptions.map((o) => (
                <MenuItem
                  key={o.value}
                  sx={{ 
                    backgroundColor: filterSelected(o.key) 
                      ? 'rgba(0, 0, 0, 0.08)' 
                      : 'transparent', ...styles.filterMenuItem 
                    }}
                  onClick={() => handleChangePeriodFilter(o.key, o.value)}
                >
                  {t(o.label)}
                </MenuItem>
              ))
            }
          </Menu>
          <CustomPeriodFilter
            anchorElCustomPeriod={ anchorElCustomPeriod }
            handleCustomPeriodClose={ handleCustomPeriodClose }
            handleCustomPeriodApply={ handleCustomPeriodApply }
          />
        </Grid>
      </Grid>
      { partnerStats && partnerStats.length > 0 
        ? (
            loading 
              ? <LoadingIndicator /> 
              : <>
                  <Box sx={styles.tableWrapper}>
                    <PartnerStatisticsTable
                      data={partnerStats}
                    />
                  </Box>
                  <TablePagination
                    total={total}
                    page={page}
                    onPageChange={handlePageChange}
                    onLimitChange={handleLimitChange}
                    limit={limit}      
                  />
                </>
          )
        : loading 
            ? <LoadingIndicator /> 
            : <EmptyText str="ACCOUNT_SETTINGS_PARTNER_STATS_NO_DATA" />
      }
    </Box>
  )
}

export default PartnerStatistics