import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';

import { Grid, Typography, TextField, Autocomplete, FormHelperText, InputAdornment, FormControl, 
  Select, MenuItem, Tooltip, Button, Box, Switch
} from '@mui/material';
import { Edit as EditIcon, AddBox as PlusIcon } from '@mui/icons-material';

import { supplierData, userRoles  } from 'src/config';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import { VatGroupType, ProjectType, CostCenterType, GLAccountType, CountryType, CurrencyType, FieldType } 
from 'src/types';
import theme from 'src/theme';

type PropsType = {
  values: Record<string, string | boolean>;
  errors:Record<string, string>;
  touched:Record<string, boolean>;
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  setTextFieldSelectedKey: (_key: string) => void;
  textFieldSelectedKey: string;
  isEdit: boolean;
  setTextFieldSelectedValue: (_value: string) => void;
  handleChangeAutocomplete: (_e:React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | 
    React.SyntheticEvent<Element, Event>, _prop:string, _value:Record<string, any>, _changeFunc:Function) => void;
  glAccounts: GLAccountType[];
  getLabel: (_entities:Record<string,any>[] , _code:string) => string;
  handleEditGLA: (_prop: string) => void;
  handleAddGLA: (_prop: string) => void;
  vatGroups: VatGroupType[],
  handleAddVATCode: (_id: string) => void;
  handleEditVATCode: (_id: string) => void;
  supplierFields: FieldType[];
  projects:ProjectType[];
  handleEditCostCenter: (_cc: string) => void;
  handleAddCostCenter: (_c: string) => void;
  costCenters: CostCenterType[];
  countries: CountryType[];
  currencies: CurrencyType[];
  handleClose: () => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
  preferredFieldsLoading: boolean;
  skip: boolean | undefined;
  handleSkip: () => void;
  isDisabled: boolean;
  newlyAddedVal: string;
  newlyAddingProp:string;
  selectedVatCode: VatGroupType | null;
}

const ClientStandardForm = (props:PropsType) => {
  const {
    touched,
    errors,
    values,
    handleBlur,
    handleChange,
    setTextFieldSelectedKey,
    textFieldSelectedKey,
    isEdit,
    setTextFieldSelectedValue,
    handleChangeAutocomplete,
    glAccounts,
    getLabel,
    handleEditGLA,
    handleAddGLA,
    vatGroups,
    handleAddVATCode,
    handleEditVATCode,
    supplierFields,
    projects,
    handleEditCostCenter,
    handleAddCostCenter,
    costCenters,
    countries,
    currencies,
    handleClose,
    handleSubmit,
    isSubmitting,
    preferredFieldsLoading,
    skip,
    handleSkip,
    isDisabled,
    newlyAddedVal,
    newlyAddingProp,
  } = props;
  
  const { t , ready } = useTranslation();
  const { user } = useAuth();
  const { BRAND_NAME } = useConfig();
  const label = { inputProps: { 'aria-label': 'Check Skip' } };

  

  const isFieldAvailable = (field: FieldType) => {
    if (field.isMandatory) {
      return false;
    }
    if (field.isActive) {
      return true;
    }
    return false;
  };


  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } } as 
        React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>);
    }
  }, [newlyAddedVal]);
  
  return (
    <form style={styles.form} className="y-scroll">

      {preferredFieldsLoading ? (<LoadingIndicator />) : <>
      <Box sx={styles.skipDiv}>
        <Switch {...label} disabled={isDisabled} checked={skip} onChange={()=>handleSkip()} />
        <Tooltip
          title={isDisabled?'':t('TOOLTIP_SKIP_REVIEW_FOR_SUPPLIER', { brand: BRAND_NAME })}
        >
          <span onClick={isDisabled?()=>{}:()=>handleSkip()}>
            <Typography sx={{fontSize:'14px',color:'#4C4E64'}}>
              {ready && t('SKIP_REVIEW_FOR_SUPPLIER_LABEL')}
            </Typography>
          </span>
        </Tooltip>
      </Box>
      <Grid item key="creditorName" sx={styles.eachField}>
        <Typography>{ready && t('SUPPLIERS_CREDITOR_NAME')}</Typography>
        <TextField
          id="creditorNameId"
          error={Boolean(touched.creditorName && errors.creditorName)}
          fullWidth
          helperText={touched.creditorName && (ready && t(errors.creditorName))}
          name="creditorName"
          onBlur={handleBlur}
          onChange={handleChange}
          required
          value={values.creditorName}
          variant="outlined"
          style={(textFieldSelectedKey === 'creditorName' && isEdit) ? styles.inputFieldSelected : styles.inputField}
          onClick={() => { setTextFieldSelectedKey('creditorName'); setTextFieldSelectedValue(values.creditorName as string); }}
          disabled={isDisabled}
        />
      </Grid>
      <Grid item key="generalLedgerAccount" sx={styles.eachField}>
        <Typography>{ready && t('SUPPLIERS_GENERAL_LEDGER_ACCOUNT')}</Typography>
        <FormControl variant="outlined" style={styles.formControl}>
          <Autocomplete
            fullWidth
            onChange={(e, v) => handleChangeAutocomplete(e, 'generalLedgerAccount', v as unknown as Record<string, any>, handleChange)}
            value={glAccounts && values.generalLedgerAccount && glAccounts.find((e:GLAccountType) => e.code === values?.generalLedgerAccount) || null}
            inputValue={getLabel(glAccounts, values.generalLedgerAccount as string)}
            options={glAccounts}
            getOptionLabel={
              (option:GLAccountType) => (option && option.code ? `${option.code} | ${option.name}` : '')
            }
            isOptionEqualToValue={(option:GLAccountType) => option.code === values.generalLedgerAccount}
            disabled={isDisabled}
            filterOptions={options => options}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: ( isDisabled ? <></> :
                    <>
                      {values.generalLedgerAccount !== supplierData.defaultGLA[0].code && ( values?.generalLedgerAccount ?
                        <InputAdornment
                          position="end"
                          onClick={() => handleEditGLA(values.generalLedgerAccount as string)}
                          style={styles.editIconContainer}
                        >
                          <EditIcon style={styles.editDimensionIcon} />
                        </InputAdornment>:<></>
                      )}
                      <InputAdornment position="end" style={styles.plusIconContainer}>
                        <PlusIcon style={styles.plusIcon} onClick={() => handleAddGLA('generalLedgerAccount')} />
                      </InputAdornment>
                    </>
                  ),
                }}
                style={(textFieldSelectedKey === 'generalLedgerAccount' && isEdit) ? styles.selectStyleSelected : styles.selectStyle}
                onClick={() => { setTextFieldSelectedKey('generalLedgerAccount'); setTextFieldSelectedValue(values.generalLedgerAccount as string); }}
                disabled={isDisabled}
              />
            )}
          />
          <FormHelperText error>
            {touched.generalLedgerAccount && errors.generalLedgerAccount && ready ? t(errors.generalLedgerAccount) : ''}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item key="vatGroup" sx={styles.eachField}>
        <Typography>{ready && t('SUPPLIERS_VAT_GROUP')}</Typography>
        <FormControl variant="outlined" style={styles.formControl} sx={{ '& .MuiFormControl-root': styles.formControlRoot }}>
          <Select
            labelId="vatGroup-label"
            id="vatGroup"
            error={Boolean(touched.vatGroup && errors.vatGroup)}
            value={values.vatGroup}
            disabled={isDisabled}
            onChange={handleChange as any }
            name="vatGroup"
            sx={{
              '& .MuiSelect-select': styles.selectStyle
            }}
            IconComponent={() => ( isDisabled ? <></> :
              <>
                {values.vatGroup !== supplierData.defaultVatGroup[0].code && ( values.vatGroup ?
                  <EditIcon style={styles.editDimensionIcon} onClick={() => handleEditVATCode(values.vatGroup as string)} />
                  :<></>
                )}
                <PlusIcon style={styles.plusIconSelect} onClick={() => handleAddVATCode('vatGroup')} />
              </>
            )}
          >
            {
            vatGroups && vatGroups.length > 0
            && vatGroups.map((s:VatGroupType) => (
              <MenuItem key={s.code} value={s.code}>
                {`${s.code}${s.name ? ` | ${s.name}` : ''}`}
              </MenuItem>
            ))
          }
          </Select>
          <FormHelperText error>
            {touched.vatGroup && errors.vatGroup && ready ? t(errors.vatGroup) : ''}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item key="autoPaymentStatus" sx={styles.eachField}>
        <Typography>{ready && t('SUPPLIERS_AUTO_PAYMENT_STATUS')}</Typography>
        <FormControl variant="outlined" style={styles.formControl}>
          <Select
            disabled={isDisabled}
            labelId="autoPaymentStatus-label"
            id="autoPaymentStatus"
            error={Boolean(touched.autoPaymentStatus && errors.autoPaymentStatus)}
            value={values.autoPaymentStatus}
            onChange={handleChange as any}
            name="autoPaymentStatus"
            sx={{
              '& .MuiSelect-select': styles.selectStyle
            }}
          >
            {
            supplierData.apStatuses && supplierData.apStatuses.length > 0
            && supplierData.apStatuses.map((s) => (
              <MenuItem key={s.code} value={s.code}>
                {s.name}
              </MenuItem>
            ))
          }
          </Select>
          <FormHelperText error>
            {touched.autoPaymentStatus && errors.autoPaymentStatus && ready ? t(errors.autoPaymentStatus) : ''}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item key="country" sx={styles.eachField}>
        <Typography>{ready && t('SUPPLIERS_COUNTRY')}</Typography>
        <FormControl variant="outlined" style={styles.formControl}>
          <Autocomplete
            fullWidth
            onChange={(e:React.SyntheticEvent<Element, Event>, v) => handleChangeAutocomplete(e, 'country', v as unknown as Record<string, any>, handleChange)}
            value={(values.country && countries.length > 0) ? countries.find((o) => o.isoCode.toLowerCase() === values.country.toString().toLowerCase()) : null}
            options={countries}
            getOptionLabel={(option) => `${option.isoCode} | ${option.name}`}
            filterOptions={options => options}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                }}
                style={styles.selectStyle}
                disabled={isDisabled}
              />
            )}
            disabled={isDisabled}
          />
          <FormHelperText error>
            {touched.country && errors.country && ready ? t(errors.country) : ''}
          </FormHelperText>
        </FormControl>
      </Grid>
      {
        supplierFields?.map((f:FieldType) => {
          if (!isFieldAvailable(f)) {
            return null;
          }
          switch (f.key) {
            case 'status':
              return (
                <Grid item  key={f.key} sx={styles.eachField}>
                  <Typography>{ready && t(f.label)}</Typography>
                  <FormControl variant="outlined" style={styles.formControl}>
                    <Select
                      disabled={isDisabled}
                      labelId={`${f.key}-label`}
                      id={f.key}
                      error={Boolean(touched[f.key] && errors[f.key])}
                      value={values[f.key]}
                      onChange={handleChange as any}
                      name={f.key}
                      label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                      required={f.isRequired}
                      sx={{
                        '& .MuiSelect-select': styles.selectStyle,
                        ...styles.capitalizeText
                      }}
                    >
                      {
                        supplierData.statuses && supplierData.statuses.length > 0
                          && supplierData.statuses.map((s) => (
                            <MenuItem key={s} value={s} sx={styles.capitalizeText}>
                              {s}
                            </MenuItem>
                          ))
                      }
                    </Select>
                    <FormHelperText error>
                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              );
            case 'generalLedgerAccLine':
              return (
                <Grid item  key={f.key} sx={styles.eachField}>
                  <Typography>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</Typography>
                  <FormControl variant="outlined" style={styles.formControl}>
                    <Autocomplete
                      disabled={isDisabled}
                      fullWidth
                      onChange={(e, v) => handleChangeAutocomplete(e, f.key, v as unknown as Record<string, any>, handleChange)}
                      value={glAccounts && values[f.key] && glAccounts.find((e:GLAccountType) => e.code === values[f.key]) || null}
                      inputValue={getLabel(glAccounts, values[f.key] as string)}
                      options={glAccounts}
                      filterOptions={options => options}
                      getOptionLabel={
                        (option) => (option && option.code ? `${option.code} | ${option.name}` :'')
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: ( isDisabled ? <></> :
                              <>
                                {values.generalLedgerAccLine !== supplierData.defaultGLA[0].code && (
                                  values?.generalLedgerAccLine ?
                                  <InputAdornment
                                    position="end"
                                    onClick={() => handleEditGLA(values.generalLedgerAccLine as string)}
                                    style={styles.editIconContainer}
                                  >
                                    <EditIcon style={styles.editDimensionIcon} />
                                  </InputAdornment>
                                  : <></>
                                )}
                                <InputAdornment position="end" style={styles.plusIconContainer}>
                                  <PlusIcon style={styles.plusIcon} onClick={() => handleAddGLA(f.key)} />
                                </InputAdornment>
                              </>
                            ),
                          }}
                          style={
                            (textFieldSelectedKey === 'generalLedgerAccLine' && isEdit) ? styles.selectStyleSelected : styles.selectStyle
                          }
                          onClick={() => { setTextFieldSelectedKey('generalLedgerAccLine'); setTextFieldSelectedValue(values.generalLedgerAccLine as string); }}
                          required={f.isRequired}
                          disabled={isDisabled}
                        />
                      )}
                    />
                    <FormHelperText error>
                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              );
            case 'vatGroupLine':
              return (
                <Grid item key={f.key} sx={styles.eachField}>
                  <Typography>{ready && t(f.label)}</Typography>
                  <FormControl variant="outlined" style={styles.formControl} sx={{ '& .MuiFormControl-root': styles.formControlRoot }}>
                    <Select
                      disabled={isDisabled}
                      labelId={`${f.key}-label`}
                      id={f.key}
                      error={Boolean(touched[f.key] && errors[f.key])}
                      value={values[f.key]}
                      onChange={handleChange as any}
                      name={f.key}
                      label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                      required={f.isRequired}
                      sx={{
                        '& .MuiSelect-select': styles.selectStyle
                      }}
                      IconComponent={() => ( isDisabled ? <></> :
                        <>
                          {values.vatGroupLine !== supplierData.defaultVatGroup[0].code && (
                            values.vatGroupLine ? 
                            <EditIcon style={styles.editDimensionIcon} onClick={() => handleEditVATCode(values.vatGroupLine as string)} />
                            : <></>
                          )}
                          <PlusIcon style={styles.plusIconSelect} onClick={() => handleAddVATCode('vatGroupLine')} />
                        </>
                      )}
                    >
                      {
                        vatGroups && vatGroups.length > 0
                          && vatGroups.map((s) => (
                            <MenuItem key={s.code} value={s.code}>
                              {`${s.code}${s.name ? ` | ${s.name}` : ''}`}
                            </MenuItem>
                          ))
                      }
                    </Select>
                    <FormHelperText error>
                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              );
            case 'costCenter':
              return (
                <Grid item key={f.key}sx={styles.eachField} >
                  <Typography>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</Typography>
                  <FormControl variant="outlined" style={styles.formControl} sx={{ '& .MuiFormControl-root': styles.formControlRoot }}>
                    <Select
                      disabled={isDisabled}
                      labelId={`${f.key}-label`}
                      id={f.key}
                      error={Boolean(touched[f.key] && errors[f.key])}
                      value={values[f.key]}
                      onChange={handleChange as any}
                      name={f.key}
                      label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                      required={f.isRequired}
                      sx={{
                        '& .MuiSelect-select': styles.selectStyle
                      }}
                      IconComponent={() => ( isDisabled ? <></> :
                        <>
                          {values.costCenter !== supplierData.defaultCostCenters[0].code && (
                            values?.costCenter ?
                            <EditIcon style={styles.editDimensionIcon} onClick={() => handleEditCostCenter(values.costCenter as string)} />
                            :<></>
                          )}
                          <PlusIcon style={styles.plusIconSelect} onClick={() => handleAddCostCenter('costCenter')} />
                        </>
                      )}
                    >
                      {
                        costCenters && costCenters.length > 0
                          && costCenters.map((s:CostCenterType) => {
                            if (s) {
                              return (
                                <MenuItem key={s.code} value={s.code}>
                                  {`${s.code}${s.name ? ` | ${s.name}` : ''}`}
                                </MenuItem>
                              );
                            }
                            return null;
                          })
                      }
                    </Select>
                    <FormHelperText error>
                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              );
            case 'project':
              return (
                <Grid item key={f.key} sx={styles.eachField}>
                  <Typography>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</Typography>
                  <FormControl variant="outlined" style={styles.formControl}>
                    <Select
                      disabled={isDisabled}
                      labelId={`${f.key}-label`}
                      id={f.key}
                      error={Boolean(touched[f.key] && errors[f.key])}
                      value={values[f.key]}
                      onChange={handleChange as any}
                      name={f.key}
                      label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                      required={f.isRequired}
                      sx={{
                        '& .MuiSelect-select': styles.selectStyle
                      }}
                    >
                      {
                        projects && projects.length > 0
                          && projects.map((s:ProjectType) => {
                            if (s) {
                              return (
                                <MenuItem key={s.code} value={s.code}>
                                  {`${s.code}${s.name ? ` | ${s.name}` : ''}`}
                                </MenuItem>
                              );
                            }

                            return null;
                          })
                      }
                    </Select>
                    <FormHelperText error>
                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              );
            case 'defaultCurrency':
              return (
                <Grid item key={f.key} sx={styles.eachField}>
                  <Typography>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</Typography>
                  <FormControl variant="outlined" style={styles.formControl}>
                    <Select
                      disabled={isDisabled}
                      labelId={`${f.key}-label`}
                      id={f.key}
                      error={Boolean(touched[f.key] && errors[f.key])}
                      value={values[f.key]}
                      onChange={handleChange as any}
                      name={f.key}
                      label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                      required={f.isRequired}
                      sx={{
                        '& .MuiSelect-select': styles.selectStyle
                      }}
                    >
                      {
                        currencies && currencies.length > 0
                          && currencies.map((s) => (
                            <MenuItem key={s.code} value={s.code}>
                              {`${s.code} | ${s.currency}`}
                            </MenuItem>
                          ))
                      }
                    </Select>
                    <FormHelperText error>
                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              );
            case 'bankCountry':
              let isRequired = f.isRequired; // eslint-disable-line
              if (f.key === 'bankCountry' && values.autoPaymentStatus === supplierData.apStatuses[2].code) {
                isRequired = false;
              }

              return (
                <Grid item key={f.key} sx={styles.eachField}>
                  <Typography>{ready && `${t(f.label)}${isRequired ? '*' : ''}`}</Typography>
                  <FormControl variant="outlined" style={styles.formControl}>
                    <Autocomplete
                      disabled={isDisabled}
                      fullWidth
                      onChange={(e, v) => handleChangeAutocomplete(e, f.key, v as unknown as Record<string, any>, handleChange)}
                      value={(values[f.key] && countries.length > 0) ? countries.find((o) => o.isoCode === values[f.key]) : null}
                      options={countries}
                      getOptionLabel={(option) => `${option.isoCode} | ${option.name}`}
                      filterOptions={options => options}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                          }}
                          style={styles.selectStyle}
                        />
                      )}
                    />
                    <FormHelperText error>
                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              );
            default:
              return (
                <Tooltip key={f.key} title={f.key === 'creditorCode' && isEdit ? t('DIMENSION_CODE_CANNOT_EDIT') : ''}>
                  <Grid item key={f.key} sx={styles.eachField}>
                    <Typography>{ready && t(f.label)}</Typography>
                    <TextField
                      id={`${f.key}Id`}
                      error={Boolean(touched[f.key] && errors[f.key])}
                      fullWidth
                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                      
                      name={f.key}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required={
                        f.isRequired
                        || ((f.key === 'ascription' || f.key === 'bicSwift')
                          && (
                            values.autoPaymentStatus === supplierData.apStatuses[0].code
                          || values.autoPaymentStatus === supplierData.apStatuses[1].code
                          )
                        )
                        || (f.key === 'iban' && values.autoPaymentStatus === supplierData.apStatuses[0].code)
                        || ((f.key === 'bankName' || f.key === 'bankCity' || f.key === 'bankStreet'
                          || f.key === 'bankHouseNumber' || f.key === 'city' || f.key === 'address1')
                          && values.autoPaymentStatus === supplierData.apStatuses[1].code
                        )
                      }
                      value={values[f.key]}
                      variant="outlined"
                      disabled={isDisabled?true:f.key === 'creditorCode'}
                      style={(textFieldSelectedKey === f.key && isEdit) ? styles.inputFieldSelected : styles.inputField}
                      onClick={() => { setTextFieldSelectedKey(f.key); setTextFieldSelectedValue(values[f.key] as string); }}
                    />
                  </Grid>
                </Tooltip>
              );
          }
        })
      }
      {user?.userRole.toLowerCase() !== userRoles.annotator && (
        isDisabled ? <></> :
        <Grid item xs={12} style={{ marginBottom: isEdit ? 10 : 0 }}>
          <Box display="flex" justifyContent="flex-end" p="20px 15px 5px">
            <Button
              variant="text"
              onClick={() => handleClose()}
              style={{...styles.yearField,textTransform:'none'}}
            >
              {ready && t('SUPPLIERS_CANCEL')}
            </Button>
            <Button
              style={{ backgroundColor: '#3E8AFF',textTransform:'none' }}
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              {ready && t(isEdit?'SUPPLIERS_SAVE_CHANGES':'DIMENSION_ADD_CLIENT')}
            </Button>
            
          </Box>
        </Grid>
      )}
      </>
    }
    </form>
  )
}

export default ClientStandardForm

const styles = {
  form:{
    padding:'10px 0 20px',
    maxHeight:'calc(94dvh - 126px)',
  },
  eachField:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    padding:'3px 16px',
    '& > p':{
      color:'#4C4E64DE',
      fontSize:'13px'
    }
  },
  inputFieldSelected: {
    backgroundColor: '#ffffff',
    maxWidth:'180px',
    '& > div':{
      border: '2px solid #C2CFE099',
    }
  },
  inputField: {
    backgroundColor: '#ffffff',
    maxWidth:'180px',
    '& > div':{
      border: '2px solid #C2CFE099',
    }
  },
  formControl: {
    width:'180px'
  },
  formControlRoot: {
    backgroundColor: '#ffffff'
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff'
  },
  selectStyleSelected: {
    color: theme.palette.text.primary,
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#fff'
  },
  editIconContainer: {
    marginRight: -5
  },
  editDimensionIcon: {
    fontSize: 18,
    color: '#757575',
    border: '1px solid #334D6E',
    borderRadius: 3,
    cursor: 'default'
  },
  plusIcon: {
    height: 24,
    width: 24,
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  plusIconContainer: {
    marginRight: -40
  },
  plusIconSelect: {
    height: 24,
    width: 24,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    marginRight: 5,
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  yearField: {
    marginLeft: 16,
    color: theme.palette.secondary.main
  },
  skipDiv:{
    display:'flex',
    alignItems:'center',
    padding:'12px',
    gap:'12px'
  }
}