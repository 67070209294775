import { useAuth } from 'src/hooks/useAuth';
import Dimensions from 'src/dimensions/Dimensions';
import DimensionsNew from 'src/dimensions-new/DimensionsNew';

const DimensionsView = () => {
  const { user } = useAuth();
  const isOldAnnotationInterface = user?.customisations.includes('oldAnnotationInterface');

  return (
    <>
      {
        isOldAnnotationInterface ?
          <Dimensions /> : <DimensionsNew />
      }
    </>
  );
}

export default DimensionsView;