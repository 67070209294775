import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import Axios from 'axios';

import {
  Box, Grid, Typography, TextField, Autocomplete, Paper, Tooltip,
} from '@mui/material';

import ConfigContext from 'src/contexts/ConfigContext';
import useWidth from 'src/hooks/useWidth';
import { useAuth } from 'src/hooks/useAuth';
import {
  CustomError, DocumentDeliveryFormatType, ExportMappingType, FieldType,
  DocType, RootState
} from 'src/types';
import {
  appendContactSupport, axiosHeaders, axiosHeadersWithArrayBuffer, getLocalisedErrorString,
  sendFeedback, isInvoiceFieldAvailable, validateEmail,
} from 'src/utils/helpers';
import {
  getCurrencyLabel, getIsError, getTermsLabel, getCountryLabel, getFieldTooltip, getBorderColor
} from 'src/document-edit/utils';
import DocumentEditActionButtons from 'src/document-edit/components/DocumentEditActionButtons/DocumentEditActionButtons';
import DocumentHeader from 'src/document-edit/components/DocumentHeader/DocumentHeader';
import ExportToEmailModal from 'src/document-edit/components/ExportToEmailModal/ExportToEmailModal';
import ManageExportFieldsNew from 'src/shared/components/ManageExportFieldsNew/ManageExportFieldsNew';
import { getIconOnTrustScore } from 'src/dashboard-new/utils';
import styles from './style';

interface PropTypes {
  fields: FieldType[];
  fieldsToDisplay: FieldType[];
  doc: DocType;
  details: Record<string, string>;
  detailsCandidates: Record<string, string[]>;
  statusChangeLoading: boolean;
  previousInvoice: DocType | null;
  nextInvoice: DocType | null;
  qbTerms: { code: string, name: string }[];
  isSubmitClicked: boolean;
  fieldTrustScores: Record<string, number>;
  fieldTrustScoresRef: React.MutableRefObject<Record<string, number>>;
  tags: string[];
  selectedTextField: FieldType;
  submitting: boolean;
  selectedTextFieldRef: React.MutableRefObject<FieldType>;
  headerEntity: string | undefined;
  isMouseDown: boolean;
  isMouseDownRef: React.MutableRefObject<boolean>;
  decimalSeparator: string;
  handleChange: (_val: string, _prop: string) => void;
  handleChangeMain: (_field: FieldType, _value: string | null) => void;
  handleChangeText: (_field: FieldType, _val: string) => void;
  handleSubmit: (_bgSave?: boolean) => void;
  setLoading: (_loading: boolean) => void;
  setStatusChangedRef: (_statusChanged: boolean) => void;
  getDocument: () => void;
  navigateInvoice: (_inv: DocType | null, _direction: number) => void;
  handleStatusChange: (_status: number, _asstID: string) => void;
  openInfoModal: () => void;
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  setIsSubmitClicked: (_val: boolean) => void;
  setMoveToNext: (_val: number | null) => void;
  handleChooseValidatorModalOpen: () => void;
  setSelectedTextField: (_val: FieldType) => void;
  setFieldTrustScores: (_val: Record<string, number>) => void;
  handleClose?:Function;
  moveNext?:Function;
  movePrevious?:Function;
  disableMove?:boolean[];
}

const metricLabels: Record<string, string> = {
  fat: 'CDC_DOC_METRICS_SECTION_FAT',
  protein: 'CDC_DOC_METRICS_SECTION_PROTEIN',
  cell: 'CDC_DOC_METRICS_SECTION_CELL',
  bact: 'CDC_DOC_METRICS_SECTION_BACT',
  adj: 'CDC_DOC_METRICS_SECTION_ADJ',
  lab: 'CDC_DOC_METRICS_SECTION_LAB',
  cuota: 'CDC_DOC_METRICS_SECTION_CUOTA'
};

const CDCDocFields = (props: PropTypes) => {
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {API} = useContext(ConfigContext);
  const { user } = useAuth();

  const lineFields: FieldType[] = useSelector((state: RootState) => state.fields.lineFields);

  const isAutoNavigationAllowed = user?.customisations.includes('autoNavigation') || false;

  const {
    fields,
    fieldsToDisplay,
    doc,
    details,
    detailsCandidates,
    statusChangeLoading,
    previousInvoice,
    nextInvoice,
    qbTerms,
    isSubmitClicked,
    fieldTrustScores,
    fieldTrustScoresRef,
    tags,
    selectedTextField,
    submitting,
    selectedTextFieldRef,
    headerEntity,
    isMouseDown,
    isMouseDownRef,
    decimalSeparator,
    handleChange,
    handleChangeMain,
    handleChangeText,
    handleSubmit,
    setLoading,
    setStatusChangedRef,
    getDocument,
    navigateInvoice,
    handleStatusChange,
    openInfoModal,
    setTags,
    setIsSubmitClicked,
    setMoveToNext,
    handleChooseValidatorModalOpen,
    setSelectedTextField,
    setFieldTrustScores,
    handleClose,
    moveNext,
    movePrevious,
    disableMove,
  } = props;

  const [renderedFields, setRenderedFields] = useState<FieldType[]>([]);
  const [deliverFormats, setDeliverFormats] = useState<DocumentDeliveryFormatType[]>([]);
  const [excelExportFieldMapping, setExcelExportFieldMapping] = useState<ExportMappingType[]>([]);
  const [datExportFieldMapping, setDatExportFieldMapping] = useState<ExportMappingType[]>([]);
  const [csvExportFieldMapping, setCsvExportFieldMapping] = useState<ExportMappingType[]>([]);
  const [showExportFieldMappings, setShowExportFieldMappings] = useState<boolean>(false);

  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [emails, setEmails] = useState(user?.deliverEmails && user?.deliverEmails?.length > 0 ? user.deliverEmails.join(', ') : user?.email || '');
  const [selectedDataFormatToEmail, setSelectedDataFormatToEmail] = useState(user?.deliverFormat || '');

  const [exportFormat, setExportFormat] = useState<DocumentDeliveryFormatType>({
    key: 'excel',
    label: 'Excel',
    fileExtension: 'xlsx',
    active: true,
  });

  const defaultFieldRef = useRef<HTMLInputElement | null>(null);

  const fieldsContainerRef = useRef<HTMLDivElement | null>(null);
  const fieldsContainerWidth = useWidth(fieldsContainerRef, 0);

  const handleSendEmailModalClose = () => {
    setSendEmailModalOpen(false);
  };

  const handleExportFieldMappingsClose = (updated?: boolean, format?: DocumentDeliveryFormatType) => {
    setShowExportFieldMappings(false);
    if (updated && format) {
      setTimeout(async () => {
        await getExcelExportFieldMapping();
        await getDatExportFieldMapping();
        await getCsvExportFieldMapping();
        handleDataFormatToExportClick(format, true);
      }, 1000);
    }
  };

  const getDeliverFormats = async () => {
    try {
      const response = await Axios.get(
        API.getDeliverFormats,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDeliverFormats(response.data.data);
        }
      }
    } catch (error) {
      setDeliverFormats([]);
    }
  };

  const getExcelExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}excel`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setExcelExportFieldMapping(response.data.data);
          return;
        }
      }
      setExcelExportFieldMapping([]);
    } catch (error) {
      setExcelExportFieldMapping([]);
    }
  };

  const getDatExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}dat`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDatExportFieldMapping(response.data.data);
          return;
        }
      }
      setDatExportFieldMapping([]);
    } catch (error) {
      setDatExportFieldMapping([]);
    }
  };

  const getCsvExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}csv`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setCsvExportFieldMapping(response.data.data);
          return;
        }
      }
      setCsvExportFieldMapping([]);
    } catch (error) {
      setCsvExportFieldMapping([]);
    }
  };

  useEffect(() => {
    getExcelExportFieldMapping();
    getDatExportFieldMapping();
    getCsvExportFieldMapping();
    getDeliverFormats();
  }, []);

  useEffect(() => {
    setRenderedFields(fields.filter((f) => f.isAvailable && f.isActive));
  }, [fields]);

  useEffect(() => {
    if (user?.deliverEmails && user.deliverEmails.length > 0) {
      setEmails(user.deliverEmails.join(', '));
    }

    if (user?.deliverFormat) {
      setSelectedDataFormatToEmail(user.deliverFormat);
    } else {
      const activeDeliverFormat = deliverFormats.find((o) => o.active);
      setSelectedDataFormatToEmail(activeDeliverFormat ? activeDeliverFormat.key : deliverFormats[0]?.key);
    }
  }, [user, deliverFormats]);

  const handleAutoSaveInvoice = async (toStatus?: number) => {
    if (!isSubmitClicked) {
      try {
        await handleSubmit();
        if (toStatus === 502) {
          handleStatusChange(502, doc.assistantID || '0');
        } else {
          handleChooseValidatorModalOpen();
        }
        return;
      } catch (error) {
        return;
      }
    }
    if (toStatus === 502) {
      handleStatusChange(502, doc.assistantID || '0');
    } else {
      handleChooseValidatorModalOpen();
    }
  };

  const handleDataFormatToExportClick = async (dataFormat: DocumentDeliveryFormatType, executeExport = false) => {
    if (((dataFormat.key === 'excel' && excelExportFieldMapping.length === 0)
      || (dataFormat.key === 'dat' && datExportFieldMapping.length === 0)
      || (dataFormat.key === 'csv' && csvExportFieldMapping.length === 0))
      && !executeExport) {
      setExportFormat(dataFormat);
      setShowExportFieldMappings(true);
      return;
    }

    try {
      setLoading(true);
      let url = '';
      let newWindow = null;

      url = `${API.export}${dataFormat.key}/${doc.assistantID}`;

      const header = (dataFormat.key !== 'quickbooks'
        && dataFormat.key !== 'holded'
        && dataFormat.key !== 'gstock'
        && dataFormat.key !== 'sftp-sender'
      )
        ? axiosHeadersWithArrayBuffer(localStorage.getItem('PROCYS_accessToken'))
        : axiosHeaders(localStorage.getItem('PROCYS_accessToken'));

      const response = await Axios.get(
        url,
        header
      );
      if (dataFormat.key === 'csv') {
        newWindow = window.open(response.data, '_blank');
      }

      if (dataFormat.key !== 'quickbooks'
        && dataFormat.key !== 'holded'
        && dataFormat.key !== 'gstock'
        && dataFormat.key !== 'sftp-sender'
      ) {
        const fileName = doc.assistantID?.toString().concat('.').concat(dataFormat.fileExtension);
        const blob = new Blob([response.data], { type: 'blob' });
        FileSaver.saveAs(blob, fileName);
        if (newWindow) {
          newWindow.close();
        }
      } else {
        enqueueSnackbar(t('EXPORT_SCHEDULED_SUCCESS'), {
          variant: 'success',
          autoHideDuration: 5000
        });
      }

      setStatusChangedRef(true);
      getDocument();
      sendFeedback(API.feedbackLogs, 'invoice export', user?.email, user?.companyID);
      setLoading(false);
      if (isAutoNavigationAllowed) {
        handleNavigateInvoice(nextInvoice, 1);
      }
    } catch (e) {
      const error = e as CustomError;
      let errString = error?.response?.data?.i18n;

      enqueueSnackbar(appendContactSupport(window.config.support_email, getLocalisedErrorString(errString || 'INVOICE_EXPORT_DATA_FAILURE', t), t), {
        variant: 'error',
        autoHideDuration: 5000
      });
      getDocument();
      setLoading(false);
    }
  };

  const handleSendToEmailClick = async () => {
    if (!emails || !emails.trim()) {
      enqueueSnackbar(t('PROCYS_VALIDATE_EMAIL_EMPTY'), {
        variant: 'error',
        autoHideDuration: 5000
      });
      return;
    }
    const emailsList = [];
    if (emails.indexOf(',') >= 0) {
      const emailsAr = emails.split(',');
      for (let i = 0; i < emailsAr.length; i++) {
        if (emailsAr[i] && emailsAr[i].trim()) {
          const resp = validateEmail(emailsAr[i].trim());
          if (!resp.isValid) {
            enqueueSnackbar(t('PROCYS_VALIDATE_EMAIL_INVALID'), {
              variant: 'error',
              autoHideDuration: 5000
            });
            return;
          }

          emailsList.push(emailsAr[i]);
        }
      }
    } else {
      const resp = validateEmail(emails.trim());
      if (!resp.isValid) {
        enqueueSnackbar(t(resp.errorMessage || 'PROCYS_VALIDATE_EMAIL_INVALID'), {
          variant: 'error',
          autoHideDuration: 5000
        });
        return;
      }

      emailsList.push(emails.trim());
    }

    try {
      setLoading(true);
      const resp = await Axios.post(
        `${API.exportToEmail}${selectedDataFormatToEmail}`,
        {
          assistantIDS: [doc.assistantID?.toString() || '000000'],
          emails: emailsList
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        setStatusChangedRef(true);
        sendFeedback(API.feedbackLogs, 'invoice export', user?.email, user?.companyID);
        enqueueSnackbar(t('INVOICE_SEND_TO_EMAIL_SUCCESS'), {
          variant: 'success',
          autoHideDuration: 5000
        });
        getDocument();
        setLoading(false);
        if (isAutoNavigationAllowed) {
          handleNavigateInvoice(nextInvoice, 1);
        }
      } else {
        enqueueSnackbar(t('INVOICE_SEND_TO_EMAIL_FAILURE'), {
          variant: 'error',
          autoHideDuration: 5000
        });
        setLoading(false);
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(error?.response?.data?.i18n || 'INVOICE_SEND_TO_EMAIL_FAILURE', t), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
      setLoading(false);
    }

    handleSendEmailModalClose();
  };

  const handleNavigateInvoice = async (toInvoice: DocType | null, direction: number) => {
    setLoading(true);
    setMoveToNext(direction);
    if (!isSubmitClicked && (doc.status === 500 || doc.status === 501 || doc.status === 509)) {
      try {
        await handleSubmit();
      } catch (error) {
        // do nothing
      }
    } else {
      setMoveToNext(null);
      navigateInvoice(toInvoice, direction);
    }
  };

  const getFieldLabel = (key: string, option: string) => {
    if (key === 'currency') {
      return getCurrencyLabel(option);
    }
    if (key === 'countryIssued' || key.includes('CountryCode')) {
      return getCountryLabel(option);
    }
    if (key === 'terms') {
      return getTermsLabel(option, qbTerms);
    }
    return option;
  };

  const onFieldFocus = (field: FieldType) => {
    if (isMouseDownRef.current) {
      const element = document.getElementById(field.key);
      if (element) {
        element.blur();
      }
      return;
    }
    setSelectedTextField(field);
  };

  const onFieldBlur = (key: string, isError: boolean, position: number) => {
    setTimeout(() => {
      const tipElement = document.getElementById('Tip');
      if (selectedTextFieldRef.current.key !== key && !tipElement && !isError) {
        setFieldTrustScores({ ...fieldTrustScoresRef.current, [key]: 2 });
      }
      if (position > 0 && position % 5 === 0
        && (doc.status === 500 || doc.status === 501 || doc.status === 502 || doc.status === 509)
        && selectedTextFieldRef.current.key !== key
      ) {
        handleSubmit(true);
      }
    }, 1000);
  };

  const renderOptions = (children: any, key: string, haveSuggestions: boolean) => (
    <Paper sx={{width: '100%'}}>
      {haveSuggestions && <Box sx={{ ...styles.suggestionsContainer, justifyContent: 'flex-end' }}>
        <Typography sx={styles.suggestionsText}>{t('DOCUMENT_EDIT_SUGGESTIONS')}</Typography>
      </Box>}
      {children}
    </Paper>
  );

  const renderInputField = (position: number, field: FieldType, error: boolean, trustScore: number) => {
    let { key , assistantKey } = field;
    const val = details[key];
    switch (key) {
      case 'period': case 'description':
        return (
          <TextField
            fullWidth
            id={key}
            name={key}
            onChange={(event) => handleChange(event.target.value, key)}
            required
            value={details[key]}
            onFocus={() => onFieldFocus(field)}
            onBlur={() => onFieldBlur(key, error, position)}
            variant="outlined"
            sx={{
              ...styles.textField,
              '& .MuiOutlinedInput-root': {
                backgroundColor: selectedTextField.key === key ? '#3E8AFF4D' : '#FFFFFF',
                '& fieldset': {
                  border: `2px solid ${getBorderColor(key, trustScore, error, details)}`,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${getBorderColor(key, trustScore, error, details)}`,
                }
              },
            }}
            error={error}
          />
        );

      default: {
        if (detailsCandidates[assistantKey] === undefined) {
          return null;
        }
        return (
          <Autocomplete
            ref={defaultFieldRef}
            id={key}
            fullWidth
            open={selectedTextField.key === key && !isMouseDown}
            onChange={(e, v) => handleChangeMain(field, v)}
            value={val}
            options={detailsCandidates[assistantKey]}
            getOptionLabel={(option) => getFieldLabel(key, option)}
            isOptionEqualToValue={(option: string, value: string) => option === value}
            PaperComponent={({ children }) => renderOptions(children, key, detailsCandidates[assistantKey].length > 0)}
            freeSolo
            openOnFocus
            onFocus={() => onFieldFocus(field)}
            onBlur={() => onFieldBlur(key, error, position)}
            sx={{
              '& .MuiOutlinedInput-root': {
                padding: '0px 2px 0px 0px !important',
                backgroundColor: selectedTextField.key === key ? '#3E8AFF4D' : '#FFFFFF',
                '& fieldset': {
                  border: `2px solid ${getBorderColor(key, trustScore, error, details)}`,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${getBorderColor(key, trustScore, error, details)}`,
                },
              }
            }}
            ListboxProps={{
              sx: styles.listBox
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                name={key}
                variant="outlined"
                InputProps={params.InputProps}
                onChange={(event) => handleChangeText(field, event.target.value)}
                error={error}
                sx={styles.textField}
              />
            )}
          />
        );
      }
    }
  };

  const renderField = (field: FieldType) => {
    let { key, label, isAvailable, isActive, position, dataType, isRequired } = field;
    const val = details[key];

    if (!isInvoiceFieldAvailable(user, key, Boolean(isAvailable && isActive))) {
      return null;
    }

    const isError = getIsError(key, dataType, val, user, decimalSeparator);
    let trustScore = fieldTrustScores[key];
    if (trustScore === undefined) {
      trustScore = -1;
    }

    return (
      <Box ref={fieldsContainerRef} key={key}>
        <Tooltip title={getFieldTooltip(key, dataType, details, [], [], user, t, decimalSeparator)}>
          <Grid container sx={styles.field}>
            <Grid item xs={6} sx={styles.fieldLabelContainer}>
              {getIconOnTrustScore(trustScore, isError, false, false)}
              <Typography sx={styles.fieldLabel}>{ready && t(label)} {isRequired && '*'}</Typography>
            </Grid>
            <Grid item xs={6} sx={styles.fieldValueContainer}>
              {renderInputField(position, field, isError, trustScore)}
            </Grid>
          </Grid>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Box sx={styles.main}>
      <DocumentHeader
        doc={doc}
        statusChangeLoading={statusChangeLoading}
        tags={tags}
        fieldsContainerWidth={fieldsContainerWidth}
        headerEntity={headerEntity}
        setTags={setTags}
        handleStatusChange={handleStatusChange}
        handleOpenManageFields={() => {}}
        openInfoModal={openInfoModal}
        setIsSubmitClicked={setIsSubmitClicked}
        handleClose={handleClose}

      />
      <Grid style={styles.fieldsContainer} className="y-scroll">
        {fieldsToDisplay.filter((f) => f.section === 'header').map((field) => renderField(field))}
        {Object.keys(metricLabels).map((key) => {
          return (
            <Box key={key} sx={styles.sectionContainer}>
              <Typography sx={styles.sectionLabel}>{t(metricLabels[key])}</Typography>
              {fieldsToDisplay.filter((f) => f.section === key).map((field) => renderField(field))}
            </Box>
          );
        })}
      </Grid>
      <DocumentEditActionButtons
        doc={doc}
        details={details}
        renderedFields={renderedFields}
        statusChangeLoading={statusChangeLoading}
        isSubmitClicked={isSubmitClicked}
        deliverFormats={deliverFormats}
        submitting={submitting}
        previousInvoice={previousInvoice}
        nextInvoice={nextInvoice}
        decimalSeparator={decimalSeparator}
        handleSubmit={handleSubmit}
        handleAutoSaveInvoice={handleAutoSaveInvoice}
        handleSelectStatus={handleStatusChange}
        handleDataFormatToExportClick={handleDataFormatToExportClick}
        setSendEmailModalOpen={setSendEmailModalOpen}
        handleNavigateInvoice={handleNavigateInvoice}
        moveNext={moveNext||function(){}}
        movePrevious={movePrevious ||function(){}}
        disableMove={disableMove}
      />
      <ExportToEmailModal
        open={sendEmailModalOpen}
        emails={emails}
        selectedDataFormatToEmail={selectedDataFormatToEmail}
        deliverFormats={deliverFormats}
        handleClose={handleSendEmailModalClose}
        setEmails={setEmails}
        setSelectedDataFormatToEmail={setSelectedDataFormatToEmail}
        handleSendToEmailClick={handleSendToEmailClick}
      />
      <ManageExportFieldsNew
        open={showExportFieldMappings}
        format={exportFormat}
        fields={fields}
        lineFields={lineFields}
        supplierFields={[]}
        excelFieldMapping={excelExportFieldMapping}
        datFieldMapping={datExportFieldMapping}
        csvFieldMapping={csvExportFieldMapping}
        handleClose={handleExportFieldMappingsClose}
      />
    </Box>
  );
};

export default CDCDocFields;
