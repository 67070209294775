import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import { Box, Button, Table, TableBody, TableRow, TableCell, Tooltip, Typography } from '@mui/material';
import {
  Add as AddIcon
} from '@mui/icons-material';

import { axiosHeaders, isActionPermitted, permissions } from 'src/utils/helpers';
import { useConfig } from 'src/hooks/useConfig';
import { useAuth } from 'src/hooks/useAuth';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import authService from 'src/utils/authService';
import styles from './style';

const Cards = (props: { subscription: Record<string, any> }) => {
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { API, LOGIN_PANEL_URL } = useConfig();
  const { user } = useAuth();

  const { subscription } = props;

  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState<Record<string, any>[]>([]);
  const [defaultCard, setDefaultCard] = useState();
  // eslint-disable-next-line no-undef
  const intervalID = useRef<NodeJS.Timer>();

  const getAllCards = async (id: string | null) => {
    setLoading(true);
    try {
      const response = await Axios.get(
        API.userCards,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        let defCard = null;
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].id === id) {
            defCard = response.data.data[i];
          }
        }

        if (defCard) {
          setCards([defCard]);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const paymentProfileMethod = async () => {
    try {
      const response = await Axios.get(
        API.subscriptionDetails,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        getAllCards(response.data.data[0].creditCard.id);
        setDefaultCard(response.data.data[0].creditCard.id);
      } else {
        getAllCards(null);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    paymentProfileMethod();
  }, []);

  const onStartInterval = () => {
    let counter = 0;
    intervalID.current = setInterval(async () => {
      counter++;
      paymentProfileMethod();

      if (counter === 120) {
        clearInterval(intervalID.current);
      }
    }, 10000);
  };

  const handleAddCard = () => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const width = screenWidth > 700 ? 700 : screenWidth - 20;
    const height = screenHeight > 700 ? 700 : screenHeight - 20;

    const excessH = screenHeight - height - 100;
    const top = excessH > 0 ? excessH / 2 : 10;

    const excessW = screenWidth - width;
    const left = excessW > 0 ? excessW / 2 : 10;

    window.open(subscription.selfServiceUrl, 'ProcysBillingInformation', `height=${height},width=${width},top=${top},left=${left}`);
    onStartInterval();
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.titleRow}>
        <Typography sx={styles.title}>{t('PLAN_CARDS_HEADER_CARDS')}</Typography>
        {
          cards && cards.length > 0 ? (
            <Tooltip title={isActionPermitted(permissions.subscriptionsEditCard, user?.permissions)
              ? ready && t('ACCOUNT_SETTINGS_BILLING_EDIT_CARD_BTN_TOOLTIP')
              : ready && t('ACCOUNT_SETTINGS_BILLING_EDIT_CARD_BTN_TOOLTIP_PERMISSION')}
            >
              <span>
                <Button
                  type="submit"
                  variant="contained"
                  sx={styles.addCardBtn}
                  onClick={handleAddCard}
                  disabled={!isActionPermitted(permissions.subscriptionsAddCard, user?.permissions)}
                >
                  {ready && t('ACCOUNT_SETTINGS_BILLING_EDIT_CARD_BTN_TITLE')}
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title={isActionPermitted(permissions.subscriptionsAddCard, user?.permissions)
              ? ready && t('ACCOUNT_SETTINGS_BILLING_INVOICES_ADD_CARD_TOOLTIP')
              : ready && t('ACCOUNT_SETTINGS_BILLING_INVOICES_ADD_CARD_TOOLTIP_PERMISSION')}
            >
              <span>
                <Button
                  type="submit"
                  variant="contained"
                  sx={styles.addCardBtn}
                  startIcon={<AddIcon sx={styles.addCardIcon} />}
                  onClick={handleAddCard}
                  disabled={!isActionPermitted(permissions.subscriptionsAddCard, user?.permissions)}
                >
                  {ready && t('ACCOUNT_SETTINGS_BILLING_INVOICES_ADD_CARD')}
                </Button>
              </span>
            </Tooltip>
          )
        }
      </Box>
      {cards && cards.length > 0
        ? (
        <Table>
          <TableBody>
            {cards.map((card) => (
              <TableRow key={card.id}>
                <TableCell sx={styles.bodyCell} className="edit-invoice-line-body">
                  <Box sx={styles.cardNumberRow}>
                    {card.cardType === 'master' && <img src="/static/images/master.svg" alt="" style={styles.cardType} />}
                    {card.cardType === 'visa' && <img src="/static/images/visa.svg" alt="" style={styles.cardType} />}
                    {card.cardType === 'american_express' && <img src="/static/images/amex.svg" alt="" style={styles.cardType} />}
                    {card.maskedCardNumber}
                    {
                      defaultCard === card.id
                        ? (
                          <Box sx={styles.defaultLabel}>
                            {ready && t('ACCOUNT_SETTINGS_BILLING_INVOICES_DEFAULT')}
                          </Box>
                        )
                        : null
                    }
                  </Box>
                </TableCell>
                <TableCell align="right" sx={styles.bodyCell} className="edit-invoice-line-body">
                  {`${card.firstName || ''} ${card.lastName || ''}`}
                </TableCell>
                <TableCell align="right" sx={styles.bodyCell} className="edit-invoice-line-body">
                {
                  ready && `${t('Exp.')}${card.expirationMonth.toString().length !== 2
                    ? `0${card.expirationMonth}`
                    : card.expirationMonth}/${card.expirationYear}`
                }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        ) : <></>
      }
    </Box>
  );
};

export default Cards;