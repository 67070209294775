import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  Box, Table, TableBody, TableCell, TableRow, Hidden, Tooltip
} from '@mui/material';

import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import { getInvoiceStatusKey } from 'src/utils/helpers';
import { invoiceStatuses } from 'src/config';
import styles from '../style';

const InvoiceLogsTable = ({
  paginatedLogs, selectedLogs, status, invoiceID
}) => {
  const navigate = useNavigate();
  const [position, setPosition] = useState({ x: undefined, y: undefined });
  const [logs, setLogs] = useState(paginatedLogs);
  const { ready, t } = useTranslation();

  const today = new Date().setHours(0, 0, 0, 0) / 1000;

  useEffect(() => {
    const filteredLogs = [];
    if (status === invoiceStatuses.all) {
      setLogs(paginatedLogs);
    } else {
      paginatedLogs.forEach((i) => {
        if (getInvoiceStatusKey(i.status) === status) {
          filteredLogs.push(i);
        }
      });

      setLogs(filteredLogs);
    }
  }, [paginatedLogs, status]);

  return (
    <Box minWidth="100%">
      <Table size="small">
        <TableBody>
          {
            logs && logs.length > 0
              ? logs.map((log) => {
                const isDocumentLogSelected = selectedLogs.includes(log.id);
                const isDocumentLogToday = log.timestamp / 1000000000 >= today;
                const todayTextStyle = isDocumentLogToday ? styles.todayText : {};

                return (
                  <Tooltip
                    key={log.id}
                    disableHoverListener={log.status !== 100}
                    title={log.status === 100 ? ready && t('INVOICE_LIST_VIEW_LOGS_DETAILS_TOOLTIP') : ''}
                    onMouseMove={(e) => setPosition({ x: e.pageX, y: e.pageY })}
                    PopperProps={{
                      anchorEl: {
                        clientHeight: 0,
                        clientWidth: 0,
                        getBoundingClientRect: () => ({
                          top: position.y,
                          left: position.x,
                          right: position.x,
                          bottom: position.y,
                          width: 0,
                          height: 0,
                        })
                      }
                    }}
                  >
                    <TableRow
                      hover
                      key={log.id}
                      style={isDocumentLogToday ? { ...styles.pointer, backgroundColor: '#F9FAFC' } : styles.pointer}
                      selected={isDocumentLogSelected}
                      onClick={
                        log.assistantID
                          ? () => navigate(`/documents/logs/${log.id}${invoiceID ? `?invoiceID=${invoiceID}` : ''}`)
                          : () => {}
                      }
                    >
                      <Hidden xsDown>
                        <TableCell
                          style={{ ...todayTextStyle, fontSize: 16, lineHeight: '24px' }}
                        >
                          {log.assistantID ? log.assistantID : '-'}
                        </TableCell>
                      </Hidden>
                      <Hidden mdDown>
                        <TableCell
                          style={{ ...todayTextStyle, fontSize: 16, lineHeight: '24px' }}
                        >
                          {log.medium}
                        </TableCell>
                      </Hidden>
                      <TableCell
                        style={{ ...todayTextStyle, fontSize: 16, lineHeight: '24px' }}
                      >
                        {log.action}
                      </TableCell>
                      {/* <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                        >
                          <Hidden xsDown>
                            <Avatar
                              alt="user"
                              style={styles.userImage}
                              src={`${API.getProfileImage}${log.ownerAvatar}`}
                            />
                          </Hidden>
                          <Hidden mdDown>
                            <Box ml={2}>
                              <Link
                                variant="subtitle2"
                                color="textPrimary"
                                underline="none"
                                style={styles.usernameText}
                              >
                                {`${log.firstname} ${log.lastname}`}
                              </Link>
                            </Box>
                          </Hidden>
                        </Box>
                      </TableCell> */}
                      {
                        isDocumentLogToday ? (
                          <TableCell
                            align="right"
                            style={{ ...todayTextStyle, fontSize: 15, lineHeight: '22.5px' }}
                          >
                            {moment.unix(log.timestamp / 1000000000).format('hh:mm')}
                          </TableCell>
                        ) : (
                          <TableCell
                            align="right"
                            style={{ ...styles.timeBox, fontSize: 15, lineHeight: '22.5px' }}
                          >
                            {moment.unix(log.timestamp / 1000000000).format('YYYY-MM-DD HH:mm')}
                          </TableCell>
                        )
                      }
                      <TableCell />
                    </TableRow>
                  </Tooltip>
                );
              })
              : <EmptyText str="INVOICE_LOGS_LIST_VIEW_NO_LOGS" />
          }
        </TableBody>
      </Table>
    </Box>
  );
};

export default InvoiceLogsTable;
