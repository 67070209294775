const styles = {
  main: {
    padding: '0px',
  },
  fieldsContainer: {
    // view height - header height (120px) - action buttons height (66px) - padding (4px)
    maxHeight: 'calc(100vh - 190px)',
    minHeight: 'calc(100vh - 190px)',
    overflow: 'scroll',
    padding: '8px 16px'
  },
  field: {
    margin: '8px 0'
  },
  fieldLabelContainer: {
    width: '100%',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
  },
  fieldValueContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  fieldLabel: {
    color: '#4C4E64DE',
    fontSize: '12px',
    fontWeight: '400',
    marginLeft: '4px',
  },
  labelIconPlaceholder: {
    width: '18px',
    height: '18px',
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      textAlign: 'right' as const,
      fontSize: '14px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '500',
    }
  },
  listBox: {
    '& .MuiAutocomplete-option': {
      justifyContent: 'flex-end',
      textAlign: 'right' as const,
      padding: '5px 10px !important',
    },
    '& .MuiAutocomplete-option.Mui-focused': {
      backgroundColor: '#C2CFE033',
    },
    '&.MuiAutocomplete-listbox': {
      border: 'none',
      boxShadow: 'none',
      borderRadius: '0px',
      padding: '0px !important',
    }
  },
  suggestionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '32px',
    padding: '0px 10px',
    opacity: 0.5
  },
  suggestionsText: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '32px',
    letterSpacing: '-0.25px',
    textAlign: 'right' as const,
    color: '#4C4E64',
    textTransform: 'uppercase',
  },
  sectionContainer: {
    margin: '20px 0',
    borderTop: '1px solid #C2CFE099',
  },
  sectionLabel: {
    fontSize: '15px',
    fontWeight: '500',
    color: '#4C4E64',
  }
};

export default styles;
