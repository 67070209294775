import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, IconButton, Typography } from '@mui/material';
import { Cancel as CloseIcon } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';

const AnnouncementBanner = () => {
  const { ready, t } = useTranslation();

  const { user } = useAuth();
  const announcement = user?.announcement;
  const { BRAND_NAME, SUPPORT_EMAIL } = useConfig();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const viewedAndClosed = sessionStorage.getItem('bannerClosed');
    if (viewedAndClosed) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, []);

  const handleClose = () => {
    setVisible(false);
    sessionStorage.setItem('bannerClosed', 'true');
  };

  if (!visible || !announcement) return null;

  return (
    <Grid style={styles.banner}>
      <Typography style={styles.message}>
        {ready && t(announcement, { brand: BRAND_NAME, email: SUPPORT_EMAIL })}
      </Typography>
      <IconButton style={styles.closeButton} onClick={handleClose}>
        <CloseIcon sx={styles.icon} />
      </IconButton>
    </Grid>
  );
};

const styles = {
  banner: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: '#fff',
    padding: '10px 10px',
    textAlign: 'center',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    zIndex: 100000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    margin: 0,
    fontSize: '16px',
    fontWeight: 'bold',
    flex: 1,
    textAlign: 'center',
  },
  closeButton: {
    background: 'none',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
  },
  icon: {
    color: '#fff',
    height: '28px',
    width: '28px',
  }
};

export default AnnouncementBanner;
