import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import { useTranslation } from 'react-i18next';
import FileSaver from 'file-saver';
import Papa from 'papaparse';
import Axios from 'axios';

import {
  Box, Button, Grid, Typography
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/GetApp';

import LoadingScreen from 'src/shared/screens/LoadingScreen';
import ConfigContext from 'src/contexts/ConfigContext';
import theme from 'src/theme';

const DocumentDisplay = () => {
  const { API } = useContext(ConfigContext);
  const { t, ready } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [fileResponse, setFileResponse] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);

  const params = useParams();

  const fileHandler = (fileObj) => {
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        localStorage.removeItem('PROCYS_PERSIST_URL');
        setError(true);
      } else {
        setError(false);
        const columns = [{ name: '', key: 0 }];
        resp.cols.forEach((item) => {
          columns.push({ name: item.name, key: item.key + 1 });
        });
        setCols(columns);
        setRows(resp.rows);
      }
    });
  };

  const csvToRowsNCols = (response) => {
    Papa.parse(response.data, {
      complete(results) {
        if (results.errors.length > 0) {
          localStorage.removeItem('PROCYS_PERSIST_URL');
          setError(true);
        } else {
          setError(false);
          const columns = [{ name: '', key: 0 }];
          results.data[0].forEach((item, index) => {
            columns.push({ name: item, key: index + 1 });
          });
          setCols(columns);
          setRows(results.data.slice(1));
        }
      }
    });
  };

  const getDoc = async (fileName) => {
    setError(false);
    const url = `${API.getAttachment}${fileName}`;
    const type = fileName.slice(fileName.indexOf('.') + 1);
    setFileType(type);
    try {
      const response = await Axios.get(
        `${url}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      let blob = null;
      switch (type) {
        case 'pdf': {
          blob = new Blob([response.data], { type: 'application/pdf' });
          setFileResponse(window.URL.createObjectURL(blob));
          break;
        }
        case 'png': case 'jpeg': case 'tiff': {
          blob = new Blob([response.data], { type: `image/${type}` });
          setFileResponse(window.URL.createObjectURL(blob));
          break;
        }
        case 'xlsx': case 'xls': {
          blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
          setFileResponse(window.URL.createObjectURL(blob));
          fileHandler(new Blob([response.data]));
          break;
        }
        case 'csv': {
          blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
          setFileResponse(window.URL.createObjectURL(blob));
          csvToRowsNCols(response);
          break;
        }
        default: {
          const blobFile = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
          FileSaver.saveAs(blobFile, fileName);
          window.open('about:blank', '_self');
          window.close();
          break;
        }
      }
      setLoading(false);
      localStorage.removeItem('PROCYS_PERSIST_URL');
    } catch (err) {
      setLoading(false);
      setError(true);
      if (document?.referrer?.indexOf('login/') >= 0) {
        localStorage.removeItem('PROCYS_PERSIST_URL');
      } else {
        localStorage.setItem('PROCYS_PERSIST_URL', window.location.href);
      }
      setTimeout(() => { window.location.href = '/dashboard'; }, 2000);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    setError(false);
    if (params?.name) {
      getDoc(params.name);
    } else {
      setLoading(false);
      setError(true);
      localStorage.removeItem('PROCYS_PERSIST_URL');
      setTimeout(() => window.location.href('/dashboard'), 2000);
    }
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <div style={styles.errorContainer}>
        <Box width={800}>
          <Typography align="center" variant="subtitle1" style={styles.errorText}>
            {ready && t('ATTACHMENT_ERROR')}
          </Typography>
        </Box>
      </div>
    );
  }

  const topBar = (
    <Grid style={styles.topBar}>
      <Typography style={styles.fileName}>{params?.name}</Typography>
      <Button
        style={styles.buttonCls}
        onClick={() => FileSaver.saveAs(fileResponse, params?.name)}
      >
        <DownloadIcon />
      </Button>
    </Grid>
  );

  return (
    <>
      {fileType === 'pdf' && (<iframe src={fileResponse} title="pdf" style={styles.pdfFrame} />)}
      {(fileType === 'png' || fileType === 'jpeg' || fileType === 'tiff')
        && (
          <Grid style={styles.root}>
            {topBar}
            <Grid style={styles.docContainer}>
              <img src={fileResponse} alt="" style={styles.imageDisplay} />
            </Grid>
          </Grid>
        )}
      {(fileType === 'xlsx' || fileType === 'xls' || fileType === 'csv')
        && (
          <Grid style={styles.root}>
            {topBar}
            <Grid style={styles.docContainer}>
              <OutTable
                data={rows}
                columns={cols}
                tablestyle={styles.excelTable2007}
                tableHeaderRowClass={styles.heading}
              />
            </Grid>
          </Grid>
        )}
    </>
  );
};

export default DocumentDisplay;

const styles = {
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'gray',
    flexDirection: 'column',
  },
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 36,
    width: '100%',
    justifyContent: 'space-between'
  },
  fileName: {
    color: '#ffffff',
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 16
  },
  buttonCls: {
    cursor: 'pointer',
    marginRight: '2px',
    minWidth: 48,
    color: '#ffffff'
  },
  errorContainer: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  errorText: {
    color: '#4E342E',
    fontWeight: '500',
    fontSize: 16
  },
  pdfFrame: {
    width: '100%',
    height: '100%',
    borderWidth: 0
  },
  docContainer: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    height: '100%',
  },
  imageDisplay: {
  },
  excelTable2007: {
    backgroundColor: '#ffffff',
    border: '1px solid #B0CBEF',
    borderWidth: '1px 0px 0px 1px',
    fontSize: 13,
    fontFamily: 'Calibri',
    fontWeight: 100,
    borderSpacing: '0px',
    borderCollapse: 'collapse',
    padding: '10px',
    '& TH': {
      backgroundColor: '#E4ECF7',
      backgroundRepeat: 'repeat-x',
      fontWeight: 'bold',
      fontSize: '14px',
      border: '1px solid #9EB6CE',
      borderWidth: '0px 1px 1px 0px',
      height: '17px',
      lineHeight: '17px',
      textAlign: 'center',
      paddingLeft: '4px',
      paddingRight: '4px !important'
    },
    '& TD': {
      padding: '0px 4px 0px 2px',
      border: '1px solid #D0D7E5',
      borderWidth: '0px 1px 1px 0px'
    },
    '& TD B': {
      border: '0px',
      fontWeight: 'bold'
    },
    '& TD.heading': {
      backgroundColor: '#E4ECF7',
      textAlign: 'center',
      border: '1px solid #9EB6CE',
      borderWidth: '0px 1px 1px 0px',
      fontWeight: 'bold'
    }
  },
  heading: {
    backgroundColor: '#E4ECF7',
    textAlign: 'center',
    border: '1px solid #9EB6CE',
    borderWidth: '0px 1px 1px 0px',
    fontWeight: 'bold'
  }
};
