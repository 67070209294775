/* eslint-disable max-len */
import * as React from "react"
import { JSX } from "react/jsx-runtime"

function SvgComponent(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width={20}
        height={20}
        rx={4}
        fill="url(#paint0_linear_3366_3248)"
        fillOpacity={0.2}
      />
      <path
        d="M6.617 13H5.05L2.961 9.848V13H1.393V7.36h1.568l2.088 3.192V7.36h1.568V13zM8.58 8.616v.92h1.8v1.192h-1.8v1.016h2.04V13H7.01V7.36h3.607v1.256h-2.04zM18.837 7.36L17.453 13h-1.928l-.776-3.456L13.957 13H12.03l-1.368-5.64h1.696l.656 3.856.888-3.856h1.72l.864 3.824.656-3.824h1.696z"
        fill="#3681E9"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3366_3248"
          x1={-1.21212}
          y1={1.57895}
          x2={23.1028}
          y2={8.37869}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3E8AFF" />
          <stop offset={1} stopColor="#9E71FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgComponent
