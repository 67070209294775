import React, { useState, useEffect, useRef, Dispatch, SetStateAction} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import { Box, Grid, Backdrop, CircularProgress, Typography, FormControl, RadioGroup, Radio, 
  FormControlLabel, Button, List, ListItem, MenuItem, Select, Checkbox
} from "@mui/material";
import { ArrowForward as ContinueIcon, CloudUpload } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { translateMap, dimensionsMap, allFields, dimensionImportStatusCheckTimer} from 'src/config';
import { appendContactSupport,axiosHeadersFormData, getLocalisedErrorString, axiosHeaders,} from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import StepLabel from 'src/dimensions-new/importView/StepLabel';
import StepLine from 'src/dimensions-new/importView/StepLine';
import { useConfig } from 'src/hooks/useConfig';
import { CustomError } from 'src/types';

type EachImportOptionType = {
  id: number;
  title: string;
  description: string;
}

type EachField = {
  key?: string;
  assistantKey?: string;
  label?: string;
  isMandatory?: boolean;
  isUpdateField?: boolean;
  isAvailable?: boolean;
  isActive?: boolean;
  isRequired?: boolean;
}

type FieldRecord = {
  invoice:EachField[];
  'invoice-line': EachField[];
  supplier: EachField[];
  gla: EachField[];
  vat: EachField[];
  cc: EachField[];
  'invoice-line-display': EachField[];
}

const dimensionImportSteps = [
  {
    id: 0,
    label: 'DIMENSION_IMPORT_STEP_ONE'
  },
  {
    id: 1,
    label: 'DIMENSION_IMPORT_STEP_TWO'
  },
  {
    id: 2,
    label: 'DIMENSION_IMPORT_STEP_THREE'
  }
];

const uploadInstructions = [
  {
    id: 0,
    label: 'DIMENSION_IMPORT_INSTRUCTIONS_01'
  },
  {
    id: 1,
    label: 'DIMENSION_IMPORT_INSTRUCTIONS_02'
  },
  {
    id: 2,
    label: 'DIMENSION_IMPORT_INSTRUCTIONS_03'
  }
];

const titleSelection: Record<string, string | any> = {
  clients:{
    receiver:'DIMENSION_IMPORT_SUPPLIER_RECEIVER_HEADER',
    sender:'DIMENSION_IMPORT_SUPPLIER_SENDER_HEADER',
    'gstock-clients': 'GSTOCK_IMPORT_SUPPLIERS',
    'gstock-products': 'GSTOCK_IMPORT_PRODUCTS'
  },
  glaccounts:'DIMENSION_IMPORT_GLA_HEADER',
  vatcodes:'DIMENSION_IMPORT_VATCODE_HEADER',
  costcenters:'DIMENSION_IMPORT_COSTCENTER_HEADER'
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const fieldUrlDimension: Record<string, string> = {
  suppliers: 'supplier',
  glaccounts: 'gla',
  vatcodes: 'vat',
  costcenters: 'cc'
}

const ImportView = (props: { setShowImport: Dispatch<SetStateAction<boolean>> }) => {
  const { setShowImport } = props;
  const type = useParams<Record<string, string>>().type || 'clients';
  const [uploadType, setUploadType] = useState<number>(-1)
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<number>(1);
  const [dimensionDataColumns, setDimensionDataColumns] = useState([]);
  const [fieldsMap, setFieldsMap] = useState<Record<string,string | number>>({});
  const [fields, setFields] = useState<Record<string, string>[]>([]);
  const [importDimensionFile, setImportDimensionFile] = useState<Blob | null>(null);
  const [importFileName, setImportFileName] = useState<string>('');
  const [importStatus, setImportStatus] = useState<string>('');
  const [importProgress, setImportProgress] = useState<string>('0');
  const [importStatusCheckCode, setImportStatusCheckCode] = useState<string>('');
  const [importing, setImporting] = useState<boolean>(false);
  const { API } = useConfig();
  const location = useLocation();
  const queryVal = new URLSearchParams(location.search);
  const type2 = queryVal.get('type2');

  const dimension = type === 'clients' ? 'suppliers' : type;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const intervalId = useRef<Record<string, any> | null>(null);
  
  const [updateField, setUpdateField] = useState(dimension === 'suppliers' ? 'creditorCode' : 'code');
 
  const tMap: Record<string, string> = translateMap;
  const dMap: Record<string, string> = dimensionsMap;

  const aFields: FieldRecord = allFields;

  const dimensionImportOptions = [
    {
      id: 0,
      title: `${tMap[dimension]}_IMPORT_OPTION_ONE_TITLE`,
      description: `${tMap[dimension]}_IMPORT_OPTION_ONE_DESCR`
    },
    {
      id: 1,
      title: `${tMap[dimension]}_IMPORT_OPTION_TWO_TITLE`,
      description: `${tMap[dimension]}_IMPORT_OPTION_TWO_DESCR`
    }
  ];

  const fetchDimensions = () =>{}

  const uploadDimensionsFile = async () =>{
    if(importDimensionFile){
      setLoading(true);
      const data = new FormData();
      data.append('importDimensionFile', importDimensionFile);
      try {
        const url = `${API.dimensions}${dMap[dimension]}/import/map`;
        const response = await Axios.post(
          url,
          data,
          axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
        );
        if (response.data.success) {
          enqueueSnackbar(t('DIMENSION_IMPORT_FILE_UPLOAD_SUCCESS'), {
            variant: 'success',
            persist: false
          });
          if (response.data.data?.columns) {
            setDimensionDataColumns(response.data.data?.columns);
          }
          const parsedFieldsMap = JSON.parse(response.data.data?.fieldsMap);
          delete parsedFieldsMap[''];
          const newFieldsMap: Record<string, number> = {} ;
          Object.keys(parsedFieldsMap).forEach((key) => {
            const indexOfColumn = response.data.data?.columns.findIndex((o:number) => o === parsedFieldsMap[key]);
            if (indexOfColumn !== -1) {
              newFieldsMap[key] = indexOfColumn;
            }
          });
          setFieldsMap(newFieldsMap);
          setLoading(false);
          setStep(3);
        } else {
          enqueueSnackbar(response.data.message, {
            variant: 'error',
            persist: false
          });
          setLoading(false);
        }
      } catch (e) {
        const error = e as CustomError;
        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || 'DIMENSION_IMPORT_FILE_UPLOAD_FAIL', t),
          t
        ), {
          variant: 'error',
          persist: true
        });
        setLoading(false);
      }
    }
    else{
      enqueueSnackbar(t('DIMENSION_IMPORT_FILE_UPLOAD_WARN'), {
        variant: 'warning',
        persist: false
      });
      setLoading(false);
    }
  };

  const handleConfirmFieldsMap = () => {
    if (step === 3 && importDimensionFile && uploadType === 1) {
      setStep(4);
    } 
    else if(step === 3 && importDimensionFile && uploadType === 0){
      uploadDimensions();
    }
    else if(step === 4) {
      uploadDimensions();
    }
  };

  const handleCheckBoxClick = (key: string) => {
    setUpdateField(key);
  };

  const handleploadDimensionsFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
   
    if (files && files?.length > 0) {
      
      const file = files[0];
      if(file){ 
        setImportDimensionFile(file);
        setImportFileName(file?.name)
      }
    } 
    
  };

  const handleContinueUploadImport = () =>{
    setStep(2)
  }

  const handleContinueUploadFile = () =>{
    uploadDimensionsFile()
  }

  const handleChangeFieldMap = (e:Record<string, any>, key:string) => {
    const val = e && e?.target && e?.target?.value ? e?.target?.value : '';
    setFieldsMap((prevState:any) => ({ ...prevState, [key]: val === 0 ? undefined : e.target.value - 1 }));
  };

  const checkImportStatus = async (code = importStatusCheckCode) => {
    if (code) {
      const url = `${API.processes}${code}/status`;
      try {
        const response = await Axios.get(
          url,
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        if (response.data.success) {
          setImportStatus(response.data.data.status);
          setImportProgress(response.data.data.progress);
          if (response.data.data.completed) {
            clearInterval(intervalId.current as unknown as  number);
            fetchDimensions();
            setImporting(false);
            setLoading(false);
            setImportStatusCheckCode('');
            setImportStatus('');
            setImportProgress('0');
            enqueueSnackbar(t(`${tMap[dimension]}_IMPORT_SUCCESS`), {
              variant: 'success',
              persist: false
            });
            setShowImport(false);
          }
        } else if (!response.data.success || response.data.data.status === 'PROCESS_STATUS_ERROR') {
          clearInterval(intervalId.current as unknown as number);
          setLoading(false);
          setImportStatusCheckCode('');
          setImportStatus('');
          setImportProgress('0');
          enqueueSnackbar(`${t(`${tMap[dimension]}_IMPORT_FAILED`)} ${response.data.data.errMsg}`, {
            variant: 'error',
            persist: false
          });
        }
      } catch (error) {
        clearInterval(intervalId.current as unknown as number);
        setImporting(false);
        setLoading(false);
        setImportStatusCheckCode('');
        setImportStatus('');
        setImportProgress('0');
        enqueueSnackbar(t(`${tMap[dimension]}_IMPORT_FAILED`), {
          variant: 'error',
          persist: false
        });
      }
    }
  };

  const onStartInterval = (code: string) => {
    intervalId.current = setInterval(() => {
      checkImportStatus(code);
    }, dimensionImportStatusCheckTimer);
  };

  const populateFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/${fieldUrlDimension[dimension]}/${encodeURIComponent(user?.companyID as string)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success && response.data.data) {
        setFields(response.data.data);
      }
    } catch (error) {
      setFields([]);
    }
  };

  const handleImportOptionChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setUploadType(parseInt(e.target.value, 10));
  };

  const uploadDimensions = async () => {
    setLoading(true);
    const url = `${API.dimensions}${dMap[dimension]}/import`;
    const data = new FormData();
    const fieldsMapArray = Object.entries(fieldsMap)
      .filter(([key, val]) => !(key === undefined || val === undefined))
      .map(([key, val]) => ({ [key]: val }));
    data.append('importDimensionFile', importDimensionFile as Blob);
    data.append('fieldsMap', JSON.stringify(fieldsMapArray));
    if (uploadType === 1 ) {
      data.append('updateField', updateField);
    }
    try {

      const response = await Axios.post(
        url,
        data,
        axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
      );
      if (response.data.success) {
        if (response.data.data.processId) {
          setImporting(true);
          setImportStatusCheckCode(response.data.data.processId);
          setTimeout(() => {
            onStartInterval(response.data.data.processId);
          }, 500);
        } else {
          fetchDimensions();
          setLoading(false);
          enqueueSnackbar(t(`${tMap[dimension]}_IMPORT_SUCCESS`), {
            variant: 'success',
            persist: false
          });
          setShowImport(false);
        }
      } else {
        enqueueSnackbar(response.data.message, {
          variant: 'error',
          persist: false
        });
        setLoading(false);
      }
    } catch (error) {
      const e = error as CustomError 
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(e?.response?.data?.i18n || `${tMap[dimension]}_IMPORT_FAILED`, t),
        t
      ), {
        variant: 'error',
        persist: true
      });
      setLoading(false);
    }
  };

  const uploadGstockImportFile = async () => {
    setLoading(true);
    if (importDimensionFile && type2) {
      const data = new FormData();
      data.append('importFile', importDimensionFile);
      const url = type2 === 'gstock-clients' ? API.gstockSuppliersImport : API.gstockProductsImport
      try {
        const response = await Axios.post(
          url,
          data,
          axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
        );
        if (response.data.success) {
          enqueueSnackbar(t('GSTOCK_IMPORT_FILE_UPLOAD_SUCCESS'), {
            variant: 'success',
            persist: false
          });
          setLoading(false);
          fetchDimensions();
          setShowImport(false);
        } else {
          enqueueSnackbar(response.data.message, {
            variant: 'error',
            persist: false
          });
          setLoading(false);
        }
      } catch (e) {
        const error = e as CustomError;
        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || 'GSTOCK_IMPORT_FILE_UPLOAD_FAIL', t),
          t
        ), {
          variant: 'error',
          persist: true
        });
        setLoading(false);
      }
    }
  };

  const renderSelectImportType = ()=>{

    return (<Box sx={styles.importStep}>
      <FormControl>
        <RadioGroup sx={styles.importOption}
         value={uploadType}
         onChange={handleImportOptionChange}
        >
          {
            dimensionImportOptions.map((e:EachImportOptionType) =>
                <FormControlLabel key={e?.id} sx={styles.eachLabel}  
                  control={<Radio />} value={e?.id} label={
                  <Box sx={styles.importText}>
                    <Typography sx={{fontSize:'14px',fontWeight:'500',color:'#4C4E64DE'}}>{t(e?.title)}</Typography>
                    <Typography sx={{fontSize:'12px',lineHeight:'20px', color:'#4C4E64DE'}}>{t(e?.description)}</Typography>
                  </Box>
              }  />
            )
          }
        </RadioGroup>
      </FormControl>
      <Box sx={styles.btnDiv}>
        <Button onClick={()=>{setShowImport(false)}} variant="outlined" sx={styles.btnCancel}>{t('DIMENSION_IMPORT_CANCEL')}</Button>
        <Button disabled={uploadType === -1} endIcon={<ContinueIcon />} 
        sx={styles.btnContinue} onClick={handleContinueUploadImport}>{t('DIMENSION_IMPORT_CONTINUE')}</Button>
      </Box>
    </Box>)
  }

  const renderUploadOption = () => {
    return(
      <Box sx={styles.uploadWrapper}>
        <FormControl sx={styles.fileBtn} >
          <Button
            component="label"
            role={undefined}
            tabIndex={-1}
            startIcon={<CloudUpload />}
            variant="text"
            sx={{textTransform:'none',color:'#3E8AFF',fontSize:'13px',height:'100%',width:'100%'}}
    
          >
            {importDimensionFile?importFileName:t('DIMENSION_IMPORT_CHOOSE_FILE')}
            <VisuallyHiddenInput
              type="file"
              onChange={handleploadDimensionsFile}
            />
          </Button> 
        </FormControl>
        <Box>
          <List>
            {
              uploadInstructions.map((eInstruction:Record<string, number | string>) =>
                <ListItem sx={styles.eachInstruction} key={eInstruction?.id}>{t(eInstruction?.label as string)}</ListItem>
              )
            }
          </List>
        </Box>
        
        <Box sx={styles.btnDiv}>
          <Button variant="outlined" onClick={()=> setStep(1)} sx={styles.btnCancel}>{t('DIMENSION_IMPORT_CANCEL')}</Button>
          <Button disabled={uploadType === null} endIcon={<ContinueIcon />} 
          sx={styles.btnContinue} onClick={handleContinueUploadFile}>{t('DIMENSION_IMPORT_CONTINUE')}</Button>
        </Box>
      </Box>
    );
  }

  const handleCancelMapField = () =>{
    setStep(2);
    setImporting(false);
    setLoading(false);    
  }

  const renderMapFieldsSectionOne = (
    <>
      <Grid sx={styles.mapFieldsSection}>
        <Grid sx={{...styles.fieldContainer,backgroundColor:'#F5F5F7',width:'calc(100% - 5px)'}}>
          <Grid sx={{...styles.fieldHeaderContainer}}>
            <Typography sx={styles.fieldHeader}>#</Typography></Grid>
          <Grid sx={styles.fieldHeaderContainer}>
            <Typography sx={styles.fieldHeader}>{t('DIMENSION_IN_APP_FIELDNAME')}</Typography>
          </Grid>
          <Grid sx={styles.fieldHeaderContainer}>
            <Typography sx={styles.fieldHeader}>{t('DIMENSION_IN_DOCUMENT_FILENAME')}</Typography>
          </Grid>
        </Grid>
        <Box sx={styles.scrollFieldContainer} className="y-scroll">
        {fields.map((field: Record<string, string>, index: number) => (
          <Grid sx={styles.fieldContainer} key={field.key}>
            <Grid sx={styles.eachFieldColumn}><Typography sx={styles.fieldContent}>{index + 1}</Typography></Grid>
            <Grid sx={styles.eachFieldColumn}>
              <Typography
                sx={styles.fieldContent}
              >
                {t(field.label)}
              </Typography>  
            </Grid>
            <Grid sx={styles.eachFieldColumn}>
              <Box sx={styles.selectFieldColumn}>
                <Select
                  name="interfacePreference"
                  onChange={(e) => handleChangeFieldMap(e, field.key)}
                  style={styles.fieldsSelect}
                  value={Number.isNaN(parseInt(fieldsMap[field.key] as string,10)) ? 0 : parseInt(fieldsMap[field.key] as string,10) + 1}
                  variant="outlined"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left'
                    },
                  }}
                >
                  {
                    ['-', ...dimensionDataColumns].map((column, index) => (
                      <MenuItem style={styles.fieldsSelectText} key={column} value={index}>
                        {column}
                      </MenuItem>
                    ))
                  }
                </Select> 
              </Box>
              
            </Grid>
            
            
          </Grid>
        ))}
        </Box>
        
      </Grid>
      <Grid style={styles.modalFooter}>
        <Button
          variant="outlined"
          sx={styles.backBtn}
          onClick={handleCancelMapField}
        >
          {t('DIMENSION_IMPORT_BACK')}
        </Button>
        {importDimensionFile && (
          <Button
            variant="contained"
            sx={styles.uploadBtn}
            endIcon={<ContinueIcon />}
            onClick={handleConfirmFieldsMap}
          >
            {t('DIMENSION_IMPORT_CONFIRM')}
          </Button>
        )}
      </Grid>
    </>
  );

  const renderMapFieldsSectionTwo = (
    <>
      <Grid style={styles.mapFieldsSection}>
        <Typography style={styles.optionTitle}>{t(`${tMap[dimension]}_IMPORT_MAP_FIELDS_02_TITLE`)}</Typography>
        <Typography style={styles.optionSubTitle}>{t(`${tMap[dimension]}_IMPORT_MAP_FIELDS_02_DESCRIPTION`)}</Typography>
        <Grid sx={styles.fieldsListContainer} className="y-scroll">
          {aFields[fieldUrlDimension[dimension] as keyof FieldRecord]
          .filter((field: EachField) => field.isUpdateField)
          .map((field: EachField) => (
            <FormControlLabel
              key={field.key}
              value="top"
              control={(
                <Checkbox
                  style={styles.checkBox}
                  onChange={() => handleCheckBoxClick(field?.key as string)}
                  checked={field.key === updateField}
                />
            )}
              label={t(field?.label as string)}
              labelPlacement="end"
            />
          ))}
        </Grid>
      </Grid>
      <Grid style={styles.modalFooter}>
        <Button
          variant="outlined"
          sx={styles.backBtn}
          onClick={() => setStep(3)}
        >
          {t('DIMENSION_IMPORT_BACK')}
        </Button>
        {importDimensionFile && (
          <Button
            variant="contained"
            sx={styles.uploadBtn}
            onClick={handleConfirmFieldsMap}
          >
            {t('DIMENSION_IMPORT_DONE')}
          </Button>
        )}
      </Grid>
    </>
  );

  const renderGstockImportSection = () => {
    return(
      <Box sx={styles.uploadWrapper}>
        <FormControl sx={styles.fileBtn} >
          <Button
            component="label"
            role={undefined}
            tabIndex={-1}
            startIcon={<CloudUpload />}
            variant="text"
            sx={{textTransform:'none',color:'#3E8AFF',fontSize:'13px',height:'100%',width:'100%'}}
    
          >
            {importDimensionFile?importFileName:t('DIMENSION_IMPORT_CHOOSE_FILE')}
            <VisuallyHiddenInput
              type="file"
              onChange={handleploadDimensionsFile}
            />
          </Button> 
        </FormControl>
        <Box>
          <List>
            {
              uploadInstructions.map((eInstruction:Record<string, number | string>) =>
                <ListItem sx={styles.eachInstruction} key={eInstruction?.id}>{t(eInstruction?.label as string)}</ListItem>
              )
            }
          </List>
        </Box>
        
        <Box sx={styles.btnDiv}>
          <Button variant="outlined" onClick={()=> setShowImport(false)} sx={styles.btnCancel}>{t('DIMENSION_IMPORT_CANCEL')}</Button>
          <Button disabled={uploadType === null} endIcon={<ContinueIcon />} 
          sx={styles.btnContinue} onClick={() => uploadGstockImportFile()}>{t('DIMENSION_IMPORT_CONFIRM')}</Button>
        </Box>
      </Box>
    );
  }

  const loadingIndicator = (
    <Grid sx={styles.loaderContainer}>
      <CircularProgress sx={{backgroundColor:'transparent', color:'#3E8AFF'}} />
      {importing ? (
        <>
          <Typography style={styles.progressText}>{`${importProgress || 0} %`}</Typography>
          <Typography style={styles.loaderText}>{t(importStatus)}</Typography>
        </>
      ) : (
        <Typography style={styles.loaderText}>{t('DIMENSION_IMPORT_FILE_UPLOAD_LOADING')}</Typography>
      )}
    </Grid>
  );

  useEffect(() => {
    populateFields();
  }, [dimension, user?.companyID]);

  useEffect(() =>{
    return ()=>{
      setStep(1);
      setImporting(false);
      setLoading(false);
      setDimensionDataColumns([]);
      setImportDimensionFile(null);
      setImportFileName('');
      setDimensionDataColumns([]);
      setFieldsMap({});
      setFields([]);  
    }
  },[])

  return (
    <>
      <Grid sx={styles.root} className='x-scroll'>
        <Box sx={styles.wrapper}>
          <Box>
            <Typography sx={styles.importTitle}>
              {
                type2 ? t(titleSelection[type][type2]) : t(titleSelection[type])
              }
            </Typography>
            <>
            {(type2 && type2.includes('gstock')) ? <></> : (
              <Box sx={styles.importSteps}>
              {dimensionImportSteps?.map((eStep: {label:string,id:number}) =>
              <Box key={eStep.id} sx={{display:'flex',alignItems:'center',flex:'1',gap:'10px'}}>
                <StepLabel key={eStep.id} step={eStep.id+1} text={t(eStep.label)} activeStep={step} />
                {
                  eStep.id !== 2 ?
                  <StepLine key={eStep.id + 'a'} lineStep={eStep.id+1} activeStep={step} />
                  :<></>
                }
              </Box>
              )}
            </Box> 
            )}
            </>
          </Box>
          <Box>
            {
              loading && importing ? loadingIndicator :
              (type2 && type2.includes('gstock')) ? renderGstockImportSection() :
              step === 1 ? renderSelectImportType() :
              step === 2 ? renderUploadOption() :
              step === 3 ? renderMapFieldsSectionOne :
              renderMapFieldsSectionTwo
            }
          </Box>
        </Box>
      </Grid>
      <Backdrop style={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

const styles={
  root:{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-start',
    padding:'20px',
    
  },
  wrapper:{
    backgroundColor:'#fff',
    minWidth:'660px',
  },
  top:{
    display:'flex',
    alignItems:'center',
    width:'100%',
    padding:'5px 0',
    gap:'10px',
    marginBlock:'0 20px'
  },
  topLeft:{
    fontSize:'16px',
    color:'#4C4E64',
    fontWeight:'500',
    marginInlineEnd:'auto'
  },
  btns:{
    gap:'8px',
    color:'#3E8AFF',
    textTransform:'Capitalize',
    fontSize:'13px',
    paddingInline:'10px',
  },
  addBtn:{
    background:'#3E8AFF',
    color:'#fff',
    paddingInline:'15px',
    gap:'8px',
    textTransform:'Capitalize',
    fontSize:'13px',
    '&:hover':{
      backgroundColor:'#3E8AFF',
      color:'#fff'
    }
  },
  menuContainer:{
    boxShadow:' 0px 4px 8px -4px #4C4E646B',
    width:'200px',
  },
  exportIconContainer:{
    display:'flex',
    alignItems:'center',
    gap:'10px',
    fontSize:'14px',
    color:'#4C4E64DE'
  },
  backdrop: {
    zIndex: 111111,
    color: '#ffffff',
  },
  importTitle:{
    fontSize:'16px',
    fontWeight:'500',
    color:'#4C4E64'
  },
  importSteps:{
    marginBlockStart:'30px',
    display:'flex',
    alignItems:'center',
    gap:'10px'
  },
  importStep:{
    marginTop:'30px'
  },
  importOption:{
    display:'grid',
    gridTemplateColumns:'1fr 1fr',
    gap:'24px'
  },
  eachLabel:{
    border:'1px solid #C2CFE099',
    borderRadius:'8px',
    flex:'1',
    height:'100%',
    display:'flex',
    alignItems:'center',
    padding:'20px',
    margin:0,
  },
  importText:{
    display:'flex',
    flexDirection:'column',
    gap:'2px'
  },
  btnDiv:{
    width:'100%',
    marginTop:'25px',
    borderTop:'1px solid #C2CFE099',
    gap:'10px',
    display:'flex',
    justifyContent:'flex-end',
    paddingTop:'15px'
  },
  btnCancel:{
    textTransform:'none',
    fontSize:'14px',
    backgroundColor:'white',
    border:'1px solid #C2CFE099',
    color:'#4C4E64',
    paddingInline:'20px'
  },
  btnContinue:{
    textTransform:'none',
    backgroundColor:'#3E8AFF',
    color:'#fff',
    fontSize:'14px',
    paddingInline:'20px',
    "&:disabled":{
      color:'#fff',
      backgroundColor:'#3E8AFF',
      opacity:0.7,
      cursor:'pointer',
    },
    "&:hover":{
      color:'#fff',
      backgroundColor:'#3E8AFF',
      opacity:1,
      cursor:'pointer',
    }
  },
  uploadWrapper:{
    display:'flex',
    flexDirection:'column',
    marginTop:'30px',
  },
  uploadLabel:{
    width:'100%',
    display:'flex',
    padding:'30px 0',
    borderRadius:'8px',
  },
  fileBtn:{
    border:'1px solid #C2CFE0',
    height:'82px',
    borderRadius:'8px',
    display:'flex',
    justfyContent:'center',
    alignItems:'center'
  },
  eachInstruction:{
    display:'flex',
    alignItems:'center',
    gap:'10px',
    fontSize:'14px',
    color:'#4C4E64DE',
    paddingInline:'10px',
    '&::before':{
      display:'inline-block',
      flexShrink:'0',
      content:'""',
      width:'5px',
      height:'5px',
      backgroundColor:'#4C4E64DE',
      borderRadius:'50%',
    }

  },
  mapFieldsSection: {
    marginTop:'30px',
    width: '100%',
  },
  scrollFieldContainer:{
    maxHeight:330,
  },
  fieldContainer: {
    display: 'grid',
    gridTemplateColumns:'70px .8fr 1fr',
    justifyContent: 'space-between',
    width: '100%'
  },
  fieldHeaderContainer: {
    display:'flex',
    alignItems:'center',
    fontSize:12,
    color:'#4C4E64DE',
    padding:'16px 15px 16px 20px',
  },
  fieldHeader: {
    fontSize: 12,
    color: '#334D6E',
    fontWeight: 500,
    display:'flex',
    alignItems:'center',
    flex:'1',
    "&::after":{
      display:'inline-block',
      content:'""',
      width:'2px',
      height:'14px',
      backgroundColor:'#4C4E641F',
      marginLeft:'auto'
    }
  },
  procysField: {
    display:'flex',
    alignItems:'center',
    fontSize: 13,
    color: '#334D6E',
    fontWeight: 400,
    minHeight: 32,
    width: 224,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    paddingTop: 0,
    paddingBottom: 0,
    cursor: 'default',
    justifyContent: 'flex-start'
  },
  fieldsSelect: {
    minHeight: 32,
    width: 'calc(100% - 20px)',
    border: '1px solid #C2CFE099 ',
    color: '#334D6E',
    fontSize: 13,
    fontWeight: 400,
  },
  fieldsSelectText: {
    color: '#334D6E',
    fontSize: 13,
    fontWeight: 400,
  },
  selectFieldColumn:{
    width:'100%',
    display:'flex',
    alignItems:'center',
    '&::after':{
      display:'inline-block',
      content:'""',
      width:'2px',
      height:'14px',
      backgroundColor:'#4C4E641F',
      marginLeft:'auto'
    }
  },
  fieldsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 300,
    maxHeight: 400,
    padding: '16px 32px'
  },
  checkBoxLabel: {
    fontSize: 14,
    color: '#334D6E'
  },
  checkBox: {
    color: '#3E8AFF',
    paddingBottom: 0,
    paddingTop: 0
  },
  checkboxContainer: {
    marginRight: 8,
    marginBottom: 8
  },
  modalFooter: {
    backgroundColor:'#fff',
    marginTop:'10px',
    paddingTop:'10px',
    borderTop:'1px solid #C2CFE099',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap:'20px'
  },
  backBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#334D6E',
    paddingTop: 0,
    paddingBottom: 0,
    border: '1px #e4e4e4 solid',
    margin: '6px 0px 6px 0px',
    boxShadow: '0px 4px 8px -4px #4C4E646B'

  },
  uploadBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    backgroundColor:'#3E8AFF',
    color:'#fff',
    margin: '6px 0px 6px 0px',
    '&:hover':{
      backgroundColor:'#3E8AFF',
      color:'#fff'
    }
  },
  optionTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: '#334D6E',
  },
  optionSubTitle: {
    fontSize: 13,
    color: '#334D6E',
  },
  loaderContainer: {
    padding: '22px 32px 12px 32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  progressText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#334D6E',
    margin: '20px 0px 0px 6px'
  },
  loaderText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#334D6E',
    margin: '6px 0px 0px 0px'
  },
  eachFieldColumn:{
    display:'flex',
    alignItems:'center',
    padding: '10px 15px 10px 20px'
  },
  fieldContent:{
    display:'flex',
    alignItems:'center',
    width:'100%',
    fontSize:'14px',
    color:'#4C4E64',
    '&::after':{
      display:'inline-block',
      content:'""',
      width:'2px',
      height:'14px',
      backgroundColor:'#4C4E641F',
      marginLeft:'auto'
    }
  }
}

export default ImportView