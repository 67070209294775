const profileBanner = require('./images/profile-bg.png');
const visa = require('./images/visa.svg');
const master = require('./images/master.svg');
const amex = require('./images/amex.svg');
const bc = require('./images/bc.png');
const api = require('./images/api.png');
const email = require('./images/email.png');
const gdrive = require('./images/gdrive.png');
const holded = require('./images/holded.webp');
const odoo = require('./images/odoo.png');
const sap = require('./images/sap.png');
const tf = require('./images/tf.png');
const onedrive = require('./images/onedrive.png');
const dropbox = require('./images/dropbox.png');
const gstock = require('./images/gstock.png');
const qb = require('./images/qb.png');
const airtable = require('./images/airtable.png');
const sftp = require('./images/sftp.png');
const hacienda = require('./images/hacienda.png');
const splitScreen = require('./images/split-screen.png');

// Can not directly assign require() function to the object property,
// as it violates eslint error: global-require
const images = {
  profileBanner,
  visa,
  master,
  amex,
  bc,
  api,
  email,
  gdrive,
  holded,
  odoo,
  sap,
  tf,
  onedrive,
  dropbox,
  gstock,
  qb,
  airtable,
  sftp,
  hacienda,
  splitScreen
};

export default images;
