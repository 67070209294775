import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button, Tooltip, Typography } from '@mui/material';

import { getMaskedCurrencyText, isActionPermitted, permissions } from 'src/utils/helpers';

const BuyExtraDocs = (props) => {
  const { ready, t } = useTranslation();
  const navigate = useNavigate();

  const { subscription, user } = props;

  return (
    (subscription?.name?.toLowerCase().indexOf('free') < 0 && subscription?.ecLimit > 0
    && !user.customisations.includes('hideManagePlanSection'))
    ? (
      <>
        <Typography style={styles.extraDescription}>
          <Trans i18nKey="MY_PLAN_EXTRA_PACKAGE">
            You can buy additional packages of
            <strong>{{ limit: subscription.ecLimit }}</strong>
            documents for
            <strong>
              &euro;
              {{ price: getMaskedCurrencyText(subscription.ecPrice) }}
            </strong>
            .
          </Trans>
        </Typography>
        <Tooltip title={isActionPermitted(permissions.subscriptionsBuyExtra, user.permissions) ? ''
          : t('SUBSCRIPTION_BUY_EXTRA_PERMISSION')}
        >
          <span>
            <Button
              style={styles.buyExtraBtn}
              onClick={() => navigate('/subscription/checkout?type=1')}
              disabled={!isActionPermitted(permissions.subscriptionsBuyExtra, user.permissions)}
            >
              {ready && t('MY_PLAN_EXTRA_PACKAGE_BTN', { limit: subscription.ecLimit })}
            </Button>
          </span>
        </Tooltip>
      </>
    ) : <></>
  );
}

export default BuyExtraDocs;

const styles = {
  extraDescription: {
    marginTop: 16,
    fontSize: 14,
    color: '#4C4E64',
    paddingBottom: 8,
    width: 600
  },
  buyExtraBtn: {
    color: '#4C4E64',
    border: '1px #e4e4e4 solid',
    textTransform: 'none'
  },
};
