import { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  Button, Grid, Typography
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIosOutlined';


import DashboardLayout from 'src/shared/layouts/DashboardLayout';
import Page from 'src/shared/screens/Page';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';

import authService from 'src/utils/authService';
import { axiosHeaders } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';

const DocumentLogView = (props) => {
  const { enqueueSnackbar } = props;
  const params = useParams();
  const navigate = useNavigate();
  const { LOGIN_PANEL_URL, API, BRAND_NAME } = useContext(ConfigContext);
  const { search } = useLocation();
  const { t, ready } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [fetchingLog, setFetchingLog] = useState(true);
  const [paramsID, setParamsID] = useState(null);

  const [log, setLog] = useState(null);

  let req; // Request;
  let resp; // Response;

  const getInvoiceLogByID = async (id, invoiceID) => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setFetchingLog(true);

    try {
      const url = `${API.getInvoiceLogByID}/${id}?assistantId=${invoiceID ? `${invoiceID}` : ''}`;

      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response) {
        if (response.data.success) {
          if (response.data.data) {
            setLog(response.data.data);
          } else {
            navigate(`/documents${invoiceID ? `/${invoiceID}` : ''}/logs`);
          }
        } else {
          navigate(`/documents${invoiceID ? `/${invoiceID}` : ''}/logs`);
        }
      } else {
        navigate(`/documents${invoiceID ? `/${invoiceID}` : ''}/logs`);
      }
    } catch (error) {
      navigate(`/documents${invoiceID ? `/${invoiceID}` : ''}/logs`);
    }

    setFetchingLog(false);
    setLoading(false);
  };

  useEffect(() => {
    const id = params?.id ? params.id : 0;

    const searchText = search.substr(1);
    let invoiceID = '';

    if (searchText.length > 0 && searchText.indexOf('=') > 0) {
      const searchParts = searchText.split('=');
      if (searchParts.length >= 2 && searchParts[0] === 'invoiceID') {
        invoiceID = searchParts[1]; // eslint-disable-line
      }
    }

    if (id && id > 0) {
      setParamsID(invoiceID);
      getInvoiceLogByID(id, invoiceID);
    } else {
      navigate(`/documents${invoiceID ? `/${invoiceID}` : ''}/logs`);
    }
  }, []);

  const gotoLogsList = () => {
    navigate(`/documents${paramsID ? `/${paramsID}` : ''}/logs`);
  };

  if (loading || fetchingLog) {
    return <LoadingIndicator />;
  }

  try {
    req = JSON.stringify(JSON.parse(log.request), null, 2);
  } catch (err) {
    req = log.request;
  }

  try {
    resp = JSON.stringify(JSON.parse(log.response), null, 2);
  } catch (err) {
    resp = log.response;
  }

  return (
    <DashboardLayout>
      <Page
        style={styles.root}
        title={ready && `${t('INVOICE_LOGS_PAGE_TITLE')} | ${BRAND_NAME}`}
      >
        {
          log && ready && (
            <>
              <Grid style={styles.titleContainer}>
                <Grid>
                  <Button style={styles.backBtn} onClick={gotoLogsList}>
                    <ArrowBackIcon style={styles.icon} />
                    {t('LOGS_VIEW_BACK_TO_OVERVIEW')}
                  </Button>
                </Grid>
                <Typography style={styles.assistantID}>{`${t('LOGS_VIEW_PROCYS_NUMBER')}: ${log.assistantID}`}</Typography>
                <Grid style={styles.titleBox}>
                  <Typography style={styles.title}>{`${t('LOGS_VIEW_INVOICE_NUMBER')}: ${log.invoiceID}`}</Typography>
                </Grid>
                <Grid container direction="row" alignItems="center">
                  <Grid style={styles.arrowBtn}>
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      component="label"
                      disabled={log.nextId === undefined || log.nextId === null}
                      style={styles.action}
                      onClick={() => getInvoiceLogByID(log.nextId, paramsID)}
                    >
                      <ArrowBackIosNewIcon fontSize="small" />
                    </Button>
                  </Grid>
                  <Grid style={styles.arrowBtn}>
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      component="label"
                      disabled={log.prevId === undefined || log.prevId === null}
                      style={styles.action}
                      onClick={() => getInvoiceLogByID(log.prevId, paramsID)}
                    >
                      <ArrowForwardIosIcon fontSize="small" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={styles.topContainer}>
                <Grid style={styles.logViewContainer}>
                  <Grid style={styles.labelsContainer}>
                    <Grid style={styles.itemContainer}>
                      <Typography style={styles.label}>#</Typography>
                      <Typography style={styles.value}>{log.id || '-'}</Typography>
                    </Grid>
                    <Grid style={styles.itemContainer}>
                      <Typography style={styles.label}>{t('LOG_VIEW_INVOICE_NUMBER')}</Typography>
                      <Typography style={styles.value}>{log.invoiceID || '-'}</Typography>
                    </Grid>
                    <Grid style={styles.itemContainer}>
                      <Typography style={styles.label}>{t('LOGS_VIEW_PROCYS_NUMBER')}</Typography>
                      <Typography style={styles.value}>{log.assistantID || '-'}</Typography>
                    </Grid>
                    <Grid style={styles.itemContainer}>
                      <Typography style={styles.label}>{t('LOG_VIEW_INVOICE_COMPANY')}</Typography>
                      <Typography style={styles.value}>{log.company || '-'}</Typography>
                    </Grid>
                    <Grid style={styles.itemContainer}>
                      <Typography style={styles.label}>{t('LOG_VIEW_INVOICE_MEDIUM')}</Typography>
                      <Typography style={styles.value}>{log.medium || '-'}</Typography>
                    </Grid>
                    <Grid style={styles.itemContainer}>
                      <Typography style={styles.label}>{t('LOG_VIEW_INVOICE_ACTION')}</Typography>
                      <Typography style={styles.value}>{log.action || '-'}</Typography>
                    </Grid>
                    <Grid style={styles.itemContainer}>
                      <Typography style={styles.label}>{t('LOG_VIEW_INVOICE_MODIFIED_BY')}</Typography>
                      <Typography style={styles.value}>
                        {
                          log.firstname && log.lastname
                            ? `${log.firstname} ${log.lastname}`
                            : '-'
                        }
                      </Typography>
                    </Grid>
                    <Grid style={styles.itemContainer}>
                      <Typography style={styles.label}>{t('LOG_VIEW_INVOICE_MODIFIED_AT')}</Typography>
                      <Typography style={styles.value}>
                        {
                          log.timestamp
                            ? moment.unix(log.timestamp / 1000000000).format('DD/MM/YYYY hh:mm:ss')
                            : '-'
                        }
                      </Typography>
                    </Grid>
                    <Grid style={styles.itemContainer}>
                      <Typography style={styles.label}>{t('LOG_VIEW_INVOICE_URL')}</Typography>
                      <Typography style={styles.value}>{log.url || '-'}</Typography>
                    </Grid>
                    <Grid style={styles.itemContainer}>
                      <Typography style={styles.label}>{t('LOG_VIEW_INVOICE_REQUEST')}</Typography>
                      <Typography style={styles.value}>{<pre style={styles.xmlView}>{req}</pre> || '-'}</Typography>
                    </Grid>
                    <Grid style={styles.itemContainer}>
                      <Typography style={styles.label}>{t('LOG_VIEW_INVOICE_RESPONSE')}</Typography>
                      <Typography style={styles.value}>{<pre style={styles.xmlView}>{resp}</pre> || '-'}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )
        }
      </Page>
    </DashboardLayout>
  );
};

export default DocumentLogView;

const styles = {
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
  },
  topContainer: {
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40
  },
  logViewContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  labelsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '6px 0px 6px 8px',
    border: 'solid rgba(0,0,0,0.075) 0px',
    borderBottomWidth: 1
  },
  label: {
    fontSize: 14,
    color: '#334D6E',
    minWidth: 140
  },
  value: {
    fontSize: 14,
    color: '#334D6E'
  },
  titleContainer: {
    backgroundColor: '#ffffff',
    padding: '24px 40px',
    boxShadow: '0px 1px 0px rgba(51, 77, 110, 0.1)',
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08))'
  },
  backBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21px',
    color: '#3E8AFF',
    cursor: 'pointer',
    paddingLeft: 0,
    marginBottom: 16,
    textTransform: 'none'
  },
  icon: {
    marginRight: 12
  },
  assistantID: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '16px',
    color: 'rgba(51, 77, 110, 0.5)',
  },
  subtitle: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '16px',
    color: 'rgba(51, 77, 110, 0.5)',
    paddingBottom: 6
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 18
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '36px',
    color: '#334D6E',
  },
  userChip: {
    height: 19,
    marginLeft: 6
  },
  chipAvatar: {
    height: '19px!important',
    width: '19px!important',
    marginLeft: '0px!important'
  },
  chipLabel: {
    fontSize: 11,
    fontWeight: 500,
    color: '#334D6E',
    lineHeight: '16px',
    paddingBottom: 1
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  xmlView: {
    display: 'block',
    width: '100%',
    height: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
    margin: '0 auto',
    lineHeight: '1.5',
    backgroundColor: 'white',
  },
  arrowBtn: {
    marginRight: 5
  },
  action: {
    textTransform: 'none',
    color: 'white',
    backgroundColor: '#3E8AFF',
    '&:hover': {
      backgroundColor: '#3E8AFF'
    }
  },
};
