const styles = {
  container:{
    width:'100%',
    padding:'20px',
    height:'100%',
    display:'flex',
    flexDirection:'column',
  },
  tableWrapper:{
    width:'100%',
    backgroundColor:'#fff',
    overflow:'clip',
    borderRadius:'8px 8px 0 0'  

  },
  searchBar: {
    flex: 1,
    margin:'20px 0',
  },
  queryField: {
    width: '100%',
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: 0,
    fontSize:'13px',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099!important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `2px solid #C2CFE099!important`,
      }
    },
  },
  searchBarInput: {
    height: '36px',
    color:'#4C4E64',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099!important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `2px solid #C2CFE099!important`,
      }
    },
  }

}


export default styles