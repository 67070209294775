import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import Axios from 'axios';

import {
  Box, Button, ClickAwayListener, Dialog, FormHelperText, IconButton, Grow, MenuList, MenuItem,
  Paper, Popper, TextField, Typography
} from '@mui/material';
import {
  ArrowDropDown,
  Close as CloseIcon,
} from '@mui/icons-material';

import useWidth from 'src/hooks/useWidth';
import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import { axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import { CustomError } from 'src/types';
import styles from './style';

interface PropTypes {
  open: boolean;
  handleClose: () => void;
  addUserCallback: () => Promise<void>;
  setUserInviteLinkOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUserInviteLink: React.Dispatch<React.SetStateAction<string>>;
}

type UserRoleType = {
  role: string;
  status: string;
  description: string;
}

const AddUserModal = (props: PropTypes) => {
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user, setUser } = useAuth();
  const { API, IS_BRAND_PROCYS } = useConfig();
  const emailRegexp = /^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/;

  const { open, handleClose: _handleClose, addUserCallback, setUserInviteLinkOpen, setUserInviteLink } = props;

  const [isUserExist, setIsUserExist] = useState(false);
  const [submitError, setSubmitError] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState('');
  const [userRoles, setUserRoles] = useState<UserRoleType[]>([]);
  const [roleOptionsOpen, setRoleOptionsOpen] = useState(false);

  const userRolesRef = useRef(null);
  const userRolesWidth = useWidth(userRolesRef, 0);

  const handleClose = () => {
    _handleClose();
    setSubmitError('');
    setSelectedRole('');
    setUserInviteLinkOpen(false);
  };

  const fetchActiveUserRoles = async () => {
    try {
      const response = await Axios.get(`${API.getUserRoles}?status=active`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.data) {
        setUserRoles(response.data.data);
        setSelectedRole('user');
      }
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    fetchActiveUserRoles();
  }, []);

  const handleUserRoleOptionsToggle = () => {
    setRoleOptionsOpen((prevOpen) => !prevOpen);
  };

  const handleUserRoleOptionsClose = () => {
    setRoleOptionsOpen(false);
  };

  const handleUserRoleClick = (option: string) => {
    setSelectedRole(option);
    setRoleOptionsOpen(false);
  };

  const handleEmailBlur = async (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    setErrors: Function,
    handleChange: Function,
    setTouched: Function,
    errors: FormikErrors<{email: string; firstname: string; lastname: string;}>,
    touched: FormikTouched<{email: string; firstname: string; lastname: string;}>
  ) => {
    setTouched({ ...touched, email: true });
    const { value } = event.target;
    if (value) {
      if (!emailRegexp.test(value)) {
        setErrors({
          ...errors,
          email: t('INVALID_EMAIL_ADDRESS_HELPER_TEXT')
        });
        return;
      }
      try {
        const response = await Axios.post(
          `${API.companyAndUserDetails}`,
          { email: value, isOnlyUser: true },
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        if (response.data.success && response.data.data) {
          setIsUserExist(response.data.data.detailsRegistered);
          handleChange({ target: { name: 'firstname', value: response.data.data.firstName } });
          handleChange({ target: { name: 'lastname', value: response.data.data.lastName } });

          if (response.data.data.detailsRegistered) {
            setTimeout(() => {
              setErrors({
                email: t('EMAIL_ADDRESS_ALREADY_EXISTS_HELPER_TEXT'),
              });
            }, 200);
          }
        } else {
          handleChange({ target: { name: 'firstname', value: '' } });
          handleChange({ target: { name: 'lastname', value: '' } });
        }
      } catch (error) {
        setIsUserExist(false);
        handleChange({ target: { name: 'firstname', value: '' } });
        handleChange({ target: { name: 'lastname', value: '' } });
      }
    } else {
      setIsUserExist(false);
      setErrors({
        ...errors,
        email: t('EMAIL_ADDRESS_REQUIRED_HELPER_TEXT')
      });
      handleChange({ target: { name: 'firstname', value: '' } });
      handleChange({ target: { name: 'lastname', value: '' } });
    }
  };
  
  return (
    <Dialog
      open={open}
      sx={styles.modal}
      onClose={handleClose}
    >
      <Box sx={{ width: '600px'}}>
        <Formik
          initialValues={{
            email: '',
            firstname: '',
            lastname: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email(t('INVALID_EMAIL_ADDRESS_HELPER_TEXT')).max(255).required(t('EMAIL_ADDRESS_REQUIRED_HELPER_TEXT')),
            firstname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_FIRSTNAME_REQ')),
            lastname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_LASTNAME_REQ')),
          })}
          onSubmit={async (values, {
            resetForm,
            setStatus,
            setSubmitting
          }) => {
            try {
              if ((user?.maxUsers || 2) <= (user?.usersCount || 1)) {
                setStatus({ success: false });
                setSubmitError(t('ACCOUNT_SETTINGS_USERS_ADD_LIMIT'));
                setSubmitting(false);
              }

              const body = {
                email: values.email,
                firstName: values.firstname,
                lastName: values.lastname,
                company: user && user.companyID ? user.companyID : '',
                role: selectedRole
              };
              const response = await Axios.post(
                `${API.userProfile}`,
                body,
                axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
              );
              if (response.data.success) {
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);

                if (!IS_BRAND_PROCYS) {
                  setUserInviteLink(response.data.data.link);
                  setUserInviteLinkOpen(true);
                } else {
                  enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_ADD_SUCCESS', { email: values.email }), {
                    variant: 'success',
                    style: { maxWidth: 400 }
                  });
                  handleClose();
                  if (user && setUser) {
                    const updatedUser = {
                      ...user,
                      usersCount: user.usersCount + 1,
                    };
                    setUser(updatedUser);
                  }
                  addUserCallback();
                }
              }
            } catch (e) {
              const error = e as CustomError;
              setStatus({ success: false });
              setSubmitError(getLocalisedErrorString(error.response?.data?.i18n, t));
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit,
            setErrors,
            setTouched,
            touched,
            values
          }) => (
            <form
              style={styles.root}
              onSubmit={handleSubmit}
            >
              <Box sx={styles.modalHeader}>
                <Box sx={styles.titleContainer}>
                  <Typography sx={styles.headerTitle}>
                    {t('ACCOUNT_SETTINGS_USERS_ADD_NEW_USER')}
                  </Typography>
                  <IconButton sx={styles.closeBtn} onClick={handleClose}>
                    <CloseIcon sx={styles.closeIcon} />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={styles.modalContent}>
                <Box sx={styles.userForm}>
                  <Box sx={styles.fieldContainer}>
                    <Typography variant="caption" sx={styles.inputLabel}>
                      {`${ready && t('ACCOUNT_SETTINGS_USERS_EMAIL')} *`}
                    </Typography>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      name="email"
                      onBlur={(e) => handleEmailBlur(e, setErrors, handleChange, setTouched, errors, touched)}
                      onChange={(e) => handleChange({ target: { name: 'email', value: e.target.value.toLowerCase() } })}
                      required
                      value={values.email}
                      variant="outlined"
                      sx={styles.textField}
                    />
                  </Box>
                  <Box sx={styles.fieldContainer}>
                    <Typography variant="caption" sx={styles.inputLabel}>
                      {`${ready && t('ACCOUNT_SETTINGS_USERS_FIRSTNAME')} *`}
                    </Typography>
                    <TextField
                      error={Boolean(touched.firstname && errors.firstname)}
                      fullWidth
                      helperText={touched.firstname && errors.firstname}
                      name="firstname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.firstname}
                      variant="outlined"
                      disabled={isUserExist}
                      sx={styles.textField}
                    />
                  </Box>
                  <Box sx={styles.fieldContainer}>
                    <Typography variant="caption" sx={styles.inputLabel}>
                      {`${ready && t('ACCOUNT_SETTINGS_USERS_LASTNAME')} *`}
                    </Typography>
                    <TextField
                      error={Boolean(touched.lastname && errors.lastname)}
                      fullWidth
                      helperText={touched.lastname && errors.lastname}
                      name="lastname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.lastname}
                      variant="outlined"
                      disabled={isUserExist}
                      sx={styles.textField}
                    />
                  </Box>
                  <Box sx={styles.fieldContainer}>
                    <Typography variant="caption" sx={styles.inputLabel}>
                      {`${ready && t('ACCOUNT_SETTINGS_USERS_ROLE')} *`}
                    </Typography>
                    <Button
                      variant="outlined"
                      ref={userRolesRef}
                      sx={styles.userRoleBtn}
                      onClick={handleUserRoleOptionsToggle}
                    >
                      {selectedRole}
                      <ArrowDropDown />
                    </Button>
                  </Box>
                  <Popper
                    open={!isUserExist && roleOptionsOpen}
                    anchorEl={userRolesRef.current}
                    role={undefined}
                    disablePortal={isUserExist}
                    transition
                    sx={{ ...styles.popper, maxWidth: userRolesWidth }}
                    className="y-scroll"
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleUserRoleOptionsClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                              {userRoles.map((option) => (
                                <MenuItem
                                  key={option.role}
                                  selected={option.role === selectedRole}
                                  onClick={() => handleUserRoleClick(option.role)}
                                  sx={{ ...styles.menuItem, maxWidth: userRolesWidth }}
                                  disabled={isUserExist}
                                >
                                  <Box>
                                    <Typography sx={styles.optionTitle}>{option.role}</Typography>
                                    <Typography sx={{ ...styles.optionDescription, maxWidth: userRolesWidth }}>{option.description}</Typography>
                                  </Box>
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  {submitError && (
                    <Box mt={3}>
                      <FormHelperText error>
                        {submitError}
                      </FormHelperText>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box sx={styles.modalFooter}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    sx={styles.cancelBtn}
                    onClick={handleClose}
                  >
                    {t('ACCOUNT_SETTINGS_USERS_CANCEL')}
                  </Button>
                  <Button
                    variant="contained"
                    sx={styles.submitBtn}
                    type="submit"
                    disabled={isSubmitting || isUserExist}
                  >
                    {t('ACCOUNT_SETTINGS_USERS_ADD_USER')}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
}

export default AddUserModal;
