import { useTranslation } from 'react-i18next';

import SideBarLayout from 'src/shared/layouts/SideBarLayout';
import PlanAndBilling from 'src/plan-n-billing/PlanAndBilling';
import DashboardLayout from 'src/shared/layouts/DashboardLayout';
import Subscription from 'src/subscription/Subscription';
import { useConfig } from 'src/hooks/useConfig';
import { useAuth } from 'src/hooks/useAuth';

const DocumentView = () => {
  const { t } = useTranslation();

  const { BRAND_NAME } = useConfig();
  const { user } = useAuth();
  const isOldAnnotationInterface = user?.customisations.includes('oldAnnotationInterface');

  return (
    <>
      {
        isOldAnnotationInterface ?
          <DashboardLayout>
            <Subscription />
          </DashboardLayout> :
          <SideBarLayout title={`${t('MY_PLAN_PAGE_TITLE')} | ${BRAND_NAME}`}>
            <PlanAndBilling />
          </SideBarLayout>
      }
    </>
  );
}

export default DocumentView;
