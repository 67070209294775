import { forwardRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const styles = {
  root: {
    paddingBottom: '80px !important'
  },
};

const Page = forwardRef(({
  title,
  children,
  style,
  ...rest
}, ref) => {

  return (
    <HelmetProvider>
      <div
        ref={ref}
        style={{...styles.root, ...style}}
        {...rest}
      >
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </div>
    </HelmetProvider>
  );
});

export default Page;
