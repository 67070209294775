import React, { useContext } from 'react';
import { Formik, FormikErrors } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Chip, FormHelperText, Grid,
  TextField, Tooltip, Typography
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { axiosHeaders, getLocalisedErrorString, isActionPermitted, permissions } from 'src/utils/helpers';
import authService from 'src/utils/authService';
import { costCenterFields } from 'src/config';
import ConfigContext from 'src/contexts/ConfigContext';
import { CostCenterType, FieldType } from 'src/types';
import theme from 'src/theme';

type PropsType = {
  costCenter: CostCenterType | null;
  getAllCostCenters: ( _page?: number, _limit?: number, _search?: string, _loadFullScreen?: boolean) => void;
  hasError: (_fields: FieldType[], _errors:Record<string,any>) => boolean;
  handleClose: () => void;
  onSuccess: (_id: string) => void;
  setLoading: (_show: boolean) => void;
  isEdit: boolean;
  isDisabled?: boolean;
}

type FormPropType = {
  errors: Record<string, any>;
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleSubmit: () => void;
  isSubmitting: boolean;
  setErrors: (_errors: FormikErrors<Record<string, string>>) => void;
  touched: Record<string, any>;
  values: Record<string, any>;
}

const CostCenterTwinfield = (props: PropsType) => {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    costCenter,
    getAllCostCenters,
    handleClose,
    hasError,
    onSuccess,
    setLoading,
    isEdit,
    isDisabled,
  } = props;

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
 
  const auth = useAuth();
  const {user} = auth;

  if(isEdit === true && (!costCenter?.name || !costCenter?.code )){
    return <></>
  }
  
  return (
    <Formik
      initialValues={{
        code: costCenter?.code || '',
        name: costCenter?.name || '',
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string()
          .max(255),
        name: Yup.string()
          .max(80, 'COST_CENTER_NAME_MAX_VALIDATION')
          .required('COST_CENTER_NAME_REQUIRED')
      })}
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          if (!authService.validateToken()) {
            enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
              variant: 'error',
            });
            setTimeout(() => {
              authService.logout(LOGIN_PANEL_URL);
            }, 2000);
            return;
          }
          setLoading(true);
          const body = {
            code: values.code,
            name: values.name,
          };

          const url = `${API.costCentersByCompany}/${encodeURIComponent(user?.companyID as string)}`;
          let response = null;
          let snackText = '';

          if (costCenter && costCenter.name) {
            response = await Axios.put(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
            snackText = 'COST_CENTERS_UPDATE_SUCCESS';
          } else {
            response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
            snackText = 'COST_CENTERS_ADD_SUCCESS';
          }
          if (response?.data?.success) {
            setLoading(false);
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar(ready && t(snackText), {
              variant: 'success',
              style: { maxWidth: 400 }
            });
            getAllCostCenters();
            handleClose();
            if (onSuccess) {
              onSuccess(response.data.id);
            }
          }
        } catch (error: any) {
          setLoading(false);
          setStatus({ success: false });
          setSubmitting(false);
          if (error.response.data.i18n.indexOf('__TFMSG__') > 0) {
            const errParts = error.response.data.i18n.split('__TFMSG__');
            setErrors({
              [errParts[0]]: errParts[1]
            });
          } else {
            setErrors({
              submit:getLocalisedErrorString(error.response.data.i18n, t)
            } as any);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setErrors,
        touched,
        values
      }:FormPropType) => (
        <form style={styles.root} className="y-scroll">
          <Card>
            <CardContent style={{ padding: '0' }}>
              <Grid sx={{display:'flex',paddingTop:'0px',flexDirection:'column',gap:'10px',marginLeft:'0',width:'100%', }}>
                {
                    costCenterFields.map((m:Record<string,any>,index:number) => (
                      <Accordion
                        key={m.title}
                        style={styles.accordionGroup}
                        defaultExpanded={index === 0 ? true : false}
                        className='twinfield-accordion'
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={m.title}
                          id={m.title}

                        >
                          <Grid style={styles.accordionHeading}>
                            {ready && t(m.title)}
                            {
                            hasError(m.fields, errors) && (
                            <Chip
                              color="error"
                              // variant="default"
                              size="small"
                              label="Error"
                              style={styles.errorChip}
                            />
                            )
                            }
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails sx={{padding:'0 24px 16px 48px'}}>
                          <Grid sx={styles.eachFieldContainer}>
                            {
                              m.fields.map((f: Record<string,any>) => {
                                switch (f.key) {
                                  default:
                                    return (
                                      <Tooltip title={f.key !== 'code' ? '' : isEdit ?  t('DIMENSION_CODE_CANNOT_EDIT'):''}>
                                        <Grid item key={f.key} sx={styles.eachField}>
                                          <Typography>{ready && t(f.label)}</Typography>
                                          <TextField
                                            error={Boolean(touched[f.key] && errors[f.key])}
                                            fullWidth
                                            helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                            name={f.key}
                                            onClick={() => setErrors({})}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            required={f.required}
                                            value={values[f.key] && values[f.key] !== 'undefined' ? values[f.key] : ''}
                                            variant="outlined"
                                            style={styles.inputField}
                                            disabled={isDisabled ? true : isEdit && f.key === 'code'}
                                          />
                                        </Grid>
                                      </Tooltip>
                                    );
                                }
                              })
                            }
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ))
                }
                <Grid
                  item
                  xs={12}
                >
                  {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>
                      {errors.submit}
                    </FormHelperText>
                  </Box>
                  )}
                  {
                    isDisabled ? <></> :
                    <Box display="flex" justifyContent="flex-end" gap="10px" p="0 16px 20px 16px">
                      <Button
                        variant="text"
                        onClick={()=>handleClose()}
                        sx={{...styles.yearField,textTransform:'none'}}
                      >
                        {ready && t('COST_CENTERS_CANCEL')}
                      </Button>
                      <Button
                        sx={{ backgroundColor: theme.palette.secondary.main,textTransform:'none' }}
                        variant="contained"
                        disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user?.permissions)}
                        onClick={()=>handleSubmit()}
                      >
                        {ready && t(isEdit ?'COST_CENTERS_SAVE_CHANGES':'COST_CENTERS_ADD_NEW')}
                      </Button>
                    </Box>
                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};


const styles = {
  root: {
    backgroundColor: '',
    maxHeight: 'calc(94dvh - 76px)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  halfPaper: {
    width: '90%',
    maxWidth:'500px',
    maxHeight: '94%',
    // overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding:'20px 16px'
  },
  headerTitle: {
    color:'#4C4E64',
    fontSize:'20px',
    fontWeight:'500',
    textTransform:'capitalize',
    LineHeight:'32px'
  },
  eachFieldContainer:{
    display:'flex',
    flexDirection:'column',
    gap:'5px',
    padding:0,
  },
  eachField:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    padding:'5px 0',
    width:'100%',
    flex:1,
    '& > p':{
      fontSize:'13px',
      color:'#4C4E64DE',
    }
  },
  closeIcon: {
    width: 20,
    height: 20,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  form: {},
  snackAction: {
    color: 'white'
  },
  expiryRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  monthField: {
    marginRight: 16
  },
  yearField: {
    color: theme.palette.secondary.main
  },
  errorText: {
    color: '#f44336'
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: 'tranparent',
    marginBottom: 24,
    boxShadow:'none',
    borderTop:'none',
    margin:'0',
    '& > div:first-of-type':{
      flexDirection:'row-reverse'
    },
    '&::before':{
      display:'none',
      opacity:'0'
    },
    '& > div:first-of-type > div:first-of-type':{
      margin:'10px',
    }
  },
  accordionHeading: {
    color: theme.palette.text.primary,
    fontWeight: 500
  },
  formControl: {
    width: '100%'
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#ffffff'
  },
  errorChip: {
    marginLeft: 16,
    fontSize: 12,
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  inputField: {
    backgroundColor: '#ffffff',
    width:'180px',
    maxWidth:'180px',
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  manageFieldsBtn: {
    height: 36,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: 8,
    fontSize: '12px'
  },
  btnIcon: {
    height: 15,
    width: 15,
    marginRight: 4,
    color: '#bebebe',
  },
};


export default CostCenterTwinfield;
