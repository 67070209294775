import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Axios from 'axios';

import { Box, Chip, Tooltip, Typography } from '@mui/material';
import {
  Announcement as AnnouncementIcon,
  DocumentScanner as DocumentProcessedIcon,
  EventRepeat as SubsRenewIcon,
  RunningWithErrors as ManualWorkIcon
} from '@mui/icons-material';

import FreeSubIcon from 'src/plan-n-billing/icons/Free';
import PremSubIcon from 'src/plan-n-billing/icons/PremiumSubscription';
import {
  appendContactSupport, axiosHeaders, getLocalisedErrorString, getMaskedText, isActionPermitted, permissions
} from 'src/utils/helpers';
import { useConfig } from 'src/hooks/useConfig';
import { useAuth } from 'src/hooks/useAuth';
import { subCategories } from 'src/settings/utils';
import { CustomError } from 'src/types';
import LoadingScreen from 'src/shared/screens/LoadingScreen';
import CancelSubscriptionModal from './components/CancelSubscriptionModal';
import ManagePlanSection from 'src/subscription/components/ManagePlanSection';
import SubscriptionActions from 'src/subscription/components/SubscriptionActions';
import BuyExtraDocs from 'src/subscription/components/BuyExtraDocs';
import Cards from 'src/plan-n-billing/components/Cards/Cards';
import Invoices from 'src/plan-n-billing/components/Invoices/Invoices';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import SubCompanyAccessModal from 'src/plan-n-billing/components/SubCompanyAccessModal';
import styles from './style';

const PlanAndBilling = () => {
  const { ready, t } = useTranslation();
  const { API, BRAND_NAME } = useConfig();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [defaultCard, setDefaultCard] = useState<Record<string, any>>({});
  const [subscription, setSubscription] = useState<Record<string, any>>({});
  const [customSubscription, setCustomSubscription] = useState<Record<string, any>>();
  const [selectedSubscription, setSelectedSubscription] = useState<Record<string, any>>({});
  const [subsError, setSubsError] = useState('');
  const [openCancelPlanModal, setOpenCancelPlanModal] = useState(false);

  const getSubscription = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(API.subscriptionDetails, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success && response.data.data && response.data.data.length > 0) {
        const subs = response.data.data[0];
        setDefaultCard(subs.creditCard.id);
        let balanceFromChargify = subs.balanceInCents;
        if (!balanceFromChargify) {
          balanceFromChargify = 0;
        }

        let currentSubsPrice = subs.currPrice;
        if (!currentSubsPrice) {
          currentSubsPrice = 0;
        }

        const subsObj = {
          id: subs.id,
          name: subs.product.name,
          i18n: subCategories[Object.keys(subCategories).find((o) => subs.product.name.toLowerCase().includes(o)) || 'enterprise']?.i18n,
          selfServiceUrl: subs.selfServiceUrl,
          invLimit: subs.invoicesLimit || 20,
          invPurchased: subs.extraPurchased || 0,
          ecCarried: subs.ecCarried || 0,
          invUsed: subs.invoicesUsed || 0,
          usersLimit: subs.usersLimit || 1,
          usersUsed: subs.usersUsed || 1,
          totalInvoices: subs.totalInvoices || 0,
          totalPayment: subs.totalRevenueinCents || 0,
          nextBillingAmount: balanceFromChargify + currentSubsPrice || 0,
          startDate: subs.activatedAt,
          endDate: subs.currentPeriodEndsAt,
          ecLimit: subs.ecLimit || 0,
          ecPrice: subs.ecPrice || 0,
          currPrice: subs.currPrice || 0,
          price: subs.currPrice || 0,
          ecCharged: subs.ecCharged || 0,
          toDowngrade: subs.toDowngrade,
          downgradeSubscription: subs.downgradeSubscription,
          level: 0,
          value: 4,
          label: 'Free',
        };
        let currSubs = subCategories.free;

        if (subs.product.name.toLowerCase().indexOf('trial') >= 0) {
          currSubs = subCategories.free;
          subsObj.level = -1;
          subsObj.value = 0;
          subsObj.label = 'Trial';
        }

        if (subs.product.name.toLowerCase().indexOf('starter') >= 0) {
          currSubs = subCategories.starter;
          subsObj.level = subCategories.starter.level;
          subsObj.value = subCategories.starter.value;
          subsObj.label = 'Starter';
        }

        if (subs.product.name.toLowerCase().indexOf('business') >= 0) {
          currSubs = subCategories.business;
          subsObj.level = subCategories.business.level;
          subsObj.value = subCategories.business.value;
          subsObj.label = 'Business';
        }

        if (subs.product.name.toLowerCase().indexOf('custom') >= 0
          || subs.product.name.toLowerCase().indexOf('enterprise') >= 0
          || subs.product.name.toLowerCase().indexOf('increment') >= 0) {
          currSubs = subCategories.enterprise;
          subsObj.level = subCategories.enterprise.level;
          subsObj.value = subCategories.enterprise.value;
          subsObj.label = 'Enterprise';

          if (!subs.invoicesLimit || subs.invoicesLimit <= 0) {
            subsObj.invLimit = 0;
          }

          setCustomSubscription(subsObj);
        } else if (response.data.data.length >= 2) {
          const customSub = response.data.data[1];
          const salesCustom = {
            level: subCategories.enterprise.level,
            value: subCategories.enterprise.value,
            label: 'Enterprise',
            name: 'Enterprise Plan',
            invoices: customSub.invoicesLimit,
            users: customSub.usersLimit,
            price: customSub.currPrice,
            ecLimit: customSub.ecLimit,
            ecPrice: customSub.ecPrice,
          };
          setCustomSubscription(salesCustom);
        }

        if (!subs.ecLimit) {
          subsObj.ecLimit = currSubs.ecLimit;
        }
        if (!subs.ecPrice) {
          subsObj.ecPrice = currSubs.ecPrice;
        }

        setSelectedSubscription(subsObj);
        setSubscription(subsObj);
      } else {
        setSubsError(appendContactSupport(window.config.support_email, t('SUBSCRIPTION_RETRIEVE_DETAILS_FAILURE'), t));
      }
      setLoading(false);
    } catch (e) {
      const err = e as CustomError;
      setLoading(false);
      setSubsError(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(err?.response?.data?.i18n || 'SUBSCRIPTION_RETRIEVE_DETAILS_FAILURE', t),
        t
      ));
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  if ((user?.isSubCompany || user?.isPartnerSubCompany) && user.parent) {
    return <SubCompanyAccessModal open parentName={user.parentName} />;
  }

  if (subsError) {
    return <EmptyText str={subsError} />;
  }

  return (
    <>
      <Box sx={styles.mainContainer}>
        {!defaultCard && (
          <Box sx={styles.noCardContainer}>
            <AnnouncementIcon sx={styles.noCardIcon} />
            <Typography sx={styles.noCardText}>
              {ready && t('MY_PLAN_NO_CARD_ADDED', { brand: BRAND_NAME })}
            </Typography>
          </Box>
        )}
        <Box sx={styles.subDetailsContainer}>
          <Box sx={styles.planDetailsContainer}>
            <Box sx={styles.containerLeft}>
              <Box sx={styles.subDetailsLeft}>
                <Chip label={subscription?.name?.toLowerCase() === 'free' ? <FreeSubIcon /> : <PremSubIcon />} sx={styles.chip} />
              </Box>
              <Box sx={styles.subDetailsMiddle}>
                <Typography sx={styles.titleText}>{subscription.label}</Typography>
                <Typography sx={styles.descr}>{t('MY_PLAN_TITLE')}</Typography>
              </Box>
              <Box sx={styles.subDetailsRight}>
                {(subscription?.name?.toLowerCase().indexOf('free') < 0 && subscription?.name?.toLowerCase().indexOf('trial') < 0) && (
                  <Tooltip title={isActionPermitted(permissions.subscriptionsCancel, user?.permissions) ? ''
                    : t('SUBSCRIPTION_CANCEL_PERMISSION')}
                  >
                    <Typography
                      onClick={() => setOpenCancelPlanModal(true)}
                      sx={{ ...styles.descr, textDecoration: 'underline', '&:hover': { cursor: 'pointer' } }}
                    >
                      {t('MY_PLAN_CANCEL_PLAN')}
                    </Typography>
                  </Tooltip>
                )}
              </Box>
            </Box>
            <Box sx={styles.containerRight}>
              <Box sx={styles.subDetailsLeft}>
                <Chip label={<DocumentProcessedIcon sx={styles.chipIcon} />} sx={styles.chip} />
              </Box>
              <Box sx={styles.subDetailsMiddle}>
                <Typography sx={styles.titleText}>
                  {/* eslint-disable-next-line max-len */}
                  {ready && subscription.invLimit > 0 ? `${(subscription.invUsed > subscription.invLimit ? subscription.invLimit : subscription.invUsed)}/${(subscription.invLimit - subscription.invPurchased)}` : subscription.invUsed}
                </Typography>
                <Typography sx={styles.descr}>{t('MY_PLAN_DOCUMENTS_PROCESSED')}</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.subRenewContainer}>
            <Box sx={styles.containerLeft}>
              <Box sx={styles.subDetailsLeft}>
                <Chip label={<SubsRenewIcon sx={styles.chipIcon} />} sx={styles.chip} />
              </Box>
              <Box sx={styles.subDetailsMiddle}>
                <Typography sx={styles.titleText}>
                  {moment(subscription.endDate).format('DD MMM, YYYY')}
                </Typography>
                <Typography sx={styles.descr}>{t('MY_PLAN_RENEW_AT')}</Typography>
              </Box>
            </Box>
            <Box sx={styles.containerRight}>
              <Box sx={styles.subDetailsLeft}>
                <Chip label={<ManualWorkIcon sx={{ ...styles.chipIcon, color: '#27AE60' }} />} sx={{ ...styles.chip, backgroundColor: '#27ae601f' }} />
              </Box>
              <Box sx={styles.subDetailsMiddle}>
                <Typography sx={styles.titleText}>
                  {`${getMaskedText((Math.round((subscription.totalInvoices * 2.5 * 100)) / 100).toString(), 3)} min`}
                </Typography>
                <Typography sx={styles.descr}>{t('MY_PLAN_SAVED_MANUAL_WORK')}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: '0 20px' }}>
          {user?.brand !== 'schubec' && !user?.customisations.includes('hideManagePlanSection') && 
            <>
              <ManagePlanSection
                subscription={subscription}
                selectedSubscription={selectedSubscription}
                setSelectedSubscription={setSelectedSubscription}
                customSubscription={customSubscription}
              />
              <SubscriptionActions
                subscription={subscription}
                selectedSubscription={selectedSubscription}
                user={user}
                setModalOpen={setOpenCancelPlanModal}
              />
            </>
          }
          <BuyExtraDocs subscription={subscription} user={user} />
        </Box>
        <Cards subscription={subscription} />
        <Invoices user={user} />
      </Box>
      <CancelSubscriptionModal
        open={openCancelPlanModal}
        subscription={subscription}
        handleClose={() => setOpenCancelPlanModal(false)}
      />
    </>
  );
};

export default PlanAndBilling;


