import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import { Box, Button, Card, CardContent, FormControl, FormHelperText, Grid, InputAdornment,
  MenuItem, Select, TextField, Tooltip, Autocomplete, Typography
} from '@mui/material';
import { Checklist as FieldsIcon, Edit as EditIcon, AddBox as PlusIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { axiosHeaders, isActionPermitted, isShowManageFields, permissions
} from 'src/utils/helpers';
import { supplierData, vatCodeTypes } from 'src/config';
import ConfigContext from 'src/contexts/ConfigContext';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import ManageFields from 'src/shared/components/ManageFieldsNew/ManageFieldsNew';
import { CostCenterType, FieldType, GLAccountType, VatGroupType } from 'src/types';
import theme from 'src/theme';
import { FormikErrors } from 'formik';


type PropsType = {
  errors: Record<string, string>,
  handleBlur:React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleSubmit: () => void;
  setErrors: (_errors: FormikErrors<Record<string, string>>) => void;
  isSubmitting: boolean;
  touched: Record<string, string | boolean>;
  values: Record<string, string | boolean>;
  vatCode: VatGroupType | null;
  handleClose: () => void;
  handleChangeAutocomplete: (_e:React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | 
    React.SyntheticEvent<Element, Event>, _prop:string, _values:Record<string,string | boolean>, _handleChange:Function) => void;
  getLabel: (_entities:Record<string, any>[] , _code:string) => string;
  costCenters: CostCenterType[];
  glaccounts:  GLAccountType[];
  handleEditCostCenter: (_cc: string) => void;
  handleAddCostCenter: (_cc: string) => void;
  newlyAddedVal: string;
  newlyAddingProp: string;
  isDisabled: boolean;
}

const VatCodeStandard = (props: PropsType) => {
  const { t, ready } = useTranslation();

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setErrors,
    isSubmitting,
    touched,
    values,
    vatCode,
    handleClose,
    handleChangeAutocomplete,
    getLabel,
    costCenters,
    glaccounts,
    handleEditCostCenter,
    handleAddCostCenter,
    newlyAddedVal,
    newlyAddingProp,
    isDisabled,
  } = props;
  const { API } = useContext(ConfigContext);
  
  const auth = useAuth();
  const {user} = auth;

  const [vatFields, setVatFields] = useState([]);
  const [showManageFields, setShowManageFields] = useState(false);
  const [loading, setLoading] = useState(false);

  const getVatFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/vat/${encodeURIComponent(user?.companyID as string)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setVatFields(response.data.data.filter((f:FieldType) => f.isAvailable));
          setLoading(false);
        } else {
          setVatFields([]);
          setLoading(false);
        }
      }
    } catch (error) {
      setVatFields([]);
      setLoading(false);
    }
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
  };

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } }  as unknown as React.ChangeEvent<HTMLInputElement 
      | HTMLTextAreaElement>
      );
    }
  }, [newlyAddedVal]);

  useEffect(() => {
    getVatFields();
  }, []);

  const isFieldAvailable = (field:FieldType) => {
    if (field.isMandatory) {
      return false;
    }
    if (field.isActive) {
      return true;
    }
    return false;
  };

  return (
    <>
      
      {
        !showManageFields ?
        <form style={styles.root} className='y-scroll'>
        {loading ? (<LoadingIndicator />) : (
          <Card style={{ boxShadow: 'none' }}>
            <CardContent style={{ padding: '0', display:'flex', flexDirection:'column',gap:'10px' }}>
              {isShowManageFields(user, 'applyParentFieldsVAT') && (
                <Grid mt={2} item xs={12} sx={styles.topManager}>
                  <Button
                    variant="outlined"
                    sx={styles.manageFieldsBtn}
                    onClick={() => setShowManageFields(true)}
                  >
                    <FieldsIcon style={styles.btnIcon} />
                    {t('INVOICE_EDIT_FORM_MANAGE_FIELDS')}
                  </Button>
                </Grid>
              )}
              <Grid item key="name" sx={styles.eachRow}>
                <Typography>{ready && t('VAT_CODE_NAME')}</Typography>
                <TextField
                  disabled={isDisabled}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && (ready && t(errors.name))}
                  
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.name}
                  variant="outlined"
                  style={styles.inputField}
                />
              </Grid>
              <Grid item key="ratePercentage" sx={styles.eachRow}>
                <Typography>{ready && t('VAT_CODE_RATE_PERCENTAGE')}</Typography>
                <TextField
                  disabled={isDisabled}
                  error={Boolean(touched.ratePercentage && errors.ratePercentage)}
                  fullWidth
                  helperText={touched.ratePercentage && (ready && t(errors.ratePercentage))}
                  name="ratePercentage"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.ratePercentage}
                  variant="outlined"
                  style={styles.inputField}
                />
              </Grid>
              {
                vatFields.map((f: FieldType,index:number) => {
                  if (!isFieldAvailable(f)) {
                    return null;
                  }
                  switch (f.key) {
                    case 'type':
                      return (
                        <Grid item key={f.key+index} sx={styles.eachRow}>
                          <Typography>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</Typography>
                          <FormControl variant="outlined" style={styles.formControl}>
                            <Select
                              disabled={isDisabled}
                              labelId={`${f.key}-label`}
                              id={f.key}
                              error={Boolean(touched[f.key] && errors[f.key])}
                              value={values[f.key]}
                              onClick={() => setErrors({})}
                              onChange={handleChange as any}
                              name={f.key}
                              required={f.isRequired}
                              sx={{
                                '& .MuiSelect-select': styles.selectStyle
                              }}
                            >
                              {
                                vatCodeTypes && vatCodeTypes.length > 0
                                && vatCodeTypes.map((s) => {
                                  if (s) {
                                    return (
                                      <MenuItem key={s.name} value={s.name}>
                                        {t(s.label)}
                                      </MenuItem>
                                    );
                                  }

                                  return null;
                                })
                              }
                            </Select>
                            <FormHelperText error>
                              {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      );
                    case 'ruleCostCenter':
                      return (
                        <Grid item key={f.key+index} sx={styles.eachRow} >
                          <Typography>
                            {ready && t(f.label)}
                          </Typography>
                          <FormControl variant="outlined" style={styles.formControl}>
                            <Autocomplete
                              disabled={isDisabled}
                              fullWidth
                              // name="ruleCostCenter"
                              onChange={(e, v) => handleChangeAutocomplete(e, 'ruleCostCenter', v as 
                                unknown as Record<string, any>, handleChange)}
                              inputValue = {getLabel(costCenters, values.ruleCostCenter as string)}
                              value={costCenters && costCenters.find(e => e.code === values.ruleCostCenters) || null}
                              options={costCenters}
                              getOptionLabel={(option) => option && option.code ? `${option.code} | ${option.name}` : ''}
                              isOptionEqualToValue={(option:CostCenterType) => option.code === values.ruleCostCenter}
                              filterOptions={options => options}
                              renderInput={(params) => (
                                <TextField
                                  // fullWidth
                                  {...params}
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: ( isDisabled ? <></> :
                                      <>
                                        {values[f.key] !== supplierData.defaultCostCenters[0].code && (
                                          <InputAdornment
                                            position="end"
                                            onClick={() => handleEditCostCenter(values[f.key] as string)}
                                            style={styles.editIconContainer}
                                          >
                                            <EditIcon style={styles.editDimensionIcon} />
                                          </InputAdornment>
                                        )}
                                        <InputAdornment position="end" style={styles.plusIconContainer}>
                                          <PlusIcon style={styles.plusIcon} onClick={() => handleAddCostCenter(f.key as string)} />
                                        </InputAdornment>
                                      </>
                                    ),
                                  }}
                                  style={styles.selectStyle}
                                />
                              )}
                            />
                            <FormHelperText error>
                              {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      );
                    case 'ruleAccount':
                      return (
                        <Grid item lg={6} key={f.key+index} sx={styles.eachRow} >
                          <Typography>{ready && t(f.label)}</Typography>
                          <FormControl variant="outlined" style={styles.formControl}>
                            <Autocomplete
                              disabled={isDisabled}
                              fullWidth
                              // name="ruleAccount"
                              onChange={(e, v) => handleChangeAutocomplete(e, 'ruleAccount', v as 
                                unknown as Record<string, any>, handleChange)}
                              value = { values.ruleAccount && glaccounts && glaccounts.find(e => e?.code === values?.ruleAccount)}
                              inputValue={getLabel(glaccounts, values.ruleAccount as string)}
                              options={glaccounts }
                              filterOptions={options => options}
                              getOptionLabel={
                                (option) => (option && option.code ? `${option.code} | ${option.name}` : '')
                              }
                              renderInput={(params) => (
                                <TextField
                                  // fullWidth
                                  {...params}
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps
                                  }}
                                  style={styles.selectStyle}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      );
                    default:
                      return (
                        <Tooltip key={f.key+index} title={(f.key !== 'code') ? '' : !values[f.key]?'': t('DIMENSION_CODE_CANNOT_EDIT')}>
                          <Grid item sx={styles.eachRow}>
                            <Typography>
                              {ready && t(f.label)}
                            </Typography>
                            <TextField
                              error={Boolean(touched[f.key] && errors[f.key])}
                              fullWidth
                              helperText={touched[f.key] && (ready && t(errors[f.key]))}
                             
                              name={f.key}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required={f.isRequired}
                              value={values[f.key]}
                              variant="outlined"
                              style={styles.inputField}
                              disabled={isDisabled ? true : vatCode ? f.key === 'code' : false}
                            />
                          </Grid>
                        </Tooltip>
                      );
                  }
                })
              }
              {errors.submit && (
                <Grid item xs={12}>
                  <Box mt={3}>
                    <FormHelperText error>
                      {errors.submit}
                    </FormHelperText>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                {
                  isDisabled ? <></> :
                  <Box display="flex" justifyContent="flex-end" padding="10px 16px 20px">
                    <Button
                      variant="text"
                      onClick={()=>handleClose()}
                      sx={{...styles.yearField,textTransform:'none'}}
                    >
                      {ready && t('VAT_CODES_CANCEL')}
                    </Button>
                    <Button
                      sx={{ backgroundColor: '#3E8AFF',textTransform:'none' }}
                      variant="contained"
                      disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user?.permissions)}
                      onClick={()=>handleSubmit()}
                    >
                      {ready && t(vatCode && vatCode?.code ?'VAT_CODES_SAVE_CHANGES':'VAT_CODES_ADD_NEW')}
                    </Button>
                  </Box>
                }
              </Grid>
              
            </CardContent>
          </Card>
        )}
        </form>:
        <Box sx={{backgroundColor:'#fff', position:'relative',top:'-20px',
          '& > div > div:nth-of-type(2)':{maxHeight:'calc(90dvh - 228px)',minHeight:'calc(90dvh - 228px)'}
          }}>
          <ManageFields
            entity="vat"
            fields={vatFields}
            fetchFields={getVatFields}
            handleCloseManageFields={handleCloseManageFields}
            parameterCompany={user?.companyID || ''}
          />
        </Box>        
      }
      
    </>
  );
};


const styles = {
  root: {
    backgroundColor: '',
    maxHeight: 'calc(94dvh - 76px)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  paper: {
    width: '96%',
    maxHeight: '94%',
    // overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    // padding: '20px 20px 28px 20px',
    outline: 'none',
  },
  halfPaper: {
    width: '52%',
    maxHeight: '94%',
    // overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    // padding: '20px',
    outline: 'none',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding:'20px 16px'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px'
  },
  closeIcon: {
    width: 20,
    height: 20,
    color: '',
  },
  topManager:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    gap:'12px',
    padding:'12px 16px',
    borderTop: '1px solid #4C4E641F',
    borderBottom: '1px solid #4C4E641F',
    margin:'0',
    marginBottom:'10px'
  },
  eachRow:{
    display:'flex',
    width:'100%',
    justifyContent:'space-between',
    padding:'0 16px',
    '& > p':{
      fontSize:'13px',
      color:'#4C4E64DE',
    }
  },
  form: {},
  snackAction: {
    color: 'white'
  },
  expiryRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  monthField: {
    marginRight: 16
  },
  yearField: {
    marginLeft: 16,
    color: theme.palette.secondary.main
  },
  errorText: {
    color: '#f44336'
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: '#f4f6f8',
    marginBottom: 24
  },
  accordionHeading: {
    color: theme.palette.text.primary,
    fontWeight: 500
  },
  formControl: {
    width: '180px'
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff'
  },
  errorChip: {
    marginLeft: 16,
    fontSize: 12,
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  inputField: {
    backgroundColor: '#ffffff',
    width:'180px'
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  manageFieldsBtn: {
    height: 'fit-content',
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#3E8AFF',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    gap:'10px',
    fontSize:'13px',
    fontWeight:'500',
    border:'none',
    '&:hover':{
      border:'none',
      backgroundColor:'#fff'
    }
  },
  btnIcon: {
    height: 20,
    width: 20,
    marginRight: 4,
    color: '#3E8AFF',
  },
  plusIcon: {
    height: 24,
    width: 24,
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  plusIconSelect: {
    height: 24,
    width: 24,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    marginRight: 5,
  },
  plusIconContainer: {
    marginRight: -40
  },
  editDimensionIcon: {
    fontSize: 18,
    color: '#757575',
    border: '1px solid #334D6E',
    borderRadius: 3,
    cursor: 'default'
  },
  editIconContainer: {
    marginRight: -5
  },
};

export default VatCodeStandard;
