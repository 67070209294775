const styles = {
  mainContainer:{
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 0px',
    gap: '12px',
    backgroundColor: '#FFFFFF',
  },
  noCardContainer: {
    display: 'flex',
    height: '49px',
    alignItems: 'center',
    backgroundColor: '#F2C94C26',
    padding: '6px 16px',
    borderRadius: '8px',
    margin: '0 20px',
    gap: '12px'
  },
  noCardIcon: {
    color: '#C29D2C',
    height: '20px',
    width: '20px',
  },
  noCardText: {
    color: '#C29D2C',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
  },
  subDetailsContainer: {
    border: '1px solid #4C4E641F',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 20px'
  },
  planDetailsContainer: {
    display: 'flex',
    borderBottom: '1px solid #4C4E641F',
  },
  subRenewContainer: {
    display: 'flex',
  },
  containerLeft: {
    borderRight: '1px solid #4C4E641F',
    flex: 1,
    padding: '0 12px',
    display: 'flex',
  },
  containerRight: {
    flex: 1,
    padding: '0 12px',
    display: 'flex',
  },
  subDetailsLeft: {
    padding: '12px 8px',
  },
  subDetailsMiddle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 8px',
  },
  subDetailsRight: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: '12px 8px',
  },
  chip: {
    backgroundColor: '#3E8AFF26',
    color: '#3E8AFF',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '12px',
    height: '50px',
    width: '50px',
    borderRadius: '8px',
  },
  chipIcon: {
    color: '#3E8AFF',
    height: '18px',
    width: '18px',
  },
  titleText: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '32px',
    color: '#4C4E64',
  },
  descr: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px',
    color: '#4C4E6499',
  }
};

export default styles;