import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  Modal, Typography, Backdrop, Fade, Grid, IconButton, TextField, FormControl,
  InputLabel, Select, MenuItem, Box, FormHelperText, Button, Tooltip
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import ConfigContext from 'src/contexts/ConfigContext';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import styles from './style';

function UserEditForm(props) {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    editUserOpen,
    handleClose,
    onGetAllUsers,
    editUser,
    user
  } = props;

  const { API } = useContext(ConfigContext);
  const [userRoles, setUserRoles] = useState([]);

  const fetchActiveUserRoles = async () => {
    try {
      const response = await Axios.get(`${API.getUserRoles}?status=active`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.data) {
        setUserRoles(response.data.data);
      }
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    fetchActiveUserRoles();
  }, []);

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      style={styles.modal}
      open={editUserOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={editUserOpen}>
        <div style={styles.paper}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {t('ACCOUNT_SETTINGS_USERS_MANAGE_USER_PERMISSIONS')}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          <Formik
            initialValues={{
              id: editUser.id,
              email: editUser.email,
              firstname: editUser.firstname,
              lastname: editUser.lastname,
              userRole: editUser.userRole || '',
              status: editUser.status || '',
            }}
            validationSchema={Yup.object().shape({
              status: Yup.string().max(255),
              firstname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_FIRSTNAME_REQ')),
              lastname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_LASTNAME_REQ')),
            })}
            onSubmit={async (values, {
              setErrors,
              setStatus,
              setSubmitting
            }) => {
              try {
                // Make API request
                const body = {
                  id: values.id.toString(),
                  role: values.userRole,
                  status: values.status,
                  firstname: values.firstname,
                  lastname: values.lastname,
                  company: user.companyID,
                };
                const response = await Axios.put(
                  API.updateUserByCreator,
                  body,
                  axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
                );
                if (response.data.success) {
                  onGetAllUsers();
                  handleClose();
                  setStatus({ success: true });
                  setSubmitting(false);
                  enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_EDIT_SUCCESS'), {
                    variant: 'success',
                  });
                } else {
                  setSubmitting(false);
                }
              } catch (error) {
                setErrors({
                  submit: appendContactSupport(
                    window.config.support_email,
                    getLocalisedErrorString(error?.response?.data?.i18n || 'ACCOUNT_SETTINGS_USERS_EDIT_FAILURE', t),
                    t
                  )
                });
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
              touched
            }) => (
              <form
                style={styles.root}
                onSubmit={handleSubmit}
              >
                <Grid style={styles.userForm}>
                  <Grid style={styles.fieldContainer}>
                    <TextField
                      fullWidth
                      label={ready && t('ACCOUNT_SETTINGS_USERS_EMAIL')}
                      name="email"
                      onChange={(e) => handleChange({ target: { name: 'email', value: e.target.value.toLowerCase() } })}
                      required
                      value={values.email}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid style={styles.fieldContainer}>
                    <TextField
                      error={Boolean(touched.firstname && errors.firstname)}
                      fullWidth
                      helperText={touched.firstname && errors.firstname}
                      label={ready && t('ACCOUNT_SETTINGS_USERS_FIRSTNAME')}
                      name="firstname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.firstname}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid style={styles.fieldContainer}>
                    <TextField
                      error={Boolean(touched.lastname && errors.lastname)}
                      fullWidth
                      helperText={touched.lastname && errors.lastname}
                      label={ready && t('ACCOUNT_SETTINGS_USERS_LASTNAME')}
                      name="lastname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.lastname}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid style={styles.fieldContainer}>
                    <FormControl variant="outlined" style={styles.formControl}>
                      <InputLabel id="user-role-label">{ready && t('ACCOUNT_SETTINGS_USERS_USER_ROLE')}</InputLabel>
                      <Select
                        labelId="user-role-label"
                        id="user-role"
                        defaultValue={values.userRole}
                        onChange={handleChange}
                        name="userRole"
                        label={ready && t('ACCOUNT_SETTINGS_USERS_USER_ROLE')}
                        sx={{
                          '& .MuiSelect-select': styles.selectStyle
                        }}
                      >
                        {
                          userRoles && userRoles.length > 0 && userRoles.map((r) => (
                            <MenuItem key={r.role} value={r.role} sx={styles.selectStyle}>
                              {r.role}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid style={styles.fieldContainer}>
                    <FormControl variant="outlined" style={styles.formControl}>
                      <InputLabel id="user-status-label">{ready && t('ACCOUNT_SETTINGS_USERS_USER_STATUS')}</InputLabel>
                      <Select
                        labelId="user-status-label"
                        id="user-status"
                        defaultValue={values.status}
                        onChange={handleChange}
                        name="status"
                        label={ready && t('ACCOUNT_SETTINGS_USERS_USER_STATUS')}
                        sx={{
                          '& .MuiSelect-select': styles.selectStyle
                        }}
                        disabled
                      >
                        <MenuItem key="Active" value="Active" sx={styles.selectStyle}>
                          {t('ACCOUNT_SETTINGS_USERS_ACTIVE')}
                        </MenuItem>
                        <MenuItem key="Inactive" value="Inactive" sx={styles.selectStyle}>
                          {t('ACCOUNT_SETTINGS_USERS_INACTIVE')}
                        </MenuItem>
                        <MenuItem key="Invited" value="Invited" sx={styles.selectStyle}>
                          {t('ACCOUNT_SETTINGS_USERS_INVITED')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>
                        {t(errors.submit)}
                      </FormHelperText>
                    </Box>
                  )}
                  <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button
                      variant="text"
                      style={styles.closeBtn}
                      disabled={isSubmitting}
                      onClick={handleClose}
                    >
                      {ready && t('ACCOUNT_SETTINGS_USERS_CANCEL')}
                    </Button>
                    <Tooltip
                      title={
                        ((values.email === user.email) && user.userRole !== values.userRole)
                          ? t('ACCOUNT_SETTINGS_USERS_CANNOT_CHANGE_ROLE') : ''
                      }
                    >
                      <span>
                        <Button
                          variant="contained"
                          style={styles.submitBtn}
                          type="submit"
                          disabled={isSubmitting || ((values.email === user.email) && user.userRole !== values.userRole)}
                        >
                          {ready && t('ACCOUNT_SETTINGS_USERS_CONFIRM')}
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </Fade>
    </Modal>
  );
}

export default UserEditForm;
