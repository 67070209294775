import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Box, Table, TableBody, TableCell, TableRow, Hidden, Card, Grid, TablePagination,
  Tooltip, IconButton, useMediaQuery, TableHead
} from '@mui/material';
import {
  CardTravel as LogsIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { isActionPermitted, permissions } from 'src/utils/helpers';
import LoadingScreen from 'src/shared/screens/LoadingScreen';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import styles from './style';

const SuppliersTable = (props) => {
  const navigate = useNavigate();

  const auth = useAuth();
  const {user} = auth;

  const canEditDimension = isActionPermitted(permissions.dimensionEdit, user.permissions);

  const {
    suppliers, getAllSuppliers, total, fetching, limit, setLimit, tfError, handleEditSupplierOpen, query, page, setPage
  } = props;

  const { ready, t } = useTranslation();

  const maxWidthRows = useMediaQuery('(max-width: 625px)');

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    getAllSuppliers(newPage, limit, query, false);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
    getAllSuppliers(0, event.target.value, query, false);
  };

  const paginationTab = () => (
    <TablePagination
      component="div"
      count={total}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleLimitChange}
      page={page}
      rowsPerPage={limit}
      rowsPerPageOptions={[5, 10, 25, 50]}
      labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
      sx={{ 
        '& .MuiTablePagination-actions': { marginLeft: '0px' },
        '& .MuiTablePagination-select': { marginRight: '0px' }
      }}
    />
  );

  const gotoLogs = (event, code) => {
    event.stopPropagation();
    event.preventDefault();
    navigate(`/dimensions/logs/suppliers/code/${code}`, { state: { code } });
  };

  return (
    <>
      {
        !isActionPermitted(permissions.dimensionRead, user.permissions)
          ? (
            <EmptyText str={'You do not have sufficient permission to view Suppliers.'
          + ' Please contact your company admin to provide you the required permissions.'}
            />
          )
          : suppliers && suppliers.length > 0 ? (
            <Card
              style={styles.cardCont}
            >
              {
                fetching
                  ? <LoadingScreen text={t('LOADING_PAGE_TEXT')} />
                  : (
                    <Box minWidth="100%">
                      <div style={styles.bulkOperations}>
                        <div style={styles.bulkActions}>
                          {paginationTab()}
                        </div>
                      </div>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell style={styles.headerName}>{ready && t('DIMENSIONS_LIST_VIEW_HEADER_NAME')}</TableCell>
                            <TableCell style={styles.headerName}>{ready && t('DIMENSIONS_LIST_VIEW_HEADER_CODE')}</TableCell>
                            <Hidden smDown>
                              <TableCell />
                            </Hidden>
                            <Hidden smDown>
                              <TableCell style={styles.headerName}>{ready && t('DIMENSIONS_LIST_VIEW_HEADER_COUNTRY')}</TableCell>
                            </Hidden>
                            <Hidden smDown>
                              <TableCell />
                            </Hidden>
                            <Hidden smDown>
                              <TableCell />
                            </Hidden>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {suppliers.map((supplier) => (
                            <TableRow
                              hover={canEditDimension}
                              key={supplier.creditorCode}
                              style={canEditDimension ? styles.pointer : styles.tableRow}
                              onClick={() => canEditDimension && handleEditSupplierOpen(supplier.creditorCode)}
                            >
                              <TableCell align="left" style={styles.creditorNameStyle}>
                                {supplier.creditorName}
                              </TableCell>
                              <TableCell style={styles.todayText}>
                                {supplier.creditorCode}
                              </TableCell>
                              <Hidden smDown>
                                <TableCell style={styles.currencyText}>
                                  {supplier.currency}
                                </TableCell>
                              </Hidden>
                              <Hidden smDown>
                                <TableCell style={styles.currencyText}>
                                  {supplier.country?.toUpperCase()}
                                </TableCell>
                              </Hidden>
                              <Hidden smDown>
                                <TableCell style={styles.currencyText}>
                                  {supplier.status}
                                </TableCell>
                              </Hidden>
                              <Hidden smDown>
                                <TableCell width={102} align="center" style={styles.currencyText}>
                                  <Tooltip title={t('SUPPLIER_LIST_VIEW_LOGS_TOOLTIP')}>
                                    <IconButton onClick={(e) => gotoLogs(e, supplier.creditorCode)}>
                                      <LogsIcon
                                        style={styles.logsIcon}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </Hidden>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        style={styles.bottomPagi}
                      >
                        {paginationTab()}
                      </Grid>
                    </Box>
                  )
              }
            </Card>
          ) : (
            <EmptyText str={tfError ? 'TF_CONNECTION_ERROR' : 'NO_SUPPLIERS'} />
          )
      }
    </>
  );
};

// SuppliersTable.propTypes = {
//   suppliers: PropTypes.array,
//   getAllSuppliers: PropTypes.func,
//   setLimit: PropTypes.func,
//   limit: PropTypes.number,
//   total: PropTypes.number,
//   fetching: PropTypes.bool,
//   tfError: PropTypes.bool,
//   handleEditSupplierOpen: PropTypes.func,
//   history: PropTypes.shape({
//     push: PropTypes.func
//   }),
//   query: PropTypes.string,
//   page: PropTypes.number,
//   setPage: PropTypes.func
// };

export default SuppliersTable;
