import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import MuiSwipeableDrawer, { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer'
import {
  List, ListItem, ListItemButton, ListItemIcon,
  SvgIconTypeMap, Box, Divider, Tooltip, Typography,
} from '@mui/material';
import {
  DashboardOutlined,
  InsertDriveFileOutlined,
  BusinessCenterOutlined,
  IntegrationInstructionsOutlined,
  AutoAwesome as NewUIIcon,
  KeyboardReturn as RevertIcon,
  ChevronRight as ExpandMoreIcon,
} from '@mui/icons-material';


import { styled, 
  alpha } from '@mui/material/styles';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import ConfigContext from 'src/contexts/ConfigContext';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import { CustomError } from 'src/types';
import authService from 'src/utils/authService';
import { navDimensionsItems } from 'src/config';
import theme from 'src/theme';
import { useAuth } from 'src/hooks/useAuth';

type SubNavItem = {
  title:string;
  href:string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

type NavItem = {
  title: string;
  href: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  subItems?: SubNavItem[];
};

export const navItems = [
  {
    title: 'DASHBOARD_NAV_DASHBOARD',
    icon: DashboardOutlined,
    href: '/dashboard'
  },
  {
    title: 'DASHBOARD_NAV_INVOICES',
    icon: InsertDriveFileOutlined,
    href: '/documents'
  },
  {
    title: 'DASHBOARD_NAV_DIMENSIONS',
    icon: BusinessCenterOutlined,
    href: '/dimensions',
    subItems:navDimensionsItems
  },
  {
    title: 'DASHBOARD_NAV_APPS',
    icon: IntegrationInstructionsOutlined,
    href: '/integrations',
  }
];

const Drawer = styled(MuiSwipeableDrawer)<SwipeableDrawerProps>({
  overflowX: 'hidden',
  transition: 'width .25s ease-in-out',
  '& ul': {
    listStyle: 'none'
  },
  '& .MuiListItem-gutters': {
    paddingLeft: 4,
    paddingRight: 4
  },
  '& .MuiDrawer-paper': {
    left: 'unset',
    right: 'unset',
    overflowX: 'hidden',
    transition: 'width .25s ease-in-out, box-shadow .25s ease-in-out'
  }
})

type PropTypes = {
  docPreview?:boolean;
  docPreviewExtra?:any
}

export default function SideBar({
    docPreview = false,
    docPreviewExtra = ''
  }:PropTypes) {
  const location = useLocation();
  const { t } = useTranslation();
  const [showIndex, setShowIndex] = useState<null | string>(null);
  const [navHover, setNavHover] = useState<boolean>(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const isTwinfield = user?.interfacePreference === 'twinfield_interface';

  const tempNavItems = navItems.map((e: NavItem) => (isTwinfield?{...e,subItems:e.subItems?.filter((e: SubNavItem) => e.href === '/dimensions/clients')}:e))


  const { API, BRAND_DOMAIN, LOGIN_PANEL_URL, BRAND_NAME } = useContext(ConfigContext);

  const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, href: string, 
    show:null | string = null ) => {
    if (show) {
      if(typeof show === 'string' && show === showIndex){
        setShowIndex(null)
        return;
      }
      setShowIndex(show);
      return;
    }
    setShowIndex(null);
    navigate(href);
  };

  const updateAnnotationFlowPreference = async () => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }
    try {
      const response = await Axios.put(
        `${API.customisations}`,
        { customisation: 'oldAnnotationInterface', enabled: true },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('ANNOTATION_INTERFACE_UPDATE_SUCCESS'), {
          variant: 'success',
        });
        navigate('/');
      } else {
        enqueueSnackbar(t('ANNOTATION_INTERFACE_UPDATE_FAILURE'), {
          variant: 'error',
        });
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'ANNOTATION_INTERFACE_UPDATE_FAILURE', t),
        t
      ), {
        variant: 'error',
      });
    }
  };


  const collapsedNavWidth = '65px'
  const navWidth = '260px';
  const navigationBorderWidth = 1;

  const DesktopDrawerProps = {
    open: true,
    onOpen: () => null,
    onClose: () => null,
    onMouseEnter: () => {
      // Declared flag to resolve first time flicker issue while trying to collapse the menu
      setNavHover(true)
    },
    onMouseLeave: () => {
      setNavHover(false)
    }
  }

  return (
    <Drawer variant="permanent"
     className="hide-scroll"
     {...DesktopDrawerProps}
     PaperProps={{
      sx: {
        backgroundColor: 'background.default',
        ' -ms-overflow-style':'none',
          scrollbarWidth: 'none',
        '&::-webkit-scrollbar':{
          'display':'none',
          'width': '0'
        },
        width:!navHover ? collapsedNavWidth : navWidth,
        ...(navHover ? { boxShadow: 10 } : {}),
        borderRight: theme =>
          navigationBorderWidth ? `${navigationBorderWidth}px solid ${theme.palette.divider}`:'',
      }
    }}
    >
      <Box sx={styles.listContainer}
        className="hide-scroll"
      >
        <List sx={styles.list}>
          <ListItem key="logo" disablePadding sx={styles.logoContainer}>
            <img
              src={`${API.brandFavicon}${BRAND_DOMAIN}`}
              alt=""
              style={{...styles.brandFavicon,objectFit:'contain',objectPosition:'center'}}
            />
            {
              navHover ? <Typography sx={styles.logoPara}>{BRAND_NAME}</Typography> : <></>
            }
          </ListItem>
          {tempNavItems.map(({title, href, icon: Icon, subItems}:NavItem, i) => {
            let open = location.pathname?.indexOf(href) >= 0;
            return (
              <Box key={title}>
                <ListItem disablePadding sx={!subItems?styles.listItem:styles.listItem2}>
                  <ListItemButton
                    onClick={(e) => handleNavigation(e, href, title+i)}
                    sx={
                      subItems && open ? styles.listItemButtonActive2 : subItems && !open ? 
                      styles.listItemButton2 : !subItems && open ? styles.listItemButtonActive : 
                      styles.listItemButton
                    }
                    href={href !== '/dimensions' ? href : ''}
                  >
                    {
                      !subItems ?
                        <Box sx={styles.listBox}>
                          <ListItemIcon sx={styles.iconContainer}>
                            <Icon style={open ? styles.iconActive : styles.icon}/>
                          </ListItemIcon>
                          {navHover
                            ? <Typography sx={open ? styles.navItemTextActive : styles.navItemText}>{t(title)}</Typography>
                            : <></>
                          }
                        </Box>
                      :
                        <Box sx={styles.listBox2}>
                          <ListItemIcon sx={styles.iconContainer}>
                            <Icon style={open ? styles.iconActive : styles.icon}/>
                          </ListItemIcon>
                          {navHover
                            ? <Box sx={styles.expandableTitle}>
                                <Typography sx={open ? styles.navItemTextActive : styles.navItemText}>{t(title)}</Typography>
                                <Box sx={{...styles.showIcon,rotate:showIndex === title+i? '90deg' : '0deg'}}>
                                  <ExpandMoreIcon  />
                                </Box>
                              </Box>
                            : <></>
                          }
                        </Box>
                    }
                  </ListItemButton>
                  {subItems &&
                    <List sx={{
                      ...styles.subList,
                      height:navHover && showIndex === title+i
                        ? `calc((${subItems.length}*44px) + (${subItems.length -1}*3px))`
                        : '0px'
                      }}
                    >
                      {subItems && subItems.length > 0 ? subItems.map(({title, href, icon: Icon}) => {
                        let open = location.pathname?.indexOf(href) >= 0;
                        return(
                          <ListItem key={title} sx={styles.subListItem}>
                            <ListItemButton
                              href={href}
                              onClick={(e)=>handleNavigation(e,href)}
                              sx={open ? styles.activeSubListItemLink : styles.subListItemLink}
                            >
                              <ListItemIcon sx={styles.iconContainer}>
                                <Icon style={open ? styles.iconActive : styles.icon}/>
                              </ListItemIcon>
                              <Typography sx={open ? styles.navItemSubTextActive : styles.navItemSubText}>{t(title)}</Typography>
                            </ListItemButton>
                          </ListItem>
                        )}
                      ) : <></>}
                    </List>
                  }
                </ListItem>
                {i === 0 && (
                  <ListItem disablePadding sx={styles.listItem}>
                    <Box sx={styles.dividerContainer}>
                      <Divider sx={styles.divider}/>
                    </Box>
                  </ListItem>
                )}
              </Box>
          )})}
          <ListItem sx={{ flex: 1 }} />
          {
            docPreview && docPreviewExtra ? docPreviewExtra
            :<></>
          }
          <ListItem disablePadding sx={styles.listItem}>
            <ListItemButton sx={styles.listItemButtonBeta}>
              <Tooltip placement="right-start" title={t('NEW_ANNOTATION_FLOW_INDICATOR_TOOLTIP')}>
                <NewUIIcon sx={styles.betaVersionIcon} />
              </Tooltip>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={styles.listItem}>
            <ListItemButton
              onClick={updateAnnotationFlowPreference}
              sx={styles.listItemButton}
            >
              <Tooltip placement="right-start" title={t('SWITCH_TO_OLD_ANNOTATION_FLOW_TOOLTIP')}>
                <ListItemIcon sx={styles.iconContainer}>
                  <RevertIcon style={styles.icon}/>
                </ListItemIcon>
              </Tooltip>
            </ListItemButton>
          </ListItem>
        </List>
       
      </Box>
    </Drawer>
  );
}

const styles = {
  listContainer: {
    height: '100%'
  },
  list: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  logoContainer: {
    height: 48,
    display: 'flex',
    padding: '0px 8px',
    margin: '0px 0px 8px 0px',
    alignItems:'center',
    gap:'10px'
  },
  logoPara:{
    fontSize:'15px',
    fontWeight:'600',
    color:'rgba(76, 78, 100, 0.87)',
    flex:'1',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    maxWidth:'185px',
  },
  listItem: {
    display: 'flex',
    padding: '0px 8px',
  },
  listItem2: {
    display: 'flex',
    flexDirection:'column',
    padding: '0px',
    width:'100%'
  },
  brandFavicon: {
    width:56,
    height:48,
    maxWidth: 56,
    maxHeight: 48,
    borderRadius: 4,
    padding: '8px, 12px, 8px, 16px',
  },
  listItemButton: {
    minHeight: 48,
    justifyContent: 'flex-start',
    alignItems:'center',
    px: 2.5,
    backgroundColor: 'transparent',
    borderRadius: 1,
    paddingInline:'15px',
    width:'100%',
    minWidth:'100%'
  },
  listItemButton2: {
    flexDirection:'column',
    minHeight: 48,
    width:'100%',
    justifyContent: 'flex-start',
    alignItems:'flex-start',
    px: 2.5,
    backgroundColor: 'transparent',
    borderRadius: 1,
    paddingInline:'0px'
  },
  listItemButtonActive2: {
    display:'flex',
    flexDirection:'column',
    minHeight: 48,
    justifyContent: 'flex-start',
    px: 2.5,
    backgroundColor: alpha(theme.palette.secondary.main, 0.05),
    borderRadius: 1,
    paddingInline:'0px',
    width:'100%'
  },
  subList:{
    display:'flex',
    flexDirection:'column',
    gap:'3px',
    overflow:'hidden',
    transition:'all 0.3s',
    width:'100%',
    padding:'0',
  },
  subListItem:{
    display:'flex',
    width:'100%',
    height:'fit-content',
    whiteSpace:'nowrap',
    padding:'0 !important',
  },
  subListItemLink:{
    display:'flex',
    overflow:'hidden',
    textOverflow:'ellipsis',
    maxWidth:'251px',
    padding:'8px 15px',
    borderRadius:'3px',
    gap:'17px',
  },
  activeSubListItemLink:{
    display:'flex',
    overflow:'hidden',
    textOverflow:'ellipsis',
    maxWidth:'251px',
    padding:'8px 15px',
    borderRadius:'3px',
    gap:'17px',
    color: theme.palette.secondary.main,
    '&:hover':{
      color: theme.palette.secondary.main,
    },
  },
  listBox:{
    display:'flex',
    gap:'10px',
    alignItems:'center'
  },
  listBox2:{
    display:'flex',
    flex:'1',
    gap:'10px',
    alignItems:'center',
    minHeight:'32px',
    width:'100%',
    padding:'0 15px'
  },
  expandableTitle:{
    display:'flex',
    alignItems:'center',
    flex:'1',
    position:'relative'
  },
  showIcon:{
    ml:'auto',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    position:'relative',
    transition:'all 0.3s'
  },
  activeIcon:{
    ml:'auto',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    position:'relative',
  },
  title:{
    transformOrigin:'0 0',
    transition:'all 0.3s',
    display:'inline-block'
  },
  listItemButtonBeta: {
    minHeight: 48,
    justifyContent: 'flex-start',
    px: '15px',
    backgroundColor: 'transparent',
    borderRadius: 1,
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    }
  },
  listItemButtonActive: {
    minHeight: 48,
    justifyContent: 'flex-start',
    px: 2.5,
    backgroundColor: alpha(theme.palette.secondary.main, 0.05),
    borderRadius: 1,
    paddingInline:'15px',
    width:'100%',
    minWidth:'100%'
  },
  iconContainer: {
    minWidth: 0,
    justifyContent: 'center',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.disabled,
    fontSize: 24
  },
  iconActive: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    fontSize: 24
  },
  navItemText: {
    color: theme.palette.text.disabled,
    fontSize: '15px',
    fontWeight: '500'
  },
  navItemTextActive: {
    color: theme.palette.secondary.main,
    fontSize: '15px',
    fontWeight: '500'
  },
  navItemSubText: {
    color: theme.palette.text.disabled,
    fontSize: '14px',
    fontWeight: '500'
  },
  navItemSubTextActive: {
    color: theme.palette.secondary.main,
    fontSize: '14px',
    fontWeight: '500'
  },
  menuActionContainerSelected: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    border: '0px solid #E1E4E8',
    borderTopWidth: 1,
    backgroundColor: '#FFFFFF',
    zIndex: 100000
  },
  menuActionContainer: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
    border: '0px solid #E1E4E8',
    borderTopWidth: 1,
    backgroundColor: '#FFFFFF',
    zIndex: 100000
  },
  subMenuIcon: {
    marginRight: 8
  },
  menuContainer: {
    border: '1px solid #E1E4E8',
    marginLeft: 1,
  },
  menuList: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  dividerContainer: {
    minHeight: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    px: 2.5,
    backgroundColor: 'transparent',
    borderRadius: 1,
    padding: '7px, 20.5px, 7px, 20.5px',
  },
  divider: {
    width: '22px',
    color: alpha('#4C4E64', 0.12),
  },
  betaVersionIcon: {
    color: '#fcd200',
  },
};
