import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useSnackbar } from 'notistack';

import { Button, Grid, Typography, Box, TableContainer, Table, TableHead,
  TableRow, TableCell, TableBody
} from '@mui/material';

import { axiosHeaders } from 'src/utils/helpers';
import styles from './style';

type PropsType = {
  brandDetails: Record<string, string>;
  setLoading: (_load: boolean) => void;
}

function ConnectionSettings(props: PropsType) {
  const { enqueueSnackbar } = useSnackbar();
  const { ready, t } = useTranslation();

  const { brandDetails, setLoading } = props;

  const [domains, setDomains] = useState<Record<string, string | number>[]>([]);

  useEffect(() => {
    if (!brandDetails.domain) {
      return;
    }
    setDomains([
      {
        id: 1,
        domainPrefix: `https://auth.${brandDetails.domain}/`,
        recordType: 'A',
        destinationIP: '34.32.211.156'
      },
      {
        id: 2,
        domainPrefix: `https://login.${brandDetails.domain}/`,
        recordType: 'A',
        destinationIP: '34.32.211.156'
      },
      {
        id: 3,
        domainPrefix: `https://app.${brandDetails.domain}/`,
        recordType: 'A',
        destinationIP: '34.32.211.156'
      },
      {
        id: 4,
        domainPrefix: `https://provider.${brandDetails.domain}/`,
        recordType: 'A',
        destinationIP: '34.32.211.156'
      },
      {
        id: 5,
        domainPrefix: `https://app-api.${brandDetails.domain}/`,
        recordType: 'A',
        destinationIP: '34.32.211.156'
      },
      {
        id: 6,
        domainPrefix: `https://docs.${brandDetails.domain}/`,
        recordType: 'A',
        destinationIP: '34.32.211.156'
      },
    ]);
  }, [brandDetails]);

  const handleCheckConnection = async () => {
    let connSuccess = false;
    for (let i = 0; i < 2; i++) {
      const domain = domains[i];
      try {
        // eslint-disable-next-line no-await-in-loop
        setLoading(true);
        const response = await Axios.get(
          domain.domainPrefix as string,
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );

        if (response.status === 200) {
          setLoading(false);
          connSuccess = true;
          enqueueSnackbar(t('BRAND_DOMAIN_CONNECTION_SUCCESS'), {
            variant: 'success'
          });
          break;
        }
      } catch (error) {
        //
        setLoading(false);
      }
    }
    if (!connSuccess) {
      setLoading(false);
      enqueueSnackbar(t('BRAND_DOMAIN_CONNECTION_FAILURE'), {
        variant: 'error'
      });
    }
  };



  return (
    (brandDetails.status === 'active') 
      ? <Grid sx={styles.container}>
          <Typography sx={styles.titleText}>
            {ready && t('BRAND_CONNECTION_SETTINGS_TITLE')}
          </Typography>
          <Typography sx={styles.description}>
            {ready && t('BRAND_CONNECTION_SETTINGS_DESCR')}
          </Typography>
          <Grid sx={styles.footer}>
            <Button
              variant="outlined"
              sx={styles.checkConnectionButton}
              disabled={!brandDetails.domain}
              onClick={handleCheckConnection}
            >
              {ready && t('BRAND_CONNECTION_SETTINGS_CHECK_CONNECTION')}
            </Button>
          </Grid>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ ...styles.tableHeadCell, borderTopLeftRadius:'15px'}}
                    >
                      {ready && t('BRAND_CONNECTION_SETTINGS_DOMAIN_PREFIX')}
                    </TableCell>
                    <TableCell sx={styles.tableHeadCell}>
                      {ready && t('BRAND_CONNECTION_SETTINGS_RECORD_TYPE')}
                    </TableCell>
                    <TableCell 
                      sx={{
                        ...styles.tableHeadCell,
                        borderImage:'none',
                        borderTopRightRadius:'15px'
                      }}
                    >
                      {ready && t('BRAND_CONNECTION_SETTINGS_DESTINATION_IP')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {domains.map((domain)=> (
                    <TableRow key={domain.id}>
                    <TableCell sx={styles.tableBodyCell}>
                      {domain.domainPrefix}
                    </TableCell>
                    <TableCell sx={styles.tableBodyCell}>
                      {domain.recordType}
                    </TableCell>
                    <TableCell sx={{...styles.tableBodyCell, borderImage:'none'}}>
                      {domain.destinationIP}
                    </TableCell>
                    </TableRow>
                  ))}                  
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      : <></>
  );
}

export default ConnectionSettings;
