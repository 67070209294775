const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '10px',
    margin: '0px',
    '& .MuiDialog-paper': {
      margin: '0px',
      borderRadius: '10px',
    },
    '& .MuiPaper-root': {
      height: '100%',
      maxHeight: '100%',
    }
  },
  container: {
    height: '100%',
    width: '500px',
    display: 'flex',
    flexDirection: 'column'
  },
  modalHeader: {
    height: '72px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottom: '1px solid #4C4E641F',
    padding: '16px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeBtn: {
    padding: '0px',
  },
  closeIcon: {
    height: '20px',
    width: '20px',
    color: '#6D788D',
  },
  closeIconText: {
    height: '14px',
    width: '14px',
    color: '#6D788D',
  },
  headerTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#4C4E64',
    lineHeight: '32px',
    letter: '-0.25px',
  },
  modalContent: {
    flex: 1,
    padding: '20px 24px',
  },
  filterRow: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '36px',
  },
  filterLabel: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '32px',
    letterSpacing: '-0.25px',
    color: '#4C4E64DE'
  },
  select: {
    width: '70%',
    '&.MuiInputBase-root': {
      height: '32px',
      borderRadius: '4px',
      border: '1px solid #4C4E6438',
      padding: '0px 8px !important',
      backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-select': {
      padding: '4px !important',
      borderWidth: '0px',
    },
    '& .MuiSelect-icon': {
      position: 'unset',
      color: '#4C4E648A'
    },
  },
  menuItem: {
    color: '#4C4E64',
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '20px',
  },
  ownerMenuContainer: {
    '& .MuiPaper-root': {
      borderRadius: '4px',
      boxShadow: '0px 3px 14px 2px #4C4E641F',
      border: '1px solid #4C4E6438',
    }
  },
  filterMenu: {
    '&.MuiList-root': {
      maxHeight: '300px',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      whiteSpace: 'nowrap',
    },
    '&.MuiList-root::-webkit-scrollbar': {
      height: '6px',
      width: '6px'
    },
    '&.MuiList-root::-webkit-scrollbar-thumb': {
      backgroundColor: '#4C4E6499',
      borderRadius: '10px',
    },
    '&.MuiList-root::-webkit-scrollbar-track': {
      backgroundColor: '#fefefe',
    },
    '&.MuiList-root::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#4C4E645F',
    },
  },
  filterMenuContainer: {
    boxShadow: '0px 2px 4px 0px rgb(0 0 0 / 8%)',
    border: '1px solid #DDDDDD',
  },
  filterMenuItem: {
    minHeight: '32px !important',
    fontSize: '13px',
    color: '#192A3E',
    overflow: 'hidden',
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  supplierFilterActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '8px',
  },
  supplierSearch: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    border: '0px solid rgba(194, 207, 224, 1)',
    borderRadius: 4,
    margin: '0px',
    padding: '8px',
    minWidth: 100
  },
  supplierFilterActionText: {
    color: '#3E8AFF',
    fontSize: '12px',
    marginLeft: '10px',
    cursor: 'pointer',
    fontWeight: '500',
    lineHeight: '20px'
  },
  supplierLabel: {
    color: '#4C4E64 !important',
    fontSize: '13px !important'
  },
  supplierLabelRoot: {
    marginRight: '0px',
  },
  searchInput: {
    padding: '4px !important',
    '&::placeholder': {
      color: '#90A0B7',
      fontSize: '13px',
      lineHeight: '21px'
    }
  },
  searchBarInput: {
    padding: '8px !important',
  },
  supplierFilterContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  checkboxRoot: {
    padding: '2px',
    marginRight: '6px'
  },
  checkbox: {
    color: '#D5D5D5',
    padding: 0,
  },
  filterBtn: {
    width: '70%',
    fontSize: '13px',
    color: '#4C4E64',
    textTransform: 'none',
    height: '32px',
    borderRadius: '4px',
    border: '1px solid #4C4E6438',
    padding: '0px 12px !important',
    backgroundColor: '#FFFFFF',
  },
  filterBtnContent: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  sortIcon: {
    color: '#4C4E648A',
    height: '24px',
    width: '24px',
  },
  modalFooter: {
    height: '70px',
    width: '100%',
    boxShadow: '0px -4px 8px -4px #4C4E646B',
    padding: '16px',
  },
  cancelBtn: {
    fontSize: '14px',
    lineHeight: '21px',
    color: '#4C4E64',
    textTransform: 'none',
    minWidth: '108px',
    height: '38px',
    border: '1px #4C4E6433 solid',
    '&:hover': {
      border: '1px #4C4E6433 solid',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 8px -4px #4C4E646B',
    }
  },
  submitBtn: {
    minWidth: '108px',
    height: '38px',
    textTransform: 'none',
    marginLeft: '10px',
    backgroundColor: '#3E8AFF',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#3E8AFF',
      boxShadow: '0px 4px 8px -4px #4C4E646B',
    }
  },
}

export default styles;