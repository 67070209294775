import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import { Box, Button, Typography } from '@mui/material';

import CommonModal from 'src/shared/components/CommonModal/CommonModal';
import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import { appendContactSupport, axiosHeaders, getMaskedCurrencyText } from 'src/utils/helpers';
import styles from './style';

interface PropTypes {
  open: boolean;
  handleClose: () => void;
}

const DocLimitReachedModal = ({
  open,
  handleClose,
}: PropTypes) => {
  const { t, ready } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { API, BRAND_NAME } = useContext(ConfigContext);

  const handleBuyExtraInvoices = async () => {
    try {
      const response = await Axios.get(API.buyExtraInvoicesPackage, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success) {
        handleClose();
        enqueueSnackbar(
          t('SUBSCRIPTION_BUY_EXTRA_PACKAGE_SUCCESS', { ecLimit: user?.ecLimit || 25 }),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
      }
    } catch (err) {
      handleClose();
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, t('SUBSCRIPTION_BUY_EXTRA_PACKAGE_FAILED'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const ModalFooter = () => (
    <Box sx={styles.modalFooter}>
      {
        (user?.subscription.toLowerCase() === 'free' || user?.subscription.toLowerCase() === 'starter') && (
          <Button
            variant="contained"
            sx={styles.submitBtn}
            onClick={() => navigate('/subscription-plan')}
          >
            {ready && t('SUBSCRIPTION_CHOOSE_PLAN')}
          </Button>
        )
      }
      {
        (user?.subscription.toLowerCase() === 'business' || (user?.subscription.toLowerCase() === 'custom' && !user?.affiliate)) && (
          <Button
            variant="contained"
            sx={styles.submitBtn}
            onClick={() => navigate('/subscription-plan?open_modal=contact_us')}
          >
            {ready && t('SUBSCRIPTION_CONTACT_US')}
          </Button>
        )
      }
      {
        (user?.subscription.toLowerCase() === 'starter'
          || user?.subscription.toLowerCase() === 'business'
          || (user?.subscription.toLowerCase() === 'custom' && !user?.affiliate)
        ) && (
          <Button
            variant="contained"
            sx={styles.submitBtn}
            onClick={handleBuyExtraInvoices}
          >
            {ready && t('SUBSCRIPTION_BUY_EXTRA_PACKAGE')}
          </Button>
        )
      }
      {
        user?.subscription.toLowerCase() === 'custom' && user?.affiliate && (
          <Button
            variant="contained"
            sx={styles.submitBtn}
            onClick={() => navigate('/subscription-plan')}
          >
            {ready && t('SUBSCRIPTION_ADD_CARD')}
          </Button>
        )
      }
    </Box>
  );

  return (
    <CommonModal
      open={open}
      title={t('SUBSCRIPTION_INVOICES_LIMIT_REACH')}
      cancelText={''}
      submitText={''}
      handleClose={handleClose}
      onSubmit={() => {}}
      footer={<ModalFooter />}
    >
      {
        user?.subscription.toLowerCase() === 'free' && (
          <Typography sx={styles.caption}>
            {ready && t('FREE_PLAN_LIMIT_REACH_MESSAGE')}
          </Typography>
        )
      }
      {
        user?.subscription.toLowerCase() === 'starter' && (
          <>
            <Typography sx={styles.caption}>
              {ready && t('OTHER_PLAN_LIMIT_REACH_MESSAGE')}
            </Typography>
            <ul style={styles.ulStyle}>
              <li style={styles.listText}>
                {ready && t('LIMIT_REACT_STARTER_LINE1')}
              </li>
              <li style={styles.listText}>
                {ready && t('LIMIT_REACT_STARTER_LINE2')}
              </li>
            </ul>
            <Typography sx={styles.caption}>
              {ready && t('LIMIT_REACT_LINE3')}
            </Typography>
          </>
        )
      }
      {
        user?.subscription.toLowerCase() === 'business' && (
          <Box sx={styles.captionBody}>
            <Typography sx={styles.caption}>
              {ready && t('OTHER_PLAN_LIMIT_REACH_MESSAGE')}
            </Typography>
            <ul style={styles.ulStyle}>
              <li style={styles.listText}>
                {ready && t('LIMIT_REACT_BUSINESS_LINE1')}
              </li>
              <li style={styles.listText}>
                {ready && t('LIMIT_REACT_BUSINESS_LINE2')}
              </li>
            </ul>
            <Typography sx={styles.caption}>
              {ready && t('LIMIT_REACT_LINE3')}
            </Typography>
          </Box>
        )
      }
      {
        user?.subscription.toLowerCase() === 'custom' && user?.affiliate ? (
          <>
            <Typography sx={styles.caption}>
              {ready && t('AFFILIATE_LIMIT_REACH_MESSAGE', { brand: BRAND_NAME })}
            </Typography>
          </>
        ) : (
          <>
            <Typography sx={styles.caption}>
              {ready && t('OTHER_PLAN_LIMIT_REACH_MESSAGE')}
            </Typography>
            <ul style={styles.ulStyle}>
              <li style={styles.listText}>
                {ready && t('LIMIT_REACT_CUSTOM_LINE1')}
              </li>
              <li style={styles.listText}>
                {ready && t(
                  'LIMIT_REACT_CUSTOM_LINE2',
                  {
                    var: user?.ecLimit || 25,
                    var1: getMaskedCurrencyText((user?.ecCharge ? user?.ecCharge : 0)?.toString()) || '12,50'
                  }
                )}
              </li>
            </ul>
            <Typography sx={styles.caption}>
              {ready && t('LIMIT_REACT_LINE3')}
            </Typography>
          </>
        )
      }
    </CommonModal>
  );
};

export default DocLimitReachedModal;
