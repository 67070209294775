import theme from "src/theme";

const styles = {
  root: {},
  form: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  paper: {
    maxWidth: 600,
    width: '90%',
    maxHeight: '96dvh',
    backgroundColor: theme.palette.background.default,
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #4C4E641F',
    padding: '16px 24px',
    marginBottom: '16px'
  },
  headerTitle: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#4C4E64',
    lineHeight: '36px',
    marginRight: 24
  },
  closeIcon: {
    width: '20px',
    height: '20px',
    color: '#6D788D',
  },
  modalContent: {
    padding: '0 24px'
  },
  modalFooter: {
    width: '100%',
    boxShadow: '0px -4px 8px -4px #4C4E646B',
    padding: '16px 24px',
    marginTop: '24px'
  },
  inputLabel: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 500,
  },
  snackAction: {
    color: 'white'
  },
  userForm: {
    width: '100%'
  },
  fieldContainer: {
    marginTop: '15px'
  },
  submitBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 500,
    textTransform: 'none',
    background: '#3E8AFF',
    color:'#fff',
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    borderRadius: '4px',
    '&:hover':{
      background: '#3E8AFF',
      color:'#fff',
      boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    }
  },
  closeBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 500,
    textTransform: 'none',
    margin:'0',
    borderRadius:'4px',
    color:'#4C4E64',
    border:'1px solid #4C4E6433',
  },
  rowItem: {
    marginTop: "15px",
  },
  rowItemComp: {
    width: '100%'
  },
  btnGroup: {
    width: '100%',
  },
  groupBtn: {
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    width: '100%',
    justifyContent: 'space-between',
  },
  optionTitle: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: 16,
    marginBottom: 10,
    textTransform: 'capitalize',
  },
  optionDescription: {
    color: theme.palette.text.primary,
    fontSize: 14,
    width: '100%',
    maxWidth: 500
  },
  menuItem: {
    color: theme.palette.text.primary,
    textTransform: 'none',
    width: '100%',
    justifyContent: 'space-between',
    whiteSpace: 'break-spaces',
    maxWidth: 500
  },
  fieldContainerRow: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  topFieldContainer: {
    marginTop: '10px'
  },
  checkBox: {
    color: '#A0AEC1',
    paddingBottom: 0,
    paddingTop: 0
  },
  helpIconBtn: {
    padding: '0px 0px',
    color: '#c8c8c8',
    fontSize: '12px'
  },
  checkboxContainer: {
    marginRight: '8px',
  },
  checkBoxLabel: {
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  interfaceIcon: {
    fontSize: '14px'
  },
  formControl: {
    width: '100%'
  },
  selectStyle: {
    color: '#4C4E64',
    fontSize: '13px',
    lineHeight: '20px',
  },
  scPaper: {
    padding: '12px',
    borderRadius: '15px',
    borderColor: '#d3d3d3',
    borderWidth: '2.3px',
    width: '400px',
    boxShadow: '0px 0px 0px #d3d3d3'
  },
  scTitle: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '8px'
  },
  scSubTitle: {
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  list: {
    marginBottom: '0px',
    color: theme.palette.text.primary,
  },
  listItem: {
    margin: '0px',
    padding: '0px'
  },
  listItemText: {
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  bulletPoint: {
    fontSize: '14px',
    color: theme.palette.text.primary,
    marginRight: '10px',
    marginBottom: '23px',
  },
  inputField:{
    fontSize:'14px',
    color:'#4C4E64',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099!important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `2px solid #C2CFE099!important`,
      }
    },
  },
  selectField:{
    color: '#4C4E64 !important',
    fontSize: '13px',
    width:'100%',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '&.MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '&.MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  },
  selectAutoField:{
    color: '#4C4E64 !important',
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '& .MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  }
};

export default styles;
