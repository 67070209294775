import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import { Grid, IconButton, Table, TableBody, TableHead, Box, TableRow, TableCell, Tooltip, Typography } from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Repeat as ResendIcon,
  Clear as RevokeIcon,
  PersonRemove as RemoveIcon,
  PersonAdd as RestoreIcon,
} from '@mui/icons-material';

import { userEditOptions } from 'src/config';
import { axiosDeleteHeaders, axiosHeaders, getLocalisedErrorString, isActionPermitted, isUserManageOptionValid, permissions } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import { CustomError, UserDataType } from 'src/types';
import EditUserModal from 'src/settings/components/Users/EditUserModal/EditUserModal';
import styles from './style';

type DataType = Record<string, string>;
type PropType= {
  data: DataType[];
  getAllUsers: () => Promise<void>;
  setUserInviteLinkOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUserInviteLink: React.Dispatch<React.SetStateAction<string>>;
}

const UsersTable = (props: PropType) => {
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { user, setUser } = useAuth();
  const { API, IS_BRAND_PROCYS } = useConfig();
  const { data, getAllUsers, setUserInviteLink, setUserInviteLinkOpen } = props;

  const [editUser, setEditUser] = useState<DataType | null>(null);
  const [editUserOpen, setEditUserOpen] = useState<boolean>(false);

  const getStatusColors = (status: string) => {
    switch (status) {
      case 'Active':
        return {
          color: '#27AE60',
          backgroundColor: '#27AE601F',
        };
      case 'Invited':
        return {
          color: '#3E8AFF',
          backgroundColor: '#3E8AFF1F'
        };
      case 'Revoked': case 'Removed':
        return {
          color: '#919191',
          backgroundColor: '#9191911F'
        };
    }
  };

  const isNotRemoveCurrentUser = (option: string, u: DataType) => !(u.email === user?.email && option === 'remove');

  const isEditUserPermitted = isActionPermitted(permissions.userLock, user?.permissions)
  && isActionPermitted(permissions.userPermissionManage, user?.permissions)
  && isActionPermitted(permissions.userRoleManage, user?.permissions);

  const onEditUser = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, u: DataType) => {
    event.stopPropagation();
    event.preventDefault();

    setEditUser(u);
    setEditUserOpen(true);
  };

  const onResendInvitation = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, body: DataType) => {
    event.stopPropagation();
    event.preventDefault();

    if (((user?.usersCount || 1) + 1) > (user?.maxUsers || 3)) {
      enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_ADD_LIMIT'), {
        variant: 'error',
        style: { maxWidth: 400 }
      });
      return;
    }

    try {
      const response = await Axios.post(
        `${API.userProfile}`,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (!IS_BRAND_PROCYS) {
          setUserInviteLink(response.data.data.link);
          setUserInviteLinkOpen(true);
        } else {
          enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_ADD_SUCCESS', { email: body.email }), {
            variant: 'success',
            style: { maxWidth: 400 }
          });
          getAllUsers();
        }
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(getLocalisedErrorString(error.response?.data?.i18n, t), {
        variant: 'error',
        style: { maxWidth: 400 }
      });
    }
  };

  const revokeUserInvitation = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, u: DataType) => {
    event.stopPropagation();
    event.preventDefault();

    try {
      const response = await Axios.post(
        `${API.revokeUser}`,
        { email: u.email },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.countChanged) {
          if (user && setUser) {
            const updatedUser: UserDataType = {
              ...user,
              usersCount: (user?.usersCount || 2) - 1
            };
            setUser(updatedUser);
          }
        }
        enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_REVOKE_SUCCESS', { email: u.email }), {
          variant: 'success',
          style: { maxWidth: 400 }
        });
        getAllUsers();
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(getLocalisedErrorString(error.response?.data?.i18n, t), {
        variant: 'error',
        style: { maxWidth: 400 }
      });
    }
  };

  const removeUser = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, u: DataType) => {
    event.stopPropagation();
    event.preventDefault();

    try {
      const response = await Axios.post(
        `${API.removeUser}`,
        { email: u.email },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.countChanged) {
          if (user && setUser) {
            const updatedUser: UserDataType = {
              ...user,
              usersCount: (user?.usersCount || 2) - 1
            };
            setUser(updatedUser);
          }
        }
        enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_REMOVE_SUCCESS', { email: u.email }), {
          variant: 'success',
          style: { maxWidth: 400 }
        });
        getAllUsers();
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(getLocalisedErrorString(error.response?.data?.i18n, t), {
        variant: 'error',
        style: { maxWidth: 400 }
      });
    }
  };

  const restoreUser = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, u: DataType) => {
    event.stopPropagation();
    event.preventDefault();

    if (((user?.usersCount || 1) + 1) > (user?.maxUsers || 3)) {
      enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_ADD_LIMIT'), {
        variant: 'error',
        style: { maxWidth: 400 }
      });
      return;
    }

    try {
      const response = await Axios.post(
        `${API.restoreUser}`,
        { email: u.email },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.countChanged) {
          if (user && setUser) {
            const updatedUser: UserDataType = {
              ...user,
              usersCount: (user?.usersCount || 1) + 1
            };
            setUser(updatedUser);
          }
        }
        enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_RESTORE_SUCCESS', { email: u.email }), {
          variant: 'success',
          style: { maxWidth: 400 }
        });
        getAllUsers();
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(getLocalisedErrorString(error.response?.data?.i18n, t), {
        variant: 'error',
        style: { maxWidth: 400 }
      });
    }
  };

  const deleteUser = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, u: DataType) => {
    event.stopPropagation();
    event.preventDefault();

    try {
      const response = await Axios.delete(
        `${API.deleteUser}`,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), { email: u.email })
      );
      if (response.data.success) {
        enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_DELETE_SUCCESS', { email: u.email }), {
          variant: 'success',
          style: { maxWidth: 400 }
        });
        getAllUsers();
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(getLocalisedErrorString(error.response?.data?.i18n, t), {
        variant: 'error',
        style: { maxWidth: 400 }
      });
    }
  };

  const handleUserManageMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, u: DataType, option: string) => {
    switch (option) {
      case 'change':
        onEditUser(event, u);
        break;

      case 'resend':
        onResendInvitation(event, u);
        break;

      case 'revoke':
        revokeUserInvitation(event, u);
        break;

      case 'remove':
        removeUser(event, u);
        break;

      case 'restore':
        restoreUser(event, u);
        break;

      case 'delete':
        deleteUser(event, u);
        break;

      default:
        break;
    }
  };

  const renderActionIcon = (action: string, u: DataType) => {
    switch (action) {
      case 'change':
        return (
          <Tooltip key={action} title={t(!isEditUserPermitted ? 'ACCOUNT_SETTINGS_USERS_EDIT_TOOLTIP_PERMISSION' : 'ACCOUNT_SETTINGS_USERS_EDIT_USER')}>
            <span>
              <IconButton onClick={(e) => handleUserManageMenuClick(e, u, action)} sx={styles.actionBtn} disabled={!isEditUserPermitted}>
                <EditIcon sx={styles.actionIcon} />
              </IconButton>
            </span>
          </Tooltip>
        );
      case 'resend':
        return (
          <Tooltip key={action} title={t(!isEditUserPermitted ? 'ACCOUNT_SETTINGS_USERS_EDIT_TOOLTIP_PERMISSION' : 'ACCOUNT_SETTINGS_USERS_EDIT_RESEND')}>
            <span>
              <IconButton onClick={(e) => handleUserManageMenuClick(e, u, action)} sx={styles.actionBtn} disabled={!isEditUserPermitted}>
                <ResendIcon sx={styles.actionIcon} />
              </IconButton>
            </span>
          </Tooltip>
        );
      case 'revoke':
        return (
          <Tooltip key={action} title={t(!isEditUserPermitted ? 'ACCOUNT_SETTINGS_USERS_EDIT_TOOLTIP_PERMISSION' : 'ACCOUNT_SETTINGS_USERS_EDIT_REVOKE')}>
            <span>
              <IconButton onClick={(e) => handleUserManageMenuClick(e, u, action)} sx={styles.actionBtn} disabled={!isEditUserPermitted}>
                <RevokeIcon sx={styles.actionIcon} />
              </IconButton>
            </span>
          </Tooltip>
        );
      case 'remove':
        return (
          <Tooltip key={action} title={t(!isEditUserPermitted ? 'ACCOUNT_SETTINGS_USERS_EDIT_TOOLTIP_PERMISSION' : 'ACCOUNT_SETTINGS_USERS_EDIT_REMOVE_USER')}>
            <span>
              <IconButton onClick={(e) => handleUserManageMenuClick(e, u, action)} sx={styles.actionBtn} disabled={!isEditUserPermitted}>
                <RemoveIcon sx={styles.actionIcon} />
              </IconButton>
            </span>
          </Tooltip>
        );
      case 'restore':
        return (
          <Tooltip key={action} title={t(!isEditUserPermitted ? 'ACCOUNT_SETTINGS_USERS_EDIT_TOOLTIP_PERMISSION' : 'ACCOUNT_SETTINGS_USERS_EDIT_RESTORE_USER')}>
            <span>
              <IconButton onClick={(e) => handleUserManageMenuClick(e, u, action)} sx={styles.actionBtn} disabled={!isEditUserPermitted}>
                <RestoreIcon sx={styles.actionIcon} />
              </IconButton>
            </span>
          </Tooltip>
        );
      case 'delete':
        return (
          <Tooltip key={action} title={t(!isEditUserPermitted ? 'ACCOUNT_SETTINGS_USERS_EDIT_TOOLTIP_PERMISSION' : 'ACCOUNT_SETTINGS_USERS_EDIT_DELETE_USER')}>
            <span>
              <IconButton onClick={(e) => handleUserManageMenuClick(e, u, action)} sx={styles.actionBtn} disabled={!isEditUserPermitted}>
                <DeleteIcon sx={styles.actionIcon} />
              </IconButton>
            </span>
          </Tooltip>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Grid sx={styles.tableWrapper} className="xy-scroll">
        <Table sx={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ ...styles.tableHeadCell, borderTopLeftRadius: '8px' }} className="edit-invoice-line-header">
                {ready && t('ACCOUNT_SETTINGS_USERS_USER')}
              </TableCell>
              <TableCell sx={styles.tableHeadCell} className="edit-invoice-line-header">
                {ready && t('ACCOUNT_SETTINGS_USERS_EMAIL')}
              </TableCell>
              <TableCell sx={styles.tableHeadCell} className="edit-invoice-line-header">
                {ready && t('ACCOUNT_SETTINGS_USERS_ROLE')}
              </TableCell>
              <TableCell sx={styles.tableHeadCell} className="edit-invoice-line-header">
                {ready && t('ACCOUNT_SETTINGS_USERS_STATUS')}
              </TableCell>
              <TableCell sx={{ ...styles.tableHeadCell, borderTopRightRadius: '8px', maxWidth: '108px' }}>
                {ready && t('DOCUMENT_LIST_TABLE_HEADER_ACTIONS')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.map((eData:DataType,index:number) =>
                <TableRow key={index}>
                  <TableCell sx={styles.tableBodyCell} className="edit-invoice-line-body">
                    {eData.name}
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell} className="edit-invoice-line-body">
                    {eData.email}
                  </TableCell>
                  <TableCell sx={{ ...styles.tableBodyCell, padding: '12px 16px 4px 16px !important' }} className="edit-invoice-line-body">
                    <Typography sx={styles.highlightLabel}>
                      {eData.userRole}
                    </Typography>
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell} className="edit-invoice-line-body">
                    <Typography sx={{ ...styles.highlightLabel, ...getStatusColors(eData.status.toString()) }}>
                      {eData.status}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ ...styles.tableBodyCell, maxWidth: '108px' }}>
                    <Box sx={styles.actionIconContainer}>
                      {userEditOptions.map((option) => {
                        if (isUserManageOptionValid(option, eData.status.toString().toLowerCase()) && isNotRemoveCurrentUser(option, eData)) {
                          return renderActionIcon(option, eData);
                        }
                      })}
                    </Box>
                  </TableCell>
                </TableRow> 
              )
            }
          </TableBody>
        </Table>
      </Grid>
      <EditUserModal
        open={editUserOpen}
        editUser={editUser}
        handleClose={() => setEditUserOpen(false)}
        onGetAllUsers={getAllUsers}
      />
    </>
  )
}


export default UsersTable