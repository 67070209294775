import CDCDocumentEdit from 'src/cdc-document-edit/CDCDocumentContainer';
import CDCDocumentEditNew from 'src/cdc-document-edit-new/CDCDocumentEditContainer';
import { useAuth } from 'src/hooks/useAuth';

const CDCDocumentEditView = () => {
  const { user } = useAuth();
  const isOldAnnotationInterface = user?.customisations.includes('oldAnnotationInterface');

  return (
    <>
      {
        isOldAnnotationInterface
          ? <CDCDocumentEdit />
          : <CDCDocumentEditNew />
      }
    </>
  );
}

export default CDCDocumentEditView;
