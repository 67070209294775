import { alpha } from "@mui/material";
import theme from "src/theme";

const styles = {
  root: {
    boxShadow:'none',
    width:'90%',
    maxWidth:'550px',
    minWidth: '316px',
  },
  header: {
    fontSize: '16px!important',
    fontWeight: 500,
    color: '#4C4E64',
    padding: '16px 20px 9px',
    lineHeight: '16.5px'
  },
  container: {
    marginTop: '-12px'
  },
  formContainer: {
    padding: '16px 20px',
    border:'none',
    boxShadow:'none'
  },
  formInput: {
    paddingTop: '13px!important',
    paddingBottom: '12px!important',
    '& .MuiOutlinedInput-input': {
      fontSize: '13px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    }
  },
  formInputLast: {
    paddingTop: '13px!important',
    paddingBottom: '0px!important',
    '& .MuiOutlinedInput-input': {
      fontSize: '13px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    }
  },
  saveBtnContainer: {
    paddingBottom: '20px',
    paddingTop: '0px'
  },
  saveBtn: {
    width: 177,
    height: 41,
    boxShadow: `0px 4px 10px ${alpha(theme.palette.secondary.main, 0.25)}`,
    textTransform: 'none',
    backgroundColor: '#3E8AFF',
    fontSize:'14px',
    color:'#fff',
    '&:hover':{
      backgroundColor:'#3E8AFF',
      color:'#fff',
      boxShadow: `0px 4px 10px ${alpha(theme.palette.secondary.main, 0.25)}`,
    }
  },
  iconWrapper: {
    position: "absolute",
    right: 15.47,
    width: 18.33,
    height: 12.5,
  },
  visibleIcon: {
    color: ''
  },
  strengthBarWrapper: {
    marginTop:'10px',
    width: '100%',
    paddingTop: '6px!important'
  },
  inputProps: {
    textAlign: 'center',
    height: 42,
    color: theme.palette.text.primary
  }
};

export default styles;
