import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';

import { Box, Grid, InputAdornment, SvgIcon, TextField, Tooltip } from '@mui/material';
import { Search as SearchIcon} from '@mui/icons-material';

import { applyUserFilters, applyPagination, axiosHeaders } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import TopDisplay from 'src/settings/components/Users/TopDisplay';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import TablePagination from 'src/shared/components/TablePagination';
import UsersTable from 'src/settings/components/Users/UsersTable/UsersTable';
import authService from 'src/utils/authService';
import AddUserModal from 'src/settings/components/Users/AddUserModal/AddUserModal';
import InviteLinkPopUp from 'src/settings/components/Users/InviteLinkPopUp/InviteLinkPopUp';

const Users = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { API, LOGIN_PANEL_URL } = useConfig();

  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<Record<string, string | number | boolean>[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const filters = {
    status: null
  };

  const [userModalOpen, setUserModalOpen] = useState(false);

  const filteredUsers = applyUserFilters(users, query, filters);
  const paginatedUsers = applyPagination(filteredUsers, page, limit);

  const [userInviteLinkOpen, setUserInviteLinkOpen] = useState(false);
  const [userInviteLink, setUserInviteLink] = useState('');

  const getAllUsers = async () => {
    setLoading(true);
    if (user && user.companyID) {
      try {
        // NOTE: Not going with pagination in V1
        const url = `${API.getUsersByCompanies}${encodeURIComponent(user.companyID)}`;
        const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
        if (response.data.success) {
          if (response.data.data.length > 0) {
            setUsers(response.data.data.map((u: Record<string, any>) => ({ ...u, name: `${u.firstname} ${u.lastname}` })));
            setTotal(response.data.total);
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement | HTMLAreaElement | any>) => {
    e.persist();
    setQuery(e.target.value);
  };

  const onLimitChange = (limit: number) => {
    setPage(0);
    setLimit(limit);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onAddUserClick = () => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setUserModalOpen(true);
  };

  const addUserCallback = async () => {
    await getAllUsers();
    if (!paginatedUsers || paginatedUsers.length === 0) {
      navigate('/settings?feedback-first-user=success');
    } else {
      navigate('/settings');
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <TopDisplay onAddUser={onAddUserClick} />
        <Grid sx={styles.searchBar} className="search-bar">
          <Tooltip title={t('DASHBOARD_INVOICES_OWNERS_SEARCH_PLACEHOLDER')}>
            <TextField
              sx={{...styles.queryField, '&.MuiTextFieldRoot': styles.searchBarInput }}
              onChange={(e)=>handleQueryChange(e)}
              placeholder={t('DASHBOARD_INVOICES_OWNERS_SEARCH_PLACEHOLDER')}
              value={query}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                      sx={{ fill: '' }}
                    >
                      <SearchIcon sx={{ color: '' }} />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
        </Grid>
        {loading
          ? <LoadingIndicator />
          : (
            users.length > 0
            ? <>
                <Box sx={styles.tableWrapper} className="x-scroll">
                  <UsersTable
                    data={paginatedUsers}
                    getAllUsers={getAllUsers}
                    setUserInviteLink={setUserInviteLink}
                    setUserInviteLinkOpen={setUserInviteLinkOpen}
                  />
                </Box>
                <TablePagination 
                  total={total}
                  limit={limit}
                  page={page}
                  onLimitChange={onLimitChange}
                  onPageChange={onPageChange}
                />
              </>
            : <EmptyText str="ACCOUNT_SETTINGS_USERS_NO_USERS" />
        )}
      </Box>
      <AddUserModal
        open={userModalOpen}
        handleClose={() => setUserModalOpen(false)}
        addUserCallback={addUserCallback}
        setUserInviteLink={setUserInviteLink}
        setUserInviteLinkOpen={setUserInviteLinkOpen}
      />
      <InviteLinkPopUp
        isOpen={userInviteLinkOpen}
        handleClose={() => setUserInviteLinkOpen(false)}
        link={userInviteLink}
      />
    </>
  );
};

export default Users;

const styles = {
  container:{
    width:'100%',
    padding:'20px',
    height:'100%',
    display:'flex',
    flexDirection:'column',
  },
  searchBar: {
    flex: 1,
    margin:'20px 0',
  },
  queryField: {
    width: '100%',
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: 0,
    fontSize:'13px',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099!important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `2px solid #C2CFE099!important`,
      }
    },
  },
  searchBarInput: {
    height: '36px',
    color: '#4C4E64',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099!important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `2px solid #C2CFE099!important`,
      }
    },
  },
  tableWrapper:{
    width: '100%',
    backgroundColor: '#fff',
    overflow: 'auto',
  },
};