import {
  useEffect, useState, useRef, useContext
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import {
  Button, ClickAwayListener, Grid, Grow, InputAdornment, MenuList, MenuItem, SvgIcon, TextField,
  Tooltip, Typography, IconButton, Paper, Popper, useMediaQuery,
} from '@mui/material';
import {
  CardTravel as LogsIcon,
  AddCircle as AddCircle,
  Search as SearchIcon,
  SystemUpdateAlt as ImportIcon,
  OpenInBrowser as ExportIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import {
  appendContactSupport, axiosHeaders, getLocalisedErrorString, isActionPermitted, permissions
} from 'src/utils/helpers';
import Page from 'src/shared/screens/Page';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';

import SuppliersTable from 'src/dimensions/suppliers/components/SuppliersTable/SuppliersTable';
import CreateSupplierModal from 'src/dimensions/suppliers/components/CreateSupplierModal/CreateSupplierModal';
import EditSupplierModal from 'src/dimensions/suppliers/components/EditSupplierModal/EditSupplierModal';
import DimensionImportModal from 'src/dimensions/components/DimensionImportModal/DimensionImportModal';
import DimensionExportPopper from 'src/dimensions/components/DimensionExportPopper/DimensionExportPopper';
import { fetchGlas } from 'src/dimensions/glas/actions';
import { fetchVatCodes } from 'src/dimensions/vatcodes/actions';
import { fetchCostCenters } from 'src/dimensions/costcenters/actions';
import styles from './style';
import theme from "src/theme";
import GstockImportModal from '../components/GstockImportModal/GstockImportModal';

function SuppliersList() {
  const { API, BRAND_NAME } = useContext(ConfigContext);

  const navigate = useNavigate();
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const auth = useAuth();
  const {user} = auth;

  const isGstock = user.integrations.includes('gstock');

  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [pageNo, setPageNo] = useState(0);

  const [tfError, setTFError] = useState(false);
  const [addSupplierOpen, setAddSupplierOpen] = useState(false);

  const [editSupplierOpen, setEditSupplierOpen] = useState(false);
  const [selectedSupplierId, setSelectedSupplierId] = useState(-1);

  const [exportOptionsOpen, setExportOptionsOpen] = useState(false);
  const exportBtnRef = useRef(null);
  
  const [gstockImportOptionsOpen, setGstockImportOptionsOpen] = useState(false);
  const gstockImportOptionsRef = useRef(null);

  const [gstockImportModalOpen, setGstockImportModalOpen] = useState(false);
  const [gstockImportUrl, setGstockImportUrl] = useState(API.gstockSuppliersImport);

  const [importModalOpen, setImportModalOpen] = useState(false);

  const maxWidth = useMediaQuery('(min-width: 830px)');
  const maxWidth2 = useMediaQuery('(min-width: 530px)');

  const getAllGLAccounts = async () => {
    dispatch(fetchGlas(user.companyID));
  };

  const getAllVatGroups = () => {
    dispatch(fetchVatCodes(user.companyID));
  };

  const getAllCostCenters = async () => {
    dispatch(fetchCostCenters(user.companyID));
  };

  const getAllSuppliers = async (page = 0, limit = 10, search = query, loadFullScreen = true) => {
    if (loadFullScreen) {
      setLoading(true);
    }
    setFetching(true);
    setTFError(false);
    try {
      const response = await Axios.get(
        `${API.findSuppliers}/${user.companyID}?page=${page + 1}&limit=${limit}&search=${search}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data.length > 0) {
          setSuppliers(response.data.data);
          setTotal(response.data.total);
        } else {
          setSuppliers([]);
          setTotal(0);
        }
      }
      setLoading(false);
      setFetching(false);
    } catch (error) {
      setTFError(true);
      setLoading(false);
      setFetching(false);
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionRead, user.permissions)) {
      getAllSuppliers();
      getAllGLAccounts();
      getAllVatGroups();
      getAllCostCenters();
    }
  }, []);

  const onSupplierCreated = () => {
    getAllSuppliers();
    setAddSupplierOpen(false);
  };

  const handleEditSupplierOpen = (id) => {
    setSelectedSupplierId(id);
    setEditSupplierOpen(true);
  };

  const handleEditSupplierClose = () => {
    setSelectedSupplierId(-1);
    setEditSupplierOpen(false);
  };

  const handleQueryChange = (e) => {
    e.persist();
    setQuery(e.target.value);
    setPageNo(0);
    getAllSuppliers(0, pageLimit, e.target.value, false);
  };

  const exportOptionsClose = (event) => {
    if (exportBtnRef.current && exportBtnRef.current.contains(event.target)) {
      return;
    }
    setExportOptionsOpen(false);
  };

  const handleImportModalClose = () => {
    setImportModalOpen(false);
  };

  const handleDimensionExport = async (format) => {
    try {
      const response = await Axios.get(
        `${API.dimensions}suppliers/export/${format}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      const fileName = `${user.company} Suppliers.${format}`;
      const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, fileName);
      setExportOptionsOpen(false);
    } catch (error) {
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'DIMENSION_EXPORT_FAIL', t),
        t
      ), {
        variant: 'error',
      });
    }
  };

  const handleGstockImportOptionsClose = (event) => {
    if (gstockImportOptionsRef.current && gstockImportOptionsRef.current.contains(event?.target)) {
      return;
    }
    setGstockImportOptionsOpen(false);
  };

  const handleImportClick = () => {
    if (isGstock) {
      setGstockImportOptionsOpen(true);
    } else {
      setImportModalOpen(true);
    }
  };

  const handleGstockImportOptionClick = (url) => {
    setGstockImportOptionsOpen(false);
    setGstockImportUrl(url);
    setGstockImportModalOpen(true);
  };

  const searchBar = (
    <Grid style={styles.searchBar} className="search-bar">
      <Tooltip title={t('DASHBOARD_SEARCH_SUPPLIER_SEARCH_TOOLTIP')}>
        <TextField
          style={styles.queryField}
          onChange={handleQueryChange}
          placeholder={ready && t('DASHBOARD_SEARCH_SUPPLIER_SEARCH')}
          value={query}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                  style={{ fill: theme.palette.icon.gray }}
                >
                  <SearchIcon style={{ color: theme.palette.icon.gray }} />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': styles.searchBarInput,
          }}
          disabled={tfError}
        />
      </Tooltip>
    </Grid>
  );

  const logsList = (
    <Tooltip title={t('SUPPLIER_LIST_VIEW_LOGS_TOOLTIP')}>
      <IconButton
        style={styles.logsIconContainer}
        onClick={() => navigate(`/dimensions/logs/suppliers/company/${user.companyID}`)}
      >
        <LogsIcon
          style={styles.logsIcon}
        />
      </IconButton>
    </Tooltip>
  );

  const titleText = (
    <Typography style={styles.title}>
      {ready && t('DASHBOARD_SUPPLIERS')}
    </Typography>
  );

  const addButton = (
    <Tooltip
      title={isActionPermitted(permissions.dimensionAdd, user.permissions)
        ? ready && t('SUPPLIERS_ADD_A_NEW_SUPPLIER')
        : 'You do not have permissions to add Suppliers'}
    >
      <span>
        <Button
          type="submit"
          variant="contained"
          style={styles.addBtn}
          onClick={() => setAddSupplierOpen(true)}
          disabled={tfError || !isActionPermitted(permissions.dimensionAdd, user.permissions)}
        >
          <SvgIcon
            fontSize="small"
            style={styles.actionIcon}
          >
            <AddCircle />
          </SvgIcon>
          {ready && t('SUPPLIERS_ADD_NEW_SUPPLIER')}
        </Button>
      </span>
    </Tooltip>
  );

  const importBtn = (
    <>
      <Tooltip
        title={!isActionPermitted(permissions.dimensionEdit, user.permissions)
          ? ready && t('DIMENSIONS_NO_PERMISSION_TO_MANAGE_TOOLTIP')
          : ''}
      >
        <span>
          <Button
            ref={gstockImportOptionsRef}
            variant="outlined"
            style={styles.importExportBtn}
            onClick={() => handleImportClick()}
            disabled={!isActionPermitted(permissions.dimensionEdit, user.permissions)}
          >
            <SvgIcon
              fontSize="small"
              style={styles.importExportIcon}
              color={!isActionPermitted(permissions.dimensionEdit, user.permissions) ? 'rgba(0, 0, 0, 0.26)' : '#546e7a'}
            >
              <ImportIcon />
            </SvgIcon>
            {ready && t('DIMENSION_IMPORT_BTN')}
          </Button>
        </span>
      </Tooltip>
      <Popper
        open={gstockImportOptionsOpen}
        anchorEl={gstockImportOptionsRef.current}
        role={undefined}
        transition
        style={{ zIndex: 111111 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleGstockImportOptionsClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    key={'suppliers'}
                    onClick={() => handleGstockImportOptionClick(API.gstockSuppliersImport)}
                    disabled={!isActionPermitted(permissions.dimensionEdit, user.permissions)}
                    style={styles.menuItem}
                  >
                    {ready && t('GSTOCK_IMPORT_SUPPLIERS')}
                  </MenuItem>
                  <MenuItem
                    key={'products'}
                    onClick={() => handleGstockImportOptionClick(API.gstockProductsImport)}
                    disabled={!isActionPermitted(permissions.dimensionEdit, user.permissions)}
                    style={styles.menuItem}
                  >
                    {ready && t('GSTOCK_IMPORT_PRODUCTS')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );

  const exportBtn = (
    <Tooltip
      title={!isActionPermitted(permissions.dimensionEdit, user.permissions)
        ? ready && t('DIMENSIONS_NO_PERMISSION_TO_MANAGE_TOOLTIP')
        : suppliers.length === 0
          ? ready && t('SUPPLIER_NO_DATA_TO_EXPORT')
          : ''}
    >
      <span>
        <Button
          ref={exportBtnRef}
          variant="outlined"
          style={styles.importExportBtn}
          onClick={() => { setExportOptionsOpen(true); }}
          disabled={!isActionPermitted(permissions.dimensionEdit, user.permissions) || suppliers.length === 0}
        >
          <SvgIcon
            fontSize="small"
            style={styles.importExportIcon}
            color={!isActionPermitted(permissions.dimensionEdit, user.permissions) ? 'rgba(0, 0, 0, 0.26)' : '#546e7a'}
          >
            <ExportIcon />
          </SvgIcon>
          {ready && t('DIMENSION_EXPORT_BTN')}
        </Button>
        <DimensionExportPopper
          exportOptionsOpen={exportOptionsOpen}
          exportBtnRef={exportBtnRef}
          exportOptionsClose={exportOptionsClose}
          t={t}
          onOptionClick={handleDimensionExport}
        />
      </span>
    </Tooltip>
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Page
      style={styles.root}
      title={ready && `${t('SUPPLIERS_LIST_TITLE')} | ${BRAND_NAME}`}
    >
      <Grid style={{...styles.header, ...styles.headerMargin}}>
        {
          maxWidth ? (
            <Grid style={styles.header}>
              {titleText}
              {searchBar}
              {importBtn}
              {exportBtn}
              {logsList}
              {addButton}
            </Grid>
          ) : maxWidth2 ? (
            <Grid style={styles.headerColumn}>
              {titleText}
              <Grid style={styles.actionRow}>
                {searchBar}
                {logsList}
              </Grid>
              <Grid style={styles.importExportRow}>
                {importBtn}
                {exportBtn}
                {addButton}
              </Grid>
            </Grid>
          ) : (
            <Grid style={styles.headerColumn}>
              {titleText}
              <Grid style={styles.actionRow}>
                {searchBar}
                {logsList}
              </Grid>
              <Grid style={styles.importExportRow}>
                {importBtn}
                {exportBtn}
              </Grid>
              {addButton}
            </Grid>
          )
        }
      </Grid>
      <SuppliersTable
        suppliers={suppliers}
        getAllSuppliers={getAllSuppliers}
        total={total}
        fetching={fetching}
        limit={pageLimit}
        setLimit={(l) => setPageLimit(l)}
        tfError={tfError}
        handleEditSupplierOpen={handleEditSupplierOpen}
        query={query}
        page={pageNo}
        setPage={setPageNo}
      />
      <CreateSupplierModal
        addSupplierOpen={addSupplierOpen}
        handleClose={() => setAddSupplierOpen(false)}
        onSupplierCreated={onSupplierCreated}
      />
      <EditSupplierModal
        editSupplierOpen={editSupplierOpen}
        setEditSupplierOpen={setEditSupplierOpen}
        supplierId={selectedSupplierId}
        handleEditSupplierClose={handleEditSupplierClose}
        getAllSuppliers={getAllSuppliers}
        company={user.companyID}
      />
      <DimensionImportModal
        dimensionImportModalOpen={importModalOpen}
        handleClose={handleImportModalClose}
        dimension="supplier"
        fetchDimenstions={getAllSuppliers}
      />
      <GstockImportModal
        importModalOpen={gstockImportModalOpen}
        onModalClose={() => setGstockImportModalOpen(false)}
        url={gstockImportUrl}
        getAllSuppliers={getAllSuppliers}
      />
    </Page>
  );
}

export default SuppliersList;
