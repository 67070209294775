const styles = {
  root: {
    boxShadow:'none',
    width:'50%',
    minWidth:'550px'
  },
  header: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#4C4E64',
    padding: '30px 20px 20px',
    lineHeight: '16.5px',
  },
  formContainer: {
    padding: '20px 20px 0 20px'
  },
  formInput: {
    paddingTop: '13px!important',
    paddingBottom: '5px!important',
  },
  selectStyle: {
    color: '#4C4E64 !important',
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '& .MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  },
  updateBtnContainer: {
    display:'flex',
    paddingBottom: '28px',
    paddingTop: '9px'
  },
  updateBtn: {
    width: '99px',
    height: '41px',
    textTransform: 'none',
    backgroundColor: '#3E8AFF',
    '&:hover':{
      color:'#fff',
      backgroundColor: '#3E8AFF',
    }
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      fontSize: '13px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      },
    },
  },
  textFieldDisabled: {
    '& .MuiOutlinedInput-input': {
      fontSize: '13px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '&.Mui-disabled': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C2CFE099 !important',
        }
      },
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
    },
  },
};

export default styles;
