import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';

import {
  Grid, Button, Container, Typography
} from '@mui/material';

import Page from 'src/shared/screens/Page';
import ConfigContext from 'src/contexts/ConfigContext';
import { axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import theme from 'src/theme';

const TwinfieldTokenCallback = () => {
  const { API, BRAND_NAME } = useContext(ConfigContext);
  const { t, ready } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const verifyCode = async () => {
    const queryVal = new URLSearchParams(window.location.search);
    const code = queryVal.get('code');
    const state = queryVal.get('state');

    try {
      const response = await Axios.get(
        `${API.twinfieldTokenCallback}?code=${code}&state=${state}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setErrorMessage('');
        setError(false);
        setLoading(false);
        setTimeout(() => {
          navigate('/integrations');
        }, [2000]);
      } else {
        setErrorMessage(t('QUICKBOOKS_VERIFYING_CODE_ERROR'));
        setError(true);
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.data) {
        setErrorMessage(getLocalisedErrorString(err.response.data.i18n, t));
      } else {
        setErrorMessage(t('QUICKBOOKS_VERIFYING_CODE_ERROR'));
      }
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ready) {
      verifyCode();
    }
  }, [ready]);

  const onBackToProcys = () => {
    navigate('/integrations');
  };

  return (
    <Page
      style={styles.root}
      title={ready && `${t('QUICKBOOKS_OAUTH2_CALLBACK_PAGE_TITLE')} | ${BRAND_NAME}`}
    >
      <Container style={styles.container}>
        {loading && (
          <Typography style={styles.loadingText}>{ready && t('QUICKBOOKS_VERIFYING_CODE')}</Typography>
        )}
        {!loading && !error && (
          <Typography style={styles.loadingText}>{ready && t('QUICKBOOKS_VERIFYING_CODE_SUCCESS')}</Typography>
        )}
        {!loading && error && (
          <Grid style={styles.errorContainer}>
            <Typography style={styles.loadingText}>{ready && errorMessage}</Typography>
            <Button
              variant="outlined"
              style={styles.backToProcysBtn}
              onClick={onBackToProcys}
              disabled={loading}
            >
              {ready && t('QUICKBOOKS_VERIFYING_CODE_BACK_TO_PROCYS')}
            </Button>
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default TwinfieldTokenCallback;

const styles = {
  root: {
    backgroundColor: 'white',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
  },
  loadingText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#334D6E',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backToProcysBtn: {
    fontSize: 13,
    color: '#334D6E',
    textTransform: 'none',
    marginLeft: 8,
    padding: '3px 8px',
    margin: 8
  }
};
