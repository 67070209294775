const styles = {
  contentText: {
    marginTop: '8px',
    color: '#4C4E64DE',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '20px'
  },
  list: {
    marginBottom: '0px',
    color: '#4C4E64',
  },
  listItem: {
    margin: '0px',
    padding: '0px'
  },
  listItemText: {
    color: '#4C4E64',
    fontSize: '13px',
  },
  bulletPoint: {
    fontSize: '6px',
    color: '#4C4E64',
    marginRight: '10px',
  },
  divider: {
    borderBottom: '1px solid #4C4E641F',
    margin: '12px -24px',
  },
  fieldDetailsContainer: {},
  fieldDetailsTitle: {
    color: '#4C4E64DE',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '32px'
  },
  fieldDetailsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '12px'
  },
  textFieldRoot: {
    width: '226px',
  },
  inputRoot: {
    height: '36px',
    width: '226px',
    backgroundColor: '#FFFFFF',
    '& fieldset': {
      height: '40px',
      border: '2px solid #C2CFE099 !important',
      padding: '0px !important',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      height: '40px',
      border: '2px solid #C2CFE099',
    }
  },
  input: {
    textAlign: 'left' as const,
    fontSize: '14px !important',
    lineHeight: '36px !important',
    color: '#4C4E64 !important',
    padding: '0 12px !important',
    backgroundColor: '#FFFFFF',
    height: '36px',
  },
  selectStyle: {
    height: '36px',
    width: '226px',
    border: '2px solid #C2CFE099',
    borderRadius: '4px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  selectItem: {
    fontSize: '14px !important',
    fontWeight: '500',
    color: '#4C4E64',
  },
  fieldNameError: {
    margin: '0px',
  }
};

export default styles;
