import { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik } from 'formik';

import {
  Modal, Fade, Backdrop, Grid, IconButton, Typography, TextField, ButtonGroup, Button,
  Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Box, FormHelperText
} from '@mui/material';
import { Close as CloseIcon, ArrowDropDown } from '@mui/icons-material';

import NewUserInviteLinkPopup from '../NewUserInviteLinkPopup/NewUserInviteLinkPopup';
import ConfigContext from 'src/contexts/ConfigContext';
import { axiosHeaders, getLocalisedErrorString, isBranded } from 'src/utils/helpers';
import { ERRORS } from 'src/config';
import styles from './style';

function UserCreateForm(props) {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    addUserOpen,
    handleClose,
    onGetAllUsers,
    user,
    updateUser,
  } = props;

  const { API, BRAND_NAME } = useContext(ConfigContext);
  const roleOptionsRef = useRef(null);
  const [roleOptionsOpen, setRoleOptionsOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState({
    role: '',
    status: '',
    description: ''
  });
  const [userRoles, setUserRoles] = useState([]);
  const [isUserExist, setIsUserExist] = useState(false);

  const [userInviteLinkOpen, setUserInviteLinkOpen] = useState(false);
  const [userInviteLink, setUserInviteLink] = useState('');
  const [newUserValues, setNewUserValues] = useState({});

  const emailRegexp = /^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/;

  const closeUserInviteLinkPopup = () => {
    setUserInviteLinkOpen(false);
    handleClose();
    const updatedUser = {
      ...user,
      usersCount: user.usersCount + 1,
    };
    updateUser(updatedUser);
    onGetAllUsers(newUserValues);
  };

  const handleEmailBlur = async (event, setErrors, handleChange, setTouched, errors, touched) => {
    setTouched({ ...touched, email: true });
    const { value } = event.target;
    if (value) {
      if (!emailRegexp.test(value)) {
        setErrors({
          ...errors,
          email: t('INVALID_EMAIL_ADDRESS_HELPER_TEXT')
        });
        return;
      }
      try {
        const response = await Axios.post(
          `${API.companyAndUserDetails}`,
          { email: value, isOnlyUser: true },
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        if (response.data.success && response.data.data) {
          setIsUserExist(response.data.data.detailsRegistered);
          handleChange({ target: { name: 'firstname', value: response.data.data.firstName } });
          handleChange({ target: { name: 'lastname', value: response.data.data.lastName } });

          if (response.data.data.detailsRegistered) {
            setTimeout(() => {
              setErrors({
                email: t('EMAIL_ADDRESS_ALREADY_EXISTS_HELPER_TEXT'),
              });
            }, 200);
          }
        } else {
          handleChange({ target: { name: 'firstname', value: '' } });
          handleChange({ target: { name: 'lastname', value: '' } });
        }
      } catch (error) {
        setIsUserExist(false);
        handleChange({ target: { name: 'firstname', value: '' } });
        handleChange({ target: { name: 'lastname', value: '' } });
      }
    } else {
      setIsUserExist(false);
      setErrors({
        ...errors,
        email: t('EMAIL_ADDRESS_REQUIRED_HELPER_TEXT')
      });
      handleChange({ target: { name: 'firstname', value: '' } });
      handleChange({ target: { name: 'lastname', value: '' } });
    }
  };

  const fetchActiveUserRoles = async () => {
    try {
      const response = await Axios.get(`${API.getUserRoles}?status=active`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.data) {
        setUserRoles(response.data.data);
        setSelectedRole(response.data.data.find((o) => o.role === 'user'));
      }
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    fetchActiveUserRoles();
  }, []);

  const handleValidatorOptionsToggle = () => {
    setRoleOptionsOpen((prevOpen) => !prevOpen);
  };

  const handleValidatorOptionsClose = (event) => {
    if (roleOptionsRef.current && roleOptionsRef.current.contains(event.target)) {
      return;
    }
    setRoleOptionsOpen(false);
  };

  const handleValidatorClick = (validator) => {
    setSelectedRole(validator);
    setRoleOptionsOpen(false);
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      style={styles.modal}
      open={addUserOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={addUserOpen}>
        <div style={styles.halfPaper}>
          <Grid style={styles.modalHeader}>
            <Typography style={styles.headerTitle}>
              {ready && t('ACCOUNT_SETTINGS_USERS_ADD_NEW_USER')}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
          <>
            <Formik
              initialValues={{
                email: '',
                firstname: '',
                lastname: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email(t('INVALID_EMAIL_ADDRESS_HELPER_TEXT')).max(255).required(t('EMAIL_ADDRESS_REQUIRED_HELPER_TEXT')),
                firstname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_FIRSTNAME_REQ')),
                lastname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_LASTNAME_REQ')),
              })}
              onSubmit={async (values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
              }) => {
                try {
                  if (user.maxUsers <= user.usersCount) {
                    setStatus({ success: false });
                    setErrors({
                      submit: ERRORS['User creation count exceeded for your company. Please upgrade your subscription']
                        || t('ACCOUNT_SETTINGS_USERS_ADD_LIMIT')
                    });
                    setSubmitting(false);
                  }

                  const body = {
                    email: values.email,
                    firstName: values.firstname,
                    lastName: values.lastname,
                    company: user && user.companyID ? user.companyID : '',
                    role: selectedRole.role
                  };
                  const response = await Axios.post(
                    `${API.userProfile}`,
                    body,
                    axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
                  );
                  if (response.data.success) {
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                    setNewUserValues(values);

                    if (isBranded(BRAND_NAME)) {
                      setUserInviteLink(response.data.data.link);
                      setUserInviteLinkOpen(true);
                    } else {
                      enqueueSnackbar(t('ACCOUNT_SETTINGS_USERS_ADD_SUCCESS', { email: values.email }), {
                        variant: 'success',
                        style: { maxWidth: 400 }
                      });
                      handleClose();
                      const updatedUser = {
                        ...user,
                        usersCount: user.usersCount + 1,
                      };
                      updateUser(updatedUser);
                      onGetAllUsers(values);
                    }
                  }
                } catch (error) {
                  setStatus({ success: false });
                  setErrors({
                    submit: getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t)
                  });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setErrors,
                setTouched,
                touched,
                values
              }) => (
                <form
                  style={styles.root}
                  onSubmit={handleSubmit}
                >
                  <Grid style={styles.userForm}>
                    <Grid
                      style={styles.fieldContainer}
                    >
                      <Typography variant="caption" style={styles.inputLabel}>
                        {`${ready && t('ACCOUNT_SETTINGS_USERS_EMAIL')} *`}
                      </Typography>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        name="email"
                        onBlur={(e) => handleEmailBlur(e, setErrors, handleChange, setTouched, errors, touched)}
                        onChange={(e) => handleChange({ target: { name: 'email', value: e.target.value.toLowerCase() } })}
                        required
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid container spacing={1} style={styles.rowItem}>
                      <Grid item xs={12} sm={6} sx={styles.rowItemComp}>
                        <Typography variant="caption" style={styles.inputLabel}>
                          {`${ready && t('ACCOUNT_SETTINGS_USERS_FIRSTNAME')} *`}
                        </Typography>
                        <TextField
                          error={Boolean(touched.firstname && errors.firstname)}
                          fullWidth
                          helperText={touched.firstname && errors.firstname}
                          name="firstname"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          value={values.firstname}
                          variant="outlined"
                          disabled={isUserExist}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} sx={styles.rowItemComp}>
                        <Typography variant="caption" style={styles.inputLabel}>
                          {`${ready && t('ACCOUNT_SETTINGS_USERS_LASTNAME')} *`}
                        </Typography>
                        <TextField
                          error={Boolean(touched.lastname && errors.lastname)}
                          fullWidth
                          helperText={touched.lastname && errors.lastname}
                          name="lastname"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          value={values.lastname}
                          variant="outlined"
                          disabled={isUserExist}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={styles.fieldContainer}>
                      <Typography variant="caption" style={styles.inputLabel}>
                        {`${ready && t('ACCOUNT_SETTINGS_USERS_ROLE')} *`}
                      </Typography>
                      <ButtonGroup variant="outlined" ref={roleOptionsRef} aria-label="split button" style={styles.btnGroup}>
                        <Button style={styles.groupBtn} onClick={handleValidatorOptionsToggle}>
                          {selectedRole?.role}
                          <ArrowDropDown />
                        </Button>
                      </ButtonGroup>
                    </Grid>
                    <Popper
                      open={!isUserExist && roleOptionsOpen}
                      anchorEl={roleOptionsRef.current}
                      role={undefined}
                      disablePortal={isUserExist}
                      transition
                      style={styles.popper}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleValidatorOptionsClose}>
                              <MenuList id="split-button-menu" autoFocusItem>
                                {userRoles.map((option) => (
                                  <MenuItem
                                    key={option.role}
                                    selected={option.role === selectedRole.role}
                                    onClick={() => handleValidatorClick(option)}
                                    style={styles.menuItem}
                                    disabled={isUserExist}
                                  >
                                    <Grid>
                                      <Typography style={styles.optionTitle}>{option.role}</Typography>
                                      <Typography style={styles.optionDescription}>{option.description}</Typography>
                                    </Grid>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>
                          {errors.submit}
                        </FormHelperText>
                      </Box>
                    )}
                    <Box mt={2} display="flex" justifyContent="flex-end">
                      <Button
                        variant="text"
                        style={styles.closeBtn}
                        disabled={isSubmitting}
                        onClick={handleClose}
                      >
                        {ready && t('ACCOUNT_SETTINGS_USERS_CANCEL')}
                      </Button>
                      <Button
                        variant="contained"
                        style={styles.submitBtn}
                        type="submit"
                        disabled={isSubmitting || isUserExist}
                      >
                        {ready && t('ACCOUNT_SETTINGS_USERS_ADD_USER')}
                      </Button>
                    </Box>
                  </Grid>
                </form>
              )}
            </Formik>
            <NewUserInviteLinkPopup
              isOpen={userInviteLinkOpen}
              handleClose={closeUserInviteLinkPopup}
              link={userInviteLink}
            />
          </>
        </div>
      </Fade>
    </Modal>
  );
}

export default UserCreateForm;
