import React, { useContext, useEffect, useState } from 'react';
// import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Formik, FormikErrors } from 'formik';

import { CircularProgress, Fade, Grid, IconButton, Modal, Typography, Backdrop } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { supplierData } from 'src/config';
import { axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import authService from 'src/utils/authService';
import { fetchCostCenters } from 'src/dimensions/costcenters/actions';
import ConfigContext from 'src/contexts/ConfigContext';
import VatCodeTwinfield from 'src/dimensions-new/vatcodes/components/VatCodeTwinField';
import VatCodeStandard from 'src/dimensions-new/vatcodes/components/VatCodeStandard';
import CostCenterModal from 'src/dimensions-new/costcenters/components/CostCenterModal';
import { CustomError, FieldType, RootState, VatGroupType } from 'src/types';
import { UnknownAction } from 'redux';
import theme from 'src/theme';

type PropsType = {
  vatCodeModalOpen: boolean;
  selectedVatCode: VatGroupType | null;
  handleClose: () => void;
  onSuccess: (_str: string) => void;
  getAllVatCodes: (_page?: number, _limit?: number, _search?: string, _loadFullScreen?: boolean) => void;
  isDisabled: boolean;
}

function VatCodeModal({
  vatCodeModalOpen,
  selectedVatCode,
  handleClose,
  onSuccess,
  getAllVatCodes,
  isDisabled
}: PropsType) {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  
  const auth = useAuth();
  const {user} = auth;

  const costCenters = useSelector((state:RootState) => [...supplierData.defaultCostCenters, ...state.costcenter.costCenters]);
  const glaccounts = useSelector((state:RootState) => [...supplierData.defaultGLA, ...state.gla.glAccounts]);

  const [ccModalOpen, setCCModalOpen] = useState<boolean>(false);
  const [selectedCostCenterCode, setSelectedCostCenterCode] = useState<string>('');
  const [isEditCostCenter, setIsEditCostCenter] = useState<boolean>(false);
  const [newlyAddingProp, setNewlyAddingProp] = useState<string>('');
  const [newlyAddedVal, setNewlyAddedVal] = useState<string>('');

  const hasError = (fields:FieldType[], errors:Record<string, string | boolean>) => {
    for (let i = 0; i < fields.length; i++) {
      if (errors[fields[i].key]) {
        // Returning true until Twinfield integration
        return false;
      }
    }
    return false;
  };

  const handleChangeAutocomplete = (e:React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | 
    React.SyntheticEvent<Element, Event>, prop:string, values:Record<string,string | boolean>, handleChange:Function) => {
    if (values && values.code) {
      handleChange({ target: { name: prop, value: values.code } });
      return;
    }

    handleChange(e, prop);
  };

  const getLabel = (entities:Record<string,string>[], code:string) => {
    for (let i = 0; i < entities?.length; i++) {
      if (code === entities[i].code) {
        let namePart = '';
        if (entities[i].name) {
          namePart = ` | ${entities[i].name}`;
        }
        return `${entities[i].code}${namePart}`;
      }
    }

    return code;
  };

  const getCostCenters = () => {
    dispatch(fetchCostCenters(user?.companyID) as unknown as UnknownAction);
  };

  const onCCModalClose = () => {
    setCCModalOpen(false);
    setSelectedCostCenterCode('');
  };

  const handleEditCostCenter = (costCenter: string) => {
    if (costCenter !== supplierData.defaultCostCenters[0].code) {
      setSelectedCostCenterCode(costCenter);
      setCCModalOpen(true);
      setIsEditCostCenter(true);
    }
  };

  const handleAddCostCenter = (prop:string) => {
    setSelectedCostCenterCode('');
    setCCModalOpen(true);
    setNewlyAddingProp(prop);
    setIsEditCostCenter(false);
  };

  const onCCCreated = (val:string) => {
    setNewlyAddedVal(val);
  };

  useEffect(()=>{
    getCostCenters()
  },[])


  return (
    <>
      <Modal
        style={styles.modal}
        open={vatCodeModalOpen}
        onClose={()=>handleClose()}
      >
        <Fade in={vatCodeModalOpen}>
          <div style={user?.interfacePreference === 'twinfield_interface' ? styles.paper : styles.halfPaper}>
            <Grid style={styles.modalHeader}>
              <Typography sx={styles.headerTitle}>
                {selectedVatCode?.name ? `${t('VAT_CODE')} - ${selectedVatCode ? selectedVatCode.name : ''} (${selectedVatCode?.code})` : t('VAT_CODE_ADD_NEW')}
              </Typography>
              <IconButton onClick={()=>handleClose()}>
                <CloseIcon style={styles.closeIcon} />
              </IconButton>
            </Grid>
            <Formik
              initialValues={{
                code: selectedVatCode?.code || '',
                name: selectedVatCode?.name || '',
                type: selectedVatCode?.type || '',
                shortName: selectedVatCode?.shortName || '',
                invoiceText: selectedVatCode?.invoiceText || '',
                ratePercentage: selectedVatCode?.ratePercentage || '',
                rateName: selectedVatCode?.rateName || '',
                rateShortName: selectedVatCode?.rateShortName || '',
                ruleAccount: selectedVatCode?.ruleAccount || supplierData.defaultGLA[0].code,
                rulePercentage: selectedVatCode?.rulePercentage || '',
                ruleCostCenter: selectedVatCode?.ruleCostCenter || supplierData.defaultCostCenters[0].code,
                ruleVatGroup: selectedVatCode?.ruleVatGroup || '',
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string().max(255)
                  .when('name', {
                    is: () => user?.interfacePreference === 'twinfield_interface',
                    then: () => Yup.string().max(255).matches(/^\\S*$/, 'VAT_CODE_CODE_SPACES_NOT_ALLOWED').required('VAT_CODE_CODE_REQUIRED')
                  } as any),
                name: Yup.string()
                  .max(80, 'VAT_CODE_NAME_MAX_VALIDATION')
                  .required('VAT_CODE_NAME_REQUIRED'),
                  
                type: Yup.string().max(255)
                  .when('name', {
                    is: () => user?.interfacePreference === 'twinfield_interface',
                    then: () => Yup.string().max(255).required('VAT_CODE_TYPE_REQUIRED'),
                    otherwise: () => Yup.string(),
                  } as any),
                ratePercentage: Yup.string()
                  .max(255)
                  .required('VAT_CODE_RATE_PERCENTAGE_REQUIRED')
                  .matches(/^100(\.0{1,2})?$|^(\d{1,2}(\.\d{1,2})?)$/, 'VAT_CODE_RATE_PERCENTAGE_LIMITED'),
                rateName: Yup.string().max(255)
                  .when('name', {
                    is: () => user?.interfacePreference === 'twinfield_interface',
                    then: () => Yup.string().max(255).required('VAT_CODE_RATE_NAME_REQUIRED')
                  } as any),
                rateShortName: Yup.string().max(255)
                  .when('name', {
                    is: () => user?.interfacePreference === 'twinfield_interface',
                    then: () => Yup.string().max(255),
                    otherwise:() => Yup.string(),
                  } as any),
                ruleAccount: Yup.string().max(255)
                  .when('name', {
                    is: () => user?.interfacePreference === 'twinfield_interface',
                    then: () => Yup.string().max(255).required('VAT_CODE_RULE_ACCOUNT_REQUIRED'),
                    otherwise:() => Yup.string(),
                  } as any),
                rulePercentage: Yup.string()
                .max(255)
                // .matches(/^(?:\\d{1,2}(?:\\.\\d{1,2})?|100(?:\\.0?0)?)$/, 'VAT_CODE_RULE_PERCENTAGE_LIMITED')
                .when('name', {
                  is: () => user?.interfacePreference === 'twinfield_interface',
                  then: () => Yup.string()
                    .max(255)
                    .matches(/^(?:\\d{1,2}(?:\\.\\d{1,2})?|100(?:\\.0?0)?)$/, 'VAT_CODE_RULE_PERCENTAGE_LIMITED')
                    .required('VAT_CODE_RULE_PERCENTAGE_REQUIRED')
                } as any),
              })}
              onSubmit={async (values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
              }) => {
                try {
                  if (!authService.validateToken()) {
                    enqueueSnackbar(ready && t('VAT_CODE_LOGIN_EXPIRED'), {
                      variant: 'error',
                    });
                    setTimeout(() => {
                      authService.logout(LOGIN_PANEL_URL);
                    }, 2000);
                    return;
                  }
                  setLoading(true);
                  const body = {
                    code: values.code,
                    name: values.name,
                    type: values.type,
                    shortName: values.shortName,
                    invoiceText: values.invoiceText,
                    ratePercentage: values.ratePercentage,
                    rateName: values.rateName,
                    rateShortName: values.rateShortName,
                    ruleAccount: values.ruleAccount,
                    rulePercentage: values.rulePercentage,
                    ruleCostCenter: values.ruleCostCenter,
                    ruleVatGroup: values.ruleVatGroup,
                  };

                  const url = `${API.vatCodesByCompany}/${encodeURIComponent(user?.companyID as string)}`;
                  let response = null;
                  let snackText = '';

                  if (selectedVatCode && selectedVatCode.name) {
                    response = await Axios.put(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
                    snackText = 'VAT_CODES_UPDATE_SUCCESS';
                  } else {
                    response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
                    snackText = 'VAT_CODES_ADD_SUCCESS';
                  }
                  if (response?.data?.success) {
                    setLoading(false);
                    getAllVatCodes();
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar(ready && t(snackText), {
                      variant: 'success',
                      style: { maxWidth: 400 }
                    });
                    handleClose();
                    
                    if (onSuccess) {
                      onSuccess(response.data.id);
                    }
                  }
                } catch (e) {
                  const error = e as CustomError;
                  setLoading(false);
                  setStatus({ success: false });
                  setSubmitting(false);
                  if (error && error?.response && error?.response?.data && 
                    error?.response?.data?.i18n && error.response.data.i18n.indexOf('__TFMSG__') > 0) {
                    const errParts = error.response.data.i18n.split('__TFMSG__');
                    setErrors({
                      [errParts[0]]: errParts[1]
                    });
                  } else {
                    setErrors({
                      submit: getLocalisedErrorString(error?.response?.data?.i18n, t)
                    } as FormikErrors<CustomError>);
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setErrors,
                isSubmitting,
                touched,
                values
              }) => (
                user?.interfacePreference === 'twinfield_interface'
                  ? (
                    <VatCodeTwinfield
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      setErrors={setErrors}
                      isSubmitting={isSubmitting}
                      touched={touched}
                      values={values}
                      vatCode={selectedVatCode}
                      handleClose={handleClose}
                      hasError={hasError}
                      handleChangeAutocomplete={handleChangeAutocomplete}
                      getLabel={getLabel}
                      costCenters={costCenters}
                      handleEditCostCenter={handleEditCostCenter}
                      handleAddCostCenter={handleAddCostCenter}
                      glaccounts={glaccounts}
                      // onSuccess={onSuccess}
                      newlyAddedVal={newlyAddedVal}
                      newlyAddingProp={newlyAddingProp}
                      isDisabled={isDisabled}
                    />
                  ) : (
                    <VatCodeStandard
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      setErrors={setErrors}
                      isSubmitting={isSubmitting}
                      touched={touched}
                      values={values}
                      vatCode={selectedVatCode}
                      handleClose={handleClose}
                      // hasError={hasError as any}
                      handleChangeAutocomplete={handleChangeAutocomplete}
                      getLabel={getLabel}
                      costCenters={costCenters}
                      handleEditCostCenter={handleEditCostCenter}
                      handleAddCostCenter={handleAddCostCenter}
                      glaccounts={glaccounts}
                      // onSuccess={onSuccess}
                      newlyAddedVal={newlyAddedVal}
                      newlyAddingProp={newlyAddingProp}
                      isDisabled={isDisabled}
                    />
                  ))}
            </Formik>
          </div>
        </Fade>
      </Modal>
      <CostCenterModal
        isEdit={isEditCostCenter}
        costCenterModalOpen={ccModalOpen}
        handleClose={onCCModalClose}
        getAllCostCenters={getCostCenters}
        onSuccess={onCCCreated}
        selectedCostCenter={selectedCostCenterCode}
        className=""
      />
      <Backdrop open={loading} sx={{zIndex:111111,color:'#fff'}}>
        <CircularProgress />
      </Backdrop>
    </>
  );
}


export default VatCodeModal;

const styles = {
  root: {
    backgroundColor: '',
    minHeight: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    maxHeight:'100dvh'
  },
  paper: {
    width: '96%',
    maxWidth:'500px',
    maxHeight: '94dvh',
    // overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    // padding: '20px 20px 28px 20px',
    outline: 'none',
    paddingBlockEnd:'10px'
  },
  halfPaper: {
    width: '90%',
    maxWidth:'500px',
    maxHeight: '94%',
    // overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    // padding: '20px',
    outline: 'none',
    paddingBlockEnd:'10px'
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
     padding:'20px 16px'
  },
  headerTitle: {
    color:'#4C4E64',
    fontSize:'20px',
    fontWeight:'500',
    textTransform:'capitalize',
    LineHeight:'32px'
  },
  closeIcon: {
    width: 20,
    height: 20,
    color: 'rgba(51, 77, 110, 0.5)',
  },
  form: {},
  snackAction: {
    color: 'white'
  },
  expiryRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  monthField: {
    marginRight: 16
  },
  yearField: {
    marginLeft: 16,
    color: theme.palette.secondary.main
  },
  errorText: {
    color: '#f44336'
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: '#f4f6f8',
    marginBottom: 24
  },
  accordionHeading: {
    color: theme.palette.text.primary,
    fontWeight: 500
  },
  formControl: {
    width: '100%'
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff'
  },
  errorChip: {
    marginLeft: 16,
    fontSize: 12,
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  inputField: {
    backgroundColor: '#ffffff'
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  manageFieldsBtn: {
    height: 36,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: 8,
    fontSize: '12px'
  },
  btnIcon: {
    height: 15,
    width: 15,
    marginRight: 4,
    color: '#bebebe',
  },
  plusIcon: {
    height: 24,
    width: 24,
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  plusIconSelect: {
    height: 24,
    width: 24,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    marginRight: 5,
  },
  plusIconContainer: {
    marginRight: -40
  },
  editDimensionIcon: {
    fontSize: 18,
    color: '#757575',
    border: '1px solid #334D6E',
    borderRadius: 3,
    cursor: 'default'
  },
  editIconContainer: {
    marginRight: -5
  },
};


