import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@mui/material';

import CommonModal from 'src/shared/components/CommonModal/CommonModal';

const SubCompanyAccessModal = (props: { open: boolean, parentName: string }) => {
  const { ready, t } = useTranslation();
  const navigate = useNavigate();

  const { open, parentName } = props;

  const [openModal, setOpenModal] = useState(open);

  const navigateToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <CommonModal
      open={openModal}
      title={t('SUB_COMPANY_ACCESS_HEADER_BILLING')}
      cancelText={''}
      submitText={''}
      handleClose={() => { setOpenModal(false); navigate('/settings?section=myprofile'); window.location.reload(); }}
      onSubmit={navigateToDashboard}
      footer={
        <Box sx={styles.actionContainer}>
          <Button
            variant="contained"
            sx={styles.submitBtn}
            onClick={navigateToDashboard}
          >
            {ready && t('SUB_COMPANY_ACCESS_GO_TO_DASHBOARD')}
          </Button>
        </Box>
      }
    >
      <Box sx={styles.descriptionMessageText}>
        {t('SUB_COMPANY_ACCESS_DESCRIPTION_BILLING', { var: parentName })}
      </Box>
    </CommonModal>
  );
}

export default SubCompanyAccessModal;

const styles = {
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px 0'
  },
  submitBtn: {
    backgroundColor: '#3E8AFF',
  },
  descriptionMessageText: {
    color: '#4C4E64',
    fontSize: '12px',
  }
};
