import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';

import {
  Box, IconButton, Table, TableBody, TableRow, TableCell, TableHead, Typography, TablePagination
} from '@mui/material';
import { Pageview as ViewInvoiceIcon } from '@mui/icons-material';

import { useConfig } from 'src/hooks/useConfig';
import { axiosHeaders, formatAmountInvoicesTable } from 'src/utils/helpers';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import { UserDataType } from 'src/types';
import styles from './style';

const Invoices = (props: { user: UserDataType | null }) => {
  const { ready, t } = useTranslation();
  const { API } = useConfig();
  const { user } = props;

  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<Record<string, any>[]>([]);
  
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  
  const getAllInvoices = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        API.userInvoices,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setInvoices(response.data.data);
        setTotalPages((Math.floor(response.data.data.length / limit) + 1));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllInvoices();
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handlePageChange = (_event: any, newPage: number) => {
    setPage(newPage + 1);
  };

  // eslint-disable-next-line no-unused-vars
  const handleLimitChange = (event: any) => {
    setPage(1);
    setLimit(event.target.value);
    setTotalPages((Math.floor(invoices.length / event.target.value) + 1));
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>{t('PLAN_INVOICES_HEADER_INVOICES')}</Typography>
      <Table>
        <TableHead>
          <TableRow sx={styles.tableHeader}>
            <TableCell sx={{ ...styles.headerCell, borderTopLeftRadius: '8px' }} className="edit-invoice-line-header">
              #
            </TableCell>
            <TableCell sx={styles.headerCell} className="edit-invoice-line-header">
              {ready && t('ACCOUNT_SETTINGS_MY_INVOICES_COLUMN_01')}
            </TableCell>
            <TableCell sx={styles.headerCell} className="edit-invoice-line-header">
              {ready && t('ACCOUNT_SETTINGS_MY_INVOICES_COLUMN_02')}
            </TableCell>
            <TableCell sx={styles.headerCell} className="edit-invoice-line-header">
              {ready && t('ACCOUNT_SETTINGS_MY_INVOICES_COLUMN_03')}
            </TableCell>
            <TableCell align="right" sx={{ ...styles.headerCell, borderTopRightRadius: '8px' }}>
              {ready && t('DOCUMENT_LIST_TABLE_HEADER_ACTIONS')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.slice((page - 1) * limit, (page) * limit).map((invoice) => (
            <TableRow key={invoice.number}>
              <TableCell sx={styles.bodyCell} className="edit-invoice-line-body">
                {`DOC-${invoice.number}`}
              </TableCell>
              <TableCell sx={styles.bodyCell} className="edit-invoice-line-body">
                {invoice.issueDate}
              </TableCell>
              <TableCell sx={styles.bodyCell} className="edit-invoice-line-body">
                {invoice.dueDate}
              </TableCell>
              <TableCell sx={styles.bodyCell} className="edit-invoice-line-body">
                {`${invoice.currency} ${invoice.totalAmount
                    && formatAmountInvoicesTable(invoice.totalAmount, user?.monetaryDecimalSeparator)}`}
              </TableCell>
              <TableCell sx={styles.bodyCellLast} align="right">
                <IconButton onClick={() => window.open(invoice.publicURL, '_blank')}>
                  <ViewInvoiceIcon sx={styles.viewInvoiceIcon} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={invoices.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page - 1}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage={t('ROWS_PER_PAGE')}
      />
    </Box>
  );
};

export default Invoices;
