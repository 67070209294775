import {
  FETCH_COSTCENTERS_FAIL,
  FETCH_COSTCENTERS_START,
  FETCH_COSTCENTERS_SUCCESS,
  FETCH_COSTCENTER_LIST_START,
  FETCH_COSTCENTER_LIST_SUCCESS,
  FETCH_COSTCENTER_LIST_FAIL
} from "../../actionTypes";

const initialState = {
  costCenters: [],
  costCenterList: [],
  total: 0,
  loading: false,
  error: null
};

const costCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COSTCENTERS_START:
      return {...state, loading: true, error: null};
    case FETCH_COSTCENTERS_SUCCESS:
      return {...state, costCenters: action.payload.costCenters, total: action.payload.total, loading: false, error: null};
    case FETCH_COSTCENTERS_FAIL:
      return {...state, loading: false, error: action.payload.error};
    case FETCH_COSTCENTER_LIST_START:
      return {...state, loading: true, error: null};
    case FETCH_COSTCENTER_LIST_SUCCESS:
      return {...state, costCenterList: action.payload.costCenterList, total: action.payload.total, loading: false, error: null};
    case FETCH_COSTCENTER_LIST_FAIL:
      return {...state, loading: false, error: action.payload.error};
    default:
      return state;
  }
};

export default costCenterReducer;
