import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Table, TableBody, TableHead, TableRow, TableCell, Typography } from '@mui/material';
import { Delete as DeleteIcon, Book as ViewIcon, Edit as EditIcon } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import CommonModal from 'src/shared/components/CommonModal/CommonModal';

type DataType = Record<string,any>;
type PropType= {
  data: DataType[],
  type: string;
  onDelete?: (_data: any) => void;
  deleteText?: string;
  onEdit?: (_data:any) => void;
  onView?: (_data:any) => void;
}

const DimensionsTable = (props: PropType) => {
  const { ready, t } = useTranslation();
  const { user } = useAuth();
  const isTwinfield = user?.interfacePreference === 'twinfield_interface';

  const { data, type, } = props;
  const onDelete = props?.onDelete ? props.onDelete : function(){};
  const onEdit = props?.onEdit ? props.onEdit : function(){};
  const onView = props?.onView ? props.onView : function(){};

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [actionData, setActionData] = useState<DataType | null>(null);
  const deleteText = props?.deleteText? props?.deleteText : 'DIMENSION_DELETE_CLIENT_BTN'

  const handleShowModal = (eData:DataType)=>{
    setOpenModal(true);
    setActionData(eData);
  }

  const onConfirmDelete = ()=>{
    if(actionData){
      setOpenModal(false);
      if (type === 'clients') {
        onDelete(actionData?.creditorCode);
      } else {
        onDelete(actionData);
      }
    }
  }
  
  return (
    <>
      <Grid sx={styles.tableWrapper} className={'y-scroll'}>
        <Table sx={styles.table}>
          <TableHead>
            <TableRow sx={styles.tableHeadRow}>
              {type === 'clients' ? 
                <>
                  <TableCell sx={styles.tableHeadCell}>
                    {ready && t('DIMENSIONS_LIST_VIEW_HEADER_NAME')}
                  </TableCell>
                  <TableCell sx={styles.tableHeadCell}>
                    {ready && t('DIMENSIONS_LIST_VIEW_HEADER_COUNTRY')}
                  </TableCell>
                  <TableCell sx={styles.tableHeadCellLast}>
                    {ready && t('DOCUMENT_LIST_TABLE_HEADER_ACTIONS')}
                  </TableCell>
                </> : type === 'glas' ?
                <>
                  <TableCell sx={styles.tableHeadCell}>
                    {ready && t('DIMENSIONS_LIST_VIEW_HEADER_NAME')}
                  </TableCell>
                  <TableCell sx={styles.tableHeadCellLast}>
                    {ready && t('DOCUMENT_LIST_TABLE_HEADER_ACTIONS')}
                  </TableCell>
                </> : type === 'vatrate' ?
                <>
                  <TableCell sx={styles.tableHeadCell}>
                    {ready && t('DIMENSIONS_LIST_VIEW_HEADER_NAME')}
                  </TableCell>
                  <TableCell sx={styles.tableHeadCell}>
                    {ready && t('VAT_CODE_RATE_PERCENTAGE')}
                  </TableCell>
                  <TableCell sx={styles.tableHeadCellLast}>
                    {ready && t('DOCUMENT_LIST_TABLE_HEADER_ACTIONS')}
                  </TableCell>
                </> :
                <>
                  <TableCell sx={styles.tableHeadCell}>
                    {ready && t('DIMENSIONS_LIST_VIEW_HEADER_NAME')}
                  </TableCell>
                  <TableCell sx={styles.tableHeadCellLast}>
                    {ready && t('DOCUMENT_LIST_TABLE_HEADER_ACTIONS')}
                  </TableCell>      
                </>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {type === 'clients' && data ?
              data.map((eData:DataType,index:number) => 
                <TableRow key={index} sx={styles.tableBodyRow}>
                  <TableCell sx={styles.tableBodyCell}>
                    {eData.creditorName}
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    {eData.country}
                  </TableCell>
                  <TableCell sx={styles.tableBodyCellLast}>
                    <EditIcon onClick={()=>onEdit(eData)} style={styles.actionIcon} />
                    <ViewIcon onClick={()=>onView(eData)} style={styles.actionIcon}/>
                    {!isTwinfield && <DeleteIcon onClick={()=>handleShowModal(eData)} style={styles.actionIcon} />}
                  </TableCell>
                </TableRow>
              ) :  type === 'glas' && data ?
              data.map((eData:DataType,index:number) =>
                <TableRow key={index} sx={styles.tableBodyRow}>
                  <TableCell sx={styles.tableBodyCell}>
                    {eData.name}
                  </TableCell>
                  <TableCell sx={styles.tableBodyCellLast}>
                    <EditIcon onClick={()=>onEdit(eData)} style={styles.actionIcon} />
                    <ViewIcon onClick={()=>onView(eData)} style={styles.actionIcon}/>
                    {!isTwinfield && <DeleteIcon onClick={()=>handleShowModal(eData)} style={styles.actionIcon} />}
                  </TableCell>
                </TableRow> 
              ) : type === 'vatrate' && data ?
              data.map((eData:DataType,index:number) =>
                <TableRow key={index} sx={styles.tableBodyRow}>
                  <TableCell sx={styles.tableBodyCell}>
                    {eData.name}
                  </TableCell>
                  <TableCell sx={styles.tableBodyCell}>
                    {eData.ratePercentage}%
                  </TableCell>
                  <TableCell sx={styles.tableBodyCellLast}>
                    <EditIcon onClick={()=>onEdit(eData)} style={styles.actionIcon} />
                    <ViewIcon onClick={()=>onView(eData?.code)} style={styles.actionIcon}/>
                    {!isTwinfield && <DeleteIcon onClick={()=>handleShowModal(eData)} style={styles.actionIcon} />}
                  </TableCell>
                </TableRow> 
              ) :
              data.map((eData:DataType,index:number) =>
                <TableRow key={index} sx={styles.tableBodyRow}>
                  <TableCell sx={styles.tableBodyCell}>
                    {eData.name}
                  </TableCell>
                  <TableCell sx={styles.tableBodyCellLast}>
                    <EditIcon onClick={()=>onEdit(eData?.code)} style={styles.actionIcon} />
                    <ViewIcon onClick={()=>onView(eData?.code)} style={styles.actionIcon}/>
                    {!isTwinfield && <DeleteIcon onClick={()=>handleShowModal(eData)} style={styles.actionIcon} />}
                  </TableCell>
                </TableRow> 
              )
            }
          </TableBody>
        </Table>
      </Grid>
      <CommonModal 
        open={openModal}
        title={t('CONFIRM_DELETE_DIMENSIONS_TITLE')}
        cancelText={t('SUPPLIERS_CANCEL') || 'Cancel'}
        submitText={t(deleteText) || 'Delete'}
        handleClose={()=>setOpenModal(false)}
        onSubmit={onConfirmDelete}
      >
        <Typography sx={styles.contentText}>
          {t('CONFIRM_DELETE_DIMENSIONS')}
        </Typography>
      </CommonModal>
    </>
  )
}

const styles = {
  tableWrapper:{
    display:'flex'
  },
  table:{
    marginBlockStart:'15px',
  },
  tableHeadRow:{
    display:'flex',
    width:'100%',
    backgroundColor:'#C2CFE033',
    padding:'19px 15px 19px 0',
    alignItems:'center',
    borderTopLeftRadius:'8px',
    borderTopRightRadius:'8px'
  },
  tableBodyRow:{
    display:'flex',
    width:'100%',
    backgroundColor:'#fff',
    padding:'15px 15px 15px 0',
    alignItems:'center',
    borderRadius:'8px 8px 0 0'
  },
  tableHeadCell:{
    display:'flex',
    alignItems:'center',
    border:'none',
    paddingBlock:'0px',
    flexShrink:'1',
    lineHeight:'1.2',
    flex:'1',
    height:'18px',
    width:'110px',
    color: '#4C4E64DE',
    borderInlineEnd:'2px solid #4C4E641F',
  },
  tableBodyCell:{
    display:'flex',
    alignItems:'center',
    border:'none',
    paddingBlock:'0px',
    flexShrink:'1',
    lineHeight:'1.2',
    flex:'1',
    height:'18px',
    width:'110px',
    color:'#4C4E64',
    fontSize:'14px',
    fontWeight:'500',
    borderInlineEnd:'2px solid #4C4E641F',
  },
  tableHeadCellLast:{
    display:'flex',
    alignItems:'center',
    border:'none',
    paddingBlock:'0px',
    flexShrink:'1',
    lineHeight:'1.2',
    flex:'1',
    height:'18px',
    width:'110px',
    color: '#4C4E64DE',
  },
  tableBodyCellLast:{
    display:'flex',
    alignItems:'center',
    border:'none',
    flexShrink:'1',
    lineHeight:'1.2',
    flex:'1',
    height:'18px',
    gap:'16px',
    width:'100%',
    justifyContent:'flex-end',
    paddingRight:'10px'
  },
  text:{
    color:'#4C4E64',
    fontSize:'14px',
    fontWeight:'500'
  },
  actionIconContainer:{
    display:'flex',
    alignItems:'center',
    gap:'16px',
    width:'100%',
    justifyContent:'flex-end',
    paddingRight:'10px'
  },
  actionIcon:{
    color:'#6D788D',
    width:'20px',
    height:'20px',
    cursor:'pointer'
  },
  contentText: {
    color: '#4C4E64',
    fontSize: '14px',
  }
}


export default DimensionsTable