import { useTranslation } from 'react-i18next'

import { Box, Button, Typography, SvgIcon, Tooltip } from '@mui/material';
import { Add as PlusCircleIcon } from '@mui/icons-material';

import { permissions, isActionPermitted, isBranded } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import theme from "src/theme";

type PropsType = {
  onAddCompany: () => void;
}

const TopDisplay = (props: PropsType) => {
  const { onAddCompany } = props
  const { ready, t } = useTranslation();
  const { user } = useAuth();
  const { BRAND_NAME } = useConfig();

  return (
    <Box sx={styles.topDisplay}>
      <Typography sx={styles.title}>
        {ready && t('ACCOUNT_SETTINGS_COMPANIES')}
      </Typography>
      <Tooltip title={user?.isSubCompany
        ? (ready && t('ACCOUNT_SETTINGS_COMPANIES_ADD_COMPANY_DISABLE_TOOLTIP'))
        : (isActionPermitted(permissions.companyAdd, user?.permissions)
          ? ready && t('ACCOUNT_SETTINGS_COMPANIES_ADD_COMPANY_TOOLTIP')
          : t('ACCOUNT_SETTINGS_COMPANIES_ADD_PERMISSION'))}
      >
        <span>
          <Button
            variant="contained"
            sx={styles.action}
            onClick={onAddCompany}
            disabled={
              user?.isSubCompany || (isBranded(BRAND_NAME) 
              && user?.isSubCompany) || !isActionPermitted(permissions.companyAdd, user?.permissions)
            }
          >
            <SvgIcon
              fontSize="small"
              sx={styles.actionIcon}
            >
              <PlusCircleIcon />
            </SvgIcon>
            {ready && t('ACCOUNT_SETTINGS_COMPANIES_ADD_COMPANY')}
          </Button>
        </span>
      </Tooltip>
    </Box>
  )
}



const styles = {
  topDisplay:{
    display:'flex',
    width:'100%',
    justifyContent:'space-between'
  },
  title:{
    fontSize:'20px',
    color:'#4C4E64',
    fontWeight:500
  },
  root: {},
  queryField: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    border: 'none',
    borderRadius: 0,
  },
  headerStyle: {
    fontSize: 13,
    fontWeight: 500,
    color: '',
    align: 'center'
  },
  searchBar: {
    flex: 3,
    marginRight: 24,
    marginTop: 12,
    marginBottom: 12,
  },
  searchBarInput: {
    height: '36px',
    width: '100%',
  },
  arrow: {
    paddingLeft: 4
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-between',
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px',
    marginRight: 24
  },
  action: {
    fontSize: 14,
    fontWeight: 500,
    boxShadow: '0px 4px 8px -4px #4C4E646B',
    backgroundColor: '#3E8AFF',
    color:'#fff',
    textTransform:'none',
    '&:hover':{
      backgroundColor: '#3E8AFF',
      color:'#fff',
    }
  },
  actionIcon: {
    color: 'rgba(255, 255, 255, 1)',
    fontWeight:500,
    marginRight: theme.spacing(1)
  },
  checkbox: {
    width: 16,
    height: 16,
    color: '#D5D5D5',
    paddingLeft: 17
  },
  caption: {
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 13,
    color: '',
    paddingLeft: 24
  },
  paginationComp: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  paginationActions: {
    marginLeft: 0
  },
};


export default TopDisplay