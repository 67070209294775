/* eslint-disable max-len */
import * as React from "react"
import { JSX } from "react/jsx-runtime"

function SvgComponent(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.146 11.354l.708-2.291L7 7.604h2.27l.73-2.25.73 2.25H13l-1.854 1.458.708 2.292L10 9.938l-1.854 1.416zM5.479 19v-5.833c-.597-.514-1.076-1.202-1.437-2.063A6.683 6.683 0 013.5 8.5c0-1.806.632-3.34 1.896-4.604C6.66 2.632 8.194 2 10 2s3.34.632 4.604 1.896C15.868 5.16 16.5 6.694 16.5 8.5c0 .889-.167 1.76-.5 2.615-.333.854-.833 1.545-1.5 2.072V19L10 18l-4.52 1zM10 13.5c1.389 0 2.57-.486 3.542-1.458C14.514 11.069 15 9.889 15 8.5c0-1.389-.486-2.57-1.458-3.542C12.569 3.986 11.389 3.5 10 3.5c-1.389 0-2.57.486-3.542 1.458C5.486 5.931 5 7.111 5 8.5c0 1.389.486 2.57 1.458 3.542.973.972 2.153 1.458 3.542 1.458zm-3.02 3.625l3.02-.667 3 .667V14.25c-.458.25-.941.438-1.448.563A6.46 6.46 0 0110 15a6.954 6.954 0 01-1.563-.177c-.513-.118-1-.31-1.458-.573v2.875z"
        fill="#3E8AFF"
      />
    </svg>
  )
}

export default SvgComponent
