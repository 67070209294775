import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';

import {
  Box, IconButton, Typography
} from '@mui/material';
import {
  FileCopyOutlined as CopyIcon, CheckCircleOutline as CheckIcon
} from '@mui/icons-material';

import CommonModal from 'src/shared/components/CommonModal/CommonModal';

interface PropTypes {
  isOpen: boolean;
  link: string;
  handleClose: () => void;
}

const InviteLinkPopUp = (props: PropTypes) => {
  const { t } = useTranslation();
  const { isOpen, link, handleClose } = props;
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    copy(link, {
      // @ts-ignore
      onCopy: setCopied(true)
    });
  };

  return (
    <CommonModal
      open={isOpen}
      title={t('USER_INVITE_LINK_TITLE')}
      cancelText={''}
      submitText={''}
      handleClose={handleClose}
      onSubmit={() => window.open(link, '_blank')}
      footer={<Box sx={styles.footer}></Box>}
      persist
    >
      <Typography variant="caption" sx={styles.primaryTextColor}>
        {t('USER_INVITE_LINK_INSTRUCTION')}
      </Typography>
      <Box sx={styles.detailRow}>
        <input
          type="text"
          value={link}
          style={styles.inputField}
          disabled
        />
        <IconButton onClick={copyToClipboard}>
          <CopyIcon />
        </IconButton>
        {copied && <CheckIcon sx={styles.checkIcon} />}
      </Box>
    </CommonModal>
  );
};

export default InviteLinkPopUp;

const styles = {
  primaryTextColor: {
    color: '#4C4E64',
  },
  detailRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center' as const,
    padding: '8px 0px 16px 0px'
  },
  inputField: {
    fontSize: '14px',
    color: '#4C4E64',
    border: 0,
    backgroundColor: '#E9E9E9',
    padding: '8px',
    flex: 1,
    margin: '0px',
    borderRadius: '4px'
  },
  checkIcon: {
    color: '#4caf50'
  },
  footer: {
    height: '16px'
  }
};
