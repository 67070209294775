import { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { countries } from 'countries-list';
import cc from 'currency-codes/data';

import { useAuth } from 'src/hooks/useAuth';
import authService from 'src/utils/authService';
import {
  ERRORS, supplierData
} from 'src/config';
import { axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import { fetchVatCodes } from 'src/dimensions/vatcodes/actions';
import { fetchGlas } from 'src/dimensions/glas/actions';
import { fetchCostCenters } from 'src/dimensions/costcenters/actions';
import ConfigContext from 'src/contexts/ConfigContext';
import GLAccountModal from 'src/dimensions/glas/components/GLAccountModal/GLAccountModal';
import VatCodeModal from 'src/dimensions/vatcodes/components/VatCodeModal/VatCodeModal';
import CostCenterModal from 'src/dimensions/costcenters/components/CostCenterModal/CostCenterModal';
import ManageFields from 'src/shared/components/ManageFields/ManageFields';
import CreateSupplierTwinfield from 'src/dimensions/suppliers/components/CreateSupplierForm/CreateSupplierTwinfield';
import CreateSupplierStandard from 'src/dimensions/suppliers/components/CreateSupplierForm/CreateSupplierStandard';

function CreateSupplierForm({
  supplier,
  onSuccess,
  onClose,
  isInvoiceEditForm,
  xmlSelectedValue,
  textFieldSelectedKey,
  textFieldSelectedValueRef,
  setTextFieldSelectedKey,
  setTextFieldSelectedValue,
}) {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  const glAccounts = useSelector((state) => [...supplierData.defaultGLA, ...state.gla.glAccounts]);
  const vatGroups = useSelector((state) => [...supplierData.defaultVatGroup, ...state.vatcode.vatCodes]);
  const costCenters = useSelector((state) => [...supplierData.defaultGLA, ...state.costcenter.costCenters]);

  const auth = useAuth();
  const {user} = auth;

  const [glaYears, setGlaYears] = useState([]);
  const [glaPeriods, setGlaPeriods] = useState([]);

  const [currencies, setCurrencies] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [projects, setProjects] = useState(supplierData.defaultProjects);
  const [isTwinfield, setIsTwinfield] = useState(false);

  const [supplierFields, setSupplierFields] = useState([]);
  const [showManageFields, setShowManageFields] = useState(false);

  const [gLAccountModalOpen, setGLAccountModalOpen] = useState(false);
  const [vatCodeModalOpen, setVatCodeModalOpen] = useState(false);
  const [ccModalOpen, setCCModalOpen] = useState(false);
  const [selectedGLA, setSelectedGLA] = useState(null);
  const [selectedVATGroupCode, setSelectedVATGroupCode] = useState(null);
  const [selectedCostCenterCode, setSelectedCostCenterCode] = useState(null);

  const [newlyAddingProp, setNewlyAddingProp] = useState(null);
  const [newlyAddedVal, setNewlyAddedVal] = useState(null);

  const [preferredFieldsLoading, setPreferredFieldsLoading] = useState(false);

  const [supplierProducts, setSupplierProducts] = useState([]);

  const updateSupplierProducts = (products) => {
    setSupplierProducts(products);
  };

  const getSupplierFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/supplier/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setSupplierFields(response.data.data.filter((f) => f.isAvailable));
          setPreferredFieldsLoading(false);
        } else {
          setSupplierFields([]);
          setPreferredFieldsLoading(false);
        }
      }
    } catch (error) {
      setSupplierFields([]);
      setPreferredFieldsLoading(false);
    }
  };

  const getGLAYears = async () => {
    try {
      const response = await Axios.get(
        `${API.glAYearsByCompany}/${user.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success && response.data.data) {
        setGlaYears(response.data.data);
      }
    } catch (err) {
      //
    }
  };

  const getGLAPeriods = async () => {
    try {
      const response = await Axios.get(
        `${API.glAPeriodsByCompany}/${user.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success && response.data.data) {
        setGlaPeriods(response.data.data);
      }
    } catch (err) {
      //
    }
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
  };

  const getAllGLAccounts = async () => {
    dispatch(fetchGlas(user.companyID));
  };

  const getAllVatGroups = () => {
    dispatch(fetchVatCodes(user.companyID));
  };

  const getAllCostCenters = async () => {
    dispatch(fetchCostCenters(user.companyID));
  };

  const getAllProjects = async () => {
    try {
      const response = await Axios.get(
        `${API.projectsByCompany}/${encodeURIComponent(user.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data.length > 0) {
          setProjects([...supplierData.defaultProjects, ...response.data.data]);
        }
      }
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    setIsTwinfield(user.accountingSystem === 'twinfield');

    getAllProjects();
    // getPreferredFields();
    getSupplierFields();
    getGLAYears();
    getGLAPeriods();

    const allCountries = Object.keys(countries).map((key) => ({
      isoCode: key,
      name: countries[key]?.name,
    }));
    setCountriesList(allCountries);

    setCurrencies(
      cc.sort((a, b) => {
        if (a.code < b.code) {
          return -1;
        }
        return 1;
      })
    );
  }, []);

  const hasError = (fields, errors, touched) => {
    for (let i = 0; i < fields.length; i++) {
      if (touched[fields[i].key] && errors[fields[i].key]) {
        return true;
      }
    }

    return false;
  };

  const handleChangeAutocomplete = (e, prop, value, changeFunc) => {
    if (value && (value.code || value.isoCode)) {
      changeFunc({ target: { name: prop, value: value.isoCode ? value.isoCode : value.code } });
    }
  };

  const getLabel = (entities, code) => {
    for (let i = 0; i < entities?.length; i++) {
      if (code === entities[i].code) {
        let namePart = '';
        if (entities[i].name) {
          namePart = ` | ${entities[i].name}`;
        }
        return `${entities[i].code}${namePart}`;
      }
    }

    return code;
  };

  const onGLAModalClose = () => {
    setGLAccountModalOpen(false);
    setSelectedGLA(null);
  };

  const handleEditGLA = (id) => {
    if (id !== supplierData.defaultGLA[0].code) {
      setSelectedGLA(glAccounts.find((gla) => gla.code === id));
      setGLAccountModalOpen(true);
    }
  };

  const handleAddGLA = (prop) => {
    setSelectedGLA(null);
    setNewlyAddedVal(null);
    setGLAccountModalOpen(true);
    setNewlyAddingProp(prop);
  };

  const onGLACreated = (val) => {
    getAllGLAccounts();
    setNewlyAddedVal(val);
  };

  const onVatCoddeModalClose = () => {
    setVatCodeModalOpen(false);
    setSelectedVATGroupCode(null);
  };

  const handleEditVATCode = (id) => {
    if (id !== supplierData.defaultVatGroup[0].code) {
      setSelectedVATGroupCode(vatGroups.find((v) => v.code === id));
      setVatCodeModalOpen(true);
    }
  };

  const handleAddVATCode = (prop) => {
    setSelectedVATGroupCode(null);
    setNewlyAddedVal(null);
    setVatCodeModalOpen(true);
    setNewlyAddingProp(prop);
  };

  const onVatCodeCreated = (val) => {
    setNewlyAddedVal(val);
  };

  const onCCModalClose = () => {
    setCCModalOpen(false);
    setSelectedCostCenterCode(null);
  };

  const handleEditCostCenter = (id) => {
    if (id !== supplierData.defaultCostCenters[0].code) {
      setSelectedCostCenterCode(id);
      setCCModalOpen(true);
    }
  };

  const handleAddCostCenter = (prop) => {
    setSelectedCostCenterCode(null);
    setNewlyAddedVal(null);
    setCCModalOpen(true);
    setNewlyAddingProp(prop);
  };

  const onCCCreated = (val) => {
    getAllCostCenters();
    setNewlyAddedVal(val);
  };

  const isFieldAvailable = (field) => {
    if (user?.interfacePreference === 'twinfield_interface') {
      return true;
    }
    return supplierFields.some((f) => f.key === field);
  };

  const calculateGLAVal = (prop, val) => {
    if (isTwinfield) {
      return (val === supplierData.defaultGLA[0].code) ? '1600' : val;
    }
    if (isFieldAvailable(prop)) {
      return val === supplierData.defaultGLA[0].code ? '' : val;
    }
    return undefined;
  };

  return (
    <>
      <Formik
        initialValues={{
          skipSupplierReview: supplier?.skipSupplierReview || false,
          creditorCode: supplier?.creditorCode || '',
          creditorName: supplier?.creditorName || '',
          fiscalName: supplier?.fiscalName || '',
          autoPaymentStatus: supplier?.autoPaymentStatus || supplierData.apStatuses[2].code,
          dueDays: supplier?.dueDays || '',
          website: supplier?.website || '',
          comment: supplier?.comment || '',
          companyRegNumber: supplier?.companyRegNumber || '',
          vatNumber: supplier?.vatNumber || '',
          referenceCode: supplier?.referenceCode || '',
          status: supplier?.status || '',
          generalLedgerAccount: supplier?.generalLedgerAccount || supplierData.defaultGLA[0].code,
          generalLedgerAccLine: supplier?.generalLedgerAccLine || supplierData.defaultGLA[0].code,
          vatGroup: supplier?.vatGroup || supplierData.defaultVatGroup[0].code,
          vatGroupLine: supplier?.vatGroupLine || supplierData.defaultVatGroup[0].code,
          costCenter: supplier?.costCenter || supplierData.defaultCostCenters[0].code,
          project: supplier?.project || supplierData.defaultProjects[0].code,
          invoiceDescriptionRule: supplier?.invoiceDescriptionRule || '',
          invoiceLineDescriptionRule: supplier?.invoiceLineDescriptionRule || '',
          defaultCurrency: supplier?.defaultCurrency || '',
          bankCountry: supplier?.bankCountry || '',
          ascription: supplier?.ascription || '',
          iban: supplier?.iban || '',
          bankAccNumber: supplier?.bankAccNumber || '',
          bicSwift: supplier?.bicSwift || '',
          nationalBankCode: supplier?.nationalBankCode || '',
          bankName: supplier?.bankName || '',
          bankCity: supplier?.bankCity || '',
          bankStreet: supplier?.bankStreet || '',
          bankHouseNumber: supplier?.bankHouseNumber || '',
          country: supplier?.country || 'ES',
          city: supplier?.city || '',
          postalCode: supplier?.postalCode || '',
          address1: supplier?.address1 || '',
          address2: supplier?.address2 || '',
          poBox: supplier?.poBox || '',
          poBoxPostalCode: supplier?.poBoxPostalCode || '',
          poBoxCity: supplier?.poBoxCity || '',
          contactPersonName: supplier?.contactPersonName || '',
          telephoneNumber: supplier?.telephoneNumber || '',
          faxNumber: supplier?.faxNumber || '',
          mobileTelNumber: supplier?.mobileTelNumber || '',
          emailAddress: supplier?.emailAddress || '',
          postcode: supplier?.postcode || '',
          state: supplier?.state || '',
          addressName: supplier?.addressName || '',
          shortname: supplier?.shortname || '',
          discountDays: supplier?.discountDays || '',
          discountPercentage: supplier?.discountPercentage || '',
          matchType: supplier?.matchType || '',
          custom1: supplier?.custom1 || '',
          custom2: supplier?.custom2 || '',
          custom3: supplier?.custom3 || '',
        }}
        validationSchema={Yup.object().shape({
          creditorCode: Yup.string().max(255)
            .when('creditorName', {
              is: () => user.interfacePreference === 'twinfield_interface',
              then: () => Yup.string().max(255).required('SUPPLIERS_CRED_CODE_REQUIRED')
            }),
          creditorName: Yup.string()
            .max(80, 'SUPPLIERS_CREDITOR_CODE_MAX_VALIDATION')
            .required('SUPPLIERS_CRED_NAME_REQUIRED'),
          fiscalName: Yup.string()
            .when('creditorName', {
              is: () => isFieldAvailable('fiscalName'),
              then: () => Yup.string().max(255, 'SUPPLIERS_FISCAL_NAME_MAX_VALIDATION')
            }),
          autoPaymentStatus: Yup.string().max(255),
          // .required('SUPPLIERS_AUTO_PAYMENT_STATUS_REQUIRED'),
          dueDays: Yup.number()
            .integer('SUPPLIERS_DUE_DAYS_TYPE_VALIDATION')
            .typeError('SUPPLIERS_DUE_DAYS_TYPE_VALIDATION')
            .min(0),
          // .required('SUPPLIERS_DUE_DAYS_REQUIRED'),
          website: Yup.string()
            .when('creditorName', {
              is: () => isFieldAvailable('website'),
              then: () => Yup.string().max(40, 'SUPPLIERS_WEBSITE_MAX_VALIDATION')
            }),
          // comment: Yup.string().max(255).required('comment is required'),
          companyRegNumber: Yup.string().max(128, 'SUPPLIERS_COMPANY_REG_NUMBER_MAX_VALIDATION'),
          vatNumber: Yup.string()
            .when('creditorName', {
              is: () => isFieldAvailable('vatNumber'),
              then: () => Yup.string().max(128, 'SUPPLIERS_VAT_NUMBER_MAX_VALIDATION')
            }),
          referenceCode: Yup.string().max(40, 'SUPPLIERS_REFERENCE_CODE_MAX_VALIDATION'),
          // status: Yup.string().max(255).required('status is required'),
          // generalLedgerAccount: Yup.string().max(255).required('General Ledge Account is required'),
          // generalLedgerAccLine: Yup.string().max(255).required('General Ledge Account is required'),
          // vatGroup: Yup.string().max(255).required('VAT Group is required'),
          invoiceDescriptionRule: Yup.string()
            .max(100, 'SUPPLIERS_INVOICE_DESCRIPTION_RULE_MAX_VALIDATION'),
          // .required('SUPPLIERS_INVOICE_DESCRIPTION_RULE_REQUIRED'),
          defaultCurrency: Yup.string()
            .when('creditorName', {
              is: () => isFieldAvailable('defaultCurrency'),
              then: () => Yup.string().max(255)
            }),
          // .required('SUPPLIERS_DEFAULT_CURRENCY_REQUIRED'),
          bankCountry: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus !== supplierData.apStatuses[2].code,
              then: () => Yup.string().max(255).required('SUPPLIERS_BANK_COUNTRY_REQUIRED')
            }) : Yup.string().max(255),
          ascription: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[0].code || autoPaymentStatus === supplierData.apStatuses[1].code,
              then: () => Yup.string().required('SUPPLIERS_ASCRIPTION_REQUIRED')
            })
            .max(40, 'SUPPLIERS_ASCRIPTION_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_ASCRIPTION_MAX_VALIDATION'),
          iban: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[0].code,
              then: () => Yup.string().required('SUPPLIERS_IBAN_REQUIRED')
            })
            .max(40, 'SUPPLIERS_IBAN_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_IBAN_MAX_VALIDATION'),
          bankAccNumber: Yup.string().max(40, 'SUPPLIERS_BANK_ACC_NUMBER_MAX_VALIDATION'),
          bicSwift: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[1].code,
              then: () => Yup.string().required('SUPPLIERS_BIC_SWIFT_REQUIRED')
            })
            .max(16, 'SUPPLIERS_BIC_SWIFT_MAX_VALIDATION') : Yup.string().max(16, 'SUPPLIERS_BIC_SWIFT_MAX_VALIDATION'),
          nationalBankCode: Yup.string().max(20, 'SUPPLIERS_NATIONAL_BANK_CODE_MAX_VALIDATION'),
          bankName: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[1].code,
              then: () => Yup.string().required('SUPPLIERS_BANK_NAME_REQUIRED')
            })
            .max(40, 'SUPPLIERS_BANK_NAME_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_BANK_NAME_MAX_VALIDATION'),
          bankCity: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[1].code,
              then: () => Yup.string().required('SUPPLIERS_BANK_CITY_REQUIRED')
            })
            .max(40, 'SUPPLIERS_BANK_CITY_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_BANK_CITY_MAX_VALIDATION'),
          bankStreet: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[1].code,
              then: () => Yup.string().required('SUPPLIERS_BANK_STREET_REQUIRED')
            })
            .max(128, 'SUPPLIERS_BANK_STREET_MAX_VALIDATION') : Yup.string().max(128, 'SUPPLIERS_BANK_STREET_MAX_VALIDATION'),
          bankHouseNumber: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[1].code,
              then: () => Yup.string().required('SUPPLIERS_BANK_HOUSE_NUMBER_REQUIRED')
            })
            .max(128, 'SUPPLIERS_BANK_HOUSE_NUMBER_MAX_VALIDATION') : Yup.string().max(128, 'SUPPLIERS_BANK_HOUSE_NUMBER_MAX_VALIDATION'),
          postcode: Yup.string().max(16, 'SUPPLIERS_BANK_POSTCODE_MAX_VALIDATION'),
          state: Yup.string().max(40, 'SUPPLIERS_BANK_STATE_MAX_VALIDATION'),
          country: Yup.string().max(255),
          // .required('SUPPLIERS_COUNTRY_REQUIRED'),
          city: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[1].code,
              then: () => Yup.string().required('SUPPLIERS_CITY_REQUIRED')
            })
            .max(40, 'SUPPLIERS_CITY_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_CITY_MAX_VALIDATION'),
          postalCode: Yup.string().max(16, 'SUPPLIERS_POSTAL_CODE_MAX_VALIDATION'),
          address1: user.interfacePreference === 'twinfield_interface' ? Yup.string()
            .when('autoPaymentStatus', {
              is: (autoPaymentStatus) => autoPaymentStatus === supplierData.apStatuses[1].code,
              then: () => Yup.string().required('SUPPLIERS_ADDRESS1_REQUIRED')
            })
            .max(128, 'SUPPLIERS_ADDRESS1_MAX_VALIDATION') : Yup.string().max(128, 'SUPPLIERS_ADDRESS1_MAX_VALIDATION'),
          address2: Yup.string().max(128, 'SUPPLIERS_ADDRESS2_MAX_VALIDATION'),
          // poBox: Yup.string().max(255).required('poBox is required'),
          // poBoxPostalCode: Yup.string().max(255).required('poBoxPostalCode is required'),
          // poBoxCity: Yup.string().max(255).required('poBoxCity is required'),
          contactPersonName: Yup.string().max(128, 'SUPPLIERS_CONTACT_PERSON_MAX_VALIDATION'),
          telephoneNumber: Yup.string()
            .when('creditorName', {
              is: () => isFieldAvailable('telephoneNumber'),
              then: () => Yup.string().max(25, 'SUPPLIERS_TELEPHONE_NUMBER_MAX_VALIDATION')
            }),
          faxNumber: Yup.string()
            .when('creditorName', {
              is: () => isFieldAvailable('faxNumber'),
              then: () => Yup.string().max(25, 'SUPPLIERS_FAX_NUMBER_MAX_VALIDATION')
            }),
          mobileTelNumber: Yup.string().max(128, 'SUPPLIERS_MOBILE_TEL_NUMBER_MAX_VALIDATION'),
          emailAddress: Yup.string()
            .when('creditorName', {
              is: () => isFieldAvailable('emailAddress'),
              then: () => Yup.string().max(200, 'SUPPLIERS_EMAIL_ADDRESS_MAX_VALIDATION')
            }),
          discountDays: Yup.number()
            .integer('SUPPLIERS_DISCOUNT_DAYS_TYPE_VALIDATION')
            .typeError('SUPPLIERS_DISCOUNT_DAYS_TYPE_VALIDATION'),
          discountPercentage: Yup.number().typeError('SUPPLIERS_DISCOUNT_PERCENTAGE_TYPE_VALIDATION'),
          custom1: Yup.string()
            .when('creditorName', {
              is: () => isFieldAvailable('custom1'),
              then: () => Yup.string().max(255, 'SUPPLIERS_CUSTOM_FIELD_01_MAX_VALIDATION')
            }),
          custom2: Yup.string()
            .when('creditorName', {
              is: () => isFieldAvailable('custom2'),
              then: () => Yup.string().max(255, 'SUPPLIERS_CUSTOM_FIELD_02_MAX_VALIDATION')
            }),
          custom3: Yup.string()
            .when('creditorName', {
              is: () => isFieldAvailable('custom3'),
              then: () => Yup.string().max(255, 'SUPPLIERS_CUSTOM_FIELD_03_MAX_VALIDATION')
            }),
        })}
        onSubmit={async (values, {
          resetForm,
          setErrors,
          setStatus,
          setSubmitting
        }) => {
          if (!authService.validateToken()) {
            enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
              variant: 'error',
            });
            setTimeout(() => {
              authService.logout(LOGIN_PANEL_URL);
            }, 2000);
            return;
          }

          try {
            const body = {
              skipSupplierReview: values.skipSupplierReview,
              creditorCode: isFieldAvailable('creditorCode') ? values.creditorCode : undefined,
              creditorName: values.creditorName,
              fiscalName: isFieldAvailable('fiscalName') ? values.fiscalName : undefined,
              autoPaymentStatus: values.autoPaymentStatus,
              dueDays: isFieldAvailable('dueDays') ? values.dueDays : undefined,
              website: isFieldAvailable('website') ? values.website : undefined,
              comment: isFieldAvailable('comment') ? values.comment : undefined,
              companyRegNumber: isFieldAvailable('companyRegNumber') ? values.companyRegNumber : undefined,
              vatNumber: isFieldAvailable('vatNumber') ? values.vatNumber : undefined,
              referenceCode: isFieldAvailable('referenceCode') ? values.referenceCode : undefined,
              status: isFieldAvailable('status') ? values.status : undefined,
              generalLedgerAccount: (values.generalLedgerAccount === supplierData.defaultGLA[0].code && isTwinfield)
                ? '1600'
                : values.generalLedgerAccount === supplierData.defaultGLA[0].code
                  ? ''
                  : values.generalLedgerAccount,
              generalLedgerAccLine: calculateGLAVal('generalLedgerAccLine', values.generalLedgerAccLine),
              vatGroup: values.vatGroup === supplierData.defaultVatGroup[0].code ? '' : values.vatGroup,
              vatGroupLine: !isFieldAvailable('vatGroupLine')
                ? undefined
                : values.vatGroupLine === supplierData.defaultVatGroup[0].code ? '' : values.vatGroupLine,
              costCenter: !isFieldAvailable('costCenter')
                ? undefined
                : values.costCenter === supplierData.defaultCostCenters[0].code ? '' : values.costCenter,
              project: !isFieldAvailable('project')
                ? undefined
                : values.project === supplierData.defaultProjects[0].code ? '' : values.project,
              invoiceDescriptionRule: isFieldAvailable('invoiceDescriptionRule') ? values.invoiceDescriptionRule : undefined,
              invoiceLineDescriptionRule: isFieldAvailable('invoiceLineDescriptionRule') ? values.invoiceLineDescriptionRule : undefined,
              defaultCurrency: isFieldAvailable('defaultCurrency') ? values.defaultCurrency : undefined,
              bankCountry: isFieldAvailable('bankCountry') ? values.bankCountry : undefined,
              ascription: isFieldAvailable('ascription') ? values.ascription : undefined,
              iban: isFieldAvailable('iban') ? values.iban : undefined,
              bankAccNumber: isFieldAvailable('bankAccNumber') ? values.bankAccNumber : undefined,
              bicSwift: isFieldAvailable('bicSwift') ? values.bicSwift : undefined,
              nationalBankCode: isFieldAvailable('nationalBankCode') ? values.nationalBankCode : undefined,
              bankName: isFieldAvailable('bankName') ? values.bankName : undefined,
              bankCity: isFieldAvailable('bankCity') ? values.bankCity : undefined,
              bankStreet: isFieldAvailable('bankStreet') ? values.bankStreet : undefined,
              bankHouseNumber: isFieldAvailable('bankHouseNumber') ? values.bankHouseNumber : undefined,
              country: values.country,
              city: isFieldAvailable('city') ? values.city : undefined,
              postalCode: isFieldAvailable('postalCode') ? values.postalCode : undefined,
              address1: isFieldAvailable('address1') ? values.address1 : undefined,
              address2: isFieldAvailable('address2') ? values.address2 : undefined,
              poBox: isFieldAvailable('poBox') ? values.poBox : undefined,
              poBoxPostalCode: isFieldAvailable('poBoxPostalCode') ? values.poBoxPostalCode : undefined,
              poBoxCity: isFieldAvailable('poBoxCity') ? values.poBoxCity : undefined,
              contactPersonName: isFieldAvailable('contactPersonName') ? values.contactPersonName : undefined,
              telephoneNumber: isFieldAvailable('telephoneNumber') ? values.telephoneNumber : undefined,
              faxNumber: isFieldAvailable('faxNumber') ? values.faxNumber : undefined,
              mobileTelNumber: isFieldAvailable('mobileTelNumber') ? values.mobileTelNumber : undefined,
              emailAddress: isFieldAvailable('emailAddress') ? values.emailAddress : undefined,
              postcode: isFieldAvailable('postcode') ? values.postcode : undefined,
              state: isFieldAvailable('state') ? values.state : undefined,
              addressName: isFieldAvailable('addressName') ? values.addressName : undefined,
              shortname: isFieldAvailable('shortname') ? values.shortname : undefined,
              discountDays: isFieldAvailable('discountDays') ? values.discountDays : undefined,
              discountPercentage: isFieldAvailable('discountPercentage') ? values.discountPercentage : undefined,
              matchType: isFieldAvailable('matchType') ? values.matchType : undefined,
              custom1: isFieldAvailable('custom1') ? values.custom1 : undefined,
              custom2: isFieldAvailable('custom2') ? values.custom2 : undefined,
              custom3: isFieldAvailable('custom3') ? values.custom3 : undefined,
              inventories: supplierProducts
            };

            // Validate DueDays and DiscountDays
            const dueDays = parseInt(values.dueDays, 10);
            const discountDays = parseInt(values.discountDays, 10);
            if (dueDays > 0 && discountDays > 0 && dueDays < discountDays) {
              setStatus({ success: false });
              setSubmitting(false);
              setErrors({
                discountDays: 'SUPPLIERS_DISCOUNT_DAYS_DUEDAYS_VALIDATION'
              });
              return;
            }

            const response = await Axios.post(
              `${API.suppliers}`,
              body,
              axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
            );
            if (response.data.success) {
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              enqueueSnackbar(ready && t('SUPPLIERS_ADD_SUCCESS'), {
                variant: 'success',
                style: { maxWidth: 400 }
              });
              onSuccess(response.data.id);
            }
          } catch (error) {
            setStatus({ success: false });
            setSubmitting(false);
            if (error.response.data.i18n.indexOf('__TFMSG__') > 0) {
              const errParts = error.response.data.i18n.split('__TFMSG__');
              setErrors({
                [errParts[0]]: errParts[1]
              });
            } else {
              setErrors({
                submit: getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t)
              });
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          user.interfacePreference === 'twinfield_interface' ? (
            <CreateSupplierTwinfield
              isInvoiceEditForm={isInvoiceEditForm}
              hasError={hasError}
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              onClose={onClose}
              handleChange={handleChange}
              handleChangeAutocomplete={handleChangeAutocomplete}
              getLabel={getLabel}
              handleEditGLA={handleEditGLA}
              handleAddGLA={handleAddGLA}
              glAccounts={glAccounts}
              handleEditVATCode={handleEditVATCode}
              handleAddVATCode={handleAddVATCode}
              vatGroups={vatGroups}
              handleEditCostCenter={handleEditCostCenter}
              handleAddCostCenter={handleAddCostCenter}
              costCenters={costCenters}
              projects={projects}
              currencies={currencies}
              countries={countriesList}
              xmlSelectedValue={xmlSelectedValue}
              textFieldSelectedKey={textFieldSelectedKey}
              textFieldSelectedValueRef={textFieldSelectedValueRef}
              setTextFieldSelectedKey={setTextFieldSelectedKey}
              setTextFieldSelectedValue={setTextFieldSelectedValue}
              newlyAddedVal={newlyAddedVal}
              newlyAddingProp={newlyAddingProp}
            />
          ) : (
            <CreateSupplierStandard
              isInvoiceEditForm={isInvoiceEditForm}
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              onClose={onClose}
              handleChange={handleChange}
              handleChangeAutocomplete={handleChangeAutocomplete}
              getLabel={getLabel}
              handleEditGLA={handleEditGLA}
              handleAddGLA={handleAddGLA}
              glAccounts={glAccounts}
              handleEditVATCode={handleEditVATCode}
              handleAddVATCode={handleAddVATCode}
              vatGroups={vatGroups}
              handleEditCostCenter={handleEditCostCenter}
              handleAddCostCenter={handleAddCostCenter}
              costCenters={costCenters}
              projects={projects}
              currencies={currencies}
              countries={countriesList}
              supplierFields={supplierFields}
              preferredFieldsLoading={preferredFieldsLoading}
              setShowManageFields={setShowManageFields}
              xmlSelectedValue={xmlSelectedValue}
              textFieldSelectedKey={textFieldSelectedKey}
              textFieldSelectedValueRef={textFieldSelectedValueRef}
              setTextFieldSelectedKey={setTextFieldSelectedKey}
              setTextFieldSelectedValue={setTextFieldSelectedValue}
              newlyAddedVal={newlyAddedVal}
              newlyAddingProp={newlyAddingProp}
              products={supplierProducts}
              updateProducts={updateSupplierProducts}
            />
          )
        )}
      </Formik>
      <ManageFields
        open={showManageFields}
        entity="supplier"
        fields={supplierFields}
        fetchFields={getSupplierFields}
        closeModal={handleCloseManageFields}
        parameterCompany={user.companyID}
      />
      <GLAccountModal
        gLAccountModalOpen={gLAccountModalOpen}
        handleClose={onGLAModalClose}
        getAllGLAccounts={getAllGLAccounts}
        getVatCodes={getAllVatGroups}
        onSuccess={onGLACreated}
        selectedGLA={selectedGLA}
        glaYears={glaYears}
        glaPeriods={glaPeriods}
      />
      <VatCodeModal
        vatCodeModalOpen={vatCodeModalOpen}
        handleClose={onVatCoddeModalClose}
        getAllVatCodes={getAllVatGroups}
        onSuccess={onVatCodeCreated}
        selectedVatCode={selectedVATGroupCode}
      />
      <CostCenterModal
        costCenterModalOpen={ccModalOpen}
        handleClose={onCCModalClose}
        getAllCostCenters={getAllCostCenters}
        onSuccess={onCCCreated}
        selectedCostCenter={selectedCostCenterCode}
      />
    </>
  );
}

// CreateSupplierForm.propTypes = {
//   className: PropTypes.string,
//   user: PropTypes.object,
//   supplier: PropTypes.object,
//   onSuccess: PropTypes.func,
//   onClose: PropTypes.func,
//   history: PropTypes.shape({
//     push: PropTypes.func
//   }),
//   isInvoiceEditForm: PropTypes.bool,
//   xmlSelectedValue: PropTypes.string,
//   textFieldSelectedKey: PropTypes.string,
//   textFieldSelectedValueRef: PropTypes.object,
//   setTextFieldSelectedKey: PropTypes.func,
//   setTextFieldSelectedValue: PropTypes.func,
// };

export default CreateSupplierForm;
