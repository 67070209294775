import React, { useContext, useEffect, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Box, Button, Card, CardContent, FormHelperText, Grid, TextField, Tooltip, Typography 
} from '@mui/material';
import { Checklist as FieldsIcon } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { axiosHeaders, getLocalisedErrorString, isActionPermitted, isShowManageFields, permissions
} from 'src/utils/helpers';
import authService from 'src/utils/authService';
import ConfigContext from 'src/contexts/ConfigContext';
import ManageFields from 'src/shared/components/ManageFieldsNew/ManageFieldsNew';
import { CostCenterType, CustomError, FieldType } from 'src/types';
import theme from 'src/theme';

type PropsType = {
  costCenter:CostCenterType | null;
  getAllCostCenters: ( _page?: number, _limit?: number, _search?: string, _loadFullScreen?: boolean) => void;
  handleClose: () => void;
  onSuccess: (_id: string) => void;
  setLoading: (_show: boolean) => void;
  loading: boolean;
  isEdit: boolean;
  isDisabled?: boolean;
}

type FormPropType = {
  errors:Record<string, string>;
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleSubmit: () => void;
  isSubmitting: boolean;
  setErrors: (_errors: FormikErrors<Record<string, string>>) => void;
  touched: Record<string,string | boolean>;
  values: Record<string,string | boolean>;
}

const CostCenterStandard = (props: PropsType) => {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    costCenter,
    getAllCostCenters,
    handleClose,
    onSuccess,
    setLoading,
    isDisabled,
  } = props;

  const isEdit = props?.isEdit || false;
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  
  const auth = useAuth();
  const {user} = auth;

  const [ccFields, setCCFields] = useState([]);
  const [showManageFields, setShowManageFields] = useState(false);

  const getCCFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/cc/${encodeURIComponent(user?.companyID as string)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setCCFields(response.data.data.filter((f:FieldType) => f.isAvailable));
          setLoading(false);
        } else {
          setCCFields([]);
          setLoading(false);
        }
      }
    } catch (error) {
      setCCFields([]);
      setLoading(false);
    }
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
  };

  useEffect(() => {
    setShowManageFields(false);
    getCCFields();
  }, []);

  const isFieldAvailable = (field:FieldType) => {
    if (field.isMandatory) {
      return false;
    }
    if (field.isActive) {
      return true;
    }
    return false;
  };


  if(isEdit === true && ( !costCenter?.name && !costCenter?.code ) ){
    return <></>
  }

  return (
    <>
      <Formik
        initialValues={{
          code: costCenter?.code || '',
          name: costCenter?.name || '',
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string()
            .max(255),
          name: Yup.string()
            .max(80, 'COST_CENTER_NAME_MAX_VALIDATION')
            .required('COST_CENTER_NAME_REQUIRED')
        })}
        onSubmit={async (values, {
          resetForm,
          setErrors,
          setStatus,
          setSubmitting
        }) => {
          try {
            if (!authService.validateToken()) {
              enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
                variant: 'error',
              });
              setTimeout(() => {
                authService.logout(LOGIN_PANEL_URL);
              }, 2000);
              return;
            }
            setLoading(true);

            const body = {
              code: values.code,
              name: values.name,
            };

            const url = `${API.costCentersByCompany}/${encodeURIComponent(user?.companyID as string)}`;
            let response = null;
            let snackText = '';

            if (costCenter && costCenter.name) {
              response = await Axios.put(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
              snackText = 'COST_CENTERS_UPDATE_SUCCESS';
            } else {
              response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
              snackText = 'COST_CENTERS_ADD_SUCCESS';
            }
            if (response?.data?.success) {
              setLoading(false);
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              enqueueSnackbar(ready && t(snackText), {
                variant: 'success',
                style: { maxWidth: 400 }
              });
              getAllCostCenters();
              handleClose();
              if (onSuccess) {
                onSuccess(response.data.id);
              }
            }
          } catch (e) {
            const error = e as CustomError;
            setLoading(false);
            setStatus({ success: false });
            setSubmitting(false);
            if (error && error?.response && error?.response?.data && 
              error?.response?.data?.i18n && error.response.data.i18n.indexOf('__TFMSG__') > 0) {
              const errParts = error.response.data.i18n.split('__TFMSG__');
              setErrors({
                [errParts[0]]: errParts[1]
              });
            } else {
              setErrors({
                submit: getLocalisedErrorString(error?.response?.data?.i18n, t)
              } as FormikErrors<CustomError>);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setErrors,
          touched,
          values
        }:FormPropType) => (
          <form style={styles.root} className="y-scroll">
            <Card style={{ boxShadow: 'none' }}>
              <CardContent style={{ padding: '0', display:'flex', flexDirection:'column',gap:'10px' }}>
                {
                  showManageFields?
                    <Box className="y-scroll" sx={{backgroundColor:'#fff',position:'relative',top:'0px',
                      maxHeight:'calc(94dvh - 76px)','& > div > div:nth-of-type(2)':{
                      minHeight:'300px'
                    }}}>
                      <ManageFields
                        entity="cc"
                        fields={ccFields}
                        fetchFields={getCCFields}
                        handleCloseManageFields={handleCloseManageFields}
                        parameterCompany={user?.companyID || ''}
                      />
                    </Box>:
                    <>
                    {
                      isShowManageFields(user, 'applyParentFieldsCC') && (
                      <Grid m={0} item xs={12} sx={styles.topManager}>
                        <Button
                          variant="outlined"
                          sx={styles.manageFieldsBtn}
                          onClick={() => {setShowManageFields(true)}}
                        >
                          <FieldsIcon style={styles.btnIcon} />
                          {t('INVOICE_EDIT_FORM_MANAGE_FIELDS')}
                        </Button>
                      </Grid>
                    )}
                    <Grid item key="name" sx={styles.eachRow}>
                      <Typography>{ready && t('COST_CENTER_NAME')}</Typography>
                      <TextField
                        disabled={isDisabled}
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && (ready && t(errors?.name as string))}
                        name="name"
                        onClick={() => setErrors({})}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.name}
                        variant="outlined"
                        style={styles.inputField}
                      />
                    </Grid>
                    {
                      ccFields.map((f:FieldType) => {
                        if (!isFieldAvailable(f)) {
                          return null;
                        }
                        switch (f.key) {
                          default:
                            return (
                              <Tooltip key={f.key} title={(f.key !== 'code') ? '' : !values[f.key] ? '' : t('DIMENSION_CODE_CANNOT_EDIT')}>
                                <Grid item key={f.key} sx={styles.eachRow}>
                                  <Typography>
                                    {ready && t(f.label)}
                                  </Typography>
                                  <TextField
                                    error={Boolean(touched[f.key] && errors[f.key])}
                                    fullWidth
                                    helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                    name={f.key}
                                    onClick={() => setErrors({})}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required={f.isRequired}
                                    value={values[f.key]}
                                    variant="outlined"
                                    style={styles.inputField}
                                    disabled={isDisabled? true : costCenter ? f.key === 'code' : false}
                                  />
                                </Grid>
                              </Tooltip>
                            );
                        }
                      })
                    }
                    <Grid
                      item
                      xs={12}
                    >
                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                      )}
                      
                      {
                        isDisabled ? <></> :
                        <Box display="flex" justifyContent="flex-end" padding="10px 16px 20px">
                          <Button
                            variant="text"
                            onClick={()=>handleClose()}
                            sx={{...styles.yearField,textTransform:'none'}}
                          >
                            {ready && t('COST_CENTERS_CANCEL')}
                          </Button>
                          <Button
                            sx={{ backgroundColor: '#3E8AFF',textTransform:'none' }}
                            variant="contained"
                            disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user?.permissions)}
                            onClick={()=>handleSubmit()}
                          >
                            {ready && t(isEdit ?'COST_CENTERS_SAVE_CHANGES':'COST_CENTERS_ADD_NEW')}
                          </Button>
                        </Box>
                      }
                    </Grid>
                  </>               
                }
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
      
    </>
  );
};


export default CostCenterStandard;


const styles = {
  root: {
    backgroundColor: '',
    maxHeight: 'calc(94dvh - 76px)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  halfPaper: {
    width: '90%',
    maxWidth:'500px',
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding:'20px 16px'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  topManager:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    gap:'12px',
    padding:'12px 16px',
    borderTop: '1px solid #4C4E641F',
    borderBottom: '1px solid #4C4E641F',
    margin:'0',
  },
  eachRow:{
    display:'flex',
    width:'100%',
    justifyContent:'space-between',
    padding:'0 16px',
    '& > p':{
      fontSize:'13px',
      color:'#4C4E64DE',
    }
  },
  form: {},
  snackAction: {
    color: 'white'
  },
  expiryRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  monthField: {
    marginRight: 16
  },
  yearField: {
    marginLeft: 16,
    color: theme.palette.secondary.main
  },
  errorText: {
    color: '#f44336'
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: '#f4f6f8',
  },
  accordionHeading: {
    color: theme.palette.text.primary,
    fontWeight: 500
  },
  formControl: {
    width: '100%'
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#ffffff'
  },
  errorChip: {
    marginLeft: 16,
    fontSize: 12,
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  inputField: {
    backgroundColor: '#ffffff',
    maxWidth:'180px'
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  manageFieldsBtn: {
    height: 'fit-content',
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#3E8AFF',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    gap:'10px',
    fontSize:'13px',
    fontWeight:'500',
    border:'none',
    '&:hover':{
      border:'none',
      backgroundColor:'#fff'
    }
  },
  btnIcon: {
    height: 20,
    width: 20,
    marginRight: 4,
    color: '#3E8AFF',
  },
};
