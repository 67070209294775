import { useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import Axios from 'axios';
import { useSnackbar } from 'notistack';

import {
  Button, Card, Grid, Tooltip, Typography, useMediaQuery, Modal, Paper, Checkbox,
  TextField, Backdrop, FormControlLabel, IconButton, SvgIcon, Alert
} from '@mui/material';
import { Close, PriorityHigh } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import {
  appendContactSupport, axiosHeaders, getLocalisedErrorString, getMaskedText, getSubscriptionTitle,
} from 'src/utils/helpers';
import {
  subscriptionTypes
} from 'src/config';
import Invoices from 'src/subscription/components/Invoices/Invoices';
import Billing from 'src/subscription/components/Billing/Billing';
import SubCompanyAccessModal from 'src/subscription/components/SubCompanyAccessModal/SubCompanyAccessModal';
import LoadingScreen from 'src/shared/screens/LoadingScreen';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import ManagePlanSection from '../ManagePlanSection';
import styles from './style';
import SubscriptionActions from '../SubscriptionActions';
import BuyExtraDocs from '../BuyExtraDocs';

function MyPlan() {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { BRAND_NAME, API } = useContext(ConfigContext);
  
  const auth = useAuth();
  const {user} = auth;

  const shrink = useMediaQuery('(max-width: 660px)');
  const shrinkSm = useMediaQuery('(max-width: 480px)');

  const [openModal, setModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelImmediately, setCancelImmediately] = useState(false);

  const [openContactModal, setContactModalOpen] = useState(false);
  const [contactEmail, setContactEmail] = useState(user.email);
  const [contactPhone, setContactPhone] = useState(user.companyPhone || '');
  const [contactCompanyName, setContactCompanyName] = useState(user.company || '');
  const [contactFirstname, setContactFirstname] = useState(user.firstname || '');
  const [contactDetails, setContactDetails] = useState('');
  const [contactEmailErr, setContactEmailErr] = useState('');
  const [contactPhoneErr, setContactPhoneErr] = useState('');

  const [indicatorMaxWidth, setIndicatorMaxWidth] = useState(shrink ? shrinkSm ? 120 : 150 : 360); // eslint-disable-line
  const [exIndicatorMaxWidth, setExIndicatorMaxWidth] = useState(shrink ? shrinkSm ? 40 : 50 : 100); // eslint-disable-line

  const [selectedSubscription, setSelectedSubscription] = useState(subscriptionTypes.starter);
  const [customSubscription, setCustomSubscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subsError, setSubsError] = useState('');
  const [defaultCard, setDefaultCard] = useState(null);

  const [subscription, setSubscription] = useState({
    name: 'Business',
    invLimit: 200,
    invUsed: 260,
    usersLimit: 10,
    usersUsed: 4,
    totalInvoices: 12000,
    totalPayment: '135050',
    nextBillingAmount: 2999,
    currPrice: 0,
    ecCharged: 0,
    startDate: '2021-10-12T14:48:12-05:00',
    endDate: '2022-04-12T14:48:12-05:00'
  });

  const getSubscription = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(API.subscriptionDetails, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success && response.data.data && response.data.data.length > 0) {
        const subs = response.data.data[0];
        setDefaultCard(subs.creditCard.id);
        let balanceFromChargify = subs.balanceInCents;
        if (!balanceFromChargify) {
          balanceFromChargify = 0;
        }

        let currentSubsPrice = subs.currPrice;
        if (!currentSubsPrice) {
          currentSubsPrice = 0;
        }

        const subsObj = {
          id: subs.id,
          name: subs.product.name,
          i18n: subscriptionTypes[Object.keys(subscriptionTypes).find((o) => subs.product.name.toLowerCase().includes(o))]?.i18n || 'MY_PLAN_ENTERPRISE',
          selfServiceUrl: subs.selfServiceUrl,
          invLimit: subs.invoicesLimit || 20,
          invPurchased: subs.extraPurchased || 0,
          ecCarried: subs.ecCarried || 0,
          invUsed: subs.invoicesUsed || 0,
          usersLimit: subs.usersLimit || 1,
          usersUsed: subs.usersUsed || 1,
          totalInvoices: subs.totalInvoices || 0,
          totalPayment: subs.totalRevenueinCents || 0,
          nextBillingAmount: balanceFromChargify + currentSubsPrice || 0,
          startDate: subs.activatedAt,
          endDate: subs.currentPeriodEndsAt,
          ecLimit: subs.ecLimit || 0,
          ecPrice: subs.ecPrice || 0,
          currPrice: subs.currPrice || 0,
          price: subs.currPrice || 0,
          ecCharged: subs.ecCharged || 0,
          toDowngrade: subs.toDowngrade,
          downgradeSubscription: subs.downgradeSubscription
        };
        let currSubs = subscriptionTypes.free;
        subsObj.level = subscriptionTypes.free.level;
        subsObj.value = subscriptionTypes.free.value;

        if (subs.product.name.toLowerCase().indexOf('trial') >= 0) {
          currSubs = subscriptionTypes.free;
          subsObj.level = -1;
          subsObj.value = 0;
        }

        if (subs.product.name.toLowerCase().indexOf('starter') >= 0) {
          currSubs = subscriptionTypes.starter;
          subsObj.level = subscriptionTypes.starter.level;
          subsObj.value = subscriptionTypes.starter.value;
        }

        if (subs.product.name.toLowerCase().indexOf('business') >= 0) {
          currSubs = subscriptionTypes.business;
          subsObj.level = subscriptionTypes.business.level;
          subsObj.value = subscriptionTypes.business.value;
        }

        if (subs.product.name.toLowerCase().indexOf('custom') >= 0
          || subs.product.name.toLowerCase().indexOf('enterprise') >= 0
          || subs.product.name.toLowerCase().indexOf('increment') >= 0) {
          currSubs = subscriptionTypes.enterprise;
          subsObj.level = subscriptionTypes.enterprise.level;
          subsObj.value = subscriptionTypes.enterprise.value;

          if (!subs.invoicesLimit || subs.invoicesLimit <= 0) {
            subsObj.invLimit = 0;
          }

          setCustomSubscription(subsObj);
        } else if (response.data.data.length >= 2) {
          const customSub = response.data.data[1];
          const salesCustom = {
            level: subscriptionTypes.enterprise.level,
            value: subscriptionTypes.enterprise.value,
            label: 'Enterprise',
            name: 'Enterprise Plan',
            invoices: customSub.invoicesLimit,
            users: customSub.usersLimit,
            price: customSub.currPrice,
            ecLimit: customSub.ecLimit,
            ecPrice: customSub.ecPrice,
          };
          setCustomSubscription(salesCustom);
        }

        if (!subs.ecLimit) {
          subsObj.ecLimit = currSubs.ecLimit;
        }
        if (!subs.ecPrice) {
          subsObj.ecPrice = currSubs.ecPrice;
        }

        setSelectedSubscription(subsObj);
        setSubscription(subsObj);
      } else {
        setSubsError(appendContactSupport(window.config.support_email, t('SUBSCRIPTION_RETRIEVE_DETAILS_FAILURE'), t));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setSubsError(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(err?.response?.data?.i18n || 'SUBSCRIPTION_RETRIEVE_DETAILS_FAILURE', t),
        t
      ));
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  useEffect(() => {
    if (shrinkSm) {
      setIndicatorMaxWidth(120);
      setExIndicatorMaxWidth(40);
      return;
    }

    if (shrink) {
      setIndicatorMaxWidth(220);
      setExIndicatorMaxWidth(60);
      return;
    }

    setIndicatorMaxWidth(360);
    setExIndicatorMaxWidth(100);
  }, [shrink, shrinkSm]);

  const getIndicatorColor = (used, limit) => {
    const usage = used / limit;

    if (usage < 0.5) {
      return 'progressIndicator0';
    }

    if (usage < 0.8) {
      return 'progressIndicator50';
    }

    if (usage < 1.0) {
      return 'progressIndicator80';
    }

    if (usage >= 1.0) {
      return 'progressIndicator100';
    }

    return 'progressIndicator0';
  };

  const handleCancelSubscription = async () => {
    try {
      const body = {
        action: 'cancel',
        toSubscription: 'free',
        reason: cancelReason,
        immediately: cancelImmediately
      };

      const response = await Axios.put(
        API.updateSubscription,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('CANCEL_SUBSCRIPTION_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setModalOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(err?.response?.data?.i18n || 'CANCEL_SUBSCRIPTION_FAILURE', t), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const sendContactForm = async () => {
    if (!contactEmail) {
      setContactEmailErr(t('PROCYS_VALIDATE_EMAIL_EMPTY'));
      return;
    }

    if (!contactPhone) {
      setContactPhoneErr(t('SUBSCRIPTION_PHONE_REQUIRED'));
      return;
    }

    try {
      const body = {
        email: contactEmail,
        phone: contactPhone,
        company: contactCompanyName,
        firstname: contactFirstname,
        details: contactDetails
      };

      const response = await Axios.post(
        API.subscriptionUpgradeContact,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('SUBSCRIPTION_SUCCESS_TO_SEND'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setContactModalOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(err?.response?.data?.i18n || 'SUBSCRIPTION_FAILED_TO_SEND', t), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const handleChangeContactFormFields = (value, prop, setter, errSetter) => {
    if (!value) {
      errSetter(t('INTEGRATIONS_PROP_CANNOT_BE_EMPTY', { prop }));
    } else {
      errSetter('');
    }

    setter(value);
  };

  const cancelSubscriptionChange = async () => {
    setLoading(true);
    try {
      const response = await Axios.post(
        API.cancelSubscriptionChange,
        { id: subscription && subscription.id ? subscription.id.toString() : '' },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        getSubscription();
        enqueueSnackbar(
          t('SUBSCRIPTION_CANCEL_CHANGE_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(err?.response?.data?.i18n || 'SUBSCRIPTION_CANCEL_CHANGE_FAILURE', t), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const cancelModal = (
    <Modal
      open={openModal}
      style={styles.modal}
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Paper style={styles.paper}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <IconButton
            style={styles.closeBtn}
            onClick={() => setModalOpen(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        </Grid>
        <Grid
          style={styles.modalBody}
        >
          <Typography style={styles.cancelSubsHeader}>
            {ready && t('UNSUBSCRIBE_HEADER')}
          </Typography>
          <Typography style={styles.msg}>
            {ready && t('UNSUBSCRIBE_MSG_ONE') + moment(subscription.endDate).format('MMM DD, YYYY')}
          </Typography>
          <Typography style={styles.secondaryMsg}>
            {ready && t('UNSUBSCRIBE_MSG_TWO')}
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="filled"
            label={ready && t('CANCEL_SUBSCRIPTION_FEEDBACK_TEXTFIELD')}
            sx={{ '&.MuiTextFieldRoot': styles.noPadding }}
            InputProps={{
              style: {
                paddingTop: 30,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
              },
            }}
            onChange={(e) => setCancelReason(e.target.value)}
          />
          <FormControlLabel
            label={ready && t('UNSUBSCRIBE_CHECKBOX')}
            style={styles.unsubscribeCheckbox}
            control={<Checkbox value={cancelImmediately} onChange={(e) => setCancelImmediately(e.target.checked)} />}
          />
          <Grid container spacing={2}>
            <Grid item>
              <Button
                style={styles.cancelSubs}
                variant="contained"
                onClick={handleCancelSubscription}
              >
                {ready && t('CANCEL_SUBSCRIPTION_BUTTON')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                style={styles.backBtn}
                onClick={() => setModalOpen(false)}
              >
                {ready && t('SUBSCRIPTION_BACK_BUTTON')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );

  const contactModal = (
    <Modal
      open={openContactModal}
      style={styles.modal}
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Paper style={styles.paper}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <IconButton
            style={styles.closeBtn}
            onClick={() => setContactModalOpen(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        </Grid>
        <Grid
          style={styles.modalBody}
        >
          <Typography
            variant="h2"
            style={styles.cancelSubsHeader}
          >
            {t('MY_PLAN_CONTACT_US')}
          </Typography>
          <TextField
            style={styles.fieldStyle}
            fullWidth
            label={ready && t('SUBSCRIPTION_PHONE_NO')}
            name="contactPhone"
            onChange={(e) => handleChangeContactFormFields(e.target.value, t('SUBSCRIPTION_PHONE_NO'), setContactPhone, setContactPhoneErr)}
            type="text"
            value={contactPhone}
            variant="outlined"
            required
            error={Boolean(contactPhoneErr)}
            helperText={contactPhoneErr}
          />
          <TextField
            style={styles.fieldStyle}
            fullWidth
            label={ready && t('SUBSCRIPTION_EMAIL')}
            name="contactEmail"
            onChange={(e) => handleChangeContactFormFields(e.target.value, t('SUBSCRIPTION_EMAIL'), setContactEmail, setContactEmailErr)}
            type="text"
            value={contactEmail}
            variant="outlined"
            required
            error={Boolean(contactEmailErr)}
            helperText={contactEmailErr}
          />
          <TextField
            style={styles.fieldStyle}
            fullWidth
            label={ready && t('SUBSCRIPTION_COMPANY_NAME')}
            name="contactCompanyName"
            onChange={(e) => setContactCompanyName(e.target.value)}
            type="text"
            value={contactCompanyName}
            variant="outlined"
          />
          <TextField
            style={styles.fieldStyle}
            fullWidth
            label={ready && t('SUBSCRIPTION_FIRST_NAME')}
            name="contactFirstname"
            onChange={(e) => setContactFirstname(e.target.value)}
            type="text"
            value={contactFirstname}
            variant="outlined"
          />
          <TextField
            style={styles.fieldStyle}
            fullWidth
            multiline
            rows={4}
            label={ready && t('SUBSCRIPTION_DETAILS')}
            name="contactDetails"
            onChange={(e) => setContactDetails(e.target.value)}
            type="text"
            value={contactDetails}
            variant="outlined"
          />
          <Grid style={styles.buttonContainer}>
            <Button
              style={styles.cancelSubs}
              variant="contained"
              onClick={sendContactForm}
            >
              {t('SUBSCRIPTION_SEND')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );

  const PlanDetails = () => (
    <>
      <Typography style={styles.subsTitle}>{user?.brand === 'schubec' ? subscription.name : t(subscription.i18n)}</Typography>
      <Typography style={styles.description}>
        {
          ready && (subscription.invLimit > 0
            ? t('MY_PLAN_PROCESSED_COUNT', {
              processed: subscription.invUsed > subscription.invLimit ? subscription.invLimit : subscription.invUsed,
              limit: subscription.invLimit - subscription.invPurchased
            })
            : t('MY_PLAN_PROCESSED_COUNT_CUSTOM', { processed: subscription.invUsed })
          )
        }
        {
          ready && subscription.invPurchased + subscription.ecCarried > 0
            && t('MY_PLAN_PROCESSED_COUNT_PURCHASED', {
              processed: subscription.invUsed - subscription.invLimit >= 0 ? subscription.invUsed - subscription.invLimit : 0,
              purchased: subscription.invPurchased + subscription.ecCarried
            })
        }
      </Typography>
      {defaultCard ? (
        <Grid style={styles.progressContainer}>
          <Tooltip title={t('MY_PLAN_LIMITS')}>
            <Grid
              style={{
                width: indicatorMaxWidth,
                ...styles[subscription.invPurchased + subscription.ecCarried > 0 ? 'progressBackground' : 'progressBackgroundRound']
              }}
            >
              <Grid
                style={{
                  width: indicatorMaxWidth * (subscription.invUsed / subscription.invLimit),
                  maxWidth: indicatorMaxWidth,
                  ...styles.progressIndicator,
                  ...styles[subscription.invLimit > 0 ? getIndicatorColor(subscription.invUsed, subscription.invLimit) : 'progressIndicator0']
                }}
              />
            </Grid>
          </Tooltip>
          {
            subscription.invPurchased + subscription.ecCarried > 0 && (
              <Tooltip title={t('MY_PLAN_EXTRA_DOC_LIMITS')}>
                <Grid style={{ ...styles.extraProgressBackground, width: exIndicatorMaxWidth }}>
                  {
                    subscription.invUsed - subscription.invLimit > 0 && (
                      <Grid
                        style={{
                          width:
                            exIndicatorMaxWidth
                            * ((subscription.invUsed - subscription.invLimit >= 0 ? subscription.invUsed - subscription.invLimit : 0)
                            / (subscription.invPurchased + subscription.ecCarried)),
                          maxWidth: exIndicatorMaxWidth,
                          ...styles.extraProgressIndicator,
                          ...styles[getIndicatorColor(
                            subscription.invUsed - subscription.invLimit >= 0
                              ? subscription.invUsed - subscription.invLimit : 0, subscription.invPurchased + subscription.ecCarried
                          )]
                        }}
                      />
                    )
                  }
                </Grid>
              </Tooltip>
            )
          }
          <Typography style={styles.progressText}>
            {
              subscription.invLimit > 0
                ? `${subscription.invUsed > subscription.invLimit
                  ? subscription.invLimit : subscription.invUsed}/${subscription.invLimit - subscription.invPurchased} ${ready && t('MY_PLAN_DOCS')}`
                : `${subscription.invUsed} ${ready && t('MY_PLAN_DOCS')}`
            }
              <br />
              {
              subscription.invPurchased > 0
                && `${subscription.invUsed - subscription.invLimit >= 0
                  ? subscription.invUsed - subscription.invLimit : 0}/${subscription.invPurchased + subscription.ecCarried} ${t('MY_PLAN_EXTRA_DOCS')}`
            }
          </Typography>
        </Grid>
      ) : (
        <Grid style={styles.noCardAddedContainer}>
          <SvgIcon fontSize="13">
            <PriorityHigh style={styles.priorityIcon} />
          </SvgIcon>
          <Typography style={styles.noCardText}>
            {ready && t('MY_PLAN_NO_CARD_ADDED', { brand: BRAND_NAME })}
          </Typography>
        </Grid>
      )}
      <Typography style={styles.description}>
        {ready && t('MY_PLAN_RENEW_AT')}
        {' '}
        <strong>{moment(subscription.endDate).format('DD MMM, YYYY')}</strong>
        .
      </Typography>
      {
        subscription.totalInvoices > 0 && (
          <Typography
            style={{...styles.description, maxWidth: 760}}
          >
            <Trans i18nKey="MY_PLAN_SAVED_TIME">
              You&apos;ve processed
              <strong>{{ docs: getMaskedText(subscription.totalInvoices.toString(), 3) }}</strong>
              documents with
              {{ brand: BRAND_NAME }}
              for the entire period, thereby saving
              <strong>{{ minutes: getMaskedText((Math.round((subscription.totalInvoices * 2.5 * 100)) / 100).toString(), 3) }}</strong>
              minutes of manual work. Thank you for using us!
            </Trans>
          </Typography>
        )
      }
      {
        subscription.invLimit > 0 && subscription.invUsed >= subscription.invLimit && (
          <>
            <Typography style={styles.warningText}>
              <span style={styles.warningSymbol}>!</span>
              {ready && t('MY_PLAN_LIMIT_REACHED')}
            </Typography>
            <Typography style={styles.description}>
              {
                ready && (
                  <>
                    {
                      subscription.name.toLowerCase().indexOf('free') >= 0
                        && t('MY_PLAN_PLEASE_UPGRADE')
                    }
                    {
                      subscription.name.toLowerCase().indexOf('starter') >= 0
                        && t('MY_PLAN_PLEASE_UPGRADE_STARTER')
                    }
                    {
                      subscription.name.toLowerCase().indexOf('business') >= 0
                        && t('MY_PLAN_PLEASE_UPGRADE_BUSINESS')
                    }
                    {
                      (subscription.name.toLowerCase().indexOf('custom') >= 0 || subscription.name.toLowerCase().indexOf('enterprise') >= 0)
                        && t('MY_PLAN_PLEASE_UPGRADE_CUSTOM', { limit: subscription.ecLimit, price: subscription.ecPrice })
                    }
                  </>
                )
              }
            </Typography>
          </>
        )
      }
    </>
  );

  if (loading) {
    return <LoadingScreen />;
  }

  if ((user.isSubCompany || user.isPartnerSubCompany) && user.parent) {
    return <SubCompanyAccessModal openModal parentName={user.parentName} />;
  }

  if (subsError) {
    return <EmptyText str={subsError} />;
  }

  return (
    <>
      <Grid container spacing={3} style={styles.root}>
        <Grid item xs={12}>
          {
            subscription.toDowngrade && (
              <Grid
                container
                direction="row"
                alignItems="center"
                style={styles.cancelContainer}
              >
                <Alert
                  severity="warning"
                  sx={{
                    '.MuiAlert-message': styles.warningBanner
                  }}
                >
                  <span>
                    {ready && t('MY_PLAN_CHANGE_TO')}
                    <strong>{` ${ready && t(getSubscriptionTitle(subscription.downgradeSubscription))} `}</strong>
                    {ready && t('MY_PLAN_CHANGE_ON', { date: moment(subscription.endDate).format('DD MMM, YYYY') })}
                  </span>
                  <Button style={styles.cancelBtn} onClick={cancelSubscriptionChange}>
                    {ready && t('MY_PLAN_CHANGE_CANCEL')}
                  </Button>
                </Alert>
              </Grid>
            )
          }
          <Card style={styles.planContainer}>
            {ready && t('MY_PLAN_TITLE')}
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                {PlanDetails()}
                {user.brand !== 'schubec' && !user.customisations.includes('hideManagePlanSection') && 
                  <ManagePlanSection
                    subscription={subscription}
                    selectedSubscription={selectedSubscription}
                    setSelectedSubscription={setSelectedSubscription}
                    customSubscription={customSubscription}
                  />
                }
                <SubscriptionActions
                  subscription={subscription}
                  selectedSubscription={selectedSubscription}
                  user={user}
                  setModalOpen={setModalOpen}
                />
                <BuyExtraDocs subscription={subscription} user={user} />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} style={styles.planItem}>
          <Card>
            <Billing header="PLAN_CARDS_HEADER_CARDS" pageLimit={5} subscription={subscription} />
          </Card>
        </Grid>
        <Grid item xs={12} style={styles.planItem}>
          <Card>
            <Invoices header="PLAN_INVOICES_HEADER_INVOICES" pageLimit={5} />
          </Card>
        </Grid>
      </Grid>
      {cancelModal}
      {contactModal}
    </>
  );
}

// MyPlan.propTypes = {
//   isChild: PropTypes.bool
// };

export default MyPlan;
