import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import {ArrowBack as BackIcon }from '@mui/icons-material';

const Header = () => {
  const { ready, t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={styles.root}>
      <Button sx={styles.btn} onClick={() => navigate('/')}>
        <BackIcon sx={styles.icon} />
        {ready && t('DASHBOARD_NAV_DIMENSIONS')}
      </Button>
    </Box>
  )
}

const styles = {
  root: {
    width:'100%',
    backgroundColor:'#fff',
    borderRadius:'8px 8px 0 0',
    padding:'15px 10px',
    borderBlockEnd:'1px solid #4C4E641F',
  },
  btn: {
    textDecoration:'none',
    color:'#4C4E64',
    fontSize:'16px',
    fontWeight:'500',
    gap:'10px',
    display:'flex',
    alignItems:'center',
    width:'fit-content',
    textTransform:'none',
  },
  icon: {
    color: '#6D788D',
  }
}

export default Header