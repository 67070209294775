import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment';
import Axios from 'axios';

import { Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';

import ConfigContext from 'src/contexts/ConfigContext';
import { getLocalisedErrorString, appendContactSupport, axiosHeaders } from 'src/utils/helpers';
import CommonModal from 'src/shared/components/CommonModal/CommonModal';
import { CustomError } from 'src/types';

interface PropTypes {
  open: boolean;
  subscription: Record<string, any>;
  handleClose: () => void;
}

const CancelSubscriptionModal = ({
  open,
  subscription,
  handleClose,
}: PropTypes) => {
  const { t, ready } = useTranslation();

  const {API} = useContext(ConfigContext);

  const [cancelReason, setCancelReason] = useState('');
  const [cancelImmediately, setCancelImmediately] = useState(false);

  const handleCancelSubscription = async () => {
    try {
      const body = {
        action: 'cancel',
        toSubscription: 'free',
        reason: cancelReason,
        immediately: cancelImmediately
      };

      const response = await Axios.put(
        API.updateSubscription,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('CANCEL_SUBSCRIPTION_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        handleClose();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      const err = e as CustomError;
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(err?.response?.data?.i18n || 'CANCEL_SUBSCRIPTION_FAILURE', t), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  return (
    <CommonModal
      open={open}
      title={t('UNSUBSCRIBE_HEADER')}
      cancelText={t('SUBSCRIPTION_BACK_BUTTON')}
      submitText={t('CANCEL_SUBSCRIPTION_BUTTON')}
      handleClose={handleClose}
      onSubmit={handleCancelSubscription}
    >
      <Typography style={styles.msg}>
        {ready && t('UNSUBSCRIBE_MSG_ONE') + moment(subscription.endDate).format('MMM DD, YYYY')}
      </Typography>
      <Typography style={styles.msg}>
        {ready && t('UNSUBSCRIBE_MSG_TWO')}
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={4}
        variant="filled"
        label={ready && t('CANCEL_SUBSCRIPTION_FEEDBACK_TEXTFIELD')}
        sx={{ '&.MuiTextFieldRoot': styles.noPadding }}
        InputProps={{
          style: {
            paddingTop: 30,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
          },
        }}
        onChange={(e) => setCancelReason(e.target.value)}
      />
      <FormControlLabel
        label={ready && t('UNSUBSCRIBE_CHECKBOX')}
        sx={styles.unsubscribeCheckbox}
        control={<Checkbox value={cancelImmediately} onChange={(e) => setCancelImmediately(e.target.checked)} />}
      />
    </CommonModal>
  );
};

export default CancelSubscriptionModal;

const styles = {
  msg: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '22px',
    color: '#4C4E64',
    marginBottom: '16px'
  },
  noPadding: {
    padding: '0px 16px',
    color: '#4C4E64',
    fontSize: '13px'
  },
  unsubscribeCheckbox: {
    marginTop: '10px',
    marginBottom: '10px',
    color: '#3E8AFF',
    '& .MuiFormControlLabel-label': {
      color: '#4C4E64 !important',
    }
  },
};
