import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, Typography, Button, Menu, MenuItem} from '@mui/material'; 
import {
  CloudUpload as ImportIcon,
  SimCardDownload as ExportIcon,
  Add as AddNewIcon,
  ListAlt as CSVIcon
} from '@mui/icons-material';

import { dimensionExportOptions } from 'src/config';
import { useAuth } from 'src/hooks/useAuth';

type PropType = {
  showImport: boolean;
  setShowImport: (_show: boolean) => void;
  title:string;
  type?:string;
  onExport?: (_format: string, _onClose: () => void) => void;
  onAdd?: () => void;
}


const TopDisplay = (props:PropType) => {
  const {
    showImport,
    setShowImport,
    title,
    type = "",
    onExport = function(){},
    onAdd = function(){},
  } = props;
  const { ready, t } = useTranslation();
  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(null);
  const [anchorElImport, setAnchorElImport] = useState<null | HTMLElement>(null);
  const openExport = Boolean(anchorElExport);
  const navigate = useNavigate();
  const { user } = useAuth();

  const isGstock = user?.integrations.includes('gstock');

  const handleOpenExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElExport(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorElExport(null);
  }
  const handleOpenImportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElImport(event.currentTarget);
  };

  const handleCloseImportMenu = () => {
    setAnchorElImport(null);
  };
  const handleShowImport = ()=>{
    setShowImport(true);
    navigate(`/dimensions/${type}`);
  }
  const handleShowClientImport = (str:string) =>{
    setShowImport(true);
    handleCloseImportMenu();
    navigate(`/dimensions/${type}?type2=${str}`)
  }
  const handleExport = async (format:string) => {
    onExport(format,handleCloseExportMenu);
  };

  const renderClientImportMenu = () =>{
    return (
      <Box>
        <Button onClick={handleOpenImportMenu} sx={{ ...styles.btns, backgroundColor: showImport ? '#3E8AFF26' : 'white' }}>
          <ImportIcon /><span> {ready && t('DIMENSION_IMPORT_BTN')}</span>
        </Button>
        <Menu
          id="user-menu"
          anchorEl={anchorElImport}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElImport)}
          onClose={handleCloseImportMenu}
          sx={{
            ".MuiMenu-paper": styles.menuContainer,
            mt: '45px'
          }}
        >
          <MenuItem onClick={()=>handleShowClientImport(isGstock ? 'gstock-clients' : 'sender')}>
            <Box sx={styles.eachMenu}>
              <span>{t(isGstock ? 'GSTOCK_IMPORT_SUPPLIERS' : 'DIMENSION_IMPORT_CLIENT_SENDER')}</span>
            </Box>
          </MenuItem>
          {/* The below condition isGstock is only until receiver implementation is fully ready */}
          {isGstock && <MenuItem onClick={()=>handleShowClientImport(isGstock ? 'gstock-products' : 'receiver')}>
            <Box sx={styles.eachMenu}>
              <span>{t(isGstock ? 'GSTOCK_IMPORT_PRODUCTS' : 'DIMENSION_IMPORT_CLIENT_RECEIVER')}</span>
            </Box>
          </MenuItem>}
        </Menu>
      </Box>
    );
  };

  return (
    <>
      <Grid sx={styles.top}>
        <Typography sx={styles.topLeft}>{title}</Typography>
        {type !== 'clients' ?
          <Button
            sx={{ ...styles.btns, backgroundColor: showImport ? '#3E8AFF26' : 'white' }}
            onClick={handleShowImport}
          >
            <ImportIcon />
            <span>
              {ready && t('DIMENSION_IMPORT_BTN')}
            </span>
          </Button> : renderClientImportMenu()
        }
        <Grid>
          <Button id="export-btn" sx={styles.btns} onClick={handleOpenExportMenu}
            aria-controls={openExport ? 'export-menu' : undefined}
            aria-haspopup="true" aria-expanded={openExport ? 'true' : undefined}
          >
            <ExportIcon /> <span>{ready && t('INVOICE_EXPORT_BTN')}</span>
          </Button>
          <Menu
            id="export-menu"
            anchorEl={anchorElExport}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={openExport}
            onClose={handleCloseExportMenu}
            sx={{
              '.MuiMenu-paper': styles.menuContainer, 
              mt: '45px'     
            }}
          >
            {dimensionExportOptions.map((option) => (
              <MenuItem
                key={option.key}
                onClick={() => handleExport(option.key)}
              >
                <Box sx={styles.exportIconContainer}>
                  <CSVIcon style={{color:'#4C4E648A'}} />{t(option.label)}
                </Box>
              </MenuItem>
            ))}
          </Menu>
        </Grid>            
        <Button sx={styles.addBtn}
          onClick={() => onAdd()}
        >
          <AddNewIcon />
          <span style={{display:'inline-block',whiteSpace:'nowrap'}}>
            {ready && t('DOCUMENT_EDIT_ADD_NEW_SUPPLIER')}
          </span>
        </Button>
      </Grid>
    </>
    )
}

const styles = {
  top:{
    display:'flex',
    alignItems:'center',
    width:'100%',
    padding:'5px 0',
    gap:'10px',
    marginBlock:'0 20px'
  },
  topLeft:{
    fontSize:'16px',
    color:'#4C4E64',
    fontWeight:'500',
    marginInlineEnd:'auto',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace: 'nowrap' 
  },
  btns:{
    gap:'8px',
    color:'#3E8AFF',
    textTransform:'Capitalize',
    fontSize:'13px',
    padding:'4px 9px',
  },
  addBtn:{
    background:'#3E8AFF',
    color:'#fff',
    paddingInline:'9px',
    paddingBlock:'3px',
    gap:'8px',
    textTransform:'Capitalize',
    fontSize:'13px',
    lineHeight:'22px',
    boxShadow: '0px 4px 8px -4px #4C4E646B',
    flexWrap:'nowrap',
    '&:hover':{
      backgroundColor:'#3E8AFF',
      color:'#fff'
    }
  },
  importMenuBtn:{
    display: 'flex',
    gap: '10px',
    color: '#4C4E64'
  },
  menuContainer:{
    boxShadow:' 0px 4px 8px -4px #4C4E646B',
    width:'fit-content',
    minWidth:'200px'

  },
  exportIconContainer:{
    display:'flex',
    alignItems:'center',
    gap:'10px',
    fontSize:'14px',
    color:'#4C4E64DE'
  },
  backdrop: {
    zIndex: 111111,
    color: '#ffffff',
  },
  eachMenu:{
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    gap: '10px',
    padding: '3px 3px',
    color: '#4C4E64',
    fontSize:'14px'
  },
  
}

export default TopDisplay