import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import Axios from 'axios';

import {
  Typography
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import ConfigContext from 'src/contexts/ConfigContext';
import { CustomError } from 'src/types';
import { axiosDeleteHeaders, getLocalisedErrorString, appendContactSupport } from 'src/utils/helpers';
import CommonModal from 'src/shared/components/CommonModal/CommonModal';
import styles from './style';

interface PropTypes {
  open: boolean;
  id: number;
  handleClose: () => void;
}

const DeleteDocumentModal = ({
  open,
  id,
  handleClose,
}: PropTypes) => {
  const { t, ready } = useTranslation();
  const navigate = useNavigate();

  const {API} = useContext(ConfigContext);
  const { user } = useAuth();

  const onDeleteInvoice = async () => {
    try {
      const body = {
        company: user?.companyID,
        IDs: [id.toString()]
      };

      const response = await Axios.delete(
        API.updateInvoice,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), body)
      );
      if (response.data.success) {
        enqueueSnackbar(t('INVOICE_DELETE_SUCCESS'), {
          variant: 'success',
          autoHideDuration: 5000,
        });
        navigate('/documents');
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('INVOICE_STATUS_CHANGE_FAILED'), t), {
        variant: 'error',
      });
    }
  };

  return (
    <CommonModal
      open={open}
      title={t('INVOICE_DELETE_INVOICE')}
      cancelText={t('INVOICE_DELETE_CANCEL')}
      submitText={t('INVOICE_DELETE_CONFIRM')}
      handleClose={handleClose}
      onSubmit={onDeleteInvoice}
    >
      <Typography sx={styles.deleteMessageText}>
        {ready && t('INVOICE_DELETE_CONFIRMATION_MESSAGE')}
      </Typography>
    </CommonModal>
  );
};

export default DeleteDocumentModal;
