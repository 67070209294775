import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Table, TableBody, TableHead, Box, TableRow, TableCell, Tooltip, Avatar, Typography } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, ExitToApp as SwitchCompanyIcon } from '@mui/icons-material';

import { getDocumentType } from 'src/utils/helpers';
import CommonModal from 'src/shared/components/CommonModal/CommonModal';
import styles from './style';

type DataType = Record<string, string | number | boolean>;
type PropType= {
  data: DataType[],
  onDelete?: (_e:React.MouseEvent<HTMLElement>, _data: Record<string, string | number | boolean>) => Promise<void>;
  deleteText?: string;
  onEdit?: (_data:Record<string, string | number | boolean>) => void;
  onView?: (_data:Record<string, string | number | boolean>) => void;
  onSwitchCompany:(_event: React.MouseEvent<any>, _data: Record<string, string | number | boolean>) => void;
  onDataAction: (_data: Record<string, string | number | boolean>) => void;
}

const DimensionsTable = (props: PropType) => {
  const { ready, t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [actionData, setActionData] = useState<DataType | null>(null);
  const {data} = props;
  const onDelete = props?.onDelete ? props.onDelete : function(){};
  const onEdit = props?.onEdit ? props.onEdit : function(){};
  const onSwitchCompany = props?.onSwitchCompany ? props.onSwitchCompany : function(){}
  const deleteText = props?.deleteText? props?.deleteText : 'DIMENSION_DELETE_CLIENT_BTN'
  
  const handleShowModal = (e:React.MouseEvent<HTMLElement>, eData:Record<string, string | number | boolean>)=>{
    setOpenModal(true);
    setActionData(eData);
  }

  const onSubmit = (e:React.MouseEvent<HTMLElement>)=>{
    if(actionData){
      onDelete(e, actionData)
      setOpenModal(false);
    }
  }

  return (
    <>
      <Grid sx={styles.tableWrapper} className="xy-scroll">
        <Table size="small" sx={styles.table}>
          <TableHead>
            <TableRow sx={styles.tableHeadRow}>
              <TableCell sx={{...styles.tableHeadRowDisplay,...styles.bLeftTop}}>
                {ready && t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_NAME')}
              </TableCell>
              <TableCell sx={styles.tableHeadRowDisplay}>
                {ready && t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE')}
              </TableCell>
              <TableCell sx={styles.tableHeadRowDisplay}>
                {ready && t('ACCOUNT_SETTINGS_COMPANIES_CONNECTED_TO')}
              </TableCell>
              <TableCell sx={styles.tableHeadRowDisplay}>
                {ready && t('ACCOUNT_SETTINGS_COMPANIES_DOC_TYPE')}
              </TableCell>
              <TableCell sx={styles.tableHeadRowDisplay}>
                {ready && t('ACCOUNT_SETTINGS_COMPANIES_INVOICE_LIMIT')}
              </TableCell>
              <TableCell sx={styles.tableHeadRowDisplay}>
                {ready && t('ACCOUNT_SETTINGS_COMPANIES_OWNER')}
              </TableCell>
              <TableCell sx={{...styles.tableHeadRowDisplay,borderImage:'none',...styles.bRightTop}}>
                {ready && t('DOCUMENT_LIST_TABLE_HEADER_ACTIONS')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data && data?.length > 0
                ? data.map((eData:DataType,index:number) =>
                  <TableRow key={index} sx={styles.tableBodyRow}>
                    <TableCell sx={styles.tableRowDisplay}>
                      <Tooltip title={eData.name}>
                        <Typography sx={styles.text}>
                          {eData.name}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={styles.tableRowDisplay}>
                      <Typography sx={{...styles.highlightLabel}}>
                        {eData.isSubCompany ? 'Subsidiary' : 'Main'}
                      </Typography>
                    </TableCell>
                    <TableCell sx={styles.tableRowDisplay}>
                      <Tooltip title={eData.parentName}>
                        <Typography sx={{...styles.text}}>
                          {eData.parentName ? eData.parentName : '-'}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={styles.tableRowDisplay}>
                      <Tooltip title={t(getDocumentType(eData.documentType))}>
                        <Typography sx={styles.text}>
                          {t(getDocumentType(eData.documentType))}
                        </Typography>
                      </Tooltip >
                    </TableCell>
                    <TableCell sx={styles.tableRowDisplay}>
                      {
                        eData.invoicesLimit 
                        ? `${eData.invoicesUsed || 0}/${eData.invoicesLimit || 0}` : eData.invoicesUsed || '-'
                      }
                    </TableCell>
                    <TableCell sx={styles.tableRowDisplay}>
                      <Tooltip title={`${eData.firstname} ${eData.lastname}`}>
                        <Box sx={styles.avatarWrapper}>
                          <Avatar
                            alt="user"
                            sx={styles.userImage}
                            src={eData.avatar as string}
                          />
                          <Typography sx={styles.text}>
                            {`${eData.firstname} ${eData.lastname}`}
                          </Typography>
                        </Box>  
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{...styles.tableRowDisplay,borderImage:'none'}}>
                        <Typography sx={styles.actionIconContainer}>
                          <Tooltip title={t(`COMPANY_EDIT_TOOLTIP`)}>
                            <EditIcon onClick={()=>onEdit(eData)} sx={styles.actionIcon} />   
                          </Tooltip>
                          <Tooltip title={t(`COMPANY_SWITCH_TOOLTIP`)}>
                            <SwitchCompanyIcon
                              onClick={(e: React.MouseEvent<any>)=>onSwitchCompany(e,eData)}
                              sx={styles.actionIcon}
                            />
                          </Tooltip>
                          <Tooltip title={t(`COMPANY_DELETE_TOOLTIP`)}>
                            <DeleteIcon
                              onClick={(e:React.MouseEvent<any>)=>handleShowModal(e,eData)} 
                              sx={styles.actionIcon}
                            />
                          </Tooltip>
                        </Typography>
                    </TableCell>
                  </TableRow>)
                : <></>
            }
          </TableBody>
        </Table>
      </Grid>
      <CommonModal 
        open={openModal}
        title={`${t(`SETTING_DELETE_COMPANY_TITLE`)} “${actionData?.name}“?`}
        cancelText={t('SUPPLIERS_CANCEL') || 'Cancel'}
        submitText={t(deleteText) || 'Delete'}
        handleClose={()=>setOpenModal(false)}
        onSubmit={(e:React.MouseEvent<HTMLElement>) => {onSubmit(e)}}
      >
        <Box>
          { ready && t(`SETTING_DELETE_COMPANY_DESCRIPTION`) }
        </Box>
      </CommonModal>
    </>
  )
}

export default DimensionsTable