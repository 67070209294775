import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { Box, TablePagination as Pagination, useMediaQuery } from "@mui/material";


type PropTypes = {
  total: number;
  onPageChange?: (_page: number) => void;
  onLimitChange?: (_limit: number) => void;
  limit:number,
  page:number
}
const TablePagination = (props:PropTypes) => {
  const {
    total,
    onPageChange = function(){},
    onLimitChange = function(){},
    limit,
    page,
  } = props;
  const { t } = useTranslation();
  const handlePageChange = (event:React.MouseEvent<HTMLButtonElement> | null, page:number) => {
    onPageChange(page);
  }
  const handleLimitChange = (event:React.ChangeEvent<HTMLInputElement>)=>{
    const tempLimit = event?.target?.value;
    onLimitChange(parseInt(tempLimit,10));
  }
  const maxWidthRows = useMediaQuery('(max-width: 625px)');

  return (
    <Box>
      <Pagination 
          component="div"
          count={total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 50]}
          labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
          sx={{ 
            '& .MuiTablePagination-actions': { marginLeft: '0px' },
            '& .MuiTablePagination-select': { marginRight: '0px' }
          }}
      />
    </Box>
  )
}

export default memo(TablePagination);