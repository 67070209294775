import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import FileSaver from 'file-saver';

import {
  Box, Grid, Typography, TextField, Autocomplete, Select, MenuItem, Paper, Button,
  IconButton, Checkbox, FormControlLabel, Switch, Tooltip,
} from '@mui/material';
import {
  ModeEdit as EditSupplierIcon,
  AddCircle as ShowIcon,
  Cancel as HideIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Send as SendIcon,
  Info as InfoIcon,
} from '@mui/icons-material';

import ConfigContext from 'src/contexts/ConfigContext';
import useWidth from 'src/hooks/useWidth';
import { useAuth } from 'src/hooks/useAuth';
import {
  CustomError, DocumentDeliveryFormatType, DocumentLineType, ExportMappingType, FieldType,
  DocType, SupplierType
} from 'src/types';
import {
  appendContactSupport, axiosHeaders, axiosHeadersWithArrayBuffer, getLocalisedErrorString,
  sendFeedback, isInvoiceFieldAvailable, isTaxNumberSwitchAvailable, validateEmail,
} from 'src/utils/helpers';
import {
  getCurrencyLabel, getIsError, getTermsLabel, getSupplierLabel, getCountryLabel,
  createSupplierMap, initializeSupplierCandidates, senderRelatedFields, receiverRelatedFields,
  selectLabelPrefixes, getVATLineTooltip, vatLineLabels, esLineLabels, getFieldTooltip,
  isAmountTallyingWithLines, getBorderColor, getFieldTrainedCount, isVatAmountTallyingWithVatLines
} from 'src/document-edit/utils';
import {
  DocumentEditFormVatEsLineType
} from 'src/document-edit/documentTypes';
import { documentEditSelectValues, documentTypes } from 'src/config';
import { IHighlight } from 'src/pdf-highligher';
import { getIconOnTrustScore } from 'src/dashboard-new/utils';
import DocumentEditActionButtons from 'src/document-edit/components/DocumentEditActionButtons/DocumentEditActionButtons';
import CreateSupplierModal from 'src/dimensions/suppliers/components/CreateSupplierModal/CreateSupplierModal';
import EditSupplierModal from 'src/dimensions/suppliers/components/EditSupplierModal/EditSupplierModal';
import DocumentHeader from 'src/document-edit/components/DocumentHeader/DocumentHeader';
import SpreadInvoice from 'src/document-edit/components/SpreadDocument/SpreadDocument';
import ExportToEmailModal from 'src/document-edit/components/ExportToEmailModal/ExportToEmailModal';
import VatESDetailsTopLineComponentNew from 'src/document-edit/components/VatESDetailsTopLineComponentNew';
import ManageExportFieldsNew from 'src/shared/components/ManageExportFieldsNew/ManageExportFieldsNew';
import DocumentView from 'src/document-edit/components/DocumentView/DocumentView';
import NewFieldTag from 'src/document-edit/icons/NewFieldTag';
import styles from './style';

interface PropTypes {
  fields: FieldType[];
  fieldsToDisplay: FieldType[];
  lineFields: FieldType[];
  supplierFields: FieldType[];
  doc: DocType;
  details: Record<string, string>;
  detailsCandidates: Record<string, string[]>;
  lineDetails: DocumentLineType[];
  statusChangeLoading: boolean;
  previousInvoice: DocType | null;
  nextInvoice: DocType | null;
  suppliers: SupplierType[];
  qbTerms: { code: string, name: string }[];
  isSubmitClicked: boolean;
  fieldTrustScores: Record<string, number>;
  fieldTrustScoresRef: React.MutableRefObject<Record<string, number>>;
  parameterDocCompany: string;
  doNotPay: boolean;
  vatLinesRef: React.MutableRefObject<DocumentEditFormVatEsLineType[]>;
  esLinesRef: React.MutableRefObject<DocumentEditFormVatEsLineType[]>
  tags: string[];
  selectedTextField: FieldType;
  submitting: boolean;
  showDetails: Record<string, boolean>;
  selectedTextFieldRef: React.MutableRefObject<FieldType>;
  headerEntity: string | undefined;
  highlights: IHighlight[];
  isMouseDown: boolean;
  isMouseDownRef: React.MutableRefObject<boolean>;
  decimalSeparator: string;
  calculateVat: boolean;
  setDoNotPay: (_doNotPay: boolean) => void;
  handleChange: (_val: string, _prop: string) => void;
  handleChangeMain: (_field: FieldType, _value: string | null) => void;
  handleChangeText: (_field: FieldType, _val: string) => void;
  handleChangeVatEsLine: (
    _val: string,
    _i: number,
    _prop: keyof DocumentEditFormVatEsLineType,
    _key: string,
  ) => void;
  handleSubmit: (_bgSave?: boolean) => void;
  setLoading: (_loading: boolean) => void;
  setStatusChangedRef: (_statusChanged: boolean) => void;
  getDocument: (_doNotFetchSuppliers?: boolean) => void;
  getSupplierByCode: (_code: string) => void;
  navigateInvoice: (_inv: DocType | null, _direction: number) => void;
  findAllSuppliers: () => void;
  openLinesComponent: () => void;
  handleStatusChange: (_status: number, _asstID: string) => void;
  handleOpenManageFields: (_entity: string) => void;
  openInfoModal: () => void;
  saveFieldsSelection: (_newField: string, _oldField: string) => Promise<void>;
  setVatLines: (_val: DocumentEditFormVatEsLineType[]) => void;
  setEsLines: (_val: DocumentEditFormVatEsLineType[]) => void;
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  setIsSubmitClicked: (_val: boolean) => void;
  setMoveToNext: (_val: number | null) => void;
  handleChooseValidatorModalOpen: () => void;
  setSelectedTextField: (_val: FieldType) => void;
  setShowDetails: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  setFieldTrustScores: (_val: Record<string, number>) => void;
  pauseInvoiceAnnotateTime: () => void;
  restartInvoiceAnnotateTime: () => void;
  handleClose?:Function;
  moveNext?:Function;
  movePrevious?:Function;
  disableMove?:boolean[];
}

const DocumentFields = (props: PropTypes) => {
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {API} = useContext(ConfigContext);
  const { user } = useAuth();

  const isAutoNavigationAllowed = user?.customisations.includes('autoNavigation') || false;
  const isSupplierLabelWithVAT = user?.customisations.includes('supplierLabelWithVAT') || false;
  const custom2FieldInDocumentForm = user?.customisations.includes('custom2FieldInDocumentForm') || false;
  const isTwinfield = user?.integrations.includes('tf')

  const {
    fields,
    fieldsToDisplay,
    lineFields,
    supplierFields,
    doc,
    details,
    detailsCandidates,
    lineDetails,
    statusChangeLoading,
    previousInvoice,
    nextInvoice,
    suppliers,
    qbTerms,
    isSubmitClicked,
    fieldTrustScores,
    fieldTrustScoresRef,
    parameterDocCompany,
    doNotPay,
    vatLinesRef,
    esLinesRef,
    tags,
    selectedTextField,
    submitting,
    showDetails,
    selectedTextFieldRef,
    headerEntity,
    highlights,
    isMouseDown,
    isMouseDownRef,
    decimalSeparator,
    calculateVat,
    setDoNotPay,
    handleChange,
    handleChangeMain,
    handleChangeText,
    handleChangeVatEsLine,
    handleSubmit,
    setLoading,
    setStatusChangedRef,
    getDocument,
    getSupplierByCode,
    navigateInvoice,
    findAllSuppliers,
    openLinesComponent,
    handleStatusChange,
    handleOpenManageFields,
    openInfoModal,
    saveFieldsSelection,
    setVatLines,
    setEsLines,
    setTags,
    setIsSubmitClicked,
    setMoveToNext,
    handleChooseValidatorModalOpen,
    setSelectedTextField,
    setShowDetails,
    setFieldTrustScores,
    pauseInvoiceAnnotateTime,
    restartInvoiceAnnotateTime,
    handleClose,
    moveNext,
    movePrevious,
    disableMove,
  } = props;

  const isSpreadFieldsNotEmpty = () => Boolean(doc.spreadFrom && doc.spreadTo && doc.gla);
  const [spreadInvoice, setSpreadInvoice] = useState<boolean>(false);
  const [spreadSuccess, setSpreadSuccess] = useState<boolean>(false);

  const [renderedFields, setRenderedFields] = useState<FieldType[]>([]);
  const [senderFields, setSenderFields] = useState<(FieldType | undefined)[]>([]);
  const [receiverFields, setReceiverFields] = useState<(FieldType | undefined)[]>([]);
  const [deliverFormats, setDeliverFormats] = useState<DocumentDeliveryFormatType[]>([]);
  const [excelExportFieldMapping, setExcelExportFieldMapping] = useState<ExportMappingType[]>([]);
  const [datExportFieldMapping, setDatExportFieldMapping] = useState<ExportMappingType[]>([]);
  const [csvExportFieldMapping, setCsvExportFieldMapping] = useState<ExportMappingType[]>([]);
  const [showExportFieldMappings, setShowExportFieldMappings] = useState<boolean>(false);

  const [addSupplierOpen, setAddSupplierOpen] = useState<boolean>(false);
  const [newSupplierCreditorCode, setNewSupplierCreditorCode] = useState<string>('');
  const [newSupplier, setNewSupplier] = useState<SupplierType>({
    creditorCode: '',
    creditorName: doc.senderCompany || '',
    website: doc.senderWebsite || '',
    vatNumber: doc.senderVatNumber || '',
    defaultCurrency: doc.currency || '',
    iban: doc.senderIban || '',
    telephoneNumber: doc.senderPhone || '',
    faxNumber: doc.senderFax || '',
    emailAddress: doc.senderEmail || '',
    dueDays: '45',
    country: 'ES',
  });
  const [editSupplierOpen, setEditSupplierOpen] = useState<boolean>(false);
  const [selectedSupplierId, setSelectedSupplierId] = useState<string>('-1');

  const supplierCandidatesi = [...suppliers.filter((s) => s.type !== 'receiver').map((s) => s.creditorCode)];
  const receiverCandidatesi =[...suppliers.filter((s) => s.type === 'receiver').map((s) => s.creditorCode)];
  const [supplierCandidates, setSupplierCandidates] = useState<string[]>(initializeSupplierCandidates(supplierCandidatesi, details.supplier));
  const [receiverCandidates, setReceiverCandidates] = useState<string[]>(initializeSupplierCandidates(receiverCandidatesi, details.receiver));
  const suppliersMap = createSupplierMap(suppliers);

  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [emails, setEmails] = useState(user?.deliverEmails && user?.deliverEmails?.length > 0 ? user.deliverEmails.join(', ') : user?.email || '');
  const [selectedDataFormatToEmail, setSelectedDataFormatToEmail] = useState(user?.deliverFormat || '');

  const [exportFormat, setExportFormat] = useState<DocumentDeliveryFormatType>({
    key: 'excel',
    label: 'Excel',
    fileExtension: 'xlsx',
    active: true,
  });

  const defaultFieldRef = useRef<HTMLInputElement | null>(null);
  // const defaultFieldWidth = useWidth(defaultFieldRef, 0);

  const fieldsContainerRef = useRef<HTMLDivElement | null>(null);
  const fieldsContainerWidth = useWidth(fieldsContainerRef, 0);

  useEffect(() => {
    const supplierCandidatesi = [...suppliers.filter((s) => s.type !== 'receiver').map((s) => s.creditorCode)];
    const receiverCandidatesi =[...suppliers.filter((s) => s.type === 'receiver').map((s) => s.creditorCode)];
    setSupplierCandidates(initializeSupplierCandidates(supplierCandidatesi, details.supplier));
    setReceiverCandidates(initializeSupplierCandidates(receiverCandidatesi, details.receiver));
  }, [suppliers]);

  const handleSendEmailModalClose = () => {
    setSendEmailModalOpen(false);
  };

  const handleExportFieldMappingsClose = (updated?: boolean, format?: DocumentDeliveryFormatType) => {
    setShowExportFieldMappings(false);
    if (updated && format) {
      setTimeout(async () => {
        await getExcelExportFieldMapping();
        await getDatExportFieldMapping();
        await getCsvExportFieldMapping();
        handleDataFormatToExportClick(format, true);
      }, 1000);
    }
  };

  const getDeliverFormats = async () => {
    try {
      const response = await Axios.get(
        API.getDeliverFormats,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDeliverFormats(response.data.data);
        }
      }
    } catch (error) {
      setDeliverFormats([]);
    }
  };

  const getExcelExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}excel`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setExcelExportFieldMapping(response.data.data);
          return;
        }
      }
      setExcelExportFieldMapping([]);
    } catch (error) {
      setExcelExportFieldMapping([]);
    }
  };

  const getDatExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}dat`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDatExportFieldMapping(response.data.data);
          return;
        }
      }
      setDatExportFieldMapping([]);
    } catch (error) {
      setDatExportFieldMapping([]);
    }
  };

  const getCsvExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}csv`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setCsvExportFieldMapping(response.data.data);
          return;
        }
      }
      setCsvExportFieldMapping([]);
    } catch (error) {
      setCsvExportFieldMapping([]);
    }
  };

  useEffect(() => {
    getExcelExportFieldMapping();
    getDatExportFieldMapping();
    getCsvExportFieldMapping();
    getDeliverFormats();
    if (details.supplier) {
      getSupplierByCode(details.supplier);
    }
  }, []);

  useEffect(() => {
    setRenderedFields(fields.filter((f) => f.isAvailable && f.isActive));
    setSenderFields(fields.filter((field) => senderRelatedFields.includes(field.key)).sort((a, b) => a?.position - b?.position));
    setReceiverFields(fields.filter((field) => receiverRelatedFields.includes(field.key)).sort((a, b) => a?.position - b?.position));
  }, [fields]);

  useEffect(() => {
    if (user?.deliverEmails && user.deliverEmails.length > 0) {
      setEmails(user.deliverEmails.join(', '));
    }

    if (user?.deliverFormat) {
      setSelectedDataFormatToEmail(user.deliverFormat);
    } else {
      const activeDeliverFormat = deliverFormats.find((o) => o.active);
      setSelectedDataFormatToEmail(activeDeliverFormat ? activeDeliverFormat.key : deliverFormats[0]?.key);
    }
  }, [user, deliverFormats]);

  const handleSpreadInvoiceChange = () => {
    setSpreadInvoice((prevState) => !prevState);
  };

  const handleAutoSaveInvoice = async (toStatus?: number) => {
    if (!isSubmitClicked) {
      try {
        await handleSubmit();
        if (toStatus === 502) {
          handleStatusChange(502, doc.assistantID || '0');
        } else {
          handleChooseValidatorModalOpen();
        }
        return;
      } catch (error) {
        return;
      }
    }
    if (toStatus === 502) {
      handleStatusChange(502, doc.assistantID || '0');
    } else {
      handleChooseValidatorModalOpen();
    }
  };

  const handleDataFormatToExportClick = async (dataFormat: DocumentDeliveryFormatType, executeExport = false) => {
    if (((dataFormat.key === 'excel' && excelExportFieldMapping.length === 0)
      || (dataFormat.key === 'dat' && datExportFieldMapping.length === 0)
      || (dataFormat.key === 'csv' && csvExportFieldMapping.length === 0))
      && !executeExport) {
      setExportFormat(dataFormat);
      setShowExportFieldMappings(true);
      return;
    }

    try {
      setLoading(true);
      let url = '';
      let newWindow = null;

      url = `${API.export}${dataFormat.key}/${doc.assistantID}`;

      const header = (dataFormat.key !== 'quickbooks'
        && dataFormat.key !== 'holded'
        && dataFormat.key !== 'gstock'
        && dataFormat.key !== 'sftp-sender'
      )
        ? axiosHeadersWithArrayBuffer(localStorage.getItem('PROCYS_accessToken'))
        : axiosHeaders(localStorage.getItem('PROCYS_accessToken'));

      const response = await Axios.get(
        url,
        header
      );
      if (dataFormat.key === 'csv') {
        newWindow = window.open(response.data, '_blank');
      }

      if (dataFormat.key !== 'quickbooks'
        && dataFormat.key !== 'holded'
        && dataFormat.key !== 'gstock'
        && dataFormat.key !== 'sftp-sender'
      ) {
        const fileName = doc.assistantID?.toString().concat('.').concat(dataFormat.fileExtension);
        const blob = new Blob([response.data], { type: 'blob' });
        FileSaver.saveAs(blob, fileName);
        if (newWindow) {
          newWindow.close();
        }
      } else {
        enqueueSnackbar(t('EXPORT_SCHEDULED_SUCCESS'), {
          variant: 'success',
          autoHideDuration: 5000
        });
      }

      setStatusChangedRef(true);
      getDocument(true);
      sendFeedback(API.feedbackLogs, 'invoice export', user?.email, user?.companyID);
      setLoading(false);
      if (isAutoNavigationAllowed) {
        handleNavigateInvoice(nextInvoice, 1);
      }
    } catch (e) {
      const error = e as CustomError;
      let errString = error?.response?.data?.i18n;

      // if (dataFormat.key !== 'quickbooks' && dataFormat.key !== 'holded' && dataFormat.key !== 'gstock') {
      //   const buffer = error.response?.data;
      //   const view = new Uint8Array(buffer);
      //   const decodedObject = String.fromCharCode.apply(null, view);
      //   const errorObject = JSON.parse(decodedObject);
      //   errString = errorObject?.i18n;
      // }

      enqueueSnackbar(appendContactSupport(window.config.support_email, getLocalisedErrorString(errString || 'INVOICE_EXPORT_DATA_FAILURE', t), t), {
        variant: 'error',
        autoHideDuration: 5000
      });
      getDocument(true);
      setLoading(false);
    }
  };

  const handleSendToEmailClick = async () => {
    if (!emails || !emails.trim()) {
      enqueueSnackbar(t('PROCYS_VALIDATE_EMAIL_EMPTY'), {
        variant: 'error',
        autoHideDuration: 5000
      });
      return;
    }
    const emailsList = [];
    if (emails.indexOf(',') >= 0) {
      const emailsAr = emails.split(',');
      for (let i = 0; i < emailsAr.length; i++) {
        if (emailsAr[i] && emailsAr[i].trim()) {
          const resp = validateEmail(emailsAr[i].trim());
          if (!resp.isValid) {
            enqueueSnackbar(t('PROCYS_VALIDATE_EMAIL_INVALID'), {
              variant: 'error',
              autoHideDuration: 5000
            });
            return;
          }

          emailsList.push(emailsAr[i]);
        }
      }
    } else {
      const resp = validateEmail(emails.trim());
      if (!resp.isValid) {
        enqueueSnackbar(t(resp.errorMessage || 'PROCYS_VALIDATE_EMAIL_INVALID'), {
          variant: 'error',
          autoHideDuration: 5000
        });
        return;
      }

      emailsList.push(emails.trim());
    }

    try {
      setLoading(true);
      const resp = await Axios.post(
        `${API.exportToEmail}${selectedDataFormatToEmail}`,
        {
          assistantIDS: [doc.assistantID?.toString() || '000000'],
          emails: emailsList
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        setStatusChangedRef(true);
        sendFeedback(API.feedbackLogs, 'invoice export', user?.email, user?.companyID);
        enqueueSnackbar(t('INVOICE_SEND_TO_EMAIL_SUCCESS'), {
          variant: 'success',
          autoHideDuration: 5000
        });
        getDocument(true);
        setLoading(false);
        if (isAutoNavigationAllowed) {
          handleNavigateInvoice(nextInvoice, 1);
        }
      } else {
        enqueueSnackbar(t('INVOICE_SEND_TO_EMAIL_FAILURE'), {
          variant: 'error',
          autoHideDuration: 5000
        });
        setLoading(false);
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(error?.response?.data?.i18n || 'INVOICE_SEND_TO_EMAIL_FAILURE', t), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
      setLoading(false);
    }

    handleSendEmailModalClose();
  };

  const handleNavigateInvoice = async (toInvoice: DocType | null, direction: number) => {
    setLoading(true);
    setMoveToNext(direction);
    if (!isSubmitClicked && (doc.status === 500 || doc.status === 501 || doc.status === 509)) {
      try {
        await handleSubmit();
      } catch (error) {
        // do nothing
      }
    } else {
      setMoveToNext(null);
      navigateInvoice(toInvoice, direction);
    }
  };

  const handleTaxNumberSwitch = (key: string) => {
    let newField = '';
    if (key.includes('sender')) {
      newField = newField.concat('sender');
    }
    if (key.includes('receiver')) {
      newField = newField.concat('receiver');
    }
    if (key.includes('TaxNumber')) {
      newField = newField.concat('VatNumber');
    }
    if (key.includes('VatNumber')) {
      newField = newField.concat('TaxNumber');
    }
    saveFieldsSelection(newField, key);
  };

  const addNewSupplier = () => {
    setNewSupplier({
      creditorCode: newSupplierCreditorCode,
      creditorName: details.senderCompany || '',
      website: details.senderWebsite || '',
      vatNumber: details.senderVatNumber || '',
      defaultCurrency: details.currency || '',
      iban: details.senderIban || '',
      telephoneNumber: details.senderPhone || '',
      faxNumber: details.senderFax || '',
      emailAddress: details.senderEmail || '',
      address1: details.senderAddress || '',
      city: details.senderCity || '',
      poBoxPostalCode: details.senderPostalCode || '',
      poBoxCity: details.senderCity || '',
      dueDays: '45',
      country: 'ES',
    });
    setAddSupplierOpen(true);
    pauseInvoiceAnnotateTime();
  };

  const handleCloseSupplierModal = () => {
    setAddSupplierOpen(false);
    restartInvoiceAnnotateTime();
  };

  const editSupplier = (id: string) => {
    if (details.supplier) {
      handleChangeMain({
        key: 'supplier',
        assistantKey: 'supplier',
        label: 'INVOICE_EDIT_FORM_SUPPLIER',
        appDbField: 'supplier',
        dataType: 'text',
        exportMapKey: 'INVOICE_SUPPLIER',
        position: -1,
      }, selectedSupplierId);
      setSelectedSupplierId(id);
      setEditSupplierOpen(true);
      pauseInvoiceAnnotateTime();
    }
  };

  const handleEditSupplierClose = () => {
    handleChangeMain({
      key: 'supplier',
      assistantKey: 'supplier',
      label: 'INVOICE_EDIT_FORM_SUPPLIER',
      appDbField: 'supplier',
      dataType: 'text',
      exportMapKey: 'INVOICE_SUPPLIER',
      position: -1,
    }, selectedSupplierId);
    setSelectedSupplierId('-1');
    setEditSupplierOpen(false);
    restartInvoiceAnnotateTime();
  };

  const onBlurSupplier = (key: string) => {
    const supplierCode = details[key];
    if (supplierCode) {
      const supp = suppliers.find(s => s.creditorCode === supplierCode);
      if (!supp) {
        setNewSupplierCreditorCode(supplierCode);
        return;
      }
    }
    setNewSupplierCreditorCode('');
  };

  const onSupplierCreated = (creditorCode: string) => {
    handleCloseSupplierModal();
    findAllSuppliers();
    handleChangeMain({
      key: 'supplier',
      assistantKey: 'supplier',
      label: 'INVOICE_EDIT_FORM_SUPPLIER',
      appDbField: 'supplier',
      dataType: 'text',
      exportMapKey: 'INVOICE_SUPPLIER',
      position: -1,
    }, creditorCode);
    setNewSupplierCreditorCode('');
  };

  const getFieldLabel = (key: string, option: string) => {
    if (key === 'currency') {
      return getCurrencyLabel(option);
    }
    if (key === 'countryIssued' || key.includes('CountryCode')) {
      return getCountryLabel(option);
    }
    if (key === 'terms') {
      return getTermsLabel(option, qbTerms);
    }
    return option;
  };

  const handleSupplierTextChange = (
    key: string,
    val: string,
    candidatesi: string[],
    setCandidates: (_value: React.SetStateAction<string[]>) => void
  ) => {
    const currentSupplier = details[key] || '';
    if (val === '' || currentSupplier === '') {
      return;
    }
    const foundVal = candidatesi.some(c => c === val);
    const foundCurrSupplier = candidatesi.some(c => c === currentSupplier);
    const newCandidates = !foundVal ? [val, ...candidatesi] : candidatesi;
    if (!foundCurrSupplier && currentSupplier !== val) {
      newCandidates.unshift(currentSupplier);
    }

    setCandidates(newCandidates);
  };

  const handleAddVatLine = (
    key: string,
    _lines: DocumentEditFormVatEsLineType[],
    _setLines: (_val: DocumentEditFormVatEsLineType[]) => void,
  ) => {
    if (_lines.length < 20) {
      _setLines([..._lines, {
        line: _lines.length + 1, taxName: '', baseAmount: '0', rate: '0', amount: '0', error: false, invalid: false
      }]);
      setIsSubmitClicked(false);
    } else {
      enqueueSnackbar(t(key === 'VAT' ? 'INVOICE_EDIT_FORM_ADD_VAT_LINE_LIMIT_TOOLTIP' : 'INVOICE_EDIT_FORM_ADD_SURCHARGE_LINE_LIMIT_TOOLTIP'), {
        variant: 'error',
        autoHideDuration: 5000
      });
    }
  };

  const handleDeleteVatLine = (
    i: number,
    _lines: DocumentEditFormVatEsLineType[],
    _setLines: (_val: DocumentEditFormVatEsLineType[]) => void
  ) => {
    const filteredVatLines: DocumentEditFormVatEsLineType[] = [];
    for (let j = 0; j < i; j++) {
      filteredVatLines.push(_lines[j]);
    }
    if ((i + 1) < _lines.length) {
      for (let j = i + 1; j < _lines.length; j++) {
        const newVl = {
          ..._lines[j],
          line: (_lines[j].line - 1)
        };
        filteredVatLines.push(newVl);
      }
    }

    if (calculateVat) {
      const newVatAmount = filteredVatLines.reduce(
        (accumulator, currentValue) => accumulator + parseFloat((currentValue.amount || '0').replace(decimalSeparator, '.')), 0
      );
      handleChange(newVatAmount.toFixed(2).replace('.', decimalSeparator), 'vatAmount');
    }

    _setLines(filteredVatLines);
    setIsSubmitClicked(false);
  };

  const onFieldFocus = (field: FieldType) => {
    if (isMouseDownRef.current) {
      const element = document.getElementById(field.key);
      if (element) {
        element.blur();
      }
      return;
    }
    setSelectedTextField(field);
  };

  const onFieldBlur = (key: string, isError: boolean, position: number) => {
    setTimeout(() => {
      const tipElement = document.getElementById('Tip');
      if (selectedTextFieldRef.current.key !== key && !tipElement && !isError) {
        setFieldTrustScores({ ...fieldTrustScoresRef.current, [key]: 2 });
      }
      if (position > 0 && position % 5 === 0
        && (doc.status === 500 || doc.status === 501 || doc.status === 502 || doc.status === 509)
        && selectedTextFieldRef.current.key !== key
      ) {
        handleSubmit(true);
      }
    }, 1000);
  };

  const handleSendNewFieldData = async (key: string) => {
    const highlight = highlights.find(h => h.procysKey === key);
    if (highlight) {
      try {
        const response = await Axios.post(
          `${API.fieldsTraining}`,
          {
            assistantID: doc.assistantID.toString(),
            annotations: [{
              name: key,
              value: details[key],
              x1: highlight.position.boundingRect.x1.toString(),
              x2: highlight.position.boundingRect.x2.toString(),
              y1: highlight.position.boundingRect.y1.toString(),
              y2: highlight.position.boundingRect.y2.toString(),
              page: highlight.position.pageNumber.toString(),
            }]
          },
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        if (response.data.success) {
          enqueueSnackbar(
            t('NEW_FIELD_TRAINING_DATA_SENT_SUCCESS'),
            {
              variant: 'success',
              autoHideDuration: 5000
            }
          );
          getDocument(true);
        }
      } catch (e) {
        const error = e as CustomError;
        let errorMessage = appendContactSupport(window.config.support_email, t('NEW_FIELD_TRAINING_DATA_SENT_FAILURE'), t);
        if (error && error.response && error.response.data) {
          errorMessage = t(error.response.data.i18n || '');
        }
  
        enqueueSnackbar(
          errorMessage,
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    }
  };

  const renderOptions = (children: any, key: string, haveSuggestions: boolean) => (
    <Paper sx={{width: '100%'}}>
      {key === 'supplier' && (
        <Button
          color="primary"
          fullWidth
          sx={styles.addNewSupplierBtn}
          onMouseDown={addNewSupplier}
        >
          {`+ ${t('DOCUMENT_EDIT_ADD_NEW_SUPPLIER')}`}
        </Button>
      )}
      {haveSuggestions && <Box sx={{ ...styles.suggestionsContainer, justifyContent: key === 'supplier' ? 'flex-start' : 'flex-end' }}>
        <Typography sx={styles.suggestionsText}>{t('DOCUMENT_EDIT_SUGGESTIONS')}</Typography>
      </Box>}
      {children}
    </Paper>
  );

  const renderShowClientDetails = (key: string, showLabel: string, hideLabel: string) => {
    const show = showDetails[key];
    return (
      <Grid container sx={styles.field}>
        <Grid item xs={6} sx={styles.fieldLabelContainer} />
        <Grid item xs={6} sx={styles.fieldValueContainer}>
          <Button
            variant="text"
            onClick={() => setShowDetails({
              ...showDetails,
              [key]: !show
            })}
            sx={styles.showDetailsBtn}
          >
            {show ? <HideIcon sx={styles.showDetailsIcon} /> : <ShowIcon sx={styles.showDetailsIcon} />}
            {show ? t(hideLabel) : t(showLabel)}
          </Button>
        </Grid>
      </Grid>
    )
  };

  const renderField = (position: number, field: FieldType, error: boolean, trustScore: number) => {
    let { key , assistantKey } = field;
    const val = details[key];
    switch (key) {
      case 'lines':
        return (
          <Button
            variant="contained"
            style={styles.viewLinesBtn}
            onClick={openLinesComponent}
            onFocus={() => onFieldFocus({
              key: '',
              assistantKey: '',
              label: '',
              appDbField: '',
              dataType: '',
              exportMapKey: '',
              position: -1,
            })}
          >
            {t('DOCUMENT_EDIT_VIEW_LINE_ITEMS', { count: lineDetails.length })}
          </Button>
        );

      case 'doNotPay':
        return (
          <Box sx={styles.doNotPayContainer}>
            <Checkbox
              checked={doNotPay}
              onChange={(e) => setDoNotPay(e.target.checked)}
              sx={styles.doNotPayCheckbox}
              onFocus={() => onFieldFocus({
                key: '',
                assistantKey: '',
                label: '',
                appDbField: '',
                dataType: '',
                exportMapKey: '',
                position: -1,
              })}
            />
          </Box>
        );

      case 'period': case 'description':
        return (
          <TextField
            fullWidth
            id={key}
            name={key}
            onChange={(event) => handleChange(event.target.value, key)}
            required
            value={details[key]}
            onFocus={() => onFieldFocus(field)}
            onBlur={() => onFieldBlur(key, error, position)}
            variant="outlined"
            sx={{
              ...styles.textField,
              '& .MuiOutlinedInput-root': {
                backgroundColor: selectedTextField.key === key ? '#3E8AFF4D' : '#FFFFFF',
                '& fieldset': {
                  border: `2px solid ${getBorderColor(key, trustScore, error, details)}`,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${getBorderColor(key, trustScore, error, details)}`,
                }
              },
            }}
            error={error}
          />
        );

      case 'documentType': case 'secondaryDocumentType': case 'typeOfTransaction': case 'paymentForm':
        return (
          <Select
            id={key}
            fullWidth
            onChange={(e) => handleChangeMain(field, e.target.value)}
            onFocus={() => onFieldFocus({
              key: '',
              assistantKey: '',
              label: '',
              appDbField: '',
              dataType: '',
              exportMapKey: '',
              position: -1,
            })}
            onBlur={() => onFieldBlur(key, error, position)}
            sx={{
              ...styles.selectText,
              '& .MuiOutlinedInput-notchedOutline': {
                border: `2px solid ${getBorderColor(key, trustScore, error, details)}`,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${getBorderColor(key, trustScore, error, details)}`,
              },
            }}
            value={details[key]}
            disabled={doc.status < 500}
            required
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
            }}
          >
            {
              documentEditSelectValues[key].map((s) => {
                if (key === 'documentType' && user?.documentType !== 'id' && s === documentTypes.passportOrId) {
                  return null;
                }
                return (
                  <MenuItem sx={styles.selectText} key={s} value={s}>
                    {t(`${selectLabelPrefixes[key]}${s}`)}
                  </MenuItem>
                );
              })
            }
          </Select>
        );

      case 'supplier': case 'receiver': {
        const clientCode = details[key];
        const isReceiver = key === 'receiver';
        return (
          <Grid ref={defaultFieldRef} sx={styles.supplierRow}>
            <Autocomplete
              fullWidth
              id={key}
              open={selectedTextField.key === key && !isMouseDown}
              value={details[key]}
              options={isReceiver ? receiverCandidates : supplierCandidates}
              getOptionLabel={(option) => getSupplierLabel(suppliersMap[option] || option, isSupplierLabelWithVAT)}
              isOptionEqualToValue={(option: string, value: string) => option === value}
              freeSolo
              PaperComponent={
                ({ children }) => renderOptions(children, key, isReceiver ? receiverCandidates.length > 0 : supplierCandidates.length > 0)
              }
              ListboxProps={{ sx: {
                ...styles.listBox,
                '& .MuiAutocomplete-option': {
                  justifyContent: 'flex-start',
                  textAlign: 'left' as const,
                  padding: '5px 10px !important',
                },
              }}}
              openOnFocus
              disableClearable
              onFocus={() => onFieldFocus(field)}
              onBlur={() => onBlurSupplier(key)}
              onChange={(e, v) => handleChangeMain(field, v)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: '0px 2px 0px 0px !important',
                  backgroundColor: selectedTextField.key === key ? '#3E8AFF4D' : '#FFFFFF',
                  '& fieldset': {
                    border: `2px solid ${getBorderColor(key, trustScore, error, details)}`,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${getBorderColor(key, trustScore, error, details)}`,
                  }
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputProps={params.InputProps}
                  onChange={(event) => handleSupplierTextChange(
                    key,
                    event.target.value,
                    isReceiver ? receiverCandidatesi : supplierCandidatesi,
                    isReceiver ? setReceiverCandidates : setSupplierCandidates,
                  )}
                  onBlur={() => onFieldBlur(key, error, position)}
                  error={error}
                  sx={styles.textField}
                />
              )}
            />
            {selectedSupplierId !== '-1' && clientCode && !isReceiver && (
              <IconButton onClick={() => editSupplier(clientCode)} sx={styles.editSupplierBtn}>
                <EditSupplierIcon sx={styles.editSupplierIcon} />
              </IconButton>
            )}
          </Grid>
        );
      }

      case 'senderCountryCode': case 'receiverCountryCode':
        return (
          <Autocomplete
            id={key}
            fullWidth
            onChange={(e, v) => handleChangeMain(field, v.toUpperCase())}
            value={val}
            options={detailsCandidates[assistantKey]}
            getOptionLabel={(option) => getFieldLabel(key, option)}
            isOptionEqualToValue={(option: string, value: string) => option === value}
            disableClearable
            openOnFocus
            onFocus={() => onFieldFocus({
              key: '',
              assistantKey: '',
              label: '',
              appDbField: '',
              dataType: '',
              exportMapKey: '',
              position: -1,
            })}
            freeSolo
            onBlur={() => onFieldBlur(key, error, position)}
            sx={{
              '& .MuiOutlinedInput-root': {
                padding: '0px 0px 0px 0px !important',
                '& fieldset': {
                  border: `2px solid ${getBorderColor(key, trustScore, error, details)}`,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${getBorderColor(key, trustScore, error, details)}`,
                },
              },
              ...styles.countryCodeInput,
            }}
            slotProps={{
              paper: {
                sx: styles.countryCodeOptions,
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={key}
                variant="outlined"
                InputProps={params.InputProps}
                onChange={(event) => handleChangeText(field, event.target.value.toUpperCase())}
                error={error}
                sx={styles.countryCodeTextField}
              />
            )}
          />
        );

      default: {
        if (detailsCandidates[assistantKey] === undefined) {
          return null;
        }
        return (
          <Autocomplete
            ref={defaultFieldRef}
            id={key}
            fullWidth
            open={selectedTextField.key === key && !isMouseDown}
            onChange={(e, v) => handleChangeMain(field, v)}
            value={val}
            options={detailsCandidates[assistantKey]}
            getOptionLabel={(option) => getFieldLabel(key, option)}
            isOptionEqualToValue={(option: string, value: string) => option === value}
            PaperComponent={({ children }) => renderOptions(children, key, detailsCandidates[assistantKey].length > 0)}
            freeSolo
            openOnFocus
            onFocus={() => onFieldFocus(field)}
            onBlur={() => onFieldBlur(key, error, position)}
            sx={{
              '& .MuiOutlinedInput-root': {
                padding: '0px 2px 0px 0px !important',
                backgroundColor: selectedTextField.key === key ? '#3E8AFF4D' : '#FFFFFF',
                '& fieldset': {
                  border: `2px solid ${getBorderColor(key, trustScore, error, details, lineDetails, vatLinesRef.current, decimalSeparator, calculateVat)}`,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${getBorderColor(key, trustScore, error, details, lineDetails, vatLinesRef.current, decimalSeparator, calculateVat)}`,
                },
              }
            }}
            ListboxProps={{
              sx: styles.listBox
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                name={key}
                variant="outlined"
                InputProps={params.InputProps}
                onChange={(event) => handleChangeText(field, event.target.value)}
                error={error}
                sx={styles.textField}
              />
            )}
          />
        );
      }
    }
  };

  const renderVatDetails = (
    key: string,
    _lines: DocumentEditFormVatEsLineType[],
    _setLines: (_val: DocumentEditFormVatEsLineType[]) => void,
    fielLabels: Record<string, string>,
  ) => (
    <Box sx={styles.vatDetails}>
      {/* @TODO: check the styles of this */}
      <VatESDetailsTopLineComponentNew />
      {_lines.map((vl, i) => (
        <Grid key={vl.id || vl.line} container sx={styles.vatLineRow}>
          <Grid item xs={6} sx={styles.fieldLabelContainer}>
            {getIconOnTrustScore(-1, false, false, false)}
            <Typography sx={styles.vatLineLabel}>
              {ready && t(key === 'VAT' ? 'INVOICE_EDIT_FORM_VAT_LINES_LABEL' : 'INVOICE_EDIT_FORM_ES_LINES_LABEL', {lineNo: vl.line})}
            </Typography>
            <IconButton onClick={() => handleDeleteVatLine(i, _lines, _setLines)}>
              <DeleteIcon sx={styles.deleteIcon} />
            </IconButton>
          </Grid>
          <Tooltip title={t(getVATLineTooltip(vl))}>
            <Grid item xs={6} sx={styles.vatLineFieldsContainer}>
              {key === 'VAT' && (
                <>
                  <Typography sx={styles.vatLinesLabel}>{t(fielLabels.taxName)}</Typography>
                  <TextField
                    fullWidth
                    id={`${key}Line_${vl.line}_taxName`}
                    onFocus={
                      () => onFieldFocus({
                        key: `${key}Line_${vl.line}_taxName`,
                        assistantKey: `${key}_line_${vl.line}_tax_name`,
                        label: `${t(fielLabels.taxName)} ${vl.line}`,
                        appDbField: '',
                        dataType: 'text',
                        exportMapKey: '',
                        position: -1,
                      })
                    }
                    onChange={(e) => handleChangeVatEsLine(e.target.value, i, 'taxName', key)}
                    value={vl.taxName}
                    sx={{
                      ...styles.textField,
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: selectedTextField.key === `${key}Line_${vl.line}_taxName` ? '#3E8AFF4D' : '#FFFFFF',
                        '& fieldset': {
                          border: `2px solid ${getBorderColor(
                            `${key}Line_${vl.line}_taxName`,
                            -1,
                            Boolean(vl.error || vl.invalid || vl.vatError),
                            details
                          )}`,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          border: `1px solid ${getBorderColor(
                            `${key}Line_${vl.line}_taxName`,
                            -1,
                            Boolean(vl.error || vl.invalid || vl.vatError),
                            details
                          )}`,
                        }
                      },
                    }}
                  />
                </>
              )}
              <Typography sx={{ ...styles.vatLinesLabel, marginTop: '12px' }}>{t(fielLabels.baseAmount)}</Typography>
              <TextField
                fullWidth
                id={`${key}Line_${vl.line}_baseAmount`}
                onFocus={() => onFieldFocus({
                  key: `${key}Line_${vl.line}_baseAmount`,
                  assistantKey: `${key}_line_${vl.line}_base_amount`,
                  label: `${t(fielLabels.baseAmount)} ${vl.line}`,
                  appDbField: '',
                  dataType: 'float',
                  exportMapKey: '',
                  position: -1,
                })}
                onChange={(e) => handleChangeVatEsLine(e.target.value, i, 'baseAmount', key)}
                value={vl.baseAmount}
                sx={{
                  ...styles.textField,
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: selectedTextField.key === `${key}Line_${vl.line}_baseAmount` ? '#3E8AFF4D' : '#FFFFFF',
                    '& fieldset': {
                      border: `2px solid ${getBorderColor(
                        `${key}Line_${vl.line}_baseAmount`,
                        -1,
                        Boolean(vl.error || vl.invalid || vl.vatError),
                        details
                      )}`,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      border: `1px solid ${getBorderColor(
                        `${key}Line_${vl.line}_baseAmount`,
                        -1,
                        Boolean(vl.error || vl.invalid || vl.vatError),
                        details
                      )}`,
                    }
                  },
                }}
              />
              <Box sx={styles.vatLineFieldsRow}>
                <Box sx={styles.vatRateContainer}>
                  <Typography sx={styles.vatLinesLabel}>{t(fielLabels.rate)}</Typography>
                  <TextField
                    id={`${key}Line_${vl.line}_rate`}
                    onFocus={() => onFieldFocus({
                      key: `${key}Line_${vl.line}_rate`,
                      assistantKey: `${key}_line_${vl.line}_rate`,
                      label: `${t(fielLabels.rate)} ${vl.line}`,
                      appDbField: '',
                      dataType: 'float',
                      exportMapKey: '',
                      position: -1,
                    })}
                    onChange={(e) => handleChangeVatEsLine(e.target.value, i, 'rate', key)}
                    value={vl.rate}
                    sx={{
                      width: '96%',
                      ...styles.textField,
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: selectedTextField.key === `${key}Line_${vl.line}_rate` ? '#3E8AFF4D' : '#FFFFFF',
                        '& fieldset': {
                          border: `2px solid ${getBorderColor(
                            `${key}Line_${vl.line}_rate`,
                            -1,
                            Boolean(vl.error || vl.invalid || vl.vatError),
                            details
                          )}`,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          border: `1px solid ${getBorderColor(
                            `${key}Line_${vl.line}_rate`,
                            -1,
                            Boolean(vl.error || vl.invalid || vl.vatError),
                            details
                          )}`,
                        }
                      },
                    }}
                  />
                </Box>
                <Box sx={styles.vatAmountContainer}>
                  <Typography sx={styles.vatLinesLabel}>{t(fielLabels.amount)}</Typography>
                  <TextField
                    id={`${key}Line_${vl.line}_amount`}
                    onFocus={() => onFieldFocus({
                      key: `${key}Line_${vl.line}_amount`,
                      assistantKey: `${key}_line_${vl.line}_amount`,
                      label: `${t(fielLabels.amount)} ${vl.line}`,
                      appDbField: '',
                      dataType: 'float',
                      exportMapKey: '',
                      position: -1,
                    })}
                    onChange={(e) => handleChangeVatEsLine(e.target.value, i, 'amount', key)}
                    value={vl.amount}
                    sx={{
                      width: '96%',
                      ...styles.textField,
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: selectedTextField.key === `${key}Line_${vl.line}_amount` ? '#3E8AFF4D' : '#FFFFFF',
                        '& fieldset': {
                          border: `2px solid ${getBorderColor(
                            `${key}Line_${vl.line}_amount`,
                            -1,
                            Boolean(vl.error || vl.invalid || vl.vatError),
                            details
                          )}`,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          border: `1px solid ${getBorderColor(
                            `${key}Line_${vl.line}_amount`,
                            -1,
                            Boolean(vl.error || vl.invalid || vl.vatError),
                            details
                          )}`,
                        }
                      },
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Tooltip>
        </Grid>
      ))}
      <Button sx={styles.addVatLineBtn} onClick={() => handleAddVatLine(key, _lines, _setLines)}>
        <AddIcon sx={styles.addIcon} />
        {t(key === 'VAT' ? 'INVOICE_EDIT_FORM_ADD_VAT_LINE' : 'INVOICE_EDIT_FORM_ADD_SURCHARGE_LINE')}
      </Button>
    </Box>
  );

  const renderClientDetails = (clientFields: (FieldType | undefined)[], position: number) => (
    <Box sx={styles.senderDetails}>
      {clientFields.map((field: FieldType | undefined) => {
        if (!field) {
          return null;
        }
        const { key, assistantKey, label, isAvailable, isActive, dataType, isRequired } = field;

        if (!isInvoiceFieldAvailable(user, key, Boolean(isAvailable && isActive))
          || (key === 'doNotPay' && isTwinfield)
        ) {
          return null;
        }

        const isError = getIsError(key, dataType, details[key], user, decimalSeparator);
        let trustScore = fieldTrustScores[key];
        if (trustScore === undefined) {
          trustScore = -1;
        }

        const countryCodeKey = key.replace('VatNumber', 'CountryCode');

        return (
          <Box key={key}>
            <Tooltip title={getFieldTooltip(key, dataType, details, lineDetails, vatLinesRef.current, user, t, decimalSeparator)}>
              <Grid container sx={styles.field}>
                <Grid item xs={6} sx={styles.clientFieldLeft}>
                  <Box sx={styles.fieldLabelContainer}>
                    {getIconOnTrustScore(
                      trustScore,
                      isError,
                      false,
                      ((key === 'senderVatNumber' || key === 'receiverVatNumber')
                        && details.senderVatNumber !== ''
                        && details.receiverVatNumber !== ''
                        && details.senderVatNumber === details.receiverVatNumber)
                    )}
                    <Typography sx={styles.fieldLabel}>{ready && t(label)} {isRequired && '*'}</Typography>
                  </Box>
                  <Box sx={styles.countryCodeContainer}>
                    {key.includes('VatNumber') && renderField(
                      -1,
                      {
                        key: countryCodeKey,
                        appDbField: assistantKey.replace('VAT_number', 'country_code'),
                        assistantKey: assistantKey.replace('VAT_number', 'country_code'),
                        dataType: 'text',
                        exportMapKey: 'INVOICE_SENDER_COUNTRY_CODE',
                        label: '',
                        position: -1,
                      },
                      getIsError(
                        countryCodeKey,
                        'text',
                        details[countryCodeKey],
                        user,
                        decimalSeparator,
                      ),
                      -1
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6} sx={styles.fieldValueContainer}>
                  {renderField(position, field, isError, trustScore)}
                </Grid>
              </Grid>
            </Tooltip>
            {isTaxNumberSwitchAvailable(key, renderedFields.map((f) => f.key)) && (
              <Box sx={styles.taxNumberSwitchContainer}>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={key.includes('VatNumber')}
                      onChange={() => handleTaxNumberSwitch(key)}
                      size='small'
                      sx={styles.taxNumberSwitch}
                    />
                  )}
                  label={t('INVOICE_EDIT_SWITCH_TO_VAT_NUMBER')}
                  sx={styles.taxNumberSwitchLabel}
                />
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );

  const renderNewField = (field: FieldType, error: boolean) => (
    <Grid container sx={styles.field}>
      <Grid item xs={6} sx={styles.fieldLabelContainer}>
        <Tooltip title={t('NEW_FIELD_ICON_TOOLTIP')}>
          <span style={styles.newFieldIconContainer}>
            <NewFieldTag />
          </span>
        </Tooltip>
        <Typography sx={styles.fieldLabel}>{field.label} {field.isRequired && '*'}</Typography>
      </Grid>
      <Grid item xs={6} sx={styles.fieldValueContainer}>
        <Tooltip title={t('NEW_FIELD_INPUT_TOOLTIP')}>
          <Box sx={styles.newFieldContainer}>
            <TextField
              fullWidth
              id={field.key}
              name={field.key}
              onChange={(event) => handleChange(event.target.value, field.key)}
              required
              value={details[field.key]}
              onFocus={() => onFieldFocus(field)}
              onBlur={() => onFieldBlur(field.key, error, field.position)}
              variant="outlined"
              error={error}
              InputProps={{
                readOnly: true,              
              }}
              sx={{
                '& .MuiOutlinedInput-input': {
                  backgroundColor: selectedTextField.key === field.key ? '#C7DCFC' : '#FFFFFF',
                  ...styles.newFieldInput,
                },
                '& .MuiOutlinedInput-root': styles.newFieldInputRoot,
              }}
            />
            <Box sx={styles.annotatedDocsCountContainer}>
              <Typography sx={styles.annotatedDocsCount}>
                {t('AUTO_ML_FIELD_TRAINED_COUNT', { count: getFieldTrainedCount(doc.AutoMLFields, field.key) })}
              </Typography>
              <InfoIcon sx={styles.infoIcon} />
            </Box>
          </Box>
        </Tooltip>
        <Tooltip title={t('NEW_FIELD_SEND_FOR_TRAINING_BTN_TOOLTIP')}>
          <Button sx={styles.sendNewFieldDataBtn} onClick={() => handleSendNewFieldData(field.key)}>
            <SendIcon sx={styles.sendIcon} />
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );

  return (
    <Box sx={styles.main}>
      <DocumentHeader
        doc={doc}
        statusChangeLoading={statusChangeLoading}
        tags={tags}
        fieldsContainerWidth={fieldsContainerWidth}
        headerEntity={headerEntity}
        setTags={setTags}
        handleStatusChange={handleStatusChange}
        handleOpenManageFields={handleOpenManageFields}
        openInfoModal={openInfoModal}
        setIsSubmitClicked={setIsSubmitClicked}
        handleClose={handleClose}
      />
      <Grid style={styles.fieldsContainer} className="y-scroll">
        {fieldsToDisplay.sort((a, b) => (b.isNotTrained ? 1 : 0) - (a.isNotTrained ? 1 : 0)).map((field) => {
          let { key, label, isAvailable, isActive, isCustomField, position, dataType, isRequired, isNotTrained } = field;
          const val = details[key];

          if (!isInvoiceFieldAvailable(user, key, Boolean(isAvailable && isActive))
            || key === 'doNotPay'
            || (key === 'clientCode' && custom2FieldInDocumentForm)
            || senderRelatedFields.includes(key)
            || receiverRelatedFields.includes(key)
          ) {
            return null;
          }

          const isError = getIsError(key, dataType, val, user, decimalSeparator);
          let trustScore = fieldTrustScores[key];
          if (trustScore === undefined) {
            trustScore = -1;
          }

          if (key === 'supplier') {
            label = 'DOCUMENT_EDIT_SENDER_LABEL';
          }

          return (
            <Box ref={fieldsContainerRef} key={key}>
              {isNotTrained
              ? renderNewField(field, isError)
              : (<Tooltip title={getFieldTooltip(key, dataType, details, lineDetails, vatLinesRef.current, user, t, decimalSeparator, calculateVat)}>
                  <Grid container sx={styles.field}>
                    <Grid item xs={6} sx={styles.fieldLabelContainer}>
                      {getIconOnTrustScore(
                        trustScore,
                        isError,
                        false,
                        ((key === 'amount' && !isAmountTallyingWithLines(details, lineDetails, decimalSeparator))
                          || (calculateVat && key === 'vatAmount' && !isVatAmountTallyingWithVatLines(details, vatLinesRef.current, decimalSeparator)))
                      )}
                      <Typography sx={styles.fieldLabel}>{isCustomField ? label : t(label)} {isRequired && '*'}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={styles.fieldValueContainer}>
                      {renderField(position, field, isError, trustScore)}
                    </Grid>
                  </Grid>
                </Tooltip>)
              }
              {key === 'supplier' && isTwinfield && (
                <Grid container sx={styles.field}>
                  <Grid item xs={6} sx={styles.fieldLabelContainer}>
                    {getIconOnTrustScore(-1, false, false, false)}
                    <Typography sx={styles.fieldLabel}>{ready && t('INVOICE_EDIT_FORM_DO_NOT_PAY')}</Typography>
                  </Grid>
                  <Grid item xs={6} sx={styles.fieldValueContainer}>
                    {renderField(
                      -1,
                      {
                        key: 'doNotPay',
                        appDbField: 'do_not_pay',
                        assistantKey: 'do_not_pay',
                        dataType: 'boolean',
                        exportMapKey: 'INVOICE_DO_NOT_PAY',
                        label: 'INVOICE_EDIT_FORM_DO_NOT_PAY',
                        position: -1
                      },
                      false,
                      -1
                    )}
                  </Grid>
                </Grid>
              )}
              {key === 'supplier' && showDetails.sender && renderClientDetails(senderFields, position)}
              {key === 'supplier' && renderedFields.some((f) => senderRelatedFields.includes(f.key))
                && renderShowClientDetails('sender', 'DOCUMENT_EDIT_SHOW_CLIENT_DETAILS', 'DOCUMENT_EDIT_HIDE_CLIENT_DETAILS')}
              {key === 'receiver' && showDetails.receiver && renderClientDetails(receiverFields, position)}
              {key === 'receiver' && renderedFields.some((f) => receiverRelatedFields.includes(f.key))
                && renderShowClientDetails('receiver', 'DOCUMENT_EDIT_SHOW_CLIENT_DETAILS', 'DOCUMENT_EDIT_HIDE_CLIENT_DETAILS')}
              {key === 'vatAmount'
                && renderShowClientDetails('VAT', 'INVOICE_EDIT_FORM_VAT_DETAILS', 'INVOICE_EDIT_FORM_VAT_DETAILS')}
              {key === 'vatAmount' && showDetails.VAT
                && renderVatDetails('VAT', vatLinesRef.current, setVatLines, vatLineLabels)}
              {key === 'equivalenceSurchargeAmount'
                && renderShowClientDetails('ES', 'INVOICE_EDIT_FORM_SURCHARGE_DETAILS', 'INVOICE_EDIT_FORM_SURCHARGE_DETAILS')}
              {key === 'equivalenceSurchargeAmount' && showDetails.ES
                && renderVatDetails('ES', esLinesRef.current, setEsLines, esLineLabels)}
            </Box>
          );
        })}
        {isTwinfield && doc.status === 503 && (
          <Box style={styles.spreadBtnContainer}>
            <FormControlLabel
              value="top"
              control={(
                <Checkbox
                  style={styles.checkBox}
                  checked={Boolean(spreadInvoice || isSpreadFieldsNotEmpty() || spreadSuccess)}
                  onChange={handleSpreadInvoiceChange}
                />
              )}
              label={ready && t('INVOICE_EDIT_FORM_SPREAD_INVOICE')}
              labelPlacement="start"
              sx={styles.spreadCheckBoxLabel}
            />
          </Box>
        )}
        {(spreadInvoice || isSpreadFieldsNotEmpty()) && (
          <SpreadInvoice
            document={doc}
            details={details}
            isSpreadFieldsNotEmpty={isSpreadFieldsNotEmpty}
            spreadSuccess={spreadSuccess}
            setSpreadSuccess={setSpreadSuccess}
          />
        )}
      </Grid>
      <DocumentEditActionButtons
        doc={doc}
        details={details}
        renderedFields={renderedFields}
        statusChangeLoading={statusChangeLoading}
        isSubmitClicked={isSubmitClicked}
        deliverFormats={deliverFormats}
        submitting={submitting}
        previousInvoice={previousInvoice}
        nextInvoice={nextInvoice}
        decimalSeparator={decimalSeparator}
        handleSubmit={handleSubmit}
        handleAutoSaveInvoice={handleAutoSaveInvoice}
        handleSelectStatus={handleStatusChange}
        handleDataFormatToExportClick={handleDataFormatToExportClick}
        setSendEmailModalOpen={setSendEmailModalOpen}
        handleNavigateInvoice={handleNavigateInvoice}
        moveNext={moveNext||function(){}}
        movePrevious={movePrevious ||function(){}}
        disableMove={disableMove}
      />
      <CreateSupplierModal
        addSupplierOpen={addSupplierOpen}
        handleClose={handleCloseSupplierModal}
        newSupplier={newSupplier}
        onSupplierCreated={onSupplierCreated}
        isInvoiceEditForm
        renderInvoicePDF={() => <DocumentView document={doc} />}
      />
      <EditSupplierModal
        editSupplierOpen={editSupplierOpen}
        setEditSupplierOpen={setEditSupplierOpen}
        supplierId={selectedSupplierId}
        handleEditSupplierClose={handleEditSupplierClose}
        getAllSuppliers={findAllSuppliers}
        isInvoiceEditForm
        renderInvoicePDF={() => <DocumentView document={doc} />}
        company={parameterDocCompany}
      />
      <ExportToEmailModal
        open={sendEmailModalOpen}
        emails={emails}
        selectedDataFormatToEmail={selectedDataFormatToEmail}
        deliverFormats={deliverFormats}
        handleClose={handleSendEmailModalClose}
        setEmails={setEmails}
        setSelectedDataFormatToEmail={setSelectedDataFormatToEmail}
        handleSendToEmailClick={handleSendToEmailClick}
      />
      <ManageExportFieldsNew
        open={showExportFieldMappings}
        format={exportFormat}
        fields={fields}
        lineFields={lineFields}
        supplierFields={supplierFields}
        excelFieldMapping={excelExportFieldMapping}
        datFieldMapping={datExportFieldMapping}
        csvFieldMapping={csvExportFieldMapping}
        handleClose={handleExportFieldMappingsClose}
      />
    </Box>
  );
};

export default DocumentFields;
