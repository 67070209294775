/* eslint-disable max-len, comma-spacing */
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import Axios from 'axios';

import {
  Container, Grid, SvgIcon, Typography
} from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Page from 'src/shared/screens/Page';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import OverviewStats from 'src/dashboard/components/OverviewStats/OverviewStats';
import InvoicesProcessed from 'src/dashboard/components/InvoicesProcessed/InvoicesProcessed';
import { fetchVatCodes } from 'src/dimensions/vatcodes/actions';
import { fetchGlas } from 'src/dimensions/glas/actions';
import { fetchCostCenters } from 'src/dimensions/costcenters/actions';
import InvoiceListView from 'src/documents/DocumentsList/DocumentsList';
import SelectDocument from 'src/documents/components/SelectDocument/SelectDocument';
import ConfigContext from 'src/contexts/ConfigContext';
import { useAuth } from 'src/hooks/useAuth';
import styles from './style';

let apiTimer = null;
const apiInterval = 60000;

const Dashboard = () => {
  const { BRAND_NAME, API } = useContext(ConfigContext);
  const dispatch = useDispatch();
  const { ready, t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [overview_stats, set_overview_stats] = useState({
    uploadingTot: 0,
    digitizedTot: 0,
    toreviewTot: 0,
    tovalidateTot: 0,
    toexportTot: 0,
    exportedTot: 0,
    rejectedTot: 0,
    archivedTot: 0,
    erroredTot: 0,
  })
  const [graph_data, set_graph_data] = useState({})

  const onGetNewAppInvoiceStats = async () => {
    try {
      const overviewResponse = Axios.request({
        method: 'GET',
        url: API.getStatsOverview,
        headers: {
          Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
        }
      });

      const graphResponse = Axios.request({
        method: 'GET',
        url: API.getStatsGraph,
        headers: {
          Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
        }
      });

      let allStats = await Promise.allSettled([overviewResponse, graphResponse]);

      if (allStats && allStats[0]?.status === 'fulfilled') {
          set_overview_stats((overview_stats)=>({
            ...overview_stats,
            uploadingTot: allStats[0].value?.data?.data?.uploading || 0,
            digitizedTot: allStats[0].value?.data?.data?.digitised || 0,
            toreviewTot: allStats[0].value?.data?.data?.toreview || 0,
            tovalidateTot: allStats[0].value?.data?.data?.tovalidate || 0,
            toexportTot: allStats[0].value?.data?.data?.toexport || 0,
            exportedTot: allStats[0].value?.data?.data?.exported || 0,
            rejectedTot: allStats[0].value?.data?.data?.rejected|| 0,
            archivedTot: allStats[0].value?.data?.data?.archived || 0,
            erroredTot: allStats[0].value?.data?.data?.errored || 0,
          }))
          set_graph_data(() => allStats[1]?.value?.data?.data)
      }
      else {
        console.log("error ocurred")
      }
      setDataLoaded(true);
    }
    catch(error) {
      setDataLoaded(true);
    }
  }

  const startInterval = async () => {
    apiTimer = setInterval(async()=>{
      onGetNewAppInvoiceStats();
    }, apiInterval);
  }

  useEffect(() => {
    onGetNewAppInvoiceStats();
    if (user && user.companyID) {
      dispatch(fetchVatCodes(user.companyID));
      dispatch(fetchGlas(user.companyID));
      dispatch(fetchCostCenters(user.companyID));
    }

    startInterval();

    return () => {
      clearInterval(apiTimer)
    }
  }, []);

  return (
    <>
      {
        user.role !== 'company' && !dataLoaded && <LoadingIndicator />
      }
      {
        !user.documentType && <SelectDocument open />
      }
      <Page
        style={styles.root}
        title={ready && `${t('DASHBOARD_ACTION_DASHBOARD')} | ${BRAND_NAME}`}
      >
        <Container
          maxWidth={false}
          style={{ ...styles.container, display: user.role !== 'company' && !dataLoaded && 'none'}}
        >
          <Grid container spacing={4} style={styles.topContainer}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                 <OverviewStats
                    totalErrored={overview_stats && overview_stats.erroredTot ? overview_stats.erroredTot : 0}
                    totalUploading={overview_stats && overview_stats.uploadingTot ? overview_stats.uploadingTot : 0}
                    totalDigitized={overview_stats && overview_stats.digitizedTot ? overview_stats.digitizedTot : 0}
                    totalToreview={overview_stats && overview_stats.toreviewTot ? overview_stats.toreviewTot : 0}
                    totalTovalidate={overview_stats && overview_stats.tovalidateTot ? overview_stats.tovalidateTot : 0}
                    totalToexport={overview_stats && overview_stats.toexportTot ? overview_stats.toexportTot : 0}
                    totalExported={overview_stats && overview_stats.exportedTot ? overview_stats.exportedTot : 0}
                    totalRejected={overview_stats && overview_stats.rejectedTot ? overview_stats.rejectedTot : 0}
                    totalArchived={overview_stats && overview_stats.archivedTot ? overview_stats.archivedTot : 0}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InvoicesProcessed
                    graph_data={graph_data}
                    user={user}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {user.brand === 'schubec' && !user.integrations.includes('api')
            && (
            <Grid style={styles.banner}>
              <SvgIcon fontSize="13">
                <PriorityHighIcon style={styles.priorityIcon} />
              </SvgIcon>
              <Typography style={styles.bannerText}>
                {ready && (
                <Trans i18nKey="PARTNER_CONNECT_API_BANNER">
                  Connect to our API to improve your experience with us. You can work with settings
                  {' '}
                  <span style={styles.connectAPILink} onClick={() => navigate('/integrations')}>here</span>
                </Trans>
                )}
              </Typography>
            </Grid>
            )}
          {/* {user.role === 'company' && overviewStats.userStats && <Results invoices={overviewStats.userStats.reverse()} />} */}
          {user.role === 'user' && <InvoiceListView onDataLoaded={() => setDataLoaded(true)} />}
        </Container>
      </Page>
    </>
  );
};

export default Dashboard;
