const styles = {
  tableWrapper:{
    display:'flex'
  },
  table:{
  },
  tableHeadCell:{
    backgroundColor:'#C2CFE033',
    borderBottom: 'none',
    paddingBlock:'0px',
    lineHeight:'1.2',
    textOverflow:'ellipsis',
    overflow:'clip visible',
    whiteSpace:'nowrap',
    padding: '16px',
    fontSize:'12px',
    color:'#4C4E64DE',
  },
  generalTableText:{
    fontSize:'12px',
    color:'#4C4E64DE',
  },
  tableBodyCell:{
    borderBottom: 'none',
    paddingBlock:'0px',
    lineHeight:'32px',
    textOverflow:'ellipsis',
    overflow:'hidden',
    whiteSpace:'nowrap',
    padding: '8px 16px !important',
    fontSize:'14px',
    color:'#4C4E64',
    fontWeight:'500',
  },
  highlightLabel:{
    color:'#6D788D',
    fontSize:'13px',
    fontWeight:'600',
    textOverflow:'ellipsis',
    overflow:'hidden',
    whiteSpace:'nowrap',
    display:'inline-block',
    padding:'6px 12px',
    borderRadius:'8px',
    background:'#6d788d1f',
  },
  actionIconContainer:{
    display:'flex',
    alignItems:'center',
    gap:'8px',
    width:'100%',
    justifyContent:'flex-end',
  },
  actionBtn: {
    padding: '4px'
  },
  actionIcon:{
    color:'#6D788D',
    width:'20px',
    height:'20px',
    cursor:'pointer'
  },
}

export default styles;