const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid #4C4E641F',
    borderBottom: '1px solid #4C4E641F',
    padding: '20px',
  },
  titleRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  title: {
    fontSize: '20px',
    fontWeight: '500',
    color: '#4C4E64',
    lineHeight: '32px',
  },
  addCardBtn: {
    fontSize: '14px',
    fontWeight: '600',
    marginTop: '0px',
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    backgroundColor: '#3E8AFF',
    textTransform: 'none' as const,
  },
  addCardIcon: {
    color: '#FFFFFF',
    width: '20px',
    height: '20px',
  },
  bodyCell: {
    height: '40px',
    fontSize: '14px',
    lineHeight: '32px',
    fontWeight: '400',
    color: '#4C4E64',
    whiteSpace: 'nowrap',
    padding: '0 12px !important',
  },
  cardNumberRow: {
    display: 'flex',
    height: '42px',
    alignItems: 'center'
  },
  cardType: {
    width: 30,
    marginLeft: 8,
    marginRight: 12
  },
  defaultLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 12px',
    whiteSpace: 'nowrap',
    border: '1px solid #E1E4E8',
    boxSizing: 'border-box',
    borderRadius: '20px',
    height: '29px',
    cursor: 'pointer',
    marginLeft: '20px',
    textTransform: 'capitalize',
    maxWidth: '108px',
    fontSize: '14px',
    fontWeight: '600',
    color: '#4C4E64',
    backgroundColor: '#E1E4E8'
  },
};

export default styles;
