import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useSnackbar } from 'notistack';

import { Grid, IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow, Typography, 
  SvgIcon, TextField, Box, Button
} from '@mui/material';
import { Close as CloseIcon, AddCircle as PlusCircleIcon, SettingsOutlined as SettingsIcon, Delete as DeleteIcon
} from '@mui/icons-material';

import { axiosHeaders } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import { ProductType, SupplierType } from 'src/types';
import theme from 'src/theme';

type PropsType = {
  open: boolean;
  handleClose: () => void;
  products: ProductType[];
  updateProducts: (_products: ProductType[]) => void;
  supplier: SupplierType | null;
}

const SupplierProducts = (props:PropsType) => {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [ focusField, setFocusField] = useState<Record<string, number | string> | null>(null)

  const {
    open:supplierProductsOpen,
    handleClose,
    products,
    updateProducts,
    supplier,
  } = props;
  const { API } = useContext(ConfigContext);

  const inventoryFields = [{
    key: 'article',
    label: 'SUPPLIER_PRODUCTS_TABLE_INNER_CODE'
  }, {
    key: 'description',
    label: 'SUPPLIER_PRODUCTS_TABLE_INNER_CODE_DESCR'
  }, {
    key: 'articleClient',
    label: 'SUPPLIER_PRODUCTS_TABLE_CLIENT_CODE'
  }, {
    key: 'descriptionClient',
    label: 'SUPPLIER_PRODUCTS_TABLE_CLIENT_CODE_DESCR'
  }, {
    key: 'unitMeasurement',
    label: 'SUPPLIER_PRODUCTS_TABLE_UNIT_MEASUREMENT'
  }];

  const [supplierProducts, setSupplierProducts] = useState(products?.length > 0 ? products : [{
    article: '',
    description: '',
    articleClient: '',
    descriptionClient: '',
    unitMeasurement: '',
  }]);

  useEffect(() => {
    console.log('products', products);
    setSupplierProducts(products?.length > 0 ? products : [{
      article: '',
      description: '',
      articleClient: '',
      descriptionClient: '',
      unitMeasurement: '',
    }]);
  }, [products]);

  const handleAddProduct = () => {
    const newProduct = {
      article: '',
      description: '',
      articleClient: '',
      descriptionClient: '',
      unitMeasurement: '',
    };
    setSupplierProducts([...supplierProducts, newProduct]);
  };

  const handleDeleteProduct = (i:number) => {
    const newProducts = [...supplierProducts];
    newProducts.splice(i, 1);
    setSupplierProducts(newProducts);
  };

  const handleChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index:number, key: keyof ProductType ) => {
    let value = e.target.value;
    const newProducts:ProductType[] = [...supplierProducts];
    newProducts[index][key] = value;
    setSupplierProducts(newProducts);
    setFocusField({field:key, index})
  };

  const closeProductsModal = () => {
    setSupplierProducts(products?.length > 0 ? products : [{
      article: '',
      description: '',
      articleClient: '',
      descriptionClient: '',
      unitMeasurement: '',
    }]);
    handleClose();
  };

  const handleSave = async () => {
    const error = supplierProducts.some((product) => {
      if (!product.article?.trim() && !product.description?.trim() && !product.articleClient?.trim()
        && !product.descriptionClient?.trim() && !product.unitMeasurement?.trim()) {
        return true;
      }
      return false;
    });
    if (error) {
      enqueueSnackbar(ready && t('SUPPLIER_PRODUCTS_ALL_EMPTY'), {
        variant: 'error',
      });
      return;
    }
    if (supplier) {
      try {
        const body = {
          ...supplier,
          inventories: supplierProducts
        };

        const response = await Axios.put(
          `${API.suppliers}`,
          body,
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );

        if (response.data.success) {
          enqueueSnackbar(ready && t('SUPPLIER_PRODUCTS_UPDATE_SUCCESS'), {
            variant: 'success',
          });
          updateProducts(supplierProducts);
          closeProductsModal();
        } else {
          enqueueSnackbar(ready && t('SUPPLIER_PRODUCTS_UPDATE_FAILED'), {
            variant: 'error',
          });
        }
        return;
      } catch (err) {
        enqueueSnackbar(ready && t('SUPPLIER_PRODUCTS_UPDATE_FAILED'), {
          variant: 'error',
        });
        return;
      }
    }
    updateProducts(supplierProducts);
    closeProductsModal();
  };

  return (
    <Modal
      sx={styles.modal}
      open={supplierProductsOpen}
      onClose={()=>closeProductsModal()}
    >
      <Box sx={styles.paper} className='y-scroll'>
        <Grid sx={styles.modalHeader}>
          <Typography sx={styles.headerTitle}>
            {ready && t('CLIENT_PRODUCTS_TITLE')}
          </Typography>
          <IconButton onClick={() => closeProductsModal()}>
            <CloseIcon sx={styles.closeIcon} />
          </IconButton>
        </Grid>
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {inventoryFields.map((field) => (
                <TableCell sx={styles.tableHeadCell} key={field.key} align="center">
                  {ready && t(field.label)}
                  <span style={{...styles.border,position:'absolute',top:'20px'}}></span>
                </TableCell>
              ))}
              <TableCell sx={styles.tableHeadCell}>
                <Grid sx={{...styles.actionBtnContainer}}>
                  <IconButton onClick={() => {}} 
                  >
                    <SettingsIcon sx={styles.settingsIcon} />
                  </IconButton>
                  <IconButton
                    onClick={() => handleAddProduct()}
                  >
                    <PlusCircleIcon sx={styles.addIcon} />
                  </IconButton>
                  <span style={{width:'2px',height:'14px',backgroundColor:'#4C4E641F'}}></span>
                </Grid>
        
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {supplierProducts.map((product, i) => (
              <TableRow key={product.article+i}>
                {inventoryFields.map((field) => (
                  <TableCell sx={{...styles.tableBodyCell}} key={`${product.article}_${field.key}`} className='product-td'>
                    <TextField
                      id={`inventory_${i}_${field.key}`}
                      autoFocus={focusField && focusField?.field === field.key && focusField.index === i ?true:false}
                      value={product[field.key as keyof ProductType]}
                      variant="outlined"
                      sx={{
                        ...styles.inputStyle,
                        '& .MuiOutlinedInput-input': styles.inputRoot,
                      }}
                      onChange={(e) => handleChange(e, i, field.key as keyof ProductType)}
                    />
                    <span style={{...styles.border,'position':'absolute',top:'20px'}}></span>
                  </TableCell>
                  
                ))}
                <TableCell sx={styles.tableBodyCell}>
                  <Grid sx={styles.actionBtnContainer}>
                    <IconButton onClick={() => handleDeleteProduct(i)}>
                      <SvgIcon sx={{ display: 'flex' }}>
                        <DeleteIcon sx={styles.deleteIcon} />
                      </SvgIcon>
                    </IconButton>
                    <span style={{width:'2px',height:'14px',backgroundColor:'#4C4E641F'}}></span>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Grid sx={styles.modalFooter}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => closeProductsModal()}
              sx={styles.cancelBtn}
            >
              {ready && t('SUPPLIER_PRODUCTS_CANCEL')}
            </Button>
            <Button
              variant="contained"
              sx={styles.saveBtn}
              onClick={() => handleSave()}
              // disabled={isSubmitting}
            >
              {ready && t('SUPPLIER_PRODUCTS_SAVE')}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};


export default SupplierProducts;


const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    zIndex: 9999999
  },
  paper: {
    width: '90%',
    maxWidth:'1200px',
    maxHeight: '94%',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: theme.shadows[5],
    outline: 'none',
    backgroundColor:'#fff',
    display:'flex',
    'flex-direction':'column',
    'box-sizing':'border-box',
    height:'fit-content',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding:'20px 24px',
  },
  headerTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#4C4E64',
    lineHeight: '32px',
  },
  closeIcon: {
    width: '20px',
    height: '20px',
    color: '#6D788D'
  },
  addIcon: {
    height: '20px',
    width: '20px',
    marginRight: '4px',
    color: '#6D788D',
  },
  settingsIcon: {
    height: '20px',
    width: '20px',
    marginRight: '4px',
    color: '#6D788D',
  },
  inputStyle: {
    backgroundColor: '#fff',
    borderRadius: '2px',
    width:'90%',
    color: '#4C4E64 !important',
  },
  inputRoot: {
    height: '36px',
    margin: '16px 0px',
    color: '#4C4E64 !important',
  },
  actionBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight:'15px'
  },
  deleteIcon: {
    fontSize: '16px',
    color: '#6D788D',
    marginLeft: '16px'
  },
  modalFooter: {
    marginTop: 0,
    padding:'20px 16px'
  },
  cancelBtn: {
    fontSize: '14px',
    fontWeight: '500',
    height: '36px',
    width: '108px',
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    border: '1px #e4e4e4 solid',
  },
  saveBtn: {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: '500',
    marginLeft: '16px',
    width: '108px',
    height: '36px',
    backgroundColor: '#3E8AFF',
  },
  tableHeadCell: {
    color: '#4C4E64DE',
    backgroundColor:'#F5F5F7',
    padding:'10px 16px 10px 20px',
    fontSize:'12px',
    lineHeight:'24px',    
    textAlign:'left' as const
  },  
  border:{
    display:'inline-block',
    borderRight:'2px solid #4C4E641F',
    height:'14px',
    right:'0'
  },
  tableBodyCell: {
    borderBottom: 'none',
    padding:'10px 0 10px 20px !important',
    color: '#4C4E64 !important',
  },
};

