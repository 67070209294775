import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import { Box, Grid, TextField, Tooltip, SvgIcon, InputAdornment } from '@mui/material';
import { Search as SearchIcon} from '@mui/icons-material';

import { useConfig } from 'src/hooks/useConfig';
import { useAuth } from 'src/hooks/useAuth';
import { axiosHeaders, appendContactSupport, getLocalisedErrorString, axiosDeleteHeaders } from 'src/utils/helpers';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import CompanyTable from 'src/settings/components/Companies/CompanyTable/CompanyTable';
import AddNewCompany from 'src/settings/components/Companies/AddNewCompany/AddNewCompany';
import EditCompany from 'src/settings/components/Companies/EditCompany/EditCompany';
import TopDisplay from 'src/settings/components/Companies/TopDisplay';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import authService from 'src/utils/authService';
import TablePagination from 'src/shared/components/TablePagination';
import { CustomError } from 'src/types';
import styles from './styles';


const Companies = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {LOGIN_PANEL_URL, API } = useConfig();
  const { user } = useAuth();

  const [addCompanyAndUserOpen, setAddCompanyAndUserOpen] = useState<boolean>(false);
  const [editCompanyOpen, setEditCompanyOpen] = useState<boolean>(false);
  const [editCompany, setEditCompany] = useState<Record<string, string | number | boolean>>({});
  const [total, setTotal] = useState<number>(0);
  const [companies, setCompanies] = useState<Record<string, string | number | boolean>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [query, setQuery] = useState<string>('');

 
  const handleAddNewCompany = () => {
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error'
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setAddCompanyAndUserOpen(true);
  };

  const onLimitChange = (newLimit: number) => {
    getAllCompanies(0, newLimit, query);
    setLimit(newLimit);
  };
  const onPageChange = (page: number) => {
    setPage(page);
    getAllCompanies(page, limit, query);
  };

  const onDeleteCompany = async (event: React.MouseEvent<HTMLElement>, c:Record<string, string | number | boolean>):Promise<void> => {
    event.stopPropagation();
    event.preventDefault();

    const body = {
      companyId: c.companyID.toString()
    };

    try {
      const response = await Axios.delete(
        `${API.deleteCompany}`,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), body)
      );
      if (response.data.success) {
        enqueueSnackbar(t('ACCOUNT_SETTINGS_COMPANIES_DELETE_SUCCESS'), {
          variant: 'success'
        });
        window.location.reload();
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(
        appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || 'ACCOUNT_SETTINGS_COMPANIES_DELETE_FAILURE', t),
          t
        ),
        {
          variant: 'error'
        }
      );
    }
  };

  const handleEditCompany = (data: Record<string, string | number | boolean>)=>{
    setEditCompany(data);
    setEditCompanyOpen(true);
  }

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement | HTMLAreaElement | any>) => {
    e.persist();
    setQuery(e.target.value);
    getAllCompanies(0, limit, e.target.value);
  };

  const getAllCompanies = async (pageNo = page, pageLimit = limit, search = query) => {
    setLoading(true);
    try {
      const url = `${API.getCompaniesByUser}${user?.email}?type=detailed&page=${pageNo + 1}&limit=${pageLimit}&search=${search}`;
      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success) {
        setCompanies(response.data.data);
        setTotal(response.data.total);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onSwitchToCompany = async (event: React.MouseEvent<any> , c: Record<string, string | number | boolean>) => {
    event.stopPropagation();
    event.preventDefault();

    try {
      const response = await Axios.get(
        `${API.changeCompany}/${encodeURIComponent(c.companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('ACCOUNT_SETTINGS_COMPANIES_CHANGE_SUCCESS'), {
          variant: 'success'
        });
        if (window.location.pathname.indexOf('invoices/') >= 0) {
          window.location.href = '/documents';
        } else {
          window.location.reload();
        }
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(
        appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || 'ACCOUNT_SETTINGS_COMPANIES_CHANGE_FAILURE', t),
          t
        ),
        {
          variant: 'error'
        }
      );
    }
  };

  useEffect(()=>{
    getAllCompanies();
  },[])

  return (
    <>
      <Box sx={styles.container}>      
        <TopDisplay 
          onAddCompany={handleAddNewCompany}
        />  
        <Grid sx={styles.searchBar} className="search-bar">
          <Tooltip title={t('ACCOUNT_SETTINGS_COMPANIES_SEARCH')}>
            <TextField
              sx={{...styles.queryField, '&.MuiTextFieldRoot': styles.searchBarInput }}
              onChange={(e)=>handleQueryChange(e)}
              placeholder={t('ACCOUNT_SETTINGS_COMPANIES_SEARCH')}
              value={query}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                      sx={{ fill: '' }}
                    >
                      <SearchIcon sx={{ color: '' }} />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
        </Grid>
        {
          companies && companies.length > 0?
            (
              loading ? <LoadingIndicator />:
                <>
                <Box sx={styles.tableWrapper}>
                  <CompanyTable
                    data={companies}
                    onDelete={onDeleteCompany}
                    deleteText= "Delete"
                    onEdit={handleEditCompany}
                    onSwitchCompany={onSwitchToCompany}
                    onDataAction={()=>{}}
                  />
                </Box>
                <TablePagination 
                  total={total}
                  limit={limit}
                  page={page}
                  onLimitChange={onLimitChange}
                  onPageChange={onPageChange}
                />
              </>
            )
          : loading 
              ? <LoadingIndicator /> 
              : <EmptyText str="ACCOUNT_SETTINGS_COMPANIES_NO_COMPANIES" />
        }
      </Box>
      <AddNewCompany 
        addCompanyAndUserOpen={addCompanyAndUserOpen}
        handleClose={() => setAddCompanyAndUserOpen(false)}
        getAllCompanies={getAllCompanies}
      />
      <EditCompany 
        editCompanyOpen={editCompanyOpen}
        user={user}
        editCompany={editCompany}
        handleClose={() => setEditCompanyOpen(false)}
        getAllCompanies={getAllCompanies}
      />
    </>
  )
}

export default Companies