import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Chip, FormControl,
  FormHelperText, Grid, InputAdornment, MenuItem, Select, TextField, Tooltip, Autocomplete, Typography,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, Edit as EditIcon, AddBox as PlusIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { isActionPermitted, permissions } from 'src/utils/helpers';
import { supplierData, vatCodeFields, vatCodeTypes } from 'src/config';
import { CostCenterType, FieldType, GLAccountType, VatGroupType } from 'src/types';
import theme from 'src/theme';
import { FormikErrors } from 'formik';

type PropsType = {
  errors: Record<string, string>;
  handleBlur:React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleSubmit: () => void;
  setErrors: (_errors: FormikErrors<Record<string, string>>) => void;
  isSubmitting: boolean;
  touched: Record<string, boolean | string>;
  values: Record<string,boolean | string>;
  vatCode: VatGroupType | null;
  handleClose: () => void;
  hasError: (_fields:FieldType[], _errors:Record<string, string | boolean>) => boolean;
  handleChangeAutocomplete: (_e:React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | 
    React.SyntheticEvent<Element, Event>, _prop:string, _values:Record<string,string | boolean>, _handleChange:Function) => void;
  getLabel: (_entities:Record<string,any>[] , _code:string) => string;
  costCenters: CostCenterType[];
  glaccounts: GLAccountType[];
  handleEditCostCenter: (_c:string) => void ;
  handleAddCostCenter: (_c:string) => void ;
  newlyAddedVal: string;
  newlyAddingProp: string;
  isDisabled: boolean;

}

const VatCodeTwinfield = (props: PropsType) => {
  const { t, ready } = useTranslation();

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setErrors,
    isSubmitting,
    touched,
    values,
    vatCode,
    handleClose,
    hasError,
    handleChangeAutocomplete,
    getLabel,
    costCenters,
    glaccounts,
    handleEditCostCenter,
    handleAddCostCenter,
    newlyAddedVal,
    newlyAddingProp,
    isDisabled
  } = props;
  
  const auth = useAuth();
  const {user} = auth;

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } } as 
        React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
    }
  }, [newlyAddedVal]);

  return (
    <form style={styles.root} className='y-scroll'>
      <Card>
        <CardContent style={{ padding: 0 }}>
          <Grid container spacing={3} sx={{display:'flex',paddingTop:'25px',flexDirection:'column',gap:'10px',marginLeft:'0',width:'100%'}}>
            {
                vatCodeFields.map((m:Record<string, any>,index:number) => (
                  <Accordion
                    key={m.title}
                    style={styles.accordionGroup}
                    defaultExpanded={index===0?true:false}
                    className="twinfield-accordion"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={m.title}
                      id={m.title}
                    >
                      <Grid style={styles.accordionHeading}>
                        {ready && t(m.title)}
                        {
                          hasError(m.fields, errors) && (
                            <Chip
                              color="error"
                              // variant="default"
                              size="small"
                              label="Error"
                              style={styles.errorChip}
                            />
                          )
                        }
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails sx={{padding:'0 24px 16px 48px'}}>
                      <Grid sx={styles.eachFieldContainer}>
                        {
                          m.fields.map((f:Record<string,any>) => {
                            switch (f.key) {
                              case 'type':
                                return (
                                  <Grid item key={f.key} sx={styles.eachField}>
                                    <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                                    <FormControl variant="outlined" style={styles.formControl}>
                                      <Select
                                        disabled={isDisabled}
                                        labelId={`${f.key}-label`}
                                        id={f.key}
                                        error={Boolean(touched[f.key] && errors[f.key])}
                                        value={values[f.key]}
                                        onClick={() => setErrors({})}
                                        onChange={handleChange as any}
                                        name={f.key}
                                        required={f.required}
                                        sx={{
                                          '& .MuiSelect-select': styles.selectStyle
                                        }}
                                      >
                                        {
                                          vatCodeTypes && vatCodeTypes.length > 0
                                            && vatCodeTypes.map((s) => {
                                              if (s) {
                                                return (
                                                  <MenuItem key={s.name} value={s.name}>
                                                    {t(s.label)}
                                                  </MenuItem>
                                                );
                                              }

                                              return null;
                                            })
                                        }
                                      </Select>
                                      <FormHelperText error>
                                        {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                );
                              case 'ruleCostCenter':
                                return (
                                  <Grid item sx={styles.eachField} key={f.key}>
                                    <Typography>{ready && t(f.label)}</Typography>
                                    <FormControl variant="outlined" style={styles.formControl}>
                                      <Autocomplete
                                        disabled={isDisabled}
                                        fullWidth
                                        // name="ruleCostCenter"
                                        onChange={(e, v) => handleChangeAutocomplete(e, 'ruleCostCenter', 
                                          v as unknown as Record<string, any>, handleChange)}
                                        inputValue={getLabel(costCenters, values.ruleCostCenter as string)}
                                        value={values?.ruleCostCenter && costCenters && 
                                          costCenters.find((e:CostCenterType) => e.code === values?.ruleCostCenter) || null}
                                        options={costCenters}
                                        getOptionLabel={
                                          (option: CostCenterType) => (option && option.code ? `${option.code} | ${option.name}` : '')
                                        }
                                        filterOptions={options => options}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            InputProps={{
                                              ...params.InputProps,
                                              endAdornment: ( isDisabled ? <></> :
                                                <>
                                                  {values[f.key] !== supplierData.defaultCostCenters[0].code && (
                                                    <InputAdornment
                                                      position="end"
                                                      onClick={() => handleEditCostCenter(values[f.key] as string)}
                                                      style={styles.editIconContainer}
                                                    >
                                                      <EditIcon style={styles.editDimensionIcon} />
                                                    </InputAdornment>
                                                  )}
                                                  <InputAdornment position="end" style={styles.plusIconContainer}>
                                                    <PlusIcon style={styles.plusIcon} onClick={() => handleAddCostCenter(f.key)} />
                                                  </InputAdornment>
                                                </>
                                              ),
                                            }}
                                            style={styles.selectStyle}
                                          />
                                        )}
                                      />
                                      <FormHelperText error>
                                        {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                );
                              case 'ruleAccount':
                                return (
                                  <Grid item sx={styles.eachField} key={f.key}>
                                    <Typography>{ready && t(f.label)}</Typography>
                                    <FormControl variant="outlined" style={styles.formControl}>
                                      <Autocomplete
                                        fullWidth
                                        disabled={isDisabled}
                                        onChange={(e, v) => handleChangeAutocomplete(e, 'ruleAccount', v as 
                                          unknown as Record<string, any>, handleChange)}
                                        inputValue={getLabel(glaccounts, values.ruleAccount as string)}
                                        value={glaccounts && values?.ruleAccount && glaccounts.find(e => e.code === values.ruleAccount)}
                                        options={glaccounts}
                                        getOptionLabel={
                                          (option) => (option && option.code ? `${option.code} | ${option.name}` : '')
                                        }
                                        filterOptions={options => options}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            InputProps={{
                                              ...params.InputProps
                                            }}
                                            style={styles.selectStyle}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                );
                              default:
                                return (
                                  <Tooltip title={(f.key !== 'code') ? '' : !values[f.key]?'': t('DIMENSION_CODE_CANNOT_EDIT')}>
                                    <Grid item sx={styles.eachField} key={f.key}>
                                      <Typography>{ready && t(f.label)}</Typography>
                                      <TextField
                                        error={Boolean(touched[f.key] && errors[f.key])}
                                        fullWidth
                                        helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                        name={f.key}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required={f.required}
                                        value={values[f.key]}
                                        variant="outlined"
                                        style={styles.inputField}
                                        disabled={isDisabled ? true : vatCode ? (vatCode?.code && f.key) === 'code' : false}
                                      />
                                    </Grid>
                                  </Tooltip>
                                );
                            }
                          })
                        }
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))
              }
            <Grid >
              {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
              )}
             { 
              isDisabled ? <></> :
              <Box mt={2} display="flex" justifyContent="flex-end" gap="10px" p="0 16px 20px">
                <Button
                  variant="text"
                  onClick={()=>handleClose()}
                  sx={{...styles.yearField,textTransform:'none'}}
                >
                  {ready && t('VAT_CODES_CANCEL')}
                </Button>
                <Button
                  sx={{ backgroundColor: '#3E8AFF',textTransform:'none' }}
                  variant="contained"
                  disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user?.permissions)}
                  onClick={()=>handleSubmit()}
                >
                  {ready && t(vatCode && vatCode?.code ?'VAT_CODES_SAVE_CHANGES':'VAT_CODES_ADD_NEW')}
                </Button> 
              </Box>
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

const styles = {
  root: {
    backgroundColor: '',
    maxHeight: 'calc(94dvh - 76px)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  paper: {
    width: '96%',
    maxHeight: '94%',
    // overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    // padding: '20px 20px 28px 20px',
    outline: 'none',
  },
  halfPaper: {
    width: '52%',
    maxHeight: '94%',
    // overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    // padding: '20px',
    outline: 'none',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding:'20px 16px'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px'
  },
  closeIcon: {
    width: 20,
    height: 20,
    color: '',
  },
  eachFieldContainer:{
    display:'flex',
    flexDirection:'column',
    gap:'5px',
  },
  eachField:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    padding:'5px 0',
    width:'100%',
    flex:1,
    '& > p':{
      fontSize:'13px',
      color:'#4C4E64DE',
    }
  },
  topManager:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    gap:'12px',
    padding:'12px 16px',
    borderTop: '1px solid #4C4E641F',
    borderBottom: '1px solid #4C4E641F',
    margin:'0',
    marginBottom:'10px'
  },
  eachRow:{
    display:'flex',
    width:'100%',
    justifyContent:'space-between',
    padding:'0 16px'
  },
  form: {},
  snackAction: {
    color: 'white'
  },
  expiryRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  monthField: {
    marginRight: 16
  },
  yearField: {
    color: theme.palette.secondary.main
  },
  errorText: {
    color: '#f44336'
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: 'tranparent',
    marginBottom: 24,
    boxShadow:'none',
    borderTop:'none',
    margin:'0',
    '&::before':{
      display:'none',
      opacity:'0'
    },
    '& > div:first-of-type > div:first-of-type':{
      margin:'10px',
    }
  },
  accordionHeading: {
    color: theme.palette.text.primary,
    fontWeight: 500
  },
  formControl: {
    width: '180px'
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff'
  },
  errorChip: {
    marginLeft: 16,
    fontSize: 12,
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  inputField: {
    backgroundColor: '#ffffff',
    width:'180px'
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  manageFieldsBtn: {
    height: 'fit-content',
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#3E8AFF',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    gap:'10px',
    fontSize:'1rem',
    border:'none',
    '&:hover':{
      border:'none',
      backgroundColor:'#fff'
    }
  },
  btnIcon: {
    height: 20,
    width: 20,
    marginRight: 4,
    color: '#3E8AFF',
  },
  plusIcon: {
    height: 24,
    width: 24,
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  plusIconSelect: {
    height: 24,
    width: 24,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    marginRight: 5,
  },
  plusIconContainer: {
    marginRight: -40
  },
  editDimensionIcon: {
    fontSize: 18,
    color: '#757575',
    border: '1px solid #334D6E',
    borderRadius: 3,
    cursor: 'default'
  },
  editIconContainer: {
    marginRight: -5
  },
};


export default VatCodeTwinfield;
