import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Box, Table, TableBody, TableCell, TableRow, Hidden, Card, Grid, TablePagination,
  Tooltip, IconButton, useMediaQuery, TableHead
} from '@mui/material';
import {
  CardTravel as LogsIcon
} from '@mui/icons-material';

import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import { isActionPermitted, permissions } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import styles from './style';

const VatCodesTable = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const canEditDimension = isActionPermitted(permissions.dimensionEdit, user.permissions);

  const {
    vatCodes, limit, setLimit, page, setPage, total, gotoItem, getAllVatCodes
  } = props;

  const { ready, t } = useTranslation();

  const maxWidthRows = useMediaQuery('(max-width: 625px)');

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    getAllVatCodes(newPage, limit);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
    getAllVatCodes(0, event.target.value);
  };

  const paginationTab = () => (
    <TablePagination
      component="div"
      // classes={{
      //   caption: classes.paginationCaption,
      //   selectRoot: classes.paginationCaption,
      //   menuItem: classes.paginationCaption,
      //   actions: classes.paginationActions,
      // }}
      count={total}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleLimitChange}
      page={page}
      rowsPerPage={limit}
      rowsPerPageOptions={[10, 25, 50]}
      labelRowsPerPage={maxWidthRows ? '' : t('ROWS_PER_PAGE')}
    />
  );

  const gotoLogs = (event, code) => {
    event.stopPropagation();
    event.preventDefault();
    navigate(`/dimensions/logs/vatcodes/code/${code}`, { state: { code } });
  };

  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        !isActionPermitted(permissions.dimensionRead, user.permissions)
          ? (
            <EmptyText str="VAT_CODE_LIST_NO_PERMISSION" />
          )
          : vatCodes && vatCodes.length > 0 ? (
            <Card
              style={styles.cardCont}
            >
              <Box minWidth="100%">
                <div style={styles.bulkOperations}>
                  <div style={styles.bulkActions}>
                    {paginationTab()}
                  </div>
                </div>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={styles.headerName}>{ready && t('DIMENSIONS_LIST_VIEW_HEADER_NAME')}</TableCell>
                      <TableCell style={styles.headerName}>{ready && t('DIMENSIONS_LIST_VIEW_HEADER_CODE')}</TableCell>
                      <TableCell style={styles.headerName}>{ready && t('DIMENSIONS_LIST_VIEW_HEADER_TYPE')}</TableCell>
                      <Hidden smDown>
                        <TableCell />
                      </Hidden>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vatCodes.map((vatCode) => (
                      <TableRow
                        hover={canEditDimension}
                        key={vatCode.code}
                        style={canEditDimension ? styles.pointer : styles.tableRow}
                        onClick={() => canEditDimension && gotoItem(vatCode)}
                      >
                        <TableCell align="left" style={styles.creditorNameStyle}>
                          {vatCode.name}
                        </TableCell>
                        <TableCell style={styles.todayText}>
                          {vatCode.code}
                        </TableCell>
                        <TableCell style={styles.todayText}>
                          {vatCode.type || '-'}
                        </TableCell>
                        <Hidden smDown>
                          <TableCell width={102} align="center" style={styles.currencyText}>
                            <Tooltip title={t('VAT_CODE_LIST_VIEW_LOGS_TOOLTIP')}>
                              <IconButton
                                onClick={(e) => gotoLogs(e, vatCode.code)}
                              >
                                <LogsIcon
                                  style={{ ...styles.logsIcon, fontSize: '1.8rem' }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </Hidden>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={styles.bottomPagi}
                >
                  {paginationTab()}
                </Grid>
              </Box>
            </Card>
          ) : (
            <EmptyText str="NO_VAT_CODES" />
          )
      }
    </>
  );
};

export default VatCodesTable;
