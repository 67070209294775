import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import SideBarLayout from 'src/shared/layouts/SideBarLayout';
import SideBar from 'src/dimensions-new/components/Sidebar';
import Header from 'src/dimensions-new/components/Header';
import Clients from 'src/dimensions-new/clients/Clients';
import Glas from 'src/dimensions-new/glas/Glas';
import VatCodes from 'src/dimensions-new/vatcodes/VatCodes';
import CostCenters from 'src/dimensions-new/costcenters/CostCenters';
import ImportView from 'src/dimensions-new/importView/ImportView';
import styles from './style';

const DimensionsNew = () => {
  const type = useParams<Record<string, string>>().type || 'clients';

  const [showImport, setShowImport] = useState(false);
  const [selectedDimension, setSelectedDimension] = useState<string>(type);

  return (
    <SideBarLayout>
      <Box sx={styles.root}>
        <Box sx={styles.wrapper}>
          <Header />
          <Box sx={styles.mainContainer}>
            <SideBar showImport={showImport} selected={selectedDimension} setSelected={setSelectedDimension} setShowImport={setShowImport} />
            {showImport ? <ImportView setShowImport={setShowImport} /> : <>
              {selectedDimension === 'clients' && <Clients showImport={showImport} setShowImport={setShowImport} />}
              {selectedDimension === 'glaccounts' && <Glas showImport={showImport} setShowImport={setShowImport} />}
              {selectedDimension === 'vatcodes' && <VatCodes showImport={showImport} setShowImport={setShowImport} />}
              {selectedDimension === 'costcenters' && <CostCenters showImport={showImport} setShowImport={setShowImport} />}
            </>}
          </Box>
        </Box>
      </Box>
    </SideBarLayout>
  )
}

export default DimensionsNew