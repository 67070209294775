const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px 20px 20px',
    backgroundColor: '#F3F5F9',
    minHeight: '100%'
  },
}

export default styles;