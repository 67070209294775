import { ReactElement } from 'react'

import { Box, Typography } from '@mui/material';
import StepIcon from 'src/dimensions-new/importView/StepIcon';

type PropsType ={
  step: number;
  text: string | ReactElement | HTMLElement;
  activeStep: number;
}

const StepLabel = ( props: PropsType ) => {
  const { step, text, activeStep } = props;
  return (
    <Box sx={styles.container}>
      <Box sx={{...styles.icon, borderColor: step <= activeStep  || step === activeStep+1 ? 
        '#3E8AFF':'#dfe9f8', backgroundColor:step <= activeStep  ?
      '#3E8AFF':'white'}}><StepIcon /></Box>
      <Typography sx={styles.step}>0{step}</Typography>
      <>
        {
          typeof text === 'string' ? 
          <Typography sx={{fontSize:'14px',color:'#4C4E64DE'}}>{text}</Typography>:
          text
        }
      </>
    </Box>
  )
}

const styles = {
  container:{
    display:'flex',
    alignItems:'center',
    gap:'10px',
  },
  icon:{
    border:'3px solid #dfe9f8',
    width:'20px',
    height:'20px',
    justifyContent:'center',
    alignItems:'center',
    display:'flex',
    borderRadius:'50%'
  },
  step:{
    fontWeight:'500',
    fontSize:'20px',
    color:'#4C4E64DE'
  }
}

export default StepLabel