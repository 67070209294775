import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Chip, FormControl,
  FormHelperText, Grid, InputAdornment, MenuItem, Select, TextField, Tooltip, Autocomplete,
  Typography
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, AddBox as PlusIcon, Edit as EditIcon } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { isActionPermitted, permissions } from 'src/utils/helpers';
import { glAccountData, glaFields } from 'src/config';
import { GLAccountType, VatGroupType, FieldType } from 'src/types';
import theme from 'src/theme';
import { FormikErrors } from 'formik';

type PropsType = {
  errors: Record<string,string>;
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  handleSubmit: () => void;
  isSubmitting: boolean;
  touched: Record<string,string | boolean>;
  values: Record<string, string | boolean>;
  setErrors: (_errors: FormikErrors<Record<string, string>>) => void;
  glAccount: GLAccountType | null;
  handleClose: () => void;
  hasError: (_fields:FieldType[], _errors:Record<string,any>) => boolean;
  years: Record<string, string>[];
  endYears: Record<string, string>[];
  periods: Record<string, string | number>[];
  handleChangeAutocomplete: (_e:React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | 
    React.SyntheticEvent<Element, Event>, _prop:string, _values:Record<string,string | boolean>, _handleChange:Function) => void;
  getLabel: (_entities:Record<string,any>[] , _code:string) => string;
  vatCodes: VatGroupType[];
  newlyAddedVal: string;
  newlyAddingProp: string;
  handleEditVATCode: (_id: string) => void;
  handleAddVATCode: (_id: string) => void;
  onSuccess: (_val:string) => void;
  isDisabled: boolean | undefined;
}

const GLAccountsTwinfield = (props : PropsType) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    setErrors,
    glAccount,
    handleClose,
    hasError,
    years,
    endYears,
    periods,
    handleChangeAutocomplete,
    getLabel,
    vatCodes,
    newlyAddedVal,
    newlyAddingProp,
    handleEditVATCode,
    handleAddVATCode,
    isDisabled
  } = props;

  const { t, ready } = useTranslation();
  
  const auth = useAuth();
  const {user} = auth;

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } } as 
        React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>);
    }
  }, [newlyAddedVal]);

  return (
    <form style={styles.root} className='y-scroll'>
      <Card>
        <CardContent style={{ padding: 0 }}>
          <Grid container spacing={3} sx={{display:'flex',paddingTop:'25px',flexDirection:'column',gap:'10px',marginLeft:'0',width:'100%'}}>
            {
              glaFields.map((m:Record<string, any>,index:number) => (
                <Accordion
                  key={m.title}
                  style={styles.accordionGroup}
                  defaultExpanded={index===0?true:false}
                  className="twinfield-accordion"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={m.title}
                    id={m.title}
                  >
                    <Grid style={styles.accordionHeading}>
                      {ready && t(m.title)}
                      {
                        hasError(m.fields, errors) && (
                          <Chip
                            color="error"
                            // variant="default"
                            size="small"
                            label="Error"
                            style={styles.errorChip}
                          />
                        )
                      }
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails sx={{padding:'0 24px 16px 48px'}}>
                    <Grid sx={styles.eachFieldContainer}>
                      {
                        m.fields.map((f:Record<string,any>) => {
                          switch (f.key) {
                            case 'fromYear':
                              return (
                                <Grid item key={f.key} sx={styles.eachField}>
                                  <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <Select
                                      disabled={isDisabled}
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      value={values[f.key]}
                                      onClick={() => setErrors({})}
                                      onChange={ handleChange as any }
                                      name={f.key}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        years && years.length > 0
                                          && years.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {t(s.name)}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'endYear':
                              return (
                                <Grid item key={f.key} sx={styles.eachField}>
                                  <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <Select
                                      disabled={isDisabled}
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      value={values[f.key]}
                                      onClick={() => setErrors({})}
                                      onChange={handleChange as any}
                                      name={f.key}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        endYears && endYears.length > 0
                                          && endYears.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {t(s.name)}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'fromPeriod': case 'endPeriod':
                              return (
                                <Grid item key={f.key} sx={styles.eachField}>
                                  <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <Select
                                      disabled={isDisabled}
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      value={values[f.key]}
                                      onChange={handleChange as any}
                                      name={f.key}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        periods && periods.length > 0
                                          && periods.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {t(s.name as string)}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'furtherAnalysis':
                              return (
                                <Grid item key={f.key} sx={styles.eachField}>
                                  <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <Select
                                      disabled={isDisabled}
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      value={values[f.key]}
                                      onChange={handleChange as any}
                                      name={f.key}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        glAccountData.furtherAnalysis && glAccountData.furtherAnalysis.length > 0
                                          && glAccountData.furtherAnalysis.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {t(s.name)}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'matchable':
                              return (
                                <Grid item key={f.key} sx={styles.eachField}>
                                  <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <Select
                                      disabled={isDisabled}
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      value={values[f.key]}
                                      onChange={handleChange as any}
                                      name={f.key}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      <MenuItem value="yes">
                                        {t('GLA_MATCHABLE_MATCHABLE')}
                                      </MenuItem>
                                      <MenuItem value="no">
                                        {t('GLA_MATCHABLE_NOT_MATCHABLE')}
                                      </MenuItem>
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'performanceType':
                              return (
                                <Grid item key={f.key} sx={styles.eachField}>
                                  <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <Select
                                      disabled={isDisabled}
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      value={values[f.key]}
                                      onChange={handleChange as any}
                                      name={f.key}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        glAccountData.performanceType && glAccountData.performanceType.length > 0
                                          && glAccountData.performanceType.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {t(s.name)}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'vat':
                              return (
                                <Grid item key={f.key} sx={styles.eachField}>
                                  <Typography>{ready && t(f.label)}</Typography>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <Autocomplete
                                      disabled={isDisabled}
                                      fullWidth
                                      // name="vat"
                                      onChange={(e, v) => handleChangeAutocomplete(e, 'vat', v as unknown as Record<string, string | boolean>, handleChange)}
                                      inputValue={getLabel(vatCodes, values.vat as string)}
                                      value={vatCodes && values.vat && vatCodes.find((e:VatGroupType) => e.code === values?.vat) || null}
                                      options={vatCodes}
                                      getOptionLabel={
                                        (option: VatGroupType) => (option && option.code ? `${option.code} | ${option.name}`  : '')
                                      }
                                      filterOptions={option => option}
                                      renderInput={(params) => (
                                        <TextField
                                          // fullWidth
                                          {...params}
                                          label={ready && t(f.label)}
                                          variant="outlined"
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: ( isDisabled ? <></> :
                                              <>
                                                {values[f.key] !== glAccountData.vat[0].code && (
                                                  <InputAdornment
                                                    position="end"
                                                    onClick={() => handleEditVATCode(values[f.key] as string)}
                                                    style={styles.editIconContainer}
                                                  >
                                                    <EditIcon style={styles.editDimensionIcon} />
                                                  </InputAdornment>
                                                )}
                                                <InputAdornment position="end" style={styles.plusIconContainer}>
                                                  <PlusIcon style={styles.plusIcon} onClick={() => handleAddVATCode(f.key)} />
                                                </InputAdornment>
                                              </>
                                            ),
                                          }}
                                          style={styles.selectStyle}
                                        />
                                      )}
                                    />
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'vatType':
                              return (
                                <Grid item key={f.key} sx={styles.eachField}>
                                  <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                                  <FormControl variant="outlined" style={styles.formControl}>
                                    <Select
                                      disabled={isDisabled}
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      value={values[f.key]}
                                      onChange={handleChange as any}
                                      name={f.key}
                                      required={f.required}
                                      sx={{
                                        '& .MuiSelect-select': styles.selectStyle
                                      }}
                                    >
                                      {
                                        glAccountData.vatType && glAccountData.vatType.length > 0
                                          && glAccountData.vatType.map((s) => {
                                            if (s) {
                                              return (
                                                <MenuItem key={s.code} value={s.code}>
                                                  {t(s.name)}
                                                </MenuItem>
                                              );
                                            }

                                            return null;
                                          })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            default:
                              return (
                                <Tooltip title={(f.key !== 'code') ? '' : !values[f.key]?'': t('DIMENSION_CODE_CANNOT_EDIT')}>
                                  <Grid item key={f.key} sx={styles.eachField}>
                                    <Typography>{ready && t(f.label)}</Typography>
                                    <TextField
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      fullWidth
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      name={f.key}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      required={f.required}
                                      value={values[f.key]}
                                      variant="outlined"
                                      style={styles.inputField}
                                      disabled={isDisabled ? true : glAccount ?  f.key === 'code' : false}
                                    />
                                  </Grid>
                                </Tooltip>
                              );
                          }
                        })
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))
            }
            <Grid
              item
              xs={12}
            >
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>
                    {errors.submit}
                  </FormHelperText>
                </Box>
              )}
              {
                isDisabled ? <></> :
                <Box mt={2} display="flex" justifyContent="flex-end" p="0px 16px 20px">
                  <Button
                    variant="text"
                    onClick={()=>handleClose()}
                    sx={{...styles.yearField,textTransform:'none'}}
                  >
                    {ready && t('GLAS_CANCEL')}
                  </Button>
                  <Button
                    sx={{ backgroundColor: '#3E8AFF',color:'#fff',textTransform:'none'}}
                    variant="contained"
                    disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user?.permissions)}
                    onClick={()=>handleSubmit()}
                  >
                    {ready && t(glAccount && glAccount?.code ?'GLAS_SAVE_CHANGES':'GLA_ADD_NEW_GLA')}
                  </Button>
                </Box>
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};


export default GLAccountsTwinfield;

const styles = {
  root: {
    backgroundColor: '',
    maxHeight: 'calc(94dvh - 76px)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  paper: {
    width: '96%',
    maxHeight: '94%',
    // overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
    outline: 'none',
  },
  halfPaper: {
    width: '52%',
    maxHeight: '94%',
    // overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px',
    outline: 'none',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px'
  },
  closeIcon: {
    width: 20,
    height: 20,
    color: '#6D788D',
  },
  eachFieldContainer:{
    display:'flex',
    flexDirection:'column',
    gap:'5px',
  },
  eachField:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    padding:'5px 0',
    width:'100%',
    flex:1,
    '& > p':{
      fontSize:'13px',
      color:'#4C4E64DE',
    }
  },
  snackAction: {
    color: 'white'
  },
  expiryRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  monthField: {
    marginRight: 16
  },
  yearField: {
    // marginLeft: 16,
    color: theme.palette.secondary.main
  },
  errorText: {
    color: '#f44336'
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: 'tranparent',
    marginBottom: 24,
    boxShadow:'none',
    borderTop:'none',
    margin:'0',
    '&::before':{
      display:'none',
      opacity:'0'
    },
    '& > div:first-of-type > div:first-of-type':{
      margin:'10px',
    }

  },
  accordionHeading: {
    color: theme.palette.text.primary,
    fontWeight: 500
  },
  formControl: {
    width: '180px'
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff'
  },
  errorChip: {
    marginLeft: 16,
    fontSize: 12,
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  inputField: {
    width:'180px',
    maxWidth:'180px',
    backgroundColor: '#ffffff'
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  manageFieldsBtn: {
    height: 36,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: 8,
    fontSize: '12px'
  },
  btnIcon: {
    height: 15,
    width: 15,
    marginRight: 4,
    color: '#bebebe',
  },
  plusIcon: {
    height: 24,
    width: 24,
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  plusIconContainer: {
    marginRight: -40
  },
  editDimensionIcon: {
    fontSize: 18,
    color: '#757575',
    border: '1px solid #334D6E',
    borderRadius: 3,
    cursor: 'default'
  },
  editIconContainer: {
    marginRight: -5
  },
};
