import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, ClickAwayListener, Grid, Grow, MenuItem, MenuList,
  Paper, Popper, Typography
} from '@mui/material';
import {
  ArrowDropDown
} from '@mui/icons-material';

import useWidth from 'src/hooks/useWidth';
import { DocumentDeliveryFormatType } from 'src/types';
import { getDeliverFormat } from 'src/utils/helpers';
import CommonModal from 'src/shared/components/CommonModal/CommonModal';
import styles from './style';

interface PropTypes {
  open: boolean;
  selectedDataFormatToExport: string;
  deliverFormats: DocumentDeliveryFormatType[];
  handleClose: () => void;
  setSelectedDataFormatToExport: Dispatch<SetStateAction<string>>;
  handleExportClick: () => void;
}

const BulkExportModal = (props: PropTypes) => {
  const { t } = useTranslation();

  const {
    open,
    selectedDataFormatToExport,
    deliverFormats,
    handleClose,
    setSelectedDataFormatToExport,
    handleExportClick,
  } = props;

  const dataFormatOptionsToExportRef = useRef(null);
  const [dataFormatOptionsToExportOpen, setDataFormatOptionsToExportOpen] = useState(false);
  
  const exportOptionsWidth = useWidth(dataFormatOptionsToExportRef, 0);

  const handleDataformatOptionsToExportToggle = () => {
    setDataFormatOptionsToExportOpen((prevOpen) => !prevOpen);
  };

  const handleDataformatOptionsToExportClose = () => {
    setDataFormatOptionsToExportOpen(false);
  };

  const handleDataFormatToEmailClick = (dataFormat: DocumentDeliveryFormatType) => {
    setSelectedDataFormatToExport(dataFormat.key);
    setDataFormatOptionsToExportOpen(false);
  };
  
  return (
    <CommonModal
      open={open}
      title={t('EXPORT_DATA_TITLE')}
      cancelText={t('DASHBOARD_INVOICES_CANCEL')}
      submitText={t('INVOICE_EXPORT_BTN')}
      onSubmit={() => handleExportClick()}
      handleClose={handleClose}
    >
      <Grid sx={styles.fieldContainer}>
        <Typography sx={styles.inputLabel}>
          {t('EXPORT_TO_EMAIL_MODAL_DATA_FORMAT')}
        </Typography>
        <Button
          ref={dataFormatOptionsToExportRef}
          variant="contained"
          sx={styles.dataFormatBtn}
          onClick={handleDataformatOptionsToExportToggle}
        >
          {getDeliverFormat(selectedDataFormatToExport, deliverFormats).label}
          <ArrowDropDown />
        </Button>
        <Popper
          open={dataFormatOptionsToExportOpen}
          anchorEl={dataFormatOptionsToExportRef.current}
          role={undefined}
          transition
          style={{ zIndex: 9999999, width: exportOptionsWidth }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleDataformatOptionsToExportClose}>
                  <MenuList className="y-scroll" autoFocusItem>
                    {deliverFormats.map((option: DocumentDeliveryFormatType) => !option.dontExportViaEmail && (
                      <MenuItem
                        key={option.key}
                        selected={option.key === selectedDataFormatToExport}
                        onClick={() => handleDataFormatToEmailClick(option)}
                        sx={styles.exportOption}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </CommonModal>
  );
};

export default BulkExportModal;
