import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';

import { Avatar, Box, IconButton, Typography } from '@mui/material';
import {
  Edit as EditIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import { sections } from 'src/settings/utils';
import { SectionType } from 'src/settings/settingsTypes';
import { appendContactSupport, axiosHeadersFormData, getLocalisedErrorString } from 'src/utils/helpers';
import { CustomError, UserDataType } from 'src/types';
import authService from 'src/utils/authService';
import styles from './style';

const LeftPanel = (props: { selectedSection: SectionType, setSelectedSection: Dispatch<SetStateAction<SectionType>> }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { user, setUser } = useAuth();
  const isHacienda = user?.integrations.includes('hacienda-certification');

  const { API, LOGIN_PANEL_URL, ENVIRONMENT, SUPPORT_EMAIL } = useConfig();
  const { selectedSection, setSelectedSection } = props;

  const handleUploadClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    let file;
    if (files && files.length > 0) {
      [file] = files;

      if (file.size > 1 * 1024 * 1024) {
        enqueueSnackbar(t('ACCOUNT_SETTINGS_PROFILE_PIC_MAX_SIZE'), {
          variant: 'error',
        });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = async (f) => {
        if (reader.result) {
          const image = new Image();
          image.src = f.target?.result?.toString() || '';
          image.onload = async () => {
            if (image.width > 800 && image.height > 800) {
              enqueueSnackbar(t('ACCOUNT_SETTINGS_PROFILE_PIC_MAX_DIMENSIONS'), {
                variant: 'error',
              });
              return;
            }

            const data = new FormData();
            data.append('image', files[0]);

            try {
              const response = await Axios.post(
                API.userAvatarImage,
                data,
                axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
              );
              if (response.data.success) {
                if (user && setUser) {
                  const updatedUser: UserDataType = {
                    ...user,
                    avatar: reader.result?.toString(),
                    avatarName: 'new avatar'
                  };
                  setUser(updatedUser);
                }
              }
            } catch (e) {
              const error = e as CustomError;
              enqueueSnackbar(appendContactSupport(
                SUPPORT_EMAIL,
                getLocalisedErrorString(error?.response?.data?.i18n || 'ACCOUNT_SETTINGS_PROFILE_PIC_UPLOAD_FAILED', t),
                t
              ), {
                variant: 'error',
                autoHideDuration: 5000
              });
            }
          };
        }
      };
      reader.readAsDataURL(file);
    }
  }

  const handleChangeSection = (section: SectionType) => {
    navigate(`/settings?section=${section.id}`);
    setSelectedSection(section);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.profileDetails}>
        <IconButton component="label" sx={styles.avatarContainer}>
          <Avatar
            sx={styles.avatar}
            src={user?.avatar}
          />
          <input
            accept=".png, .jpg, .jpeg"
            type="file"
            onChange={handleUploadClick}
            style={{ display: 'none' }}
            required
          />
          <IconButton component="span" sx={styles.editIconContainer}>
            <EditIcon sx={styles.editIcon} />
          </IconButton>
        </IconButton>
        <Typography sx={styles.nameText}>
          {`${user?.firstname} ${user?.lastname}`}
        </Typography>
        <Typography sx={styles.userType}>
          {user?.role === 'company' && 'Admin'}
          {user?.role === 'user' && user.userRole}
        </Typography>
      </Box>
      <Box sx={styles.sectionContainer}>
        {sections.map((section) => {
          if (ENVIRONMENT !== 'local' && section.id === 'projectsnworkflows') {
            return null;
          }
          if ((user?.isSubCompany || user?.isPartnerSubCompany) && user.parent && section.id === 'plannbilling') {
            return null;
          }
          if ((user?.userRole !== 'admin' || user.isSubCompany) && section.id === 'partnerstats') {
            return null;
          }
          if ((user?.userRole !== 'admin' || user.isSubCompany || user.isPartnerSubCompany) && section.id === 'brand') {
            return null;
          }
          if (!isHacienda && section.id === 'hacienda') {
            return null;
          }
          return (
            <Box
              key={section.id}
              sx={selectedSection.id === section.id ? styles.itemContainerActive : styles.itemContainer}
              onClick={() => handleChangeSection(section)}
            >
              <Typography sx={selectedSection.id === section.id ? styles.itemTextActive : styles.itemText}>
                {t(section.title)}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default LeftPanel;
