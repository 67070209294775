import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';

interface PropsType {
  showImport?: boolean;
  selected: string,
  setSelected: Dispatch<SetStateAction<string>>
  setShowImport: Dispatch<SetStateAction<boolean>>
}

const Sidebar = (props: PropsType) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const {
    showImport = false,
    selected,
    setSelected,
    setShowImport,
  } = props;

  const getStyles = (option: string) => {
    return {
      ...styles.btn,
      color: selected === option && !showImport ? '#3E8AFF' : '#4C4E64DE',
      backgroundColor: selected === option && !showImport ? '#3E8AFF26' : '#FFFFFFF',
    };
  };

  const handleClick = (option: string) => {
    setSelected(option);
    setShowImport(false);
    navigate(`/dimensions/${option}`);
  };

  return (
    <Box sx={styles.root} className="y-scroll">
      <Button sx={getStyles('clients')} onClick={()=> handleClick('clients')}>
        {t('DASHBOARD_NAV_CLIENTS')}
      </Button>
      {user?.interfacePreference !== 'twinfield_interface' &&
        <>
          <Button sx={getStyles('glaccounts')} onClick={()=> handleClick('glaccounts')}>{
            t('DASHBOARD_NAV_GLACCOUNTS')}
          </Button>
          <Button sx={getStyles('vatcodes')} onClick={()=> handleClick('vatcodes')}>
            {t('DASHBOARD_NAV_VAT_CODES')}
          </Button>
          <Button sx={getStyles('costcenters')} onClick={()=> handleClick('costcenters')}>
            {t('DASHBOARD_NAV_COST_CENTERS')}
          </Button>
        </>
      }
      {showImport &&
        <Button sx={{ ...styles.btn, ...styles.selected }} onClick={()=> setShowImport(true)}>
          {t('DIMENSION_IMPORT_BTN')}
        </Button>
      }
    </Box>
  )
}

const styles={
  root: {
    width:'290px',
    flexShrink:'0',
    borderInlineEnd:'1px solid #4C4E641F',
    padding:'20px 12px 8px 12px',
    display:'flex',
    flexDirection:'column',
    gap:'6px',
    minHeight:'100%'
  },
  btn: {
    textDecoration:'none',
    textTransform:'none',
    fontWeight:'500',
    fontSize:'14px',
    lineHeight:'24px',
    padding:'8px 12px 8px 16px',
    borderRadius:'8px',
    justifyContent: 'flex-start',
  },
  selected: {
    color: '#3E8AFF',
    backgroundColor: '#3E8AFF26',
  }
}

export default Sidebar;
