import { useState } from 'react';
import { useParams } from 'react-router-dom';

import DashboardLayout from 'src/shared/layouts/DashboardLayout';
import Suppliers from 'src/dimensions/suppliers/Suppliers';
import Glas from 'src/dimensions/glas/GLAccounts';
import VatCodes from 'src/dimensions/vatcodes/VatCodes';
import CostCenters from 'src/dimensions/costcenters/CostCenters';

const Dimensions = () => {
  const { type } = useParams();
  const [selectedDimension] = useState(type);

  return (
    <DashboardLayout>
      {selectedDimension === 'clients' && <Suppliers />}
      {selectedDimension === 'glaccounts' && <Glas />}
      {selectedDimension === 'vatcodes' && <VatCodes />}
      {selectedDimension === 'costcenters' && <CostCenters />}
    </DashboardLayout> 
  );
}

export default Dimensions