import { useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import {
  Grid, Box, Button, Backdrop,
  CircularProgress
} from '@mui/material';

import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import BrandCompanyInfo from 'src/settings/components/BrandSettings/BrandCompanyInfo/BrandCompanyInfo';
import ConnectionSettings from 'src/settings/components/BrandSettings/ConnectionSettings/ConnectionSettings';
import BackToProcysConfirmation from 'src/settings/components/BrandSettings/BackToProcysConfirmation/BackToProcysConfirmation';

import styles from './style';
import { useConfig } from 'src/hooks/useConfig';
import { CustomError } from 'src/types';

type ErrorsType = Record<string, boolean>;

function BrandSettings() {
  const { enqueueSnackbar } = useSnackbar();
  const { ready, t } = useTranslation();
  const { API } = useConfig();
  const [loading, setLoading] = useState<boolean>(false);

  const [isEditable, setIsEditable] = useState<boolean>(false);

  const [errors, setErrors] = useState<ErrorsType>({
      name: false,
      domain: false,
      supportEmail: false,
      favicon: false,
      logo: false,
      terms: false,
      privacyPolicy: false,
  });

  const [values, setValues] = useState<Record<string, string>>({
    name: '',
    domain: '',
    supportEmail: '',
    terms: '',
    privacyPolicy: '',
  });
  
  const [brandDetails, setBrandDetails] = useState<Record<string, string>>({
    commercialName: '',
    domain: '',
    supportEmail: '',
    status: '',
  });

  const [openBackToProcysConfirmation, setOpenBackToProcysConfirmation] = useState<boolean>(false);

  const fetchBrandDetails = async () => {
    try {
      const response = await Axios.get(
        API.brand,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );

      if (response.data.success) {
        if (response.data.data) {
          const { status } = response.data.data;
          setIsEditable(status !== 'active');
          setBrandDetails(response.data.data);
        } else {
          setIsEditable(true);
        }
      }
    } catch (error) {
      setIsEditable(false);
      setBrandDetails({
        commercialName: '',
        domain: '',
        supportEmail: '',
        status:''
      });
    }
  };

  const helperTexts: Record<string, string | boolean> = {
    name: ready && t('BRAND_COMPANY_COM_NAME_HELPER_TEXT'),
    domain: ready && t('BRAND_COMPANY_DOMAIN_HELPER_TEXT'),
    supportEmail: ready && t('BRAND_COMPANY_SUPPORT_EMAIL_HELPER_TEXT'),
    favicon: ready && t('BRAND_COMPANY_SMALL_ICON_ERROR'),
    logo: ready && t('BRAND_COMPANY_LOGO_ERROR'),
    terms: ready && t('BRAND_COMPANY_TERMS_HELPER_TEXT'),
    privacyPolicy: ready && t('BRAND_COMPANY_PRIVACY_POLICY_HELPER_TEXT'),
  };

  useEffect(() => {
    fetchBrandDetails();
  }, []);

  const closeBackToProcysConfirmation = () => {
    setOpenBackToProcysConfirmation(false);
  };

  const handleStatusChange = async (status:string) => {
    try {
      setLoading(true);
      const response = await Axios.post(
        API.brandStatus,
        {
          status,
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setLoading(false)
        enqueueSnackbar(t('BRANDING_INITIATION_SUCCESS'), {
          variant: 'success'
        });
        fetchBrandDetails();
        closeBackToProcysConfirmation();
      }

    } catch (e) {
      setLoading(false);
      const error = e as CustomError;
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'BRANDING_INITIATION_FAILURE', t),
        t
      ), {
        variant: 'error'
      });
    }
  };

  return (
    <Grid sx={styles.root}>
      <Box sx={styles.card}>          
        <BrandCompanyInfo 
          isEditable={isEditable} 
          brandDetails={brandDetails} 
          fetchBrandDetails={fetchBrandDetails} 
          errors={errors} setErrors={setErrors} 
          helperTexts={helperTexts}
          values={values}
          setValues={setValues}
        />
        <ConnectionSettings setLoading={setLoading} brandDetails={brandDetails} />
        <Grid sx={styles.footer}>
          {brandDetails.status === 'active' && (
            <Button
              variant="outlined"
              sx={styles.backToProcysBtn}
              onClick={() => setOpenBackToProcysConfirmation(true)}
            >
              {ready && t('BRAND_SETTINGS_BACK_TO_PROCYS')}
            </Button>
          )}
          {brandDetails.status !== 'active' && (
            <Button
              variant="contained"
              sx={styles.saveBtn}
              disabled={!isEditable}
              onClick={() => handleStatusChange('active')}
            >
              {ready && t('BRAND_SETTINGS_SAVE')}
            </Button>
          )}

          {!brandDetails.status && (
          <Button
            variant="contained"
            sx={styles.brandBtn}
            disabled={!isEditable}
            onClick={() => handleStatusChange('active')}
          >
            {ready && t('BRAND_SETTINGS_BRAND_MY_APP')}
          </Button>
          )}

          {(brandDetails.status === 'active') && (
            <Button
              variant="outlined"
              sx={styles.editBtn}
              onClick={() => handleStatusChange('cancelled')}
            >
              {ready && t('BRAND_SETTINGS_EDIT')}
            </Button>
          )}

        </Grid>
      </Box>
      <BackToProcysConfirmation
        openConfirmation={openBackToProcysConfirmation}
        onClose={closeBackToProcysConfirmation}
        handleStatusChange={handleStatusChange}
      />
      <Backdrop open={loading} sx={{zIndex:1111111, color:'inherit'}}>
        <CircularProgress sx={{color:'#fff'}} />
      </Backdrop>
    </Grid>
  );
}

export default BrandSettings;
