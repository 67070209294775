const style = {
  companyBtn:{
    height: '36px',
    maxWidth: '320px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  companyBtnText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '14px',
    color: '#4C4E64',
  },
  dropdownIcon: {
    color: '#4C4E648A',
    height: '24px',
    width: '24px'
  },
  menuPaper: {
    boxShadow:' 0px 4px 8px -4px #4C4E646B',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    minWidth: '360px',
  },
  menuContainer: {
    mt: '38px',
    '.MuiMenu-paper::-webkit-scrollbar': {
      height: '6px',
      width: '6px'
    },
    '.MuiMenu-paper::-webkit-scrollbar-thumb': {
      backgroundColor: '#4C4E6499',
      borderRadius: '10px',
    },
    '.MuiMenu-paper::-webkit-scrollbar-track': {
      backgroundColor: '#fefefe',
    },
    '.MuiMenu-paper::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#4C4E645F',
    },
  },
  menuItemDescr: {
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    padding: '4px 10px',
    color: '#4C4E64',
    fontSize: '10px',
  },
  menuItem:{
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    padding: '4px 10px',
    color: '#4C4E64',
    fontSize: '14px',
    ':hover':{
      backgroundColor: '#C2CFE033'
    }
  },
}

export default style;
