import * as React from 'react'
import { JSX } from 'react/jsx-runtime'

function CheckIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
      {...props} 
    >
    <path d="M5.25009 9.43241L2.81759 6.99991L1.98926 7.82241L5.25009 11.0832L12.2501 4.08324L11.4276 3.26074L5.25009 9.43241Z" fill="white"/>
    </svg>
  )
}

export default CheckIcon