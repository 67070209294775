const styles = {
  pdfActionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%'
  },
  pdfActionsComponent: {
    maxWidth: '480px',
    height: '60px',
    backgroundColor: '#596274',
    borderRadius: '4px',
    padding: '16px 20px',
    boxShadow: '0px 6px 18px -8px #4C4E648F',
    display: 'flex',
    justifycontent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  zoomButtonIcon: {
    width: '20px',
    height: '20px',
    color: '#ffffff'
  },
  zoomButton: {
    padding: '0px',
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  zoomPercentageText: {
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: '500',
    marginLeft: '16px',
    marginRight: '8px',
    width: '32px'
  },
  pdfActionButton: {
    color: '#ffffff',
    marginLeft: '8px',
    marginRight: '8px',
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionIcon: {
    width: '20px',
    height: '20px',
  },
  zoomOutMapIcon: {
    width: '18px',
    height: '18px',
  },
  pdfPageText: {
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: '500',
    marginLeft: '8px',
    marginRight: '8px'
  },
  pdfActionsSlider: {
    width: '115px',
    marginLeft: '8px',
    marginRight: '8px',
    marginBottom: '6px'
  },
};

export default styles;
