import theme from 'src/theme';

const styles = {
  root: {
    backgroundColor: '',
    minHeight: '100%',
    border:'none',
    justifyContent: 'center',
    display: 'flex',
  },
  card: {
    padding: theme.spacing(2),
    width: '100%',
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px',
    marginBottom: theme.spacing(2),
  },
  descriptionLink: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
  },
  descriptionText: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '0px 16px 0px 0px',
    minWidth: '324px'
  },
  backToProcysBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    color:'#4C4E64',
    border: '1px solid #4C4E6433',
    boxShadow:' 0px 4px 8px -4px #4C4E646B',
    marginTop:'6px',
    paddingTop:'5px',
    backgroundColor:'#fff',
    '&:disabled, &:hover':{
      color:'#4C4E64',
      backgroundColor:'#fff',
      boxShadow:' 0px 4px 8px -4px #4C4E646B',
      opacity:'1',
      border: '1px solid #4C4E6433',
    }
  },
  editBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    border: 'none',
    margin: '6px 0px 6px 10px',
    backgroundColor:'#3E8AFF',
    boxShadow:' 0px 4px 8px -4px #4C4E646B',
    color:'#fff',
    '&:disabled, &:hover':{
      color:'#fff',
      backgroundColor:'#3E8AFF',
      boxShadow:' 0px 4px 8px -4px #4C4E646B',
      opacity:'1',
      cursor:'pointer',
      border:'none'
    }
  },
  saveBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    margin: '6px 0px 6px 12px',
    color:'#4C4E64',
    border: '1px solid #4C4E6433',
    boxShadow:' 0px 4px 8px -4px #4C4E646B',
    backgroundColor:'#fff',
    '&:disabled, &:hover':{
      color:'#4C4E64',
      backgroundColor:'#fff',
      boxShadow:' 0px 4px 8px -4px #4C4E646B',
      opacity:'1',
    }
  },
  brandBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    margin: '6px 0px 6px 12px',
    backgroundColor:'#3E8AFF',
    boxShadow:' 0px 4px 8px -4px #4C4E646B',
    '&:disabled, &:hover':{
      color:'#fff',
      backgroundColor:'#3E8AFF',
      boxShadow:' 0px 4px 8px -4px #4C4E646B',
      opacity:'1',
      cursor:'pointer',
    }
  },
  uploadBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    backgroundColor: '',
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    border: '',
    margin: '6px 0px 6px 0px'
  },
};

export default styles;
