import theme from 'src/theme';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px'
  },
  paperModal: {
    width: 600,
    maxHeight: '96dvh',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  closeIcon: {
    width: '20px',
    height: '20px',
    color: 'rgba(51, 77, 110, 0.5)'
  },
  headerTitle: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#4C4E64',
    lineHeight: '36px',
  },
  deleteMessageText: {
    color: '#334D6E',
    paddingTop: '16px',
    fontSize:'14px',
  },
  closeBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    margin: '6px 0px 6px 12px',
    color:'#4C4E64',
    border: '1px solid #4C4E6433',
    boxShadow:' 0px 4px 8px -4px #4C4E646B',
    backgroundColor:'#fff',
    '&:disabled, &:hover':{
      color:'#4C4E64',
      backgroundColor:'#fff',
      boxShadow:' 0px 4px 8px -4px #4C4E646B',
      opacity:'1',
    }
  },
  userForm: {
    width: '100%'
  },
  submitBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    color: '#fff',
    paddingTop: 0,
    paddingBottom: 0,
    border: 'none',
    margin: '6px 0px 6px 0px',
    boxShadow:' 0px 4px 8px -4px #4C4E646B',
    
    backgroundColor:'#3E8AFF',
    '&:disabled,&:hover':{
      color:'#fff',
      backgroundColor:'#3E8AFF',
      boxShadow:' 0px 4px 8px -4px #4C4E646B',
      opacity:'1',
      cursor:'pointer',
      border:'none'
    }
  },
};

export default styles;
