import theme from "src/theme";

const styles = {
  root: {},
  form: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  paper: {
    maxWidth: 600,
    width: '90%',
    maxHeight: '96dvh',
    backgroundColor: theme.palette.background.default,
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #4C4E641F',
    padding: '16px 24px',
    marginBottom: '16px'
  },
  headerTitle: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#4C4E64',
    lineHeight: '36px',
    marginRight: 24
  },
  closeIcon: {
    width: 20,
    height: 20,
    color: '#6D788D',
  },
  modalContent: {
    padding: '0 24px'
  },
  modalFooter: {
    width: '100%',
    boxShadow: '0px -4px 8px -4px #4C4E646B',
    padding: '16px 24px',
    marginTop: '24px'
  },
  snackAction: {
    color: 'white'
  },
  userForm: {
    width: '100%'
  },
  fieldContainer: {
    marginTop: '24px'
  },
  submitBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 500,
    textTransform: 'none',
    background: '#3E8AFF',
    color:'#fff',
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    borderRadius: '4px',
    paddingInline:'15px',
    '&:hover':{
      background: '#3E8AFF',
      color:'#fff',
    }
  },
  closeBtn: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 500,
    color: '#4C4E64',
    textTransform: 'none',
    marginRight: '20px',
    border: '1px solid #4C4E6433',
    padding:'7px 22px'
  },
  moreButton: {
    textTransform: 'none'
  },
  formControl: {
    width: '100%'
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: 13,
    lineHeight: '20px',
  },
  inputField:{
    color:'#4C4E64',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099!important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `2px solid #C2CFE099!important`,
      }
    },
  },
  selectAutoField:{
    color: '#4C4E64 !important',
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '& .MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099 !important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099 !important`,
      }
    },
  }
};

export default styles;