import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';

import { Box, Button, Card, CardContent, FormControl, FormHelperText, Grid, InputAdornment, InputLabel,
  MenuItem, Select, TextField, Tooltip, Autocomplete, Typography
} from '@mui/material';
import { Checklist as FieldsIcon, AddBox as PlusIcon, Edit as EditIcon } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { axiosHeaders, isActionPermitted, isShowManageFields, permissions } from 'src/utils/helpers';
import { glAccountData } from 'src/config';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import ConfigContext from 'src/contexts/ConfigContext';
import ManageFields from 'src/shared/components/ManageFieldsNew/ManageFieldsNew';
import { FieldType, GLAccountType, VatGroupType } from 'src/types';
import theme from 'src/theme';

type PropsType = {
  errors:Record<string,string>,
  handleBlur:React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleSubmit: () => void;
  isSubmitting: boolean;
  touched: Record<string, string | boolean>;
  values: Record<string,string | boolean>;
  glAccount: GLAccountType | null;
  handleClose: () => void;
  handleChangeAutocomplete: (_e:React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | 
  React.SyntheticEvent<Element, Event>, _prop:string, _values:Record<string,string | boolean>, _handleChange:Function) => void;
  getLabel: (_entities:Record<string,any>[] , _code:string) => string;
  vatCodes: VatGroupType[];
  newlyAddedVal: string;
  newlyAddingProp:string;
  handleEditVATCode: (_id: string) => void;
  handleAddVATCode: (_id: string) => void;
  isDisabled: boolean;
}

const GLAccountsStandard = (props: PropsType) => {
  const { API } = useContext(ConfigContext);
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    glAccount,
    handleClose,
    handleChangeAutocomplete,
    getLabel,
    vatCodes,
    newlyAddedVal,
    newlyAddingProp,
    handleEditVATCode,
    handleAddVATCode,
    isDisabled,
  } = props;
  
  const { t, ready } = useTranslation();

  const auth = useAuth();
  const {user} = auth;
  const [glaFields, setGlaFields] = useState([]);
  const [showManageFields, setShowManageFields] = useState(false);
  const [loading, setLoading] = useState(false);

  const getGLAFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/gla/${encodeURIComponent(user?.companyID as string)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setGlaFields(response.data.data.filter((f:FieldType) => f.isAvailable));
          setLoading(false);
        } else {
          setGlaFields([]);
          setLoading(false);
        }
      }
    } catch (error) {
      setGlaFields([]);
      setLoading(false);
    }
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
  };

  useEffect(() => {
    getGLAFields();
  }, []);

  

  const isFieldAvailable = (field:FieldType) => {
    if (field.isMandatory) {
      return false;
    }
    if (field.isActive) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } } as unknown as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
    }
  }, [newlyAddedVal]);


  return (
    <>
    
      {
        !showManageFields?
        <form
          style={styles.root}
          className="y-scroll"
        >
          {loading ? (<LoadingIndicator />) : (
            <Card style={{ boxShadow: 'none' }}>
              <CardContent sx={{ padding: '0', display:'flex', flexDirection:'column',gap:'8px' }}>
                {isShowManageFields(user, 'applyParentFieldsGLA') && (
                  <Grid m={0} item xs={12} sx={styles.topManager}>
                    <Button
                      variant="outlined"
                      sx={styles.manageFieldsBtn}
                      onClick={() => setShowManageFields(true)}
                    >
                      <FieldsIcon style={styles.btnIcon} />
                      {t('INVOICE_EDIT_FORM_MANAGE_FIELDS')}
                    </Button>
                  </Grid>
                )}
                <Grid item key="name" sx={styles.eachRow}>
                  <Typography>{ready && t('GLA_NAME')}</Typography>
                  <TextField
                    disabled={isDisabled}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && (ready && t(errors.name))}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                    sx={styles.inputField}
                  />
                </Grid>
                <Grid item key="vat" sx={styles.eachRow}>
                  <Typography>{ready && t('GLA_VAT')}</Typography>
                  <FormControl variant="outlined" style={styles.formControl}>
                    <Autocomplete
                      disabled={isDisabled}
                      fullWidth
                      onChange={(e, v) => handleChangeAutocomplete(e, 'vat', v as unknown as Record<string, boolean | string>, handleChange)}
                      inputValue={getLabel(vatCodes, values.vat as string) as string}
                      value={vatCodes && values.vat && vatCodes.find((e:VatGroupType) => e.code === values?.vat) || null}
                      options={vatCodes}
                      getOptionLabel={
                            (option: VatGroupType) => (option && option.code ? `${option.code} | ${option.name}` : '')
                        }
                      filterOptions={options => options}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: ( isDisabled ? <></> :
                              <>
                                {values.vat !== glAccountData.vat[0].code && (
                                  <InputAdornment
                                    position="end"
                                    onClick={() => handleEditVATCode(values.vat as string)}
                                    style={styles.editIconContainer}
                                  >
                                    <EditIcon style={styles.editDimensionIcon} />
                                  </InputAdornment>
                                )}
                                <InputAdornment position="end" style={styles.plusIconContainer}>
                                  <PlusIcon style={styles.plusIcon} onClick={() => handleAddVATCode('vat')} />
                                </InputAdornment>
                              </>
                            ),
                          }}
                          style={styles.selectStyle}
                        />
                      )}
                    />
                    <FormHelperText error>
                      {touched.vat && errors.vat && ready ? t(errors.vat) : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {
                  glaFields.map((f:FieldType,i: number) => {
                    if (!isFieldAvailable(f)) {
                      return null;
                    }
                    switch (f.key) {
                      case 'furtherAnalysis':
                        return (
                          <Grid item key={f.key+`${i}`} sx={styles.eachRow}>
                            <Typography>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</Typography>
                            <FormControl variant="outlined" style={styles.formControl}>
                              <Select
                                disabled={isDisabled}
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                value={values[f.key]}
                                onChange={handleChange as any}
                                name={f.key}
                                label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                                required={f.isRequired}
                                sx={{
                                  '& .MuiSelect-select': styles.selectStyle
                                }}
                              >
                                {
                                  glAccountData.furtherAnalysis && glAccountData.furtherAnalysis.length > 0
                                      && glAccountData.furtherAnalysis.map((s) => {
                                        if (s) {
                                          return (
                                            <MenuItem key={s.code} value={s.code}>
                                              {t(s.name)}
                                            </MenuItem>
                                          );
                                        }

                                        return null;
                                      })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'matchable':
                        return (
                          <Grid item key={f.key+i} sx={styles.eachRow}>
                            <Typography>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</Typography>
                            <FormControl variant="outlined" style={styles.formControl}>
                              <Select
                                disabled={isDisabled}
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                value={values[f.key]}
                                onChange={handleChange as any}
                                name={f.key}
                                label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                                required={f.isRequired}
                                sx={{
                                  '& .MuiSelect-select': styles.selectStyle
                                }}
                              >
                                <MenuItem value="yes">
                                  {t('GLA_MATCHABLE_MATCHABLE')}
                                </MenuItem>
                                <MenuItem value="no">
                                  {t('GLA_MATCHABLE_NOT_MATCHABLE')}
                                </MenuItem>
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'performanceType':
                        return (
                          <Grid item key={f.key+i} sx={styles.eachRow}>
                            <Typography>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</Typography>
                            <FormControl variant="outlined" style={styles.formControl}>
                              <Select
                                disabled={isDisabled}
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                value={values[f.key]}
                                onChange={handleChange as any}
                                name={f.key}
                                label={ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}
                                required={f.isRequired}
                                sx={{
                                  '& .MuiSelect-select': styles.selectStyle
                                }}
                              >
                                {
                                  glAccountData.performanceType && glAccountData.performanceType.length > 0
                                      && glAccountData.performanceType.map((s) => {
                                        if (s) {
                                          return (
                                            <MenuItem key={s.code} value={s.code}>
                                              {t(s.name)}
                                            </MenuItem>
                                          );
                                        }

                                        return null;
                                      })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'vatType':
                        return (
                          <Grid item key={f.key+i} sx={styles.eachRow}>
                            <Typography>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</Typography>
                            <FormControl variant="outlined" style={styles.formControl}>
                              <InputLabel id={`${f.key}-label`}></InputLabel>
                              <Select
                                disabled={isDisabled}
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                value={values[f.key]}
                                onChange={handleChange as any}
                                name={f.key}
                                required={f.isRequired}
                                sx={{
                                  '& .MuiSelect-select': styles.selectStyle
                                }}
                              >
                                {
                                  glAccountData.vatType && glAccountData.vatType.length > 0
                                      && glAccountData.vatType.map((s) => {
                                        if (s) {
                                          return (
                                            <MenuItem key={s.code} value={s.code}>
                                              {t(s.name)}
                                            </MenuItem>
                                          );
                                        }

                                        return null;
                                      })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      default:
                        return (
                          <Tooltip key={f.key+i} title={( f.key !== 'code') ? '' :!values[f.key] ? '' : t('DIMENSION_CODE_CANNOT_EDIT')}>
                            <Grid item sx={styles.eachRow}>
                              <Typography>{ready && t(f.label)}</Typography>
                              <TextField
                                error={Boolean(touched[f.key] && errors[f.key])}
                                fullWidth
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                name={f.key}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                required={f.isRequired}
                                value={values[f.key]}
                                variant="outlined"
                                style={styles.inputField}
                                disabled={isDisabled? true: (glAccount ? f.key === 'code' : false) }
                              />
                            </Grid>
                          </Tooltip>
                        );
                    }
                  })
                }
                {errors.submit && (
                  <Grid item xs={12}>
                    <Box mt={3}>
                      <FormHelperText error>
                        {errors.submit}
                      </FormHelperText>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>

                  {
                    isDisabled ? <></> :
                    <Box display="flex" justifyContent="flex-end" gap="10px" padding="10px 16px 0">
                      <Button
                        variant="text"
                        onClick={()=>handleClose()}
                        sx={{...styles.yearField,textTransform:'none'}}
                      >
                        {ready && t('GLAS_CANCEL')}
                      </Button>
                      <Button
                        sx={{ backgroundColor: '#3E8AFF',textTransform:'none' }}
                        variant="contained"
                        disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user?.permissions)}
                        onClick={()=>handleSubmit()}
                      >
                        {ready && t(glAccount && glAccount?.code ?'GLAS_SAVE_CHANGES':'GLA_ADD_NEW_GLA')}
                      </Button>
                    </Box>
                  }
                </Grid>
              </CardContent>
            </Card>
          )}
        </form>:
        <Box sx={{backgroundColor:'#fff', position:'relative',top:'-20px',
          '& > div > div:nth-of-type(2)':{maxHeight:'calc(90dvh - 228px)',minHeight:'calc(90dvh - 228px)'}}}>
          <ManageFields
            entity="gla"
            fields={glaFields}
            fetchFields={getGLAFields}
            handleCloseManageFields={handleCloseManageFields}
            parameterCompany={user?.companyID || ''}
          />
        </Box>
      }

    
    
    </>
  );
};

export default GLAccountsStandard;

const styles = {
  root: {
    backgroundColor: '',
    maxHeight: 'calc(94dvh - 76px)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px'
  },
  paper: {
    width: '96%',
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px 20px 28px 20px',
    outline: 'none',
  },
  halfPaper: {
    width: '52%',
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '20px',
    outline: 'none',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: '36px'
  },
  closeIcon: {
    width: 11.67,
    height: 11.67,
    color: '#3E8AFF',
  },
  topManager:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    gap:'12px',
    padding:'12px 16px',
    borderBottom: '1px solid #4C4E641F',
    marginBottom:'10px'
  },
  snackAction: {
    color: 'white'
  },
  eachRow:{
    display:'flex',
    width:'100%',
    justifyContent:'space-between',
    padding:'0 16px',
    '& > p':{
      fontSize:'13px',
      color:'#4C4E64DE',
    }
  },
  expiryRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  monthField: {
    marginRight: 16
  },
  yearField: {
    color: theme.palette.secondary.main
  },
  errorText: {
    color: '#f44336'
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: '#f4f6f8',
    marginBottom: 24
  },
  accordionHeading: {
    color: theme.palette.text.primary,
    fontWeight: 500
  },
  formControl: {
    width: '180px'
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    width:'180px'
  },
  errorChip: {
    marginLeft: 16,
    fontSize: 12,
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  inputField: {
    width:'180px',
    backgroundColor: '#ffffff'
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  manageFieldsBtn: {
    height: 'fit-content',
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#3E8AFF',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    gap:'10px',
    fontSize:'13px',
    fontWeight:'500',
    border:'none',
    '&:hover':{
      border:'none',
      backgroundColor:'#fff'
    }
  },
  btnIcon: {
    height: 20,
    width: 20,
    marginRight: 4,
    color: '#3E8AFF',
  },
  plusIcon: {
    height: 24,
    width: 24,
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  plusIconContainer: {
    marginRight: -40
  },
  editDimensionIcon: {
    fontSize: 18,
    color: '#757575',
    border: '1px solid #334D6E',
    borderRadius: 3,
    cursor: 'default'
  },
  editIconContainer: {
    marginRight: -5
  },
};
