import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import { Grid, IconButton, Slider, styled, Typography } from '@mui/material';
import {
  AddCircleOutline,
  RemoveCircleOutline,
  RotateRight,
  ZoomOutMap,
  ZoomInMap,
  DownloadOutlined
} from '@mui/icons-material';

import ConfigContext from 'src/contexts/ConfigContext';
import { UserDataType } from 'src/types';
import styles from './style';

const FileSaver = require('file-saver');

interface PropTypes {
  doc: any;
  pagesRotation: number;
  pdfViewerWidth: string;
  viewLines: boolean;
  numPages: number;
  pageNumber: string;
  zoomPercentage: string;
  fitHeightScaleValue: React.MutableRefObject<string>;
  user: UserDataType | null;
  setZoomPercentage: React.Dispatch<React.SetStateAction<string>>;
  onScaleChange: (_scale: string) => void;
  onRotationChange: (_rotation: number) => void;
  onChangePageNumber: (_pageNumber: string) => void;
}

const PDFActions = (props: PropTypes) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { API } = useContext(ConfigContext);
  
  const {
    doc,
    pagesRotation,
    pdfViewerWidth,
    viewLines,
    numPages,
    pageNumber,
    zoomPercentage,
    fitHeightScaleValue,
    user,
    setZoomPercentage,
    onScaleChange,
    onRotationChange,
    onChangePageNumber,
  } = props;

  const isHacienda = user?.integrations.includes('hacienda-certification');

  useEffect(() => {
    if (zoomPercentage === 'page-width') {
      onScaleChange('page-width');
    } else {
      onScaleChange((parseInt(zoomPercentage, 10) / 100).toFixed(2));
    }
  }, [zoomPercentage]);

  const onZoomIn = () => {
    let zoomPercentAsInt = parseInt(zoomPercentage, 10);
    const isZoomPercentageANumber = isNaN(zoomPercentAsInt);

    if (isZoomPercentageANumber && zoomPercentage === 'page-width') {
      setZoomPercentage('130');
      return;
    } else {
      if (zoomPercentAsInt >= 200) {
        return;
      }
      zoomPercentAsInt += 10
    }

    setZoomPercentage(`${zoomPercentAsInt}`);
  };

  const onZoomOut = () => {
    let zoomPercentAsInt = parseInt(zoomPercentage, 10);
    const isZoomPercentageNotANumber = isNaN(zoomPercentAsInt);
    // @TODO: Calculate the zoom percentage when the scale is 'page-width'
    if (isZoomPercentageNotANumber && zoomPercentage === 'page-width') {
      setZoomPercentage('120');
      return;
    } else {
      if (zoomPercentAsInt <= 40) {
        return;
      }
      zoomPercentAsInt -= 10
    }

    setZoomPercentage(`${zoomPercentAsInt}`);
  };

  const onRotateDocument = () => {
    if (pagesRotation === 270) {
      onRotationChange(0);
      return;
    }

    onRotationChange(pagesRotation + 90);
  };

  const onSetToPageWidth = () => {
    if (zoomPercentage === 'page-width') {
      setZoomPercentage(fitHeightScaleValue.current);
    } else {
      setZoomPercentage('page-width');
    }
  };

  const onChangePage = (val: string) => {
    const pageNumberVal = parseInt(val, 10);

    if (Number.isNaN(pageNumberVal)) {
      onChangePageNumber('');
      return;
    }

    if (val === pageNumber) {
      return;
    }

    if (pageNumberVal < 1) {
      onChangePageNumber('1');
      return;
    }

    if (pageNumberVal > numPages) {
      onChangePageNumber(numPages.toString());
      return;
    }

    if (pageNumberVal > 0 && pageNumberVal <= numPages) {
      onChangePageNumber(val);
    }
  };

  const onDownload = async () => {
    try {
      const response = await Axios.get(
        `${isHacienda ? API.getSignedDocument : API.getInvoiceImage}${doc.documentFile}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      FileSaver.saveAs(window.URL.createObjectURL(blob), doc.assistantID)
    } catch (err) {
      enqueueSnackbar(t('DOCUMENT_DOWNLOAD_FAILED'), {
        variant: 'error',
      });
    }
  };

  const StyledSlider = styled(Slider)(() => ({
    color: '#fff',
    height: '5px !important',
    '& .MuiSlider-thumb': {
      height: '16px !important',
      width: '16px !important',
      backgroundColor: '#fff',
      marginLeft: '0px !important',
    },
    '& .MuiSlider-track': {
      border: 'none',
      height: '5px !important',
      color: '#fff',
    },
    '& .MuiSlider-rail': {
      boxShadow: 'inset 0px 0px 4px -2px #000',
      backgroundColor: '#d0d0d0',
      height: '5px !important',
    },
  }));

  return (
    <Grid
      sx={{
        width: pdfViewerWidth,
        position: 'absolute',
        top: viewLines ? '12px' : 'calc(100vh - 66px)',
        ...styles.pdfActionsContainer
      }}
    >
      <Grid sx={styles.pdfActionsComponent}>
        <IconButton
          onClick={onZoomOut}
          sx={styles.zoomButton}
        >
          <RemoveCircleOutline sx={styles.zoomButtonIcon} />
        </IconButton>
        <StyledSlider
          size="small"
          value={zoomPercentage === 'page-width' ? 125 : parseInt(zoomPercentage, 10)}
          aria-label="Small"
          valueLabelDisplay="off"
          min={40}
          max={200}
          step={10}
          sx={styles.pdfActionsSlider}
          disabled
        />
        <IconButton
          onClick={onZoomIn}
          sx={styles.zoomButton}
        >
          <AddCircleOutline sx={styles.zoomButtonIcon} />
        </IconButton>
        <Typography sx={styles.zoomPercentageText}>
          {zoomPercentage === 'page-width' ? 125 : parseInt(zoomPercentage, 10) || 0}%
        </Typography>
        <IconButton
          onClick={onRotateDocument}
          sx={styles.pdfActionButton}
        >
          <RotateRight sx={styles.actionIcon} />
        </IconButton>
        <IconButton
          onClick={onSetToPageWidth}
          sx={styles.pdfActionButton}
        >
          {zoomPercentage === 'page-width'
            ? <ZoomInMap sx={styles.zoomOutMapIcon}></ZoomInMap>
            : <ZoomOutMap sx={styles.zoomOutMapIcon} />
          }
        </IconButton>
        <IconButton
          onClick={onDownload}
          sx={styles.pdfActionButton}
        >
          <DownloadOutlined sx={styles.actionIcon} />
        </IconButton>
        <Typography sx={styles.pdfPageText}>
          <input
            className='pdf-actions-input'
            type="text"
            value={pageNumber}
            onChange={(e) => onChangePage(e.target.value)}
          /> / {numPages}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PDFActions;
