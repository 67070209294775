import { useContext, useEffect, useState } from 'react';
// import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';

import { Backdrop, CircularProgress, Fade, Grid, IconButton, Modal, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { axiosHeaders, isActionPermitted, permissions } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import CostCenterTwinfield from 'src/dimensions-new/costcenters/components/CostCenterTwinField';
import CostCenterStandard from 'src/dimensions-new/costcenters/components/CostCenterStandard';
import {CostCenterType, FieldType } from 'src/types';
import theme from 'src/theme';


type PropsType = {
  costCenterModalOpen: boolean;
  selectedCostCenter: string;
  handleClose: () => void;
  getAllCostCenters: ( _page?: number, _limit?: number, _search?: string, _loadFullScreen?: boolean) => void;
  onSuccess: (_id: string) => void;
  className: string;
  isEdit?: boolean;
  isDisabled?: boolean;
}

function CostCenterModal(props : PropsType) {
  const { t } = useTranslation();
  const {
    costCenterModalOpen,
    selectedCostCenter,
    handleClose,
    getAllCostCenters,
    onSuccess,
  } = props;

  const isEdit = props?.isEdit || false;
  const isDisabled = props?.isDisabled || false;

  const { API } = useContext(ConfigContext);

  const auth = useAuth();
  const {user} = auth;

  const [costCenter, setCostCenter] = useState<CostCenterType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getCostCenterByCode = async (code : string) => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.costCenterByCode}/${encodeURIComponent(code)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setCostCenter(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionEdit, user?.permissions)) {
      if (selectedCostCenter) {
        getCostCenterByCode(selectedCostCenter as string);
      } else {
        setCostCenter(null);
      }
    }
  }, [selectedCostCenter]);

  const hasError = (fields: FieldType[], errors:Record<string,any>): boolean => {
    for (let i = 0; i < fields.length; i++) {
      if (errors[fields[i].key]) {
        // Returning true until Twinfield integration
        return false;
      }
    }

    return false;
  };

  return (
    <>
    <Modal
      style={styles.modal}
      open={costCenterModalOpen}
      onClose={() =>handleClose()}
    >
      <Fade in={costCenterModalOpen}>
        <div style={styles.halfPaper}>
          <Grid style={styles.modalHeader}>
            <Typography sx={styles.headerTitle}>
              {!loading
                && (costCenter?.name
                  ? `${t('COST_CENTER')} - ${costCenter ? costCenter.name : ''} (${costCenter?.code})`
                  : t('COST_CENTER_ADD_NEW_COST_CENTER'))}
            </Typography>
            <IconButton onClick={()=>handleClose()}>
              <CloseIcon style={styles.closeIcon} />
            </IconButton>
          </Grid>
            {
            user?.interfacePreference === 'twinfield_interface'
              ? (
                <CostCenterTwinfield
                  costCenter={costCenter}
                  getAllCostCenters={getAllCostCenters}
                  handleClose={handleClose}
                  hasError={hasError}
                  onSuccess={onSuccess}
                  setLoading={setLoading}
                  isEdit={isEdit}  
                  isDisabled={isDisabled}              
                />
              ) : (
                <CostCenterStandard
                  costCenter={costCenter}
                  getAllCostCenters={getAllCostCenters}
                  handleClose={handleClose}
                  onSuccess={onSuccess}
                  setLoading={setLoading}
                  loading={loading}
                  isEdit={isEdit} 
                  isDisabled={isDisabled}
                />
              )
          }
        </div>
      </Fade>
    </Modal>
    <Backdrop open={loading} sx={{zIndex:111111,color:'#fff'}}>
      <CircularProgress  />
    </Backdrop>
    </>
  );
}

export default CostCenterModal;



const styles = {
  root: {
    backgroundColor: '',
    maxHeight: '100dvh',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    maxHeight:'100dvh',
  },
  halfPaper: {
    width: '90%',
    maxWidth:'500px',
    maxHeight: '94dvh',
    overflow:'hidden',
    // overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    outline: 'none',
     paddingBlockEnd:'10px'
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding:'20px 16px'
  },
  headerTitle: {
    color:'#4C4E64',
    fontSize:'20px',
    fontWeight:'500',
    textTransform:'capitalize',
    LineHeight:'32px'
  },
  closeIcon: {
    width: 20,
    height: 20,
    color: 'rgba(51, 77, 110, 0.5)'
  },
  form: {},
  snackAction: {
    color: 'white'
  },
  expiryRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  monthField: {
    marginRight: 16
  },
  yearField: {
    marginLeft: 16,
    color: theme.palette.secondary.main
  },
  errorText: {
    color: '#f44336'
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: '#f4f6f8',
  },
  accordionHeading: {
    color: theme.palette.text.primary,
    fontWeight: 500
  },
  formControl: {
    width: '100%'
  },
  selectStyle: {
    color: theme.palette.text.primary,
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#ffffff'
  },
  errorChip: {
    marginLeft: 16,
    fontSize: 12,
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  inputField: {
    backgroundColor: '#ffffff'
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  manageFieldsBtn: {
    height: 36,
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: 8,
    fontSize: '12px'
  },
  btnIcon: {
    height: 15,
    width: 15,
    marginRight: 4,
    color: '#bebebe',
  },
};


