import { useTranslation } from 'react-i18next';

import { Box, Button, Fade, Grid, IconButton, Modal, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import styles from './style';

type PropsType = {
  openConfirmation: boolean;
  onClose: () => void
  handleStatusChange: (_status: string) => Promise<void>;
}

const BackToProcysConfirmation = ({
  openConfirmation,
  onClose,
  handleStatusChange
}: PropsType) => {
  const { t, ready: tReady } = useTranslation();

  return (
    <Modal
      sx={styles.modal}
      open={openConfirmation}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={openConfirmation}>
        <Box sx={styles.paperModal} className='y-scroll'>
          <Grid sx={styles.modalHeader}>
            <Typography sx={styles.headerTitle}>
              {tReady && t('BRAND_BACK_TO_PROCYS_CONF_TITLE')}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon sx={styles.closeIcon} />
            </IconButton>
          </Grid>
          <Grid sx={styles.deleteMessageText}>
            <Typography variant="caption">
              {tReady && t('BRAND_BACK_TO_PROCYS_CONF_MESSAGE')}
            </Typography>
          </Grid>
          <Grid sx={styles.userForm}>
            <Box mt={2} display="flex" gap={'10px'} justifyContent="flex-end">
              <Button
                variant="text"
                sx={styles.closeBtn}
                onClick={onClose}
              >
                {tReady && t('BRAND_BACK_TO_PROCYS_CONF_CANCEL')}
              </Button>
              <Button
                variant="contained"
                sx={styles.submitBtn}
                onClick={() => handleStatusChange('cancelled')}
              >
                {tReady && t('BRAND_BACK_TO_PROCYS_CONFIRM')}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default BackToProcysConfirmation;
