import { useContext, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  Tooltip
} from '@mui/material';

import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import {
  appendContactSupport, axiosHeaders
} from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from '../style';

const periodsList = [
  {
    id: 0,
    key: 'none',
    label: 'INTEGRATION_HACIENDA_PERIOD_NONE'
  },
  {
    id: 1,
    key: 'monthlyOn5th',
    label: 'INTEGRATION_HACIENDA_PERIOD_MONTHLY_ON_5TH'
  },
  {
    id: 2,
    key: 'quarterlyOn5th',
    label: 'INTEGRATION_HACIENDA_PERIOD_QUARTERLY_ON_5TH'
  },
];

const Hacienda = (props) => {
  const { API } = useContext(ConfigContext);
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  const [period, setPeriod] = useState('none');

  const {
    isIntegrationActive
  } = props;

  useEffect(() => {
    if (isIntegrationActive) {
      getSavedDetails();
    }
  }, []);

  const getSavedDetails = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        API.savedHaciendaPeriod, axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setPeriod(response.data.data.period);
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(
          appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'), t),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const saveNewDetails = async (value) => {
    try {
      const data = {
        period: value
      };

      const response = await Axios.post(
        API.savedHaciendaPeriod,
        data,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSaved(true);
        setPeriod(value);
      } else {
        enqueueSnackbar(
          appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'), t),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, t('INTEGRATIONS_ERROR_RETRIEVE_DETAILS'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid style={styles.detailsContainer}>
      <Grid style={styles.detailRow}>
        <Grid style={styles.labelWrapper}>
          <span style={styles.labelText}>{ready && t('INTEGRATIONS_HACIENDA_PERIOD_PREFERENCE')}</span>
          {saved && <Grid style={styles.savedChip}>{ready && t('INTEGRATIONS_SAVED')}</Grid>}
        </Grid>
        <Tooltip title={period && period !== 'none' ? t('INTEGRATION_HACIENDA_OPTION_SELECT_DISABLED') : ''}>
          <select
            type="text"
            value={period}
            style={styles.inputField}
            onChange={(e) => saveNewDetails(e.target.value)}
            disabled={period && period !== 'none'}
          >
            {
              periodsList.map((period) => (
                <option key={period.id} value={period.key}>{t(period.label)}</option>
              ))
            }
          </select>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default Hacienda;
