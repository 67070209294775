import { useState, useEffect, useContext, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { enqueueSnackbar } from 'notistack';
import Axios from 'axios';

import {
  Box, Button, Dialog, Grid, IconButton, MenuItem, Select, SelectChangeEvent, TextField, Typography,
  FormControlLabel, Switch
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  DragIndicator as ReorderIcon,
  Delete as RemoveIcon,
  Add as AddIcon,
  ArrowForward as SaveIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { CustomError, DocumentDeliveryFormatType, ExportMappingType, FieldType } from 'src/types';
import {
  supplierFieldMapping, vatLineFieldMapping, esLineFieldMapping, vatLineLabelsForExport, esLineLabelsForExport,
  vatLineFields, esLineFields
} from 'src/document-edit/utils';
import { axiosHeaders, appendContactSupport } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';

const dataFormats = [
  {
    key: 'excel',
    label: 'Excel',
  },
  {
    key: 'csv',
    label: 'CSV',
  },
  {
    key: 'dat',
    label: 'DAT',
  },
];

const entities = [
  {
    key: 'header',
    label: 'EXPORT_FIELD_MAPPINGS_HEADER_FIELDS',
  },
  {
    key: 'supplier',
    label: 'EXPORT_FIELD_MAPPINGS_SUPPLIER_FIELDS',
  },
  {
    key: 'line',
    label: 'EXPORT_FIELD_MAPPINGS_LINE_FIELDS',
  },
  {
    key: 'vat',
    label: 'EXPORT_FIELD_MAPPINGS_VAT_LINE_FIELDS',
  },
  {
    key: 'es',
    label: 'EXPORT_FIELD_MAPPINGS_ES_LINE_FIELDS',
  },
];

const linkToDocument: FieldType = {
  key: 'linkToDocument',
  appDbField: 'link_to_document',
  assistantKey: 'link_to_document',
  dataType: 'text',
  label: 'LINK_TO_DOCUMENT',
  exportMapKey: 'LINK_TO_DOCUMENT',
  isAvailable: true,
  position: -1,
};

const linkToDocumentPdf: FieldType = {
  key: 'linkToDocumentPdf',
  appDbField: 'link_to_document_pdf',
  assistantKey: 'link_to_document_pdf',
  dataType: 'text',
  exportMapKey: 'LINK_TO_DOCUMENT_PDF',
  label: 'LINK_TO_DOCUMENT_PDF',
  isAvailable: true,
  position: -1,
};

const documentOwner: FieldType = {
  key: 'documentOwner',
  appDbField: 'document_owner',
  assistantKey: 'document_owner',
  dataType: 'text',
  exportMapKey: 'DOCUMENT_OWNER',
  label: 'DOCUMENT_OWNER',
  isAvailable: true,
  position: -1,
};

const companyCode: FieldType = {
  key: 'companyCode',
  appDbField: 'company_code',
  assistantKey: 'company_code',
  dataType: 'text',
  exportMapKey: 'INTEGRATION_COMPANY_CODE',
  label: 'INTEGRATION_COMPANY_CODE',
  isAvailable: true,
  position: -1,
};

const metaDataFields = [
  'LINK_TO_DOCUMENT',
  'LINK_TO_DOCUMENT_PDF',
  'DOCUMENT_OWNER'
];

interface PropTypes {
  open: boolean;
  format: DocumentDeliveryFormatType;
  fields: FieldType[];
  lineFields: FieldType[];
  supplierFields: FieldType[];
  excelFieldMapping: ExportMappingType[];
  datFieldMapping: ExportMappingType[];
  csvFieldMapping: ExportMappingType[];
  handleClose: (_updated?: boolean, _format?: DocumentDeliveryFormatType) => void;
}

const ManageExportFieldsNew = ({
  open,
  format,
  fields,
  lineFields,
  supplierFields,
  excelFieldMapping,
  datFieldMapping,
  csvFieldMapping,
  handleClose,
}: PropTypes) => {
  const { t, ready } = useTranslation();
  const {API} = useContext(ConfigContext);
  const { user } = useAuth();

  const isPassportOrID = user?.documentType === 'id';

  const [isEdited, setIsEdited] = useState(Boolean(format?.key));
  const [loading, setLoading] = useState(false);
  const [dataFormat, setDataFormat] = useState(format?.key || 'excel');
  const [selectedEntity, setSelectedEntity] = useState(entities[0].key);

  const [headerFieldMapping, setHeaderFieldMapping] = useState<Record<string, string>>([
    ...fields,
    linkToDocument,
    linkToDocumentPdf,
    documentOwner,
    companyCode
  ].reduce((acc, item) => {
    acc[item.key] = item.exportMapKey;
    return acc;
  }, {} as Record<string, string>));
  const [lineFieldMapping, setLineFieldMapping] = useState(lineFields.reduce((acc, item) => {
    acc[item.key] = item.exportMapKey;
    return acc;
  }, {} as Record<string, string>));

  const [isApplyToAll, setIsApplyToAll] = useState(false);

  const [mappings, setMappings] = useState<ExportMappingType[]>([]);
  const [headerMappings, setHeaderMappings] = useState(
    mappings.filter(m => m.entity === 'header')
  );
  const [lineMappings, setLineMappings] = useState(
    mappings.filter(m => m.entity === 'line')
  );
  const [vatMappings, setVatMappings] = useState(
    mappings.filter(m => m.entity === 'vat')
  );
  const [esMappings, setEsMappings] = useState(
    mappings.filter(m => m.entity === 'es')
  );
  const [supplierMappings, setSupplierMappings] = useState(
    mappings.filter(m => m.entity === 'supplier')
  );

  useEffect(() => setDataFormat(format?.key ? format.key : 'excel'), [format]);

  useEffect(() => {
    if (dataFormat) {
      setIsApplyToAll(
        Boolean(user?.customisations?.some((c) => c === `applyParentMappings${dataFormat[0].toUpperCase() + dataFormat.slice(1)}`))
      );
    }
  }, [dataFormat, user]);

  useEffect(() => {
    setHeaderMappings(mappings.filter((m) => m.entity === 'header'));
    setLineMappings(mappings.filter((m) => m.entity === 'line'));
    setVatMappings(mappings.filter((m) => m.entity === 'vat'));
    setEsMappings(mappings.filter((m) => m.entity === 'es'));
    setSupplierMappings(mappings.filter((m) => m.entity === 'supplier'));
  }, [mappings]);

  useEffect(() => {
    let isFieldsEmpty = false;
    if (format?.key === 'excel' || dataFormat === 'excel') {
      setMappings(excelFieldMapping);
      if (excelFieldMapping.length === 0) {
        isFieldsEmpty = true;
      }
    } else if (format?.key === 'dat' || dataFormat === 'dat') {
      setMappings(datFieldMapping);
      if (datFieldMapping.length === 0) {
        isFieldsEmpty = true;
      }
    } else if (format?.key === 'csv' || dataFormat === 'csv') {
      setMappings(csvFieldMapping);
      if (csvFieldMapping.length === 0) {
        isFieldsEmpty = true;
      }
    }
    if (isFieldsEmpty) {
      let newFields = fields.filter((f) => f.isAvailable && f.isActive).map((f) => ({
        procysField: f.exportMapKey,
        field: t(f.label),
        dataType: 'string',
        format: '',
        entity: 'header'
      }));
      if (!isPassportOrID) {
        newFields = [...newFields,...supplierFields.filter(f => f.isAvailable && f.isActive).map(f => ({
          procysField: supplierFieldMapping[f.key as string],
          field: t(f.label),
          dataType: 'string',
          format: '',
          entity: 'supplier'
        }))];
        newFields = [...newFields, ...lineFields.filter((f) => f.isAvailable && f.isActive).map((f) => ({
          procysField: f.exportMapKey,
          field: t(f.label),
          dataType: 'string',
          format: '',
          entity: 'line'
        }))];
        newFields = [...newFields, ...vatLineFields.map((f) => ({
          procysField: f.exportMapKey,
          field: t(vatLineLabelsForExport[f.key as string]),
          dataType: 'string',
          format: '',
          entity: 'vat'
        }))];
        newFields = [...newFields, ...esLineFields.map((f) => ({
          procysField: f.exportMapKey,
          field: t(esLineLabelsForExport[f.key as string]),
          dataType: 'string',
          format: '',
          entity: 'es'
        }))];
      }
      setMappings(newFields);
    }
    setHeaderFieldMapping([
      ...fields,
      linkToDocument,
      linkToDocumentPdf,
      documentOwner,
      companyCode
    ].reduce((acc, item) => {
      acc[item.key] = item.exportMapKey;
      return acc;
    }, {} as Record<string, string>));
    setLineFieldMapping(lineFields.reduce((acc, item) => {
      acc[item.key] = item.exportMapKey;
      return acc;
    }, {} as Record<string, string>));
  }, [excelFieldMapping, datFieldMapping, csvFieldMapping, format, dataFormat, fields, lineFields, supplierFields, isPassportOrID, t]);

  const procysFields: Record<string, FieldType[]> = {
    header: [...fields, linkToDocument, linkToDocumentPdf, documentOwner, companyCode],
    supplier: supplierFields,
    line: lineFields,
    vat: vatLineFields,
    es: esLineFields
  };

  const fieldMapping: Record<string, Record<string, string>> = {
    header: headerFieldMapping,
    supplier: supplierFieldMapping,
    line: lineFieldMapping,
    vat: vatLineFieldMapping,
    es: esLineFieldMapping
  };

  const entityMappings: Record<string, ExportMappingType[]> = {
    header: headerMappings,
    supplier: supplierMappings,
    line: lineMappings,
    vat: vatMappings,
    es: esMappings
  };

  const addBtnLabel: Record<string, string> = {
    header: 'EXPORT_FIELD_MAPPINGS_ADD_HEADER_FIELD',
    supplier: 'EXPORT_FIELD_MAPPINGS_ADD_SUPPLIER_FIELD',
    line: 'EXPORT_FIELD_MAPPINGS_ADD_LINE_FIELD',
    vat: 'EXPORT_FIELD_MAPPINGS_ADD_VAT_FIELD',
    es: 'EXPORT_FIELD_MAPPINGS_ADD_ES_FIELD'
  };

  const handleFormatChange = (event: SelectChangeEvent<string>) => {
    if (isEdited) {
      enqueueSnackbar(t('EXPORT_FIELD_MAPPING_SAVE_BEFORE_FORMAT_CHANGE'), {
        variant: 'error',
        autoHideDuration: 3000
      });
      return;
    }
    setDataFormat(event.target.value as string);
  };

  const handleChange = (i: number, prop: string, val: string, entity: string) => {
    setIsEdited(true);
    switch (entity) {
      case 'header':
        {
          const newMappings = [...headerMappings];
          newMappings[i][prop as keyof ExportMappingType] = val;
          setHeaderMappings(newMappings);
        }
        break;
      case 'supplier':
        {
          const newMappings = [...supplierMappings];
          newMappings[i][prop as keyof ExportMappingType] = val;
          setSupplierMappings(newMappings);
        }
        break;
      case 'line':
        {
          const newMappings = [...lineMappings];
          newMappings[i][prop as keyof ExportMappingType] = val;
          setLineMappings(newMappings);
        }
        break;
      case 'vat':
        {
          const newMappings = [...vatMappings];
          newMappings[i][prop as keyof ExportMappingType] = val;
          setVatMappings(newMappings);
        }
        break;
      case 'es':
        {
          const newMappings = [...esMappings];
          newMappings[i][prop as keyof ExportMappingType] = val;
          setEsMappings(newMappings);
        }
        break;

      default:
    }
  };

  const handleReorder = (source: number, destination: number | undefined, entity: string) => {
    if (destination === undefined) {
      return;
    }
    switch (entity) {
      case 'header':
        {
          const newMappings = [...headerMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setHeaderMappings(newMappings);
        }
        break;
      case 'supplier':
        {
          const newMappings = [...supplierMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setSupplierMappings(newMappings);
        }
        break;
      case 'line':
        {
          const newMappings = [...lineMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setLineMappings(newMappings);
        }
        break;
      case 'vat':
        {
          const newMappings = [...vatMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setVatMappings(newMappings);
        }
        break;
      case 'es':
        {
          const newMappings = [...esMappings];
          const [removed] = newMappings.splice(source, 1);
          newMappings.splice(destination, 0, removed);
          setEsMappings(newMappings);
        }
        break;

      default:
    }
  };

  const removeMapping = (index: number, entity: string) => {
    switch (entity) {
      case 'header':
        {
          const newMappings = [...headerMappings];
          newMappings.splice(index, 1);
          setHeaderMappings(newMappings);
        }
        break;
      case 'supplier':
        {
          const newMappings = [...supplierMappings];
          newMappings.splice(index, 1);
          setSupplierMappings(newMappings);
        }
        break
      case 'line':
        {
          const newMappings = [...lineMappings];
          newMappings.splice(index, 1);
          setLineMappings(newMappings);
        }
        break;
      case 'vat':
        {
          const newMappings = [...vatMappings];
          newMappings.splice(index, 1);
          setVatMappings(newMappings);
        }
        break;
      case 'es':
        {
          const newMappings = [...esMappings];
          newMappings.splice(index, 1);
          setEsMappings(newMappings);
        }
        break;

      default:
    }
  };

  const handleAddMapping = (entity: string) => {
    switch (entity) {
      case 'header':
        setHeaderMappings([
          ...headerMappings,
          {
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      case 'supplier':
        setSupplierMappings([
          ...supplierMappings,
          {
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      case 'line':
        setLineMappings([
          ...lineMappings,
          {
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      case 'vat':
        setVatMappings([
          ...vatMappings,
          {
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      case 'es':
        setEsMappings([
          ...esMappings,
          {
            procysField: '',
            field: '',
            dataType: 'string',
            format: '',
            entity
          }
        ]);
        break;
      default:
    }
  };

  const onChangeApplyToAll = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    e.persist();
    const { checked } = e.target;
    try {
      const response = await Axios.put(
        `${API.customisations}`,
        {
          customisation: `applyParentMappings${dataFormat[0].toUpperCase() + dataFormat.slice(1)}`,
          enabled: checked
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t(checked ? 'EXPORT_MAPPINGS_APPLY_TO_ALL_SUCCESS' : 'EXPORT_MAPPINGS_UNAPPLY_TO_ALL_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 3000
          }
        );
        setIsApplyToAll(checked);
        setLoading(false);
      }
    } catch (e) {
      const error = e as CustomError;
      let errorMessage = appendContactSupport(
        window.config.support_email,
        t('EXPORT_MAPPINGS_APPLY_TO_ALL_FAILURE'),
        t
      );
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n || error.response.data.message || '');
      }

      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errorMessage, t),
        {
          variant: 'error',
          autoHideDuration: 3000
        }
      );
      setLoading(false);
    }
  };

  const handleSaveMappings = async () => {
    const hasEmpty = [
      ...headerMappings,
      ...supplierMappings,
      ...lineMappings,
      ...vatMappings,
      ...esMappings
    ].some(m => !m.procysField || !m.field);
    if (hasEmpty) {
      enqueueSnackbar(t('EXPORT_FIELD_MAPPINGS_SAVE_EMPTY'), {
        variant: 'error',
        autoHideDuration: 3000
      });
      return;
    }
    let reqBody = {
      mappings: [
        ...headerMappings,
        ...supplierMappings,
        ...lineMappings,
        ...vatMappings,
        ...esMappings
      ]
    };
    try {
      const response = await Axios.post(
        `${API.exportFieldMapping}${dataFormat}`,
        reqBody,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setIsEdited(false);
        enqueueSnackbar(t('EXPORT_FIELD_MAPPINGS_SAVE_SUCCESS'), {
          variant: 'success',
          autoHideDuration: 3000
        });
        handleClose(true, format);
      } else {
        enqueueSnackbar(t('EXPORT_FIELD_MAPPINGS_SAVE_FAILED'), {
          variant: 'error',
          autoHideDuration: 3000
        });
      }
    } catch (error) {
      enqueueSnackbar(t('EXPORT_FIELD_MAPPINGS_SAVE_FAILED'), {
        variant: 'error',
        autoHideDuration: 3000
      });
    }
  };

  const renderFieldMapping = (procysField: string, field: string, i: number) => (
    <Draggable
      key={procysField ? `${procysField}_${i}` : i.toString()}
      draggableId={
        procysField ? `${procysField}_${i}` : i.toString()
      }
      index={i}
    >
      {draggableProvided => (
        <Grid
          sx={styles.itemRow}
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
        >
          <Grid {...draggableProvided.dragHandleProps} sx={styles.dragIconContainer}>
            <ReorderIcon sx={styles.dragIcon} />
          </Grid>
          <Grid sx={styles.inputItem} >
            <TextField
              value={field}
              onChange={(e) => handleChange(i, 'field', e.target.value, selectedEntity)}
              variant="outlined"
              fullWidth
              sx={styles.inputText}
            />
          </Grid>
          <Grid sx={styles.inputItem}>
            <Select
              id={`${selectedEntity}-procys-field`}
              variant="outlined"
              onChange={(e) => handleChange(i, 'procysField', e.target.value, selectedEntity)}
              sx={styles.selectInput}
              value={procysField}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
              }}
            >
              {procysFields[selectedEntity].filter((f) => f.isAvailable).map(f => (
                <MenuItem sx={styles.menuItem} key={f.key} value={fieldMapping[selectedEntity][f.key]}>
                  {ready && t(f.label)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <IconButton onClick={() => removeMapping(i, selectedEntity)}>
            <RemoveIcon sx={styles.deleteIcon} />
          </IconButton>
        </Grid>
      )}
    </Draggable>
  );

  return (
    <Dialog
      open={open}
      sx={styles.modal}
      fullScreen
      fullWidth
      slots={{ backdrop: 'div' }}
      slotProps={{ backdrop: { onClick: () => {} } }}
    >
      <Grid sx={styles.modalHeader}>
        <IconButton sx={styles.backBtn} onClick={() => handleClose()}>
          <ArrowBackIcon sx={styles.backIcon} />
        </IconButton>
        <Typography sx={styles.headerTitle}>
          {t('EXPORT_FIELD_MAPPINGS_MODAL_TITLE')}
        </Typography>
      </Grid>
      <Grid sx={styles.modalContent} container>
        <Grid item sx={styles.leftPanel} xs={2.5}>
          <Typography sx={styles.dataFormatText}>
            {t('EXPORT_FIELD_MAPPING_DATA_FORMAT')}
          </Typography>
          <Select
            id="format-change"
            variant="outlined"
            onChange={handleFormatChange}
            sx={styles.selectStyle}
            value={dataFormat}
          >
            {dataFormats.map(f => (
              <MenuItem sx={styles.selectItem} key={f.key} value={f.key}>
                {f.label}
              </MenuItem>
            ))}
          </Select>
          <Box sx={styles.entitiesContainer}>
            {entities.map(entity => (
              <Box
                key={entity.key}
                sx={selectedEntity === entity.key ? styles.entityContainerActive : styles.entityContainer}
                onClick={() => setSelectedEntity(entity.key)}
              >
                <Typography sx={selectedEntity === entity.key ? styles.entityTextActive : styles.entityText}>
                  {t(entity.label)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item sx={styles.contentContainer} xs={9.5}>
          <Typography sx={styles.contentTitle}>
            {t(entities.find(e => e.key === selectedEntity)?.label || '')}
          </Typography>
          <Grid sx={styles.columnHeaderContainer}>
            <Box sx={styles.columnHeaderFieldsContainer}>
              <Typography sx={styles.columnHeaderText}>{t('MANAGE_EXPORT_FIELDS_COLUMN_HEADER_FIELDS', {
                format: dataFormats.find((d) => d.key === dataFormat)?.label
              })}</Typography>
            </Box>
            <Box sx={styles.columnHeaderFieldsContainer}>
              <Typography sx={styles.columnHeaderText}>{t('MANAGE_EXPORT_FIELDS_COLUMN_HEADER_PROCYS_FIELDS')}</Typography>
            </Box>
          </Grid>
          <DragDropContext onDragEnd={(e) => handleReorder(e?.source?.index, e?.destination?.index, selectedEntity)}>
            <Droppable droppableId="droppable" direction="vertical">
              {droppableProvided => (
                <Grid
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                  sx={styles.mappingsContainer}
                >
                  {entityMappings[selectedEntity].map((item, i) => {
                    const { procysField, field } = item;
                    if (metaDataFields.includes(procysField)) return null;
                    return renderFieldMapping(procysField, field, i);
                  })}
                  {selectedEntity === 'header' && (
                    <Grid sx={styles.metaFieldsContainer}>
                      {entityMappings[selectedEntity].map((item, i) => {
                        const { procysField, field } = item;
                        if (metaDataFields.includes(procysField)) {
                          return renderFieldMapping(procysField, field, i);
                        }
                      })}
                    </Grid>)}
                  {droppableProvided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
          <Box sx={styles.addMappingBtnContainer}>
            <Button
              onClick={() => handleAddMapping(selectedEntity)}
              sx={styles.addMappingBtn}
              startIcon={<AddIcon sx={styles.addMappingIcon} />}
            >
              {t(addBtnLabel[selectedEntity])}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid sx={styles.modalFooter}>
        <FormControlLabel
          sx={styles.formControlLabel}
          control={<Switch checked={isApplyToAll} onChange={(e) => onChangeApplyToAll(e)} />}
          label={t('MANAGE_FIELDS_APPLY_TO_ALL')}
          disabled={loading}
        />
        <Button
          variant="text"
          sx={styles.cancelBtn}
          onClick={() => handleClose()}
        >
          {ready && t('EXPORT_FIELD_MAPPINGS_CANCEL')}
        </Button>
        <Button
          variant="contained"
          sx={styles.submitBtn}
          onClick={handleSaveMappings}
          endIcon={<SaveIcon sx={styles.saveIcon} />}
        >
          {ready && t('EXPORT_FIELD_MAPPINGS_SAVE')}
        </Button>
      </Grid>
    </Dialog>
  );
};

export default ManageExportFieldsNew;
