import { useTranslation, Trans } from 'react-i18next';

import { Grid, Slider, Typography } from '@mui/material';
import { subscriptionTypes, subscriptionTypesAr } from 'src/config';
import { subscriptionLabel, getMaskedCurrencyText } from 'src/utils/helpers';

const ManagePlanSection = (props) => {
  const { ready, t } = useTranslation();

  const { subscription, selectedSubscription, setSelectedSubscription, customSubscription } = props;

  const onChangeSlider = (value) => {
    switch (value) {
      case subscriptionTypesAr[0].value:
        setSelectedSubscription(subscriptionTypes.free);
        break;
      case subscriptionTypesAr[1].value:
        setSelectedSubscription(subscriptionTypes.starter);
        break;
      case subscriptionTypesAr[2].value:
        setSelectedSubscription(subscriptionTypes.business);
        break;
      case subscriptionTypesAr[3].value:
        setSelectedSubscription(customSubscription || subscriptionTypes.enterprise);
        break;

      default:
        break;
    }
  };

  const getBulletStyle = (index) => {
    if (selectedSubscription.level < index) {
      return {
        textDecoration: 'line-through',
        color: '#9E9E9E',
        maxWidth: 300
      };
    }

    return {
      maxWidth: 300
    };
  };

  return (
    <>
      <Typography style={styles.descTitle}>{ready && t('MY_PLAN_MANAGE_TIME')}</Typography>
      <Typography style={styles.description}>
        {subscription.invLimit > 0
          ? (
            <Trans i18nKey="MY_PLAN_CURRENT_PLAN_LIMITS">
              Now you have
              <span style={styles.planName}>{{ subscription: subscription.name }}</span>
              with the limit of
              {{ limit: subscription.invLimit }}
              documents and
              {{ userLimit: subscription.usersLimit }}
              users.
              Increase your limit by choosing new plan.
            </Trans>
          )
          : (
            <Trans i18nKey="MY_PLAN_CURRENT_PLAN_LIMITS_CUSTOM">
              Now you have
              <span style={styles.planName}>{{ subscription: subscription.name }}</span>
              with the limit of
              {{ userLimit: subscription.usersLimit }}
              users.
              Increase your limit by contacting us.
            </Trans>
          )}
      </Typography>
      <Grid style={styles.sliderContainer}>
        <Grid style={styles.sliderLabelsContainer}>
          <Grid style={styles.sliderLimitContainer}>
            <Typography
              align="center"
              style={selectedSubscription?.name?.toLowerCase().indexOf('free') >= 0 ? styles.selectedLimitText : styles.limitText}
            >
              {ready && t('MY_PLAN_NO_OF_DOCS', { docs: subscriptionTypes.free.invoices })}
            </Typography>
            <Typography
              align="center"
              style={selectedSubscription?.name?.toLowerCase().indexOf('free') >= 0 ? styles.selectedLimitText : styles.limitText}
            >
              {ready && t('MY_PLAN_NO_OF_USERS', { users: subscriptionTypes.free.users })}
            </Typography>
          </Grid>
          <Grid style={styles.sliderLimitContainerS}>
            <Typography
              align="center"
              style={selectedSubscription?.name?.toLowerCase().indexOf('starter') >= 0 ? styles.selectedLimitText : styles.limitText}
            >
              {ready && t('MY_PLAN_NO_OF_DOCS', { docs: subscriptionTypes.starter.invoices })}
            </Typography>
            <Typography
              align="center"
              style={selectedSubscription?.name?.toLowerCase().indexOf('starter') >= 0 ? styles.selectedLimitText : styles.limitText}
            >
              {ready && t('MY_PLAN_NO_OF_USERS', { users: subscriptionTypes.starter.users })}
            </Typography>
          </Grid>
          <Grid style={styles.sliderLimitContainerB}>
            <Typography
              align="center"
              style={selectedSubscription?.name?.toLowerCase().indexOf('business') >= 0 ? styles.selectedLimitText : styles.limitText}
            >
              {ready && t('MY_PLAN_NO_OF_DOCS', { docs: subscriptionTypes.business.invoices })}
            </Typography>
            <Typography
              align="center"
              style={selectedSubscription?.name?.toLowerCase().indexOf('business') >= 0 ? styles.selectedLimitText : styles.limitText}
            >
              {ready && t('MY_PLAN_NO_OF_USERS', { users: subscriptionTypes.business.users })}
            </Typography>
          </Grid>
          <Grid style={styles.sliderLimitContainerC}>
            <Typography
              align="center"
              style={
                (selectedSubscription?.name?.toLowerCase().indexOf('custom') >= 0 || selectedSubscription?.name?.toLowerCase().indexOf('enterprise') >= 0)
                  ? styles.selectedLimitText : styles.limitText
              }
            >
              {ready && t('MY_PLAN_NO_OF_DOCS', { docs: subscriptionTypes.enterprise.invoices })}
            </Typography>
            <Typography
              align="center"
              style={
                (selectedSubscription?.name?.toLowerCase().indexOf('custom') >= 0 || selectedSubscription?.name?.toLowerCase().indexOf('enterprise') >= 0)
                  ? styles.selectedLimitText : styles.limitText
              }
            >
              {ready && t('MY_PLAN_NO_OF_USERS', { users: subscriptionTypes.enterprise.users })}
            </Typography>
          </Grid>
        </Grid>
        <Grid style={styles.sliderComponent}>
          <Slider
            aria-label="Restricted values"
            value={selectedSubscription.value}
            valueLabelDisplay="off"
            marks={subscriptionTypesAr}
            step={null}
            onChange={(e, v) => onChangeSlider(v)}
          />
        </Grid>
        <Grid style={styles.sliderLabelsContainer}>
          <Grid style={styles.sliderLimitContainer}>
            <Typography
              align="center"
              style={selectedSubscription?.name?.toLowerCase().indexOf('free') >= 0 ? styles.selectedLabelText : styles.labelText}
            >
              {ready && t(subscriptionLabel[subscriptionTypes.free.label])}
            </Typography>
          </Grid>
          <Grid style={styles.sliderLimitContainerS}>
            <Typography
              align="center"
              style={selectedSubscription?.name?.toLowerCase().indexOf('starter') >= 0 ? styles.selectedLabelText : styles.labelText}
            >
              {ready && t(subscriptionLabel[subscriptionTypes.starter.label])}
            </Typography>
          </Grid>
          <Grid style={styles.sliderLimitContainerB}>
            <Typography
              align="center"
              style={selectedSubscription?.name?.toLowerCase().indexOf('business') >= 0 ? styles.selectedLabelText : styles.labelText}
            >
              {ready && t(subscriptionLabel[subscriptionTypes.business.label])}
            </Typography>
          </Grid>
          <Grid style={styles.sliderLimitContainerC}>
            <Typography
              align="center"
              style={
                (selectedSubscription?.name?.toLowerCase().indexOf('custom') >= 0 || selectedSubscription?.name?.toLowerCase().indexOf('enterprise') >= 0)
                  ? styles.selectedLabelText : styles.labelText
              }
            >
              {ready && t(subscriptionLabel[subscriptionTypes.enterprise.label])}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Typography style={styles.description}>
        <Trans i18nKey="MY_PLAN_INCLUDES">
          <span style={styles.planName}>{{ plan: selectedSubscription.name }}</span>
          also includes:
        </Trans>
      </Typography>
      <Grid style={styles.description}>
        <ul style={styles.planFunctions}>
          <li style={getBulletStyle(1)}>
            {ready && t('MY_PLAN_FUNC_01')}
          </li>
          <li style={getBulletStyle(1)}>
            {ready && t('MY_PLAN_FUNC_02')}
          </li>
          <li style={getBulletStyle(2)}>
            {ready && t('MY_PLAN_FUNC_03')}
          </li>
          <li style={getBulletStyle(3)}>
            {ready && t('MY_PLAN_FUNC_04')}
          </li>
        </ul>
      </Grid>
      {
        (selectedSubscription.level === 1 || selectedSubscription.level === 2
          || (selectedSubscription.level === 3 && customSubscription && customSubscription.price > 0)) && (
          <>
            <Typography style={styles.usageValue}>
              &euro;
              {` ${getMaskedCurrencyText(selectedSubscription.price.toString())}`}
              <span style={styles.usageDescription}>
                {ready && t('MY_PLAN_PER_MONTH')}
              </span>
            </Typography>
            <Typography style={styles.vatMessage}>
              {ready && t('MY_PLAN_VAT_NOT_INCLUDED')}
            </Typography>
          </>
        )
      }
    </>
  );
}

export default ManagePlanSection;

const styles = {
  descTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: '#4C4E64',
    paddingTop: 16,
    paddingBottom: 8
  },
  description: {
    fontSize: 14,
    color: '#4C4E64',
    paddingBottom: 8,
  },
  planName: {
    fontSize: 14,
    color: '#3E8AFF',
  },
  sliderContainer: {
    width: 500,
    flexDirection: 'column',
    paddingTop: 16,
    paddingBottom: 16
  },
  sliderComponent: {
    paddingLeft: 20,
    paddingRight: 20
  },
  sliderLabelsContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 12
  },
  sliderLimitContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 90
  },
  sliderLimitContainerS: {
    display: 'flex',
    flexDirection: 'column',
    width: 150
  },
  sliderLimitContainerB: {
    display: 'flex',
    flexDirection: 'column',
    width: 113
  },
  sliderLimitContainerC: {
    display: 'flex',
    flexDirection: 'column',
    width: 145
  },
  sliderRail: {
    height: 16,
    borderRadius: 16,
    marginTop: 4
  },
  sliderTrack: {
    height: 16,
    borderRadius: 16,
    marginTop: 4
  },
  sliderThumb: {
    height: 36,
    width: 36,
    border: '3px solid white'
  },
  sliderMark: {
    height: 6,
    width: 6,
    borderRadius: 6,
    top: 18,
    color: 'white',
    marginTop: 4
  },
  limitText: {
    fontSize: 13,
    color: '#4C4E64',
  },
  selectedLimitText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#4C4E64',
  },
  labelText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#4C4E64',
  },
  selectedLabelText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#4C4E64',
  },
  planFunctions: {
    paddingLeft: 60,
    columns: 2,
    'WebkitColumns': 2,
    'MozColumns': 2,
    maxWidth: 700,
    margin: 0,
  },
  usageValue: {
    fontSize: 36,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#4C4E64',
  },
  usageDescription: {
    fontSize: 12,
    color: '#4C4E64',
    paddingLeft: 8,
    textAlign: 'left',
    marginTop: 16
  },
  vatMessage: {
    fontSize: 12,
    color: '#4C4E64',
    fontStyle: 'italic'
  },
};
