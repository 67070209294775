import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';

import {
  Backdrop, Button, Grid, IconButton, Modal, Paper, TextField, Tooltip, Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { appendContactSupport, axiosHeaders, getLocalisedErrorString, isActionPermitted, permissions, sendFeedback } from 'src/utils/helpers';
import { useConfig } from 'src/hooks/useConfig';

const SubscriptionActions = (props) => {
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { API } = useConfig();

  const { selectedSubscription, subscription, user, setModalOpen } = props;

  const [openContactModal, setContactModalOpen] = useState(false);
  const [contactEmail, setContactEmail] = useState(user.email);
  const [contactPhone, setContactPhone] = useState(user.companyPhone || '');
  const [contactCompanyName, setContactCompanyName] = useState(user.company || '');
  const [contactFirstname, setContactFirstname] = useState(user.firstname || '');
  const [contactDetails, setContactDetails] = useState('');
  const [contactEmailErr, setContactEmailErr] = useState('');
  const [contactPhoneErr, setContactPhoneErr] = useState('');

  const handleChangeContactFormFields = (value, prop, setter, errSetter) => {
    if (!value) {
      errSetter(t('INTEGRATIONS_PROP_CANNOT_BE_EMPTY', { prop }));
    } else {
      errSetter('');
    }

    setter(value);
  };

  const sendContactForm = async () => {
    if (!contactEmail) {
      setContactEmailErr(t('PROCYS_VALIDATE_EMAIL_EMPTY'));
      return;
    }

    if (!contactPhone) {
      setContactPhoneErr(t('SUBSCRIPTION_PHONE_REQUIRED'));
      return;
    }

    try {
      const body = {
        email: contactEmail,
        phone: contactPhone,
        company: contactCompanyName,
        firstname: contactFirstname,
        details: contactDetails
      };

      const response = await Axios.post(
        API.subscriptionUpgradeContact,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('SUBSCRIPTION_SUCCESS_TO_SEND'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        setContactModalOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(err?.response?.data?.i18n || 'SUBSCRIPTION_FAILED_TO_SEND', t), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const getTooltip = (selectedSub, curSub) => {
    if ((selectedSub?.level > curSub?.level || !curSub.level) && !isActionPermitted(permissions.subscriptionsUpgrade, user.permissions)) {
      return t('SUBSCRIPTION_UPGRADE_PERMISSION');
    } if (selectedSub?.level < curSub?.level && !isActionPermitted(permissions.subscriptionsDowngrade, user.permissions)) {
      return t('SUBSCRIPTION_DOWNGRADE_PERMISSION');
    }
    return '';
  };

  const canChangePlan = (selectedSub, curSub) => {
    if (selectedSub?.level > curSub?.level || !curSub.level) {
      return isActionPermitted(permissions.subscriptionsUpgrade, user.permissions);
    } if (selectedSub?.level < curSub?.level) {
      return isActionPermitted(permissions.subscriptionsDowngrade, user.permissions);
    }
    return false;
  };

  const handleChangePlanClick = () => {
    sendFeedback(API.feedbackLogs, 'change plan clicked', user.email, user.companyID);
    navigate('/subscription/checkout?type=0', { state: { selectedSubscription }});
  };

  const contactModal = (
    <Modal
      open={openContactModal}
      style={styles.modal}
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Paper style={styles.paper}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <IconButton
            style={styles.closeBtn}
            onClick={() => setContactModalOpen(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        </Grid>
        <Grid
          style={styles.modalBody}
        >
          <Typography
            variant="h2"
            style={styles.cancelSubsHeader}
          >
            {t('MY_PLAN_CONTACT_US')}
          </Typography>
          <TextField
            style={styles.fieldStyle}
            fullWidth
            label={ready && t('SUBSCRIPTION_PHONE_NO')}
            name="contactPhone"
            onChange={(e) => handleChangeContactFormFields(e.target.value, t('SUBSCRIPTION_PHONE_NO'), setContactPhone, setContactPhoneErr)}
            type="text"
            value={contactPhone}
            variant="outlined"
            required
            error={Boolean(contactPhoneErr)}
            helperText={contactPhoneErr}
          />
          <TextField
            style={styles.fieldStyle}
            fullWidth
            label={ready && t('SUBSCRIPTION_EMAIL')}
            name="contactEmail"
            onChange={(e) => handleChangeContactFormFields(e.target.value, t('SUBSCRIPTION_EMAIL'), setContactEmail, setContactEmailErr)}
            type="text"
            value={contactEmail}
            variant="outlined"
            required
            error={Boolean(contactEmailErr)}
            helperText={contactEmailErr}
          />
          <TextField
            style={styles.fieldStyle}
            fullWidth
            label={ready && t('SUBSCRIPTION_COMPANY_NAME')}
            name="contactCompanyName"
            onChange={(e) => setContactCompanyName(e.target.value)}
            type="text"
            value={contactCompanyName}
            variant="outlined"
          />
          <TextField
            style={styles.fieldStyle}
            fullWidth
            label={ready && t('SUBSCRIPTION_FIRST_NAME')}
            name="contactFirstname"
            onChange={(e) => setContactFirstname(e.target.value)}
            type="text"
            value={contactFirstname}
            variant="outlined"
          />
          <TextField
            style={{ ...styles.fieldStyle, height: '200px' }}
            variant="filled"
            sx={{ '&.MuiTextFieldRoot': styles.noPadding }}
            InputProps={{
              style: {
                paddingTop: 30,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
              },
            }}
            fullWidth
            multiline
            rows={4}
            label={ready && t('SUBSCRIPTION_DETAILS')}
            name="contactDetails"
            onChange={(e) => setContactDetails(e.target.value)}
            type="text"
            value={contactDetails}
            // variant="outlined"
          />
          <Grid style={styles.buttonContainer}>
            <Button
              style={styles.cancelSubs}
              variant="contained"
              onClick={sendContactForm}
            >
              {t('SUBSCRIPTION_SEND')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );

  return (
    <>
      <Grid style={styles.actionContainer}>
        {
          selectedSubscription?.name?.toLowerCase().indexOf('custom') >= 0
          || selectedSubscription?.name?.toLowerCase().indexOf('enterprise') >= 0
          || user.customisations.includes('hideManagePlanSection')
            ? (
              <Tooltip title={
                  !isActionPermitted(permissions.subscriptionsUpgrade, user.permissions)
                    ? t('SUBSCRIPTION_UPGRADE_PERMISSION')
                    : ''
                  }
              >
                <span>
                  <Button
                    variant="contained"
                    style={styles.changeBtn}
                    onClick={() => setContactModalOpen(true)}
                    disabled={!isActionPermitted(permissions.subscriptionsUpgrade, user.permissions)}
                  >
                    {ready && t('MY_PLAN_CONTACT_US')}
                  </Button>
                </span>
              </Tooltip>
            )
            : (
              <Tooltip title={getTooltip(selectedSubscription, subscription)}>
                <span>
                  <Button
                    variant="contained"
                    style={styles.changeBtn}
                    disabled={selectedSubscription?.name?.toLowerCase().indexOf(subscription?.name?.toLowerCase()) >= 0
                      || !canChangePlan(selectedSubscription, subscription)}
                    onClick={
                      selectedSubscription?.name?.toLowerCase().indexOf(subscription?.name?.toLowerCase()) >= 0
                        ? () => {}
                        : () => handleChangePlanClick()
                    }
                  >
                    {
                      selectedSubscription?.name?.toLowerCase().indexOf(subscription?.name?.toLowerCase()) >= 0
                        ? ready && t('MY_PLAN_CURRENT_PLAN')
                        : ready && t('MY_PLAN_CHANGE_PLAN')
                    }
                  </Button>
                </span>
              </Tooltip>
            )
        }
        {
          (subscription?.name?.toLowerCase().indexOf('free') < 0 && subscription?.name?.toLowerCase().indexOf('trial') < 0) && (
            <Tooltip title={isActionPermitted(permissions.subscriptionsCancel, user.permissions) ? ''
              : t('SUBSCRIPTION_CANCEL_PERMISSION')}
            >
              <span>
                <Button
                  style={styles.cancelBtn}
                  onClick={() => setModalOpen(true)}
                  disabled={!isActionPermitted(permissions.subscriptionsCancel, user.permissions)}
                >
                  {ready && t('MY_PLAN_CANCEL_PLAN')}
                </Button>
              </span>
            </Tooltip>
          )
        }
      </Grid>
      {contactModal}
    </>
  );
}

export default SubscriptionActions;

const styles = {
  actionContainer: {
    paddingTop: 16,
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 520
  },
  changeBtn: {
    textTransform: 'none',
    backgroundColor: '#3E8AFF',
    color: '#FFFFFF',
  },
  cancelBtn: {
    color: '#4C4E64',
    border: '1px #e4e4e4 solid',
    textTransform: 'none',
    marginLeft: 24
  },
  paper: {
    maxHeight: '94%',
    overflow: 'scroll',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    maxHeight: 760,
  },
  closeBtn: {
    padding: '2!important',
  },
  modalBody: {
    maxWidth: 600,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24,
    maxHeight: 760,
  },
  cancelSubsHeader: {
    marginBottom: 24,
    color: '#4C4E64',
    textAlign: 'center',
    fontSize: 30,
    fontWeight: '500'
  },
  fieldStyle: {
    paddingBottom: 24
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cancelSubs: {
    fontWeight: '600',
    textTransform: 'capitalize',
    backgroundColor: '#3E8AFF'
  },
  noPadding: {
    padding: '0px 16px',
    color: '#4C4E64',
    fontSize: '13px'
  },
};
