import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NProgress from 'nprogress';

import { Box, LinearProgress, Grid, Typography } from '@mui/material';

import theme from 'src/theme';

function LoadingIndicator({ text } : { text?: string }) {
  const { ready, t } = useTranslation();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Grid sx={styles.root}>
      <Box width={400}>
        <Typography
          align="center"
          variant="subtitle1"
        >
          {ready && t(text || 'LOADING_PAGE_TEXT')}
        </Typography>
        <LinearProgress />
      </Box>
    </Grid>
  );
}

export default LoadingIndicator;

const styles = {
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    height: '60vh',
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(3),
  }
}
