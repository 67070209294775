import { useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
import { UnknownAction } from 'redux';

import { Box, Grid, Backdrop, CircularProgress } from '@mui/material';

import { axiosHeaders, isActionPermitted, permissions, appendContactSupport, getLocalisedErrorString, axiosDeleteHeaders 
} from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import { fetchGlas } from 'src/dimensions/glas/actions';
import { fetchVatCodes } from 'src/dimensions/vatcodes/actions';
import { fetchCostCenters } from 'src/dimensions/costcenters/actions';
import TopDisplay from 'src/dimensions-new/components/TopDisplay';
import Search from 'src/dimensions-new/components/Search';
import ClientTable from 'src/dimensions-new/components/DimensionsTable';
import TablePagination from 'src/dimensions-new/components/TablePagination';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import AddClientModal from 'src/dimensions-new/clients/components/AddClientModal';
import { CustomError, SupplierInventoryType, SupplierType } from 'src/types';

type ClientType = {
  country: string;
  creditorCode: string;
  creditorName: string;
  inventories: SupplierInventoryType[];
};

const Clients = (props: { showImport: boolean, setShowImport: Dispatch<SetStateAction<boolean>> }) => {
  const { showImport, setShowImport } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [data, setData] = useState<ClientType[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const { ready, t } = useTranslation();
  const [resetSearch] = useState<boolean>(false);
  const [showAddClient, setShowAddClient] = useState<boolean>(false);
  const [clientId, setClientId] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const abortControllerRef = useRef<AbortController | null>(null);

  const { user } = useAuth();
  const { API } = useConfig();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const title = ready ? t('DASHBOARD_NAV_CLIENTS') : '';

  const handleDelete = async (creditorCode: string) => {
    const body = {
      creditorCode,
    }
    try {
      setLoading(true);
      const response = await Axios.delete(
        `${API.suppliers}`,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), body),
      );

      if (response.data.success) {
        getAllSuppliers(page);
        enqueueSnackbar(t('DIMENSION_DELETE_SUCCESS'), {
          variant: 'success',
          persist: false
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(t('DIMENSION_DELETE_FAILED'), {
        variant: 'error',
        persist: false
      });
    }
  };

  const handleCloseAddClient = () => {
    setShowAddClient(false);
    setIsDisabled(false);
    setIsEdit(false);
    setClientId('');
  };

  const handleShowAddClient = () => {
    setIsEdit(false);
    setClientId('');
    setIsDisabled(false);
    setShowAddClient(true);
  };

  const handleAddEditSuccess = (page: number) => {
    getAllSuppliers(page);
    setShowAddClient(false);
    setClientId('');
  };

  const handleExport = async (format: string, closeMenu: ()=>void) => {
    try {
      const response = await Axios.get(
        `${API.dimensions}suppliers/export/${format}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      const fileName = `${user?.company} Suppliers.${format}`;
      const blob = new Blob([response.data], {
        type: 'text/plain;charset=utf-8'
      });
      FileSaver.saveAs(blob, fileName);
      closeMenu();
    } catch (e) {
      const error = e as CustomError;
      closeMenu();
      enqueueSnackbar(
        appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(
            error?.response?.data?.i18n || 'DIMENSION_EXPORT_FAIL',
            t
          ),
          t
        ),
        {
          variant: 'error'
        }
      );
    }
  };

  const handleSearchChange = (str: string) => {
    setQuery(str);
    getAllSuppliers(0, limit, str, false, true);
  };
  const onLimitChange = (newLimit: number) => {
    getAllSuppliers(0, newLimit, query, false);
    setLimit(newLimit);
  };
  const onPageChange = (page: number) => {
    setPage(page);
    getAllSuppliers(page, limit, query, false);
  };
  const getAllGLAccounts = async () => {
    dispatch((fetchGlas(user?.companyID) as unknown) as UnknownAction);
  };

  const getAllVatGroups = () => {
    dispatch((fetchVatCodes(user?.companyID) as unknown) as UnknownAction);
  };

  const getAllCostCenters = async () => {
    dispatch((fetchCostCenters(user?.companyID) as unknown) as UnknownAction);
  };

  const getAllSuppliers = async (
    page = 0,
    limit = 10,
    search = query,
    loadFullScreen = true,
    loadSearch = false
  ) => {
    if (
      abortControllerRef &&
      abortControllerRef.current &&
      abortControllerRef.current.abort
    )
      abortControllerRef.current.abort('');
    abortControllerRef.current = new AbortController();
    if (loadFullScreen) {
      setLoading(true);
    }
    try {
      if (!loadSearch) setFetchLoading(true);
      const response = await Axios.get(
        `${API.findSuppliers}/${user?.companyID}?page=${page +
          1}&limit=${limit}&search=${search}`,
        {
          ...axiosHeaders(localStorage.getItem('PROCYS_accessToken')),
          signal: abortControllerRef?.current?.signal
        }
      );

      if (response.data.success) {
        if (response.data.data.length > 0) {
          setTotal(response.data.total);
          setData(response.data.data.slice(0, limit));
        } else {
          setData([]);
          setTotal(0);
        }
      }
      setLoading(false);
      setFetchLoading(false);
    } catch (error) {
      setLoading(false);
      setFetchLoading(false);
    }
  };

  const handleEditClient = (data: SupplierType) => {
    setClientId(data?.creditorCode);
    setIsEdit(true);
    setIsDisabled(false);
    setShowAddClient(true);
  };

  const handleView = (data: SupplierType) => {
    setClientId(data?.creditorCode);
    setIsEdit(true);
    setIsDisabled(true);
    setShowAddClient(true);
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionRead, user?.permissions)) {
      getAllSuppliers(page);
      getAllGLAccounts();
      getAllCostCenters();
      getAllVatGroups();
    }
  }, []);

  return (
    <>
      {!fetchLoading ? (
        <Grid sx={styles.root} className="x-scroll">
          <Box sx={styles.wrapper}>
            <TopDisplay
              title={title}
              showImport={showImport}
              setShowImport={setShowImport}
              type="clients"
              onExport={handleExport}
              onAdd={handleShowAddClient}
            />
            <Search onChange={handleSearchChange} reset={resetSearch} tempQuery={query} />
            <ClientTable
              data={[...data]}
              type="clients"
              onDelete={handleDelete}
              deleteText="DIMENSION_DELETE_CLIENT_BTN"
              onEdit={handleEditClient}
              onView={handleView}
            />
            <TablePagination
              total={total}
              limit={limit}
              page={page}
              onLimitChange={onLimitChange}
              onPageChange={onPageChange}
            />
          </Box>
        </Grid>
      ) : (
        <LoadingIndicator />
      )}
      {showAddClient ? 
        <AddClientModal
          showAddClient={showAddClient}
          handleClose={handleCloseAddClient}
          onSuccess={handleAddEditSuccess}
          clientId={clientId}
          isEdit={isEdit}
          getAllSuppliers={getAllSuppliers}
          isDisabled={isDisabled}
        />:<></>
      }
      <Backdrop style={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '20px'
  },
  wrapper: {
    backgroundColor: '#fff',
    minWidth: '500px'
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '5px 0',
    gap: '10px',
    marginBlock: '0 20px'
  },
  topLeft: {
    fontSize: '16px',
    color: '#4C4E64',
    fontWeight: '500',
    marginInlineEnd: 'auto'
  },
  btns: {
    gap: '8px',
    color: '#3E8AFF',
    textTransform: 'Capitalize',
    fontSize: '13px',
    paddingInline: '10px'
  },
  addBtn: {
    background: '#3E8AFF',
    color: '#fff',
    paddingInline: '15px',
    gap: '8px',
    textTransform: 'Capitalize',
    fontSize: '13px',
    '&:hover': {
      backgroundColor: '#3E8AFF',
      color: '#fff'
    }
  },
  menuContainer: {
    boxShadow: ' 0px 4px 8px -4px #4C4E646B',
    width: '200px'
  },
  exportIconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: '14px',
    color: '#4C4E64DE'
  },
  backdrop: {
    zIndex: 111111,
    color: '#ffffff'
  }
};
export default Clients;
