import theme from 'src/theme';

const styles = {
  container: {
    margin: '24px 0px'
  },
  titleText: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#4C4E64',
  },
  description: {
    fontSize: 12,
    fontWeight: 400,
    color: '#4C4E64DE',
    margin: '8px 0px',
    lineHeight:'2'
  },
  domainContainer: {
    padding: '0px',
  },
  domainRow: {
    padding: '0px',
    display: 'flex',
  },
  domainItem: {
    padding: '8px 16px',
  },
  columnLabel: {
    color: theme.palette.text.primary,
    fontSize: 14,
    margin: '8px 0px',
    fontWeight: 500,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '0px',
    marginBottom:'10px',
  },
  checkConnectionButton: {
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    minWidth: 108,
    textTransform: 'none',
    backgroundColor: '',
    color: '#3E8AFF',
    background:'#3E8AFF1A',
    paddingTop: 0,
    paddingBottom: 0,
    border: 'none',
    margin: '6px 0px 6px 0px',
    '&:hover':{
      color: '#3E8AFF',
      background:'#3E8AFF1A',
      boxShadow:' 0px 4px 8px -4px #4C4E646B',
      border:'none',
    }
  },
  tableHeadCell:{
    fontSize:'12px',
    borderImage: 'linear-gradient(transparent 25%, #4C4E641F 25% 75%, transparent 75%) 0 1 0 0/2px',
    backgroundColor:'#C2CFE033',
    color:'#4C4E64DE',
    borderBottom:'none'
  },
  tableBodyCell:{
    fontSize:'14px',
    borderImage: 'linear-gradient(transparent 25%, #4C4E641F 25% 75%, transparent 75%) 0 1 0 0/2px',
    backgroundColor:'transparent',
    color:'#4C4E64',
    height:'48px',
    padding:'10px 0 10px 20px!important',
    borderBottom:'none'
  }
};

export default styles;
