// import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import './i18n';
import './index.css';

if (window.self !== window.top) {
  document.body.innerHTML = '<h1>Embedding in iframes is not allowed.</h1>';
  throw new Error('Embedding in iframes is not allowed.');
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const app = (
  <Provider store={store}>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </Provider>
);

window.onload = () => {
  root.render(app);
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
