import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';

import {
  Grid, IconButton, Typography
} from '@mui/material';
import {
  FileCopyOutlined as CopyIcon, CheckCircleOutline as CheckIcon
} from '@mui/icons-material';

import CommonModal from 'src/shared/components/CommonModal/CommonModal';

interface PropTypes {
  isOpen: boolean;
  link: string;
  handleClose: () => void;
}

const BulkSignedDownloadLinkPopup = (props: PropTypes) => {
  const { t } = useTranslation();

  const { isOpen, link, handleClose } = props;

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    copy(link, {
      // @ts-ignore
      onCopy: setCopied(true)
    });
  };

  return (
    <CommonModal
      open={isOpen}
      title={t('BULK_DOWNLOAD_SIGNED_TITLE')}
      cancelText={t('DASHBOARD_INVOICES_CANCEL')}
      submitText={t('BULK_DOWNLOAD_SIGNED_GO_TO_LINK')}
      handleClose={handleClose}
      onSubmit={() => window.open(link, '_blank')}
      persist
    >
      <Typography variant="caption" sx={styles.primaryTextColor}>
        {t('BULK_DOWNLOAD_SIGNED_INSTRUCTIONS')}
      </Typography>
      <Grid sx={styles.detailRow}>
        <input
          type="text"
          value={link}
          style={styles.inputField}
          disabled
        />
        <IconButton onClick={copyToClipboard}>
          <CopyIcon />
        </IconButton>
        {copied && <CheckIcon sx={styles.checkIcon} />}
      </Grid>
    </CommonModal>
  );
};

export default BulkSignedDownloadLinkPopup;

const styles = {
  primaryTextColor: {
    color: '#4C4E64',
  },
  detailRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center' as const,
    padding: '8px 0px 16px 0px'
  },
  inputField: {
    fontSize: '14px',
    color: '#4C4E64',
    border: 0,
    backgroundColor: '#E9E9E9',
    padding: '8px',
    flex: 1,
    margin: '0px',
    borderRadius: '4px'
  },
  checkIcon: {
    color: '#4caf50'
  },
};
