export const sections = [
  {
    id: 'myprofile',
    title: 'ACCOUNT_SETTINGS_MY_PROFILE'
  },
  {
    id: 'users',
    title: 'ACCOUNT_SETTINGS_USERS'
  },
  {
    id: 'plannbilling',
    title: 'ACCOUNT_SETTINGS_PLAN_N_BILLING'
  },
  {
    id: 'projectsnworkflows',
    title: 'ACCOUNT_SETTINGS_PROJECTS_N_WORKFLOWS'
  },
  {
    id: 'system',
    title: 'ACCOUNT_SETTINGS_SYSTEM_SETTINGS'
  },
  {
    id: 'security',
    title: 'ACCOUNT_SETTINGS_SECURITY'
  },
  {
    id: 'companies',
    title: 'ACCOUNT_SETTINGS_COMPANIES'
  },
  {
    id: 'brand',
    title: 'ACCOUNT_SETTINGS_BRAND_SETTINGS'
  },
  {
    id: 'partnerstats',
    title: 'ACCOUNT_SETTINGS_PARTNER_STATS'
  },
  {
    id: 'hacienda',
    title: 'ACCOUNT_SETTINGS_HACIENDA',
  },
];

export const subCategories: Record<string, Record<string, any>> = {
  free: {
    level: 0,
    value: 4,
    label: 'Free',
    name: 'Free Plan',
    i18n: 'MY_PLAN_FREE',
    invoices: 20,
    users: 1,
    apiAccess: false,
    deliverViaEmail: true,
    deliverViaIntegrations: false,
    supportQueries: false,
    customeModalTraining: false
  },
  starter: {
    level: 1,
    value: 29,
    label: 'Starter',
    name: 'Starter Plan',
    i18n: 'MY_PLAN_STARTER',
    invoices: 50,
    users: 3,
    price: 999,
    ecLimit: 10,
    ecPrice: 350,
    apiAccess: true,
    deliverViaEmail: true,
    deliverViaIntegrations: true,
    supportQueries: false,
    customeModalTraining: false
  },
  business: {
    level: 2,
    value: 57,
    label: 'Business',
    name: 'Business Plan',
    i18n: 'MY_PLAN_BUSINESS',
    invoices: 200,
    users: 10,
    price: 3499,
    ecLimit: 25,
    ecPrice: 750,
    apiAccess: true,
    deliverViaEmail: true,
    deliverViaIntegrations: true,
    supportQueries: true,
    customeModalTraining: false
  },
  enterprise: {
    level: 3,
    value: 86,
    label: 'Enterprise',
    name: 'Enterprise Plan',
    i18n: 'MY_PLAN_ENTERPRISE',
    invoices: '>200',
    users: '>10',
    price: 0,
    ecLimit: 0,
    ecPrice: 0,
    apiAccess: true,
    deliverViaEmail: true,
    deliverViaIntegrations: true,
    supportQueries: true,
    customeModalTraining: true
  }
};