import { useTranslation } from 'react-i18next';

import { Grid, Table, TableBody, TableHead, TableRow, TableCell, Tooltip, Typography } from '@mui/material';
import { getDocumentType } from 'src/utils/helpers';

import styles from './style';

type DataType = Record<string, string | number | boolean>;
type PropType= {
  data: DataType[],
}

const DimensionsTable = (props: PropType) => {

  const { ready, t} = useTranslation();
  const {data} = props;

  return (
    <>
      <Grid sx={styles.tableWrapper} className="xy-scroll">
        <Table sx={styles.table}>
          <TableHead>
            <TableRow sx={styles.tableHeadRow}>
              <TableCell sx={{...styles.tableHeadRowDisplay,...styles.bLeftTop}}>
                {ready && t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_NAME')}
              </TableCell>
              <TableCell sx={styles.tableHeadRowDisplay}>
                {ready && t('ACCOUNT_SETTINGS_COMPANIES_COMPANY_TYPE')}
              </TableCell>
              <TableCell sx={styles.tableHeadRowDisplay}>
                {ready && t('ACCOUNT_SETTINGS_COMPANIES_CONNECTED_TO')}
              </TableCell>
              <TableCell sx={styles.tableHeadRowDisplay}>
                {ready && t('ACCOUNT_SETTINGS_COMPANIES_DOC_TYPE')}
              </TableCell>
              <TableCell sx={styles.tableHeadRowDisplay}>
                {ready && t('ACCOUNT_SETTINGS_COMPANIES_INVOICE_LIMIT')}
              </TableCell>
              <TableCell sx={styles.tableHeadRowDisplay}>
                {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_PAGES_PROCESSED')}
              </TableCell>
              <TableCell sx={{...styles.tableHeadRowDisplay,borderImage:'none',...styles.bRightTop}}>
                {ready && t('ACCOUNT_SETTINGS_PARTNER_STATS_USERS')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data && data?.length > 0 
                ? data.map((eData:DataType,index:number) =>
                    <TableRow key={index} sx={styles.tableBodyRow}>
                      <TableCell sx={styles.tableRowDisplay}>
                        <Tooltip title={eData.name}>
                          <Typography sx={styles.text}>
                            {eData.name}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={styles.tableRowDisplay}>
                          <Typography sx={styles.highlightLabel}>
                            { eData.isSubCompany ? 'Subsidiary' : 'Main' }
                          </Typography>
                      </TableCell>
                      <TableCell sx={styles.tableRowDisplay}>
                        <Tooltip title={eData.parentName}>
                          <Typography sx={styles.text}>
                            { 
                              eData.parentName 
                                ? eData.parentName 
                                : '-' 
                            }
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={styles.tableRowDisplay}>
                        <Tooltip title={ t(getDocumentType(eData.documentType)) }>
                          <Typography sx={styles.text}>
                            { t(getDocumentType(eData.documentType)) }
                          </Typography>
                        </Tooltip >
                      </TableCell>
                      <TableCell sx={styles.tableRowDisplay}>
                        { eData?.documentsUsed || '-' }
                      </TableCell>
                      <TableCell style={styles.tableRowDisplay}>
                        { eData?.pagesProcessed || '-' }                       
                      </TableCell>           
                      <TableCell style={{...styles.tableRowDisplay, borderImage:'none'}}>
                        { eData?.usersUsed || '-' } 
                      </TableCell>   
                    </TableRow> 
                  )
                : <></>
            }
          </TableBody>
        </Table>
      </Grid>

    </>
  )
}


export default DimensionsTable