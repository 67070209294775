import { useTranslation } from 'react-i18next'

import { Box, Button, Typography, SvgIcon, Tooltip } from '@mui/material';
import { Add as PlusCircleIcon } from '@mui/icons-material';

import { permissions, isActionPermitted } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import theme from "src/theme";

type PropsType = {
  onAddUser: () => void;
}

const TopDisplay = (props: PropsType) => {
  const { onAddUser } = props
  const { ready, t } = useTranslation();
  const { user } = useAuth();

  return (
    <Box sx={styles.topDisplay}>
      <Typography sx={styles.title}>
        {ready && t('ACCOUNT_SETTINGS_USERS')}
      </Typography>
      <Tooltip
        title={
          user?.usersCount && user.usersCount >= user?.maxUsers
            ? t('ACCOUNT_SETTINGS_USERS_ADD_LIMIT')
            : isActionPermitted(permissions.userAdd, user?.permissions)
              ? t('ACCOUNT_SETTINGS_USERS_ADD_TOOLTIP')
              : t('ACCOUNT_SETTINGS_USERS_ADD_TOOLTIP_PERMISSION')
        }
      >
        <span>
          <Button
            variant="contained"
            sx={styles.action}
            onClick={onAddUser}
            disabled={user?.usersCount && user.usersCount >= user?.maxUsers || !isActionPermitted(permissions.userAdd, user?.permissions)}
          >
            <SvgIcon
              fontSize="small"
              sx={styles.actionIcon}
            >
              <PlusCircleIcon />
            </SvgIcon>
            {ready && t('ACCOUNT_SETTINGS_USERS_ADD_USER')}
          </Button>
        </span>
      </Tooltip>
    </Box>
  )
}

const styles = {
  topDisplay:{
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  title:{
    fontSize: '20px',
    color: '#4C4E64',
    fontWeight: '500'
  },
  action: {
    fontSize: 14,
    fontWeight: 500,
    boxShadow: '0px 4px 8px -4px #4C4E646B',
    backgroundColor: '#3E8AFF',
    color: '#fff',
    textTransform: 'none',
    '&:hover':{
      backgroundColor: '#3E8AFF',
      color: '#fff',
    }
  },
  actionIcon: {
    color: 'rgba(255, 255, 255, 1)',
    fontWeight:500,
    marginRight: theme.spacing(1)
  },
};


export default TopDisplay